import React from "react";

export const InfoIcon = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 30}
      height={height ? height : 30}
      fill="none"
      viewBox="0 0 30 30"
    >
      <g clipPath="url(#clip0_1507_7069)">
        <circle cx="15.5" cy="15" r="13.5" fill="#3571FF"></circle>
        <circle cx="15.5" cy="15" r="12.5" fill="#3571FF"></circle>
        <path
          fill="#F6F8FC"
          d="M14.317 21.14c0 .308.116.603.321.82.205.217.484.34.774.34.29 0 .568-.123.774-.34a1.218 1.218 0 00.237-1.263 1.164 1.164 0 00-.237-.376 1.091 1.091 0 00-.355-.25 1.043 1.043 0 00-1.193.25 1.164 1.164 0 00-.237.376c-.055.14-.084.292-.084.444zm3.023-4.373c1.54-.898 2.322-2.2 2.322-3.868 0-2.865-2.135-4.149-4.25-4.149-2.06 0-4.25 1.585-4.25 4.524 0 .298.112.584.311.795.2.211.47.33.751.33.282 0 .552-.119.752-.33.199-.21.31-.497.31-.795 0-1.563 1.103-2.274 2.126-2.274.793 0 2.125.246 2.125 1.899 0 .586-.146 1.27-1.221 1.898-.731.424-1.967 1.525-1.967 2.953 0 .298.112.584.312.796.199.21.469.329.75.329.283 0 .553-.119.752-.33.2-.21.311-.497.311-.795 0-.21.42-.723.866-.983z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1507_7069">
          <path fill="#fff" d="M0 0H30V30H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
