import React from "react";

export const SubjectIcon = () => {
    return (

        <svg width="22" height="25" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="1">
                <path d="M5.89116 5.67094C5.8947 5.71495 5.89914 5.7587 5.90357 5.80245C6.22935 9.13065 8.38738 11.7026 11.0016 11.7026C13.6154 11.7026 15.7738 9.13065 16.0995 5.80245C16.1043 5.7587 16.1078 5.71495 16.112 5.67094C16.1353 5.52663 16.1507 5.38045 16.1601 5.23187C16.1664 5.13051 16.1708 5.02888 16.1708 4.92591C16.1708 4.67277 16.1507 4.42389 16.112 4.18089C15.7342 1.82257 13.59 0.015625 11.0016 0.015625C8.4131 0.015625 6.26896 1.82257 5.89116 4.18089C5.85213 4.42389 5.83203 4.67277 5.83203 4.92591C5.83203 5.02888 5.83647 5.13078 5.84238 5.23187C5.85243 5.38045 5.8678 5.52663 5.89116 5.67094ZM7.81269 3.02347C7.81269 3.02347 9.30056 3.67513 11.0016 3.71728C12.7026 3.6754 14.1901 3.02347 14.1901 3.02347C15.2683 3.40705 14.7574 6.14308 14.7574 6.14308C15.0267 5.9737 15.2736 5.8854 15.4746 5.84006C15.1778 8.7476 13.2888 10.9894 11.0016 10.9894C8.71434 10.9894 6.82473 8.7476 6.52792 5.84006C6.72895 5.8854 6.97638 5.9737 7.2451 6.14308C7.2451 6.14308 6.73456 3.40732 7.81269 3.02347Z" fill="white" />
                <path d="M15.1012 11.1738C15.0743 12.2851 13.2485 13.1822 11.0006 13.1822C9.45571 13.1822 8.11122 12.7588 7.41178 12.1336L9.82257 17.1892L10.4771 18.5622H12.6493C13.7206 18.5622 14.5895 19.3462 14.5895 20.3129V20.8914C14.5895 21.8587 13.7206 22.6424 12.6493 22.6424H4.28027C3.20864 22.6424 2.33981 21.8587 2.33981 20.8914V20.3131C2.33981 19.5364 2.90119 18.8783 3.67749 18.6497L3.84393 17.217L4.47242 11.8036C2.27329 12.8093 0.765625 14.8672 0.765625 17.2439C0.765625 17.9623 0.904271 18.5265 1.15732 18.9703C0.940928 19.3774 0.818245 19.8325 0.818245 20.3134V20.892C0.818245 22.6144 2.37144 24.0156 4.28056 24.0156H12.6496C14.5587 24.0156 16.1113 22.6144 16.1113 20.8917V20.5913C19.0539 20.5281 21.2356 20.1029 21.2356 17.2437C21.2353 14.0667 18.5416 11.4574 15.1012 11.1738Z" fill="white" />
                <path d="M6.63698 21.256H7.73847V22.2499C7.73847 22.351 7.81858 22.4331 7.91909 22.4331H9.00639C9.10542 22.4331 9.18701 22.351 9.18701 22.2499V21.256H10.2885C10.4005 21.256 10.4913 21.1831 10.4913 21.0927V20.1119C10.4913 20.0215 10.4005 19.9486 10.2885 19.9486H9.18701V18.9547C9.18701 18.8536 9.10572 18.7715 9.00639 18.7715H7.91909C7.81888 18.7715 7.73847 18.8536 7.73847 18.9547V19.9486H6.63698C6.52494 19.9486 6.43359 20.0215 6.43359 20.1119V21.0927C6.43359 21.1831 6.52494 21.256 6.63698 21.256Z" fill="white" />
            </g>
        </svg>

    );
}
