import { useEffect, useState } from 'react';
import { CustomScrollDiv } from '../../components/custom-scroll-div/CustomScrollDiv';
import { useSocketInstance } from '../../context/SocketContext';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import { BackNavIcon, NotifiactionBell } from '../../assets/common-svg/CommonSvg';
import NotifiactionApi from '../../services/notofiactionService';
import { CustomSnackbar } from '../../components/snackbar/CustomSnackbar';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from '../../store/reducers/snackBar';
import NoDataCard from '../../components/no-data-card';
import SkeletonLoader from '../../utilites/SkeltonLoader';
import { SpinnerLoader } from '../../assets/loader/loader';
import InfiniteScroll from 'react-infinite-scroll-component';
let notifiactionFilter = {
  page: 1,
  limit: 10,
  maxPages: null,
};
const Notifications = () => {
  const dispatch = useDispatch()
  const loginID = useSelector((state) => state.loginData.loginId);
  const snackbar = useSelector((state) => state.snackBar);
  const { socketInstance, setNotifiactionCount } = useSocketInstance();
  const [socketData, setSocketData] = useState([]);
  const [loading, setLoading] = useState(false)
  const [spinner, setSpinner] = useState(false);
  const navigate = useNavigate();
  const role_id = useSelector((state) => state.loginData.role_id);
 
  const handleReceiveMessage = (data) => {
    setNotifiactionCount(data)
  };
  useEffect(() => {
    if (!socketInstance?.connected) return
    emitMessage()
  }, [socketInstance]);


  const emitMessage = () => {
    socketInstance.on("receive_notification", handleReceiveMessage);
  };

  const emitNotifiactionCount = () => {
    socketInstance.emit("count_notifiaction", { userId: loginID });
  };

  const getNotifiaction = () => {
    notifiactionFilter.page === 1 && setLoading(true);
    NotifiactionApi.getNotifiactionList(
      notifiactionFilter.limit,
      notifiactionFilter.page
    ).then(res => {
      if (res?.success == false) {
        setSocketData([]);
      } else {
        notifiactionFilter.maxPages = Math.ceil(
          res.page_details.no_of_records / notifiactionFilter.limit
        );
        res.page_details.page > 1
          ? setSocketData((prev) => [...prev, ...res.data])
          : setSocketData(res.data);
      }
      setLoading(false)
      setSpinner(false)
    }).catch(err => {
      console.log(err);
      setLoading(false)
      setSpinner(false)
    })
  }


  useEffect(() => {
    ResetFilter();
    getNotifiaction();
    PageIncrement()
  }, []);




  const readNotifiaction = (id) => {
    setLoading(true)
    NotifiactionApi.updateNotifiaction(id).then(() => {
      emitNotifiactionCount()
      getNotifiaction()
    }).catch(err => {
      dispatch(
        setSnackbar({ value: true, message: err, color: "error" })
      );
      setLoading(false)
    })
  }

  const PageIncrement = () => {
    notifiactionFilter.page++;
  };
  const ResetFilter = () => {
    notifiactionFilter.page = 1;
  };

  const fetchData = () => {
    if (notifiactionFilter?.page <= notifiactionFilter?.maxPages) {
      getNotifiaction();
      PageIncrement();
    }
  }

  const handleNavigate = () => {
    navigate('/');
  }

  return (
    <div className='flex flex-col gap-4 mt-4 ml-4 mr-4'>
       {role_id === 5 && <div className="cursor-pointer flex items-center mb-0 gap-1 w-[fit-content]"  onClick={handleNavigate}>
          <div className="block p-2 !pl-0 w-max">
            <BackNavIcon width={18} height={18} />
          </div>
          <span className="!m-0">Go Back</span>
        </div>}
      <div className='overflow-auto scroll-smooth bg-white pl-[15px]'>
        {loading
          ? <SkeletonLoader rowCount={5} isTable={false} />
          : socketData && socketData?.length == 0
            ? <div className="mt-4">
              <NoDataCard />
            </div>
            : <InfiniteScroll
              className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 gap-4"
              dataLength={socketData?.length}
              next={fetchData}
              hasMore={notifiactionFilter?.page <= notifiactionFilter?.maxPages}
              loader={
                spinner && (
                  <div className="flex justify-center">
                    {" "}
                    <SpinnerLoader />
                  </div>
                )
              }
              scrollThreshold={0.9} // Adjust this value as needed
            >
              {socketData?.map((notify, index) => (
                <Link to={notify?.redirect_url} key={index} className='flex gap-4 border-b border-[#d5d5d5] ' onClick={() => readNotifiaction(notify?.id)}>
                  {/* <div key={index} className='flex gap-4 border-b border-[#d5d5d5] ' > */}
                  <span className='align-self-center'> <NotifiactionBell /></span>
                  <CustomScrollDiv heading={notify?.message} subHeading={moment(notify?.created_at).utc().format("DD/MM/YYYY hh:mm a")} />
                  {/* </div> */}
                </Link>
              ))}
            </InfiniteScroll>}
      </div>
      <CustomSnackbar
        open={snackbar?.value}
        setOpen={dispatch(setSnackbar)}
        color={snackbar?.color}
        message={snackbar?.message}
      />
    </div>
  );
};

export default Notifications;
