    import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Slide from '@mui/material/Slide';
import PropTypes from 'prop-types';
import { CloseIcon } from '../../assets/common-svg/CommonSvg';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: 300, sm: 400, md: 500, lg: 400 },
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: "10px",
    py: "15px"
}

export default function CustomModal({ children, editHospitalBoolean, open, setOpen, ModalTitle, SetValue }) {

    const handleClose = () => {
        setOpen(false);
        if (SetValue) {
            SetValue("");
        }

    }

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open||false}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    {editHospitalBoolean ? 
                    <Slide direction='left' in={open} mountOnEnter unmountOnExit >
                     <div className='relative h-full w-full'>
                            <Box sx={style} className="relative" >

                                {ModalTitle &&
                                    <div className='pb-[10px] border-b border-[#DDDDDD]'>
                                        <Typography id="transition-modal-title" variant="h6" textAlign={'center'} component="h6">
                                            {ModalTitle}
                                        </Typography>
                                    </div>}
                                {children}
                                <div onClick={handleClose} className=" absolute right-[-30px] top-[-30px] cursor-pointer">
                                    <Tooltip title="close"><CloseIcon /></Tooltip>
                                </div>

                            </Box>
                        </div>
                    </Slide>
                        
                        :
                        <div className='relative h-full w-full'>
                            <Box sx={style} className="relative" >

                                {ModalTitle &&
                                    <div className='pb-[15px] border-b border-[#DDDDDD]'>
                                        <Typography id="transition-modal-title" variant="h6" textAlign={'center'} component="h6">
                                            {ModalTitle}
                                        </Typography>
                                    </div>}
                            
                                    {children}
                            
                                <div onClick={handleClose} className=" absolute right-[-27px] top-[-17px] cursor-pointer">
                                    <Tooltip title="close"><div><CloseIcon /></div></Tooltip>
                                </div>
                            </Box>
                        </div>}
                </Fade>
            </Modal>
        </div>
    );
}

CustomModal.proptypes = {
    children: PropTypes.node,
    editHospitalBoolean: PropTypes.bool,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    ModalTitle: PropTypes.string,
    SetValue: PropTypes.func
}
