import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Button, CardActions, CardHeader, IconButton } from '@mui/material';
import { DeleteIcon, EditIcon, NextIcon } from '../../assets/common-svg/CommonSvg';
import { TaskManagementHeader } from '../../components/task-management-header/TaskManagementHeader';
import { useNavigate } from 'react-router';
import RolesApi from '../../services/rolesManagemant';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from '../../store/reducers/snackBar';
import { CustomSnackbar } from '../../components/snackbar/CustomSnackbar';
import { setRoles } from '../../store/reducers/roles-management';
import SkeletonLoader from '../../utilites/SkeltonLoader';
import CustomMenuBar from '../../components/custom-menubar/CustomMenuBar';
import DeleteConfirmContent from '../../components/modal-contents/DeleteConfirmModalContent';
import NoDataCard from '../../components/no-data-card';
import { getUserHasAccess } from '../../utils/authorities';

export default function Roles() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const LoginData = useSelector((state) => state.loginData);
  const userAuthority = LoginData?.auth || [];
  const [searchData, setSearchData] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [rowId, setRowId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const snackbar = useSelector((state) => state.snackBar);
  const rolesMange = useSelector((state) => state.rolesManagement.roles);

  const getRoles = () => {
    setLoading(true);
    RolesApi.getAllRoles(null, null, searchData)
      .then((res) => {
        if (res.status == 200) {
          dispatch(
            setRoles({
              roles: res?.data.map((op) => {
                return {
                  ...op,
                  predefined_role: op?.predefined_role,
                  options: op?.predefined_role
                    ? [
                      {
                        id:"edit",
                        optionName: "Edit",
                        optionIcon: <EditIcon />,
                        auth: { module: "rolesManagement", auth: 97 }
                      },
                    ]
                    : [
                      {
                        id:"edit",
                        optionName: "Edit",
                        optionIcon: <EditIcon />,
                        auth: { module: "rolesManagement", auth: 97 }
                      },
                      {
                        id: "delete",
                        optionName: "Delete",
                        optionIcon: <DeleteIcon />,
                        auth: { module: "rolesManagement", auth: 99 }
                      },
                    ],
                };
              }),
            })
          );
          setLoading(false);
        } else if (res.status == 204) {
          dispatch(setRoles({ roles: [] }));
          setLoading(false);
        }
      })
      .catch((err) => {
        const errorMessage = err.message || "An error occurred"; // Extracting the message from the error object
        dispatch(
          setSnackbar({ value: true, message: errorMessage, color: "error" })
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    getRoles();
  }, [searchData]);

  const handleButtonClick = () => {
    navigate(`add-modules-management`, {
      state: [
        { name: "Permission", path: location.pathname },
        {
          name: "Add Role",
          path: `${location.pathname}/modules-management`,
        },
      ],
    });
  };

  const handleSearch = (value) => {
    setSearchData(value);
  };
  const handleEdit = (id) => {
    navigate(`edit-modules-management/${id}`, {
      state: [
        { name: "Permission", path: location.pathname },
        {
          name: "Edit Role",
          path: `${location.path}/edit-modules-management/${id}`,
        },
      ],
    });
  };
  const handleDelete = () => {
    setSubmitLoading(true);
    setDeleteModal(false)
    setLoading(true);
    RolesApi.deleteRole(rowId)
      .then((res) => {
        setSubmitLoading(false);
        dispatch(setSnackbar({ value: true, message: res?.message, color: "success" }));
        getRoles();
      })
      .catch((err) => {
        dispatch(setSnackbar({ value: true, message: err, color: "error" }));
        setLoading(false);
        setSubmitLoading(false);
      });
  };
  return (
    <div className="p-3">
      <div className="pb-3">
        <TaskManagementHeader
          ButtonAuth={{ module: "rolesManagement", auth: 96 }}
          value={searchData}
          handleSearch={handleSearch}
          SearchbarPlaceholder="Search by Name"
          width={"100%"}
          ButtonText="Add Role"
          handleButtonClick={handleButtonClick}
        />
      </div>
      {rolesMange?.length == 0 ? (
        <div className="mt-4">
          <NoDataCard />
        </div>
      ) : (
        <div className="grid lg:grid-cols-3 sm:grid-cols-1 gap-3">
          {loading ? (
            <SkeletonLoader
              rowCount={3}
              isTable={false}
              width={"100%"}
              height={145}
            />
          ) : (
            rolesMange?.map((card, index) => (
              <Card className="rounded-xl  w-100" key={index}>
                <CardHeader
                  action={
                    <IconButton aria-label="settings">
                      <CustomMenuBar
                        handleEdit={handleEdit}
                        handleDelete={() => {
                          setDeleteModal(true), setRowId(card?.id);
                        }}
                        cardId={card?.id}
                        options={card?.options}
                      />
                    </IconButton>
                  }
                  title={card?.title}
                />
                <CardContent>
                  <Typography variant="body1">{card?.description}</Typography>
                </CardContent>
                <hr className="ml-3 mr-3" />
                <CardActions className="p-3">
                  {getUserHasAccess(userAuthority, "rolesManagement", 98) &&
                    <Button
                      color="primary"
                      variant="outlined"
                      endIcon={<NextIcon />}
                      onClick={() =>
                        navigate(`role-details/${card?.id}`, {
                          state: [
                            {
                              name: "Permission",
                              path: location.pathname,
                            },
                            {
                              name: "Role Details",
                              path: `${location.pathname}/role-details/${card?.id}`,
                            },
                          ],
                        })
                      }
                      size="small"
                    >
                      Details
                    </Button>}
                </CardActions>
              </Card>
            ))
          )}
        </div>
      )}
      <CustomSnackbar
        open={snackbar?.value}
        setOpen={dispatch(setSnackbar)}
        color={snackbar?.color}
        message={snackbar?.message}
      />
      <DeleteConfirmContent
        open={deleteModal}
        setOpen={setDeleteModal}
        handleDelete={handleDelete}
        isLoading={submitLoading}
      />
    </div>
  );
}

