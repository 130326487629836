import { useEffect, useRef, useState } from "react";
import FormGenerator from "../../components/form-template/FormGenerator";
import BreadCrumb from "../breadcrumb/BreadCrumb";
import FormTemlateApi from "../../services/form-template";
import SkeletonLoader from "../../utilites/SkeltonLoader";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../store/reducers/snackBar";
import { CustomSnackbar } from "../../components/snackbar/CustomSnackbar";
import { useParams, useLocation } from "react-router";
import { FormBreadCrumbGetter } from "../../utilites/Common";
import { Button, Typography } from "@mui/material";
import { useReactToPrint } from "react-to-print";
import NoDataCard from "../../components/no-data-card";
import Logo from "../../assets/main-logo/header-secondary-logo.png"

export const FormTemplateDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();
  const rowId = id;
  const componentRef = useRef();
  const snackbar = useSelector((state) => state.snackBar);
  const phaseDetails = useSelector((state) => state?.phaseReducer?.phaseDetail)
  const [formData, setFormData] = useState([]);
  const [templateData, setTemplateData] = useState({});
  const [breadCrumbArray] = useState(FormBreadCrumbGetter(location.pathname, phaseDetails));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.querySelectorAll(".row").forEach((row) => {
      Array.from(row.children).forEach((child) => {
        const classList = Array.from(child.classList);
        classList.forEach((cls) => {
          if (cls.startsWith("col-md-")) {
            const colMdClass = cls.replace("col-md-", "col-sm-");
            const colSmClass = cls; // keep the col-sm-* class as is
            child.classList.add(colMdClass);
            child.classList.add(colSmClass);
          }
        });
      });
    });
  }, [formData]);

  useEffect(() => {
    setLoading(true);
    FormTemlateApi.getFormTemplateDetails(rowId)
      .then((res) => {
        if (res?.status === 200) {
          res = res?.data;
          setTemplateData(res)
          setFormData(res?.questions);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        dispatch(setSnackbar({ value: true, message: error, color: "error" }));
      });
  }, [rowId]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <div
      className="p-3 border-1 rounded-lg"
      style={{ fontFamily: "Arial, sans-serif" }}
    >
      <div className="pb-2 flex justify-content-between">
        <BreadCrumb BreadCrumbsListing={breadCrumbArray} />
        <Button onClick={() => handlePrint()}>Print</Button>
      </div>
      <div className="bg-white border-1 rounded-lg p-3" ref={componentRef}>
        <div className="mb-4 text-center print-only">
          <span className="flex justify-content-center"><img src={Logo} width={150} height={150} /></span>
          <Typography variant="h6">{templateData?.template_type?.name} ( {templateData?.title} )</Typography>
        </div>
        {loading ? (
          <SkeletonLoader
            rowCount={3}
            isTable={false}
            width={"100%"}
            height={145}
          />
        ) : formData?.length != 0 ? (
          <FormGenerator formData={formData} />
        ) : (
          <div className="mt-4">
            <NoDataCard />
          </div>
        )}
      </div>
      <CustomSnackbar
        open={snackbar?.value}
        setOpen={dispatch(setSnackbar)}
        color={snackbar?.color}
        message={snackbar?.message}
      />
    </div>
  );
};
