
// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    title: '',
    description: '',
    modules: [],
    roles: [],
    rolesCardData: [
        {
            title: "All Permission", toggle: false,
            subCard: []
        }
    ]
};

// ==============================|| SLICE - MENU ||============================== //

const rolesManagement = createSlice({
    name: 'rolesManagement',
    initialState,
    reducers: {
        setRolesValues(state, action) {
            state.title = action.payload.title ?? state.title;
            state.description = action.payload.description ?? state.description;
        },
        setModulesData(state, action) {
            state.modules = [...action.payload.modules]
        },
        setRolesCardValues(state, action) {
            state.rolesCardData = [...action.payload.rolesCardData]
        },
        setRoles(state, action) {
            state.roles = [...action.payload.roles]
        },
        clearRolesValues(state) {
            state.title = '';
            state.description = '';
            state.modules = [];
            state.rolesCardData = [
                {
                    title: "All Permission", toggle: false,
                    subCard: []
                }
            ];
        },
    }
});

export default rolesManagement.reducer;

export const { setRolesValues, setRoles, clearRolesValues, setModulesData, setRolesCardValues, clearEditRoleId, setEditRoleId } = rolesManagement.actions;
