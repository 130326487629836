import React from "react";

export const SendIcon = ({ width, height }) => {
  return (
    <svg
      width={width ? width : "24"}
      height={height ? height : "22"}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.42539 17.3995L18.8754 9.91953C19.6854 9.56953 19.6854 8.42953 18.8754 8.07953L1.42539 0.599529C0.765391 0.309529 0.0353906 0.799529 0.0353906 1.50953L0.0253906 6.11953C0.0253906 6.61953 0.395391 7.04953 0.895391 7.10953L15.0254 8.99953L0.895391 10.8795C0.395391 10.9495 0.0253906 11.3795 0.0253906 11.8795L0.0353906 16.4895C0.0353906 17.1995 0.765391 17.6895 1.42539 17.3995Z"
        fill="#3571FF"
      />
    </svg>
  );
};
