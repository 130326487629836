import React from 'react';
import { Button, Chip, Typography } from "@mui/material";
import { DeleteIcon, EditIcon } from "../../../assets/common-svg/CommonSvg";
import moment from "moment";
import { useSelector } from 'react-redux';
import { getUserHasAccess } from '../../../utils/authorities';

export const CustomProductDetailCard = ({ handleDelete, handleEdit, data, editDeleteBtn }) => {
  const LoginData = useSelector((state) => state.loginData);
  const userAuthority = LoginData?.auth || [];
  const { phase_code, title, phase_type_details, phase_status_details, phase_start_date } = data;
  const formattedDate = phase_start_date ? moment(phase_start_date).format('DD/MM/YYYY') : "";
  const statusColor = phase_status_details?.toLowerCase() === "pending to start"
    ? "error"
    : phase_status_details?.toLowerCase() === "enrollment pending"
      ? "warning"
      : "success";
  return (
    <div className="flex flex-col gap-1">
      <span className="text-[0.7rem] text-gray-400">{phase_code}</span>
      <Typography variant="h5">{title === 'undefined undefined' ? "" : title}</Typography>
      <Typography variant="body2">Phase Started: {formattedDate}</Typography>
      <div className="flex gap-2">
        <Chip size="small" color="success" label={phase_type_details} />
        <Chip size="small" color={statusColor} label={phase_status_details} />
      </div>
      {editDeleteBtn ? <div className="flex gap-2 mt-2">
        {getUserHasAccess(userAuthority, "trials", 11) &&
          <Button
            variant="contained"
            onClick={handleEdit}
            className="bg-[#333B4A80]"
            startIcon={<EditIcon filled="#ffffff" />}
          >
            Edit
          </Button>}
        {getUserHasAccess(userAuthority, "trials", 12) &&
          <Button
            variant="contained"
            onClick={handleDelete}
            className="bg-[#333B4A80]"
            startIcon={<DeleteIcon filled="#ffffff" />}
          >
            Delete
          </Button>}
      </div> : null}
    </div>
  );
};

export default CustomProductDetailCard;
