import React from 'react';
// project import
import Loadable from '../components/Loadable';
import MainLayout from '../layout/MainLayout';
import { PrinciPleInvestigatorPage } from '../pages/principle-investigator-page/PrincipleInvestigator';
import { HospitalManagement } from '../pages/hospital-managment-page/HospitalManagment';
import { FormTemplateListing } from '../pages/form-template-listing-page/FormTemplateListing';
import { TrailManagement } from "../pages/trial-management/TrialManagement"
import Notifications from '../pages/notifications/Notifications';
import { ImmunoQA } from '../pages/Immuno-qa-page/ImmunoQA';
import { EditTrialDetails } from '../pages/edit-trial-details-page/EditTrialDetails';
import { CreateFormTemplate } from '../pages/form-template/create-form-template/CreateFormTemplate';
import Message from '../pages/message/Message';
import { PrincipleInvestigatorDetails } from '../pages/principle-investigator-details/PrincipleInvestigatorDetails';
import { AppointmentDetails } from '../pages/appointment-details/AppointmentDetails';
import { ProductDetails } from '../pages/product-details-page/ProductDetails';
import { DiseaseManagementPage } from '../pages/disease-management/Diseasemanagement';
import { EditorManagementPage } from '../pages/editor-management/EditorManagement';
import PrivateRoute from './PrivateRoute';
import { AccessDenied } from '../pages/access-denied/AccessDenied';
import { EditorPage } from '../pages/editor-table-listing/Editor';
import { SubjectPage } from '../pages/subject-page/Subject';
import { PreviewFormTemplate } from '../pages/form-template/preview-form-template/PreviewFormTemplate';
import { EditorManagementDetails } from '../pages/editor-management-details/EditorManagementDetails';
import { TrialsListing } from '../pages/trias-listing/TrialsListing';
import Editor from '../pages/editor';
import { EditorDetails } from '../pages/editor/editor-details';
import { FormTemplateDetails } from '../pages/form-template-details/FormTemplateDetails';
import { EditorListingDetails } from '../pages/editor-table-details/editor-details';
import { AuthGurad } from './AuthGurad';
import Roles from '../pages/roles-management/Roles';
import RolesModules from '../pages/roles-management/RolesModules';
import RolesDetails from '../pages/roles-management/RolesDetails';
import { UserManagement } from '../pages/user-management/UserManagement';
import { QualityAssociate } from '../pages/quality-associate/QualityAssociate';
import { ClinicalPhaseManager } from '../pages/clinical-phase-manager/ClinicalPhaseManager';
import { UserManagementDetails } from '../pages/user-management-detail/UserManagementDetails';
import { TrailsDetails } from '../pages/trails-details/TrailsDetails';
import { AuditHistory } from '../pages/audit-history/AuditHistory';

// render - dashboard
const DashboardDefault = Loadable(React.lazy(() => import('../pages/dashboard')));
const ProductPage = Loadable(React.lazy(() => import('../pages/product-page/ProductPage')));
const SamplePage = Loadable(React.lazy(() => import('../pages/extra-pages/SamplePage')));

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [

    {
      path: 'dashboard',
      element: <AuthGurad><DashboardDefault /></AuthGurad>
    },
    {
      path: 'notifications',
      element: <AuthGurad><Notifications /></AuthGurad>
    },
    {
      path: 'message',
      element: <AuthGurad><Message /></AuthGurad>
    },
    {
      path: 'access_denied',
      element: <AuthGurad><AccessDenied /></AuthGurad>
    },
    {
      path: 'editor',
      element: <PrivateRoute
        authorities={[88]}
        element={<Editor />}
      />
    },
    {
      path: 'editor-details',
      element: <PrivateRoute
        authorities={[89]}
        element={<EditorDetails />}
      />
    },
    {
      path: 'products', element: <PrivateRoute authorities={[77]}   element={<ProductPage/>}
      />,
    },
    {
      path: 'products/product-details/:id',
      element: <PrivateRoute authorities={[79]} element={<ProductDetails />}
        />
    },
    {
      path: 'products/product-details/:id/patient-details/:id',
      element: <PrivateRoute authorities={[121]} element={<AppointmentDetails />} />
    },
    {
      path: 'phases',
      element: <PrivateRoute
        authorities={[9]}
        element={<TrialsListing />}
      />
    },
    {
      path: 'phases/phase-details/:id',
      element: <PrivateRoute authorities={[14]} element={<TrailsDetails />} />
    },
    {
      path: 'phases/phase-details/:id/patient-details/:id',
      element: <PrivateRoute authorities={[121]} element={<AppointmentDetails />} />
    },
    {
      path: 'products/product-details/:id/edit-trial-details/:id',
      element: <PrivateRoute authorities={[79]} element={<EditTrialDetails />} />
    },
    {
      path: 'products/product-details/:id/edit-trial-details/:id/form-view/:id',
      element: <PrivateRoute authorities={[7]} element={<FormTemplateDetails />} />
    },
    {
      path: 'principal-investigator',
      element: <PrivateRoute authorities={[40]} element={<PrinciPleInvestigatorPage />} />
    },
    {
      path: 'principal-investigator/principal-investigator-details/:id',
      element: <PrivateRoute authorities={[44]} element={<PrincipleInvestigatorDetails />} />
    },
    {
      path: 'principal-investigator/principal-investigator-details/:id/patient-details/:id',
      element: <PrivateRoute authorities={[121]} element={<AppointmentDetails />} />
    }
    ,
    {
      path: 'add-hospital',
      element: <PrivateRoute authorities={[32]} element={<HospitalManagement />} />
    },
    {
      path: 'form-builder',
      element: <PrivateRoute authorities={[1]} element={<FormTemplateListing />} />
    },
    {
      path: 'form-builder-preview',
      element: <PrivateRoute authorities={[7]} element={<PreviewFormTemplate />} />
    },
    {
      path: 'form-builder/form-builder-preview/:id',
      element: <PrivateRoute authorities={[7]} element={<PreviewFormTemplate />} />
    },
    {
      path: 'onboard-users',
      element: <PrivateRoute authorities={[49]} element={<UserManagement />} />
    },
    {
      path: 'onboard-users/onboard-users-details/:id',
      element: <PrivateRoute authorities={[53]} element={<UserManagementDetails/>} />
    },
    {
      path: 'onboard-users/onboard-users-details/:id/patient-details/:id',
      element: <PrivateRoute authorities={[121]} element={<AppointmentDetails />} />
    },

    {
      path: 'editor-management',
      element: <PrivateRoute authorities={[58]} element={<EditorManagementPage />} />
    },
    {
      path: 'editor-management/editor-management-details/:id',
      element: <PrivateRoute authorities={[62]} element={<EditorManagementDetails />} />
    },
    {
      path: 'editor-management/editor-management-details/:id/patient-details/:id',
      element: <PrivateRoute authorities={[121]} element={<AppointmentDetails />} />
    },
    {
      path: 'quality-associate/quality-associate-details/:id',
      element: <PrivateRoute authorities={[112]} element={<UserManagementDetails />} />
    },
    {
      path: 'quality-associate/quality-associate-details/:id/patient-details/:id',
      element: <PrivateRoute authorities={[121]} element={<AppointmentDetails />} />
    },
    {
      path: 'clinical-phase-manager',
      element: <PrivateRoute authorities={[103]} element={<ClinicalPhaseManager/>} />
    },
    {
      path: 'clinical-phase-manager/clinical-phase-manager-details/:id/patient-details/:id',
      element: <PrivateRoute authorities={[121]} element={<AppointmentDetails />} />
    },
    {
      path: 'clinical-phase-manager/clinical-phase-manager-details/:id',
      element: <PrivateRoute authorities={[106]} element={<UserManagementDetails />} />
    },
    {
      path: 'add-hospital',
      element: <PrivateRoute authorities={[32]} element={<SamplePage />} />
    },
    {
      path: 'our-pocs',
      element: <PrivateRoute element={<ImmunoQA />} />
    },
   
    {
      path: 'quality-associate',
      element: <PrivateRoute authorities={[109]} element={<QualityAssociate/>} />
    },
    {
      path: 'phase-review-board',
      element: <PrivateRoute authorities={[27]} element={<TrailManagement />} />
    },
    {
      path: 'phase-review-board/form-view/:id',
      element: <PrivateRoute authorities={[7]} element={<FormTemplateDetails />} />
    },
    {
      path: 'add-disease',
      element: <PrivateRoute authorities={[67]} element={<DiseaseManagementPage />} />
    },
    {
      path: 'form-builder/create-form-builder/:id',
      element: <PrivateRoute authorities={[2]} element={<CreateFormTemplate />} />
    },
    {
      path: 'form-builder/create-form-builder/:id/form-builder-preview/:id',
      element: <PrivateRoute authorities={[7]} element={<PreviewFormTemplate />} />
    },
    {
      path: 'form-builder/update-form-builder/:id',
      element: <PrivateRoute authorities={[2]} element={<CreateFormTemplate />} />
    },
    {
      path: 'form-builder/update-form-builder/:id/form-builder-preview/:id',
      element: <PrivateRoute authorities={[7]} element={<PreviewFormTemplate />} />
    },
    {
      path: 'form-builder/form-view/:id',
      element: <PrivateRoute authorities={[7]} element={<FormTemplateDetails />} />
    },
    {
      path: 'roles-management',
      element: <PrivateRoute authorities={[95]} element={<Roles />} />
    },
    {
      path: 'audit-history',
      element: <PrivateRoute authorities={[140]} element={<AuditHistory />} />
    },
    {
      path: 'roles-management/edit-modules-management/:id',
      element: <PrivateRoute authorities={[96]} element={<RolesModules />} />
    },
    {
      path: 'roles-management/add-modules-management',
      element: <PrivateRoute authorities={[96]} element={<RolesModules />} />
    },
    {
      path: 'roles-management/role-details/:id',
      element: <PrivateRoute authorities={[96]} element={<RolesDetails />} />
    },
    
    {
      path: 'assigned-editors',
      element: <PrivateRoute authorities={[21]} element={<EditorPage />} />
    },
    {
      path: 'assigned-editors/assigned-editors-details/:id',
      element: <PrivateRoute authorities={[21]} element={<EditorListingDetails />} />
    },
    {
      path: 'assigned-editors/assigned-editors-details/:id/patient-details/:id',
      element: <PrivateRoute authorities={[17]} element={<AppointmentDetails />} />
    },
    {
      path: 'patient',
      element: <PrivateRoute authorities={[15]} element={<SubjectPage />} />
    },
    {
      path: 'patient/patient-details/:id',
      element: <PrivateRoute authorities={[121]} element={<AppointmentDetails />} />
    },

  ],
};
export default MainRoutes;
