export const navMenuItems = [
    {
        name: "Dashboard",
        icon: "dashboard",
        link: "/",
        allowedDept: [1, 2, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13],
    },
    {
        name: "Trials",
        icon: "partnerDecision",
        link: "/partnerMeetings",
        allowedDept: [2],
        authorities: [140],
    },
];
export const PHONE_REGEX = /^(\+91[-\s]?)?[6-9]\d{9}$/;
export const NAME_REGEX = /^(?!\d+$)(?!\s+$)[a-zA-Z0-9\s.]+$/;
export const Hospital_REGEX = /^(?!\d+$)(?!\s+$)[a-zA-Z0-9\s.,]+$/;


export const TemplateStatus = [{ id: 1, name: "All" }, { id: 2, name: "Completed" }, { id: 3, name: "Saved as Draft" }]

export const GenderList = [{ id: 1, name: "Male" },{ id: 2, name: "Female" },{ id: 3, name: "Others" }] 