import * as React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import CustomDrawer from "../../components/custom-drawer/CustomDrawer";
import Loadable from "../../components/Loadable";
import NoDataCard from "../../components/no-data-card";
import SkeletonLoader from "../../utilites/SkeltonLoader";
import { DynamicHeading } from "../../components/dynamic-heading/DynamicHeading";
const HeaderBackNav = Loadable(
  React.lazy(() =>
    import("../../components/header-back-navigation/HeaderBackNav")
  )
);

const ListDrawer = ({
  open,
  toggleDrawer,
  listData,
  loading = false,
  headingRedirect = false,
  title,
}) => {
  return (
    <CustomDrawer toggleDrawer={toggleDrawer} open={open}>
      <Box
        sx={{
          width: { xs: 250, sm: 430 },
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          ".css-1u2w381-MuiModal-root-MuiDrawer-root": { zIndex: 1202 },
          paddingX: "16px",
          paddingY: "44px",
        }}
        role="presentation"
      >
        <div className="flex flex-col gap-[17px]">
          <HeaderBackNav
            handleBackNav={toggleDrawer}
            TitleFontSize={"18px"}
            TitleFontWeight={700}
            Title={title}
          />
          <div className="border-b border-[#DDDDDD]"></div>
          <div className="flex flex-col gap-[17px]">
            {loading ? (
              <SkeletonLoader />
            ) : (
              <div className="flex flex-col gap-[13px]">
                {listData && listData?.length > 0 ? (
                  listData?.map((item,index) => (
                    <div key={index}>
                      <div className="flex    pb-[14px] border-b">
                        <div
                          className={`flex flex-col items-start justify-center gap-0 ml-[10px] ${
                            headingRedirect ? "cursor-pointer" : ""
                          }`}
                        >
                          <DynamicHeading
                            HeadingFontSize="14px"
                            HeadingColor={"#000000"}
                            headingName={`${(item?.product_details?.name || "")?.trim()} : ${(item?.title || "")?.trim()}`}
                            HeadingFontWeight={"400"}
                          />
                          <DynamicHeading
                            HeadingFontSize="14px"
                            HeadingColor={"#828282"}
                            headingName={
                              <>
                                <span className="text-xs">Total Patients:</span>{" "}
                                <span className="text-black">
                                  {item?.subjectCount ?? 0}
                                </span>
                              </>
                            }
                            HeadingFontWeight={"400"}
                          />
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <NoDataCard />
                )}
              </div>
            )}
          </div>
        </div>
      </Box>
    </CustomDrawer>
  );
};

ListDrawer.proptypes = {
  open: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  formTemplateList: PropTypes.array,
  handleCheckboxChange: PropTypes.func,
  handleAssignTemp: PropTypes.func,
  disabledBtn: PropTypes.bool,
  loading: PropTypes.bool,
  handleSearch: PropTypes.func,
};

export default ListDrawer;
