import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'simplebar/src/simplebar.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { store, persistor } from './store/index';
import { PersistGate } from 'redux-persist/integration/react';
import GlobalDndContext from './utils/dnd-context';
import 'react-circular-progressbar/dist/styles.css';
import { SocketProvider } from './context/SocketContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <GlobalDndContext>
    <ReduxProvider store={store}>
      <PersistGate persistor={persistor}>
      <SocketProvider>
        <BrowserRouter basename="/" >
            <App />
        </BrowserRouter>
      </SocketProvider>
      </PersistGate>
    </ReduxProvider>
  </GlobalDndContext>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
