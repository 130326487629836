export const LogoutIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.534 12.9034L18.3153 8.6846C18.1561 8.53091 17.943 8.44586 17.7218 8.44779C17.5006 8.44971 17.2889 8.53844 17.1325 8.69488C16.9761 8.85132 16.8873 9.06294 16.8854 9.28417C16.8835 9.5054 16.9685 9.71853 17.1222 9.87767L19.9007 12.6561H10.125C9.90122 12.6561 9.68661 12.745 9.52838 12.9033C9.37014 13.0615 9.28125 13.2761 9.28125 13.4999C9.28125 13.7237 9.37014 13.9383 9.52838 14.0965C9.68661 14.2547 9.90122 14.3436 10.125 14.3436H19.9007L17.1222 17.1221C17.0416 17.1999 16.9774 17.293 16.9331 17.396C16.8889 17.4989 16.8656 17.6096 16.8647 17.7217C16.8637 17.8337 16.885 17.9448 16.9275 18.0485C16.9699 18.1522 17.0325 18.2464 17.1118 18.3256C17.191 18.4048 17.2852 18.4675 17.3889 18.5099C17.4926 18.5523 17.6037 18.5737 17.7157 18.5727C17.8277 18.5717 17.9385 18.5485 18.0414 18.5043C18.1443 18.46 18.2374 18.3958 18.3153 18.3152L22.534 14.0964C22.6922 13.9382 22.7811 13.7236 22.7811 13.4999C22.7811 13.2762 22.6922 13.0616 22.534 12.9034Z"
      fill="black"
    />
    <path
      d="M8.4375 21.0938H5.90625V5.90625H8.4375C8.66128 5.90625 8.87589 5.81736 9.03412 5.65912C9.19236 5.50089 9.28125 5.28628 9.28125 5.0625C9.28125 4.83872 9.19236 4.62411 9.03412 4.46588C8.87589 4.30765 8.66128 4.21875 8.4375 4.21875H5.0625C4.83872 4.21875 4.62411 4.30765 4.46588 4.46588C4.30765 4.62411 4.21875 4.83872 4.21875 5.0625V21.9375C4.21875 22.1613 4.30765 22.3759 4.46588 22.5341C4.62411 22.6924 4.83872 22.7812 5.0625 22.7812H8.4375C8.66128 22.7812 8.87589 22.6924 9.03412 22.5341C9.19236 22.3759 9.28125 22.1613 9.28125 21.9375C9.28125 21.7137 9.19236 21.4991 9.03412 21.3409C8.87589 21.1826 8.66128 21.0938 8.4375 21.0938Z"
      fill="black"
    />
  </svg>
);
