import { useCallback } from "react";
import { DocotorIcon, DocumentIcon } from "../../../assets/common-svg/CommonSvg";
import { CustomWrapperCard } from "../../../components/custom-product-cards/custom-wrapper-card/CustomWrapperCard"
import CustomTable from "../../../components/custom-table/CustomTable"
import CustomDetailComponent from "../../../components/doctor-detail-component/DocotorDetail"
import TrialInfoComponent from "../../../components/trial-info-component/TrialInfoComponent"
import { useNavigate } from "react-router";
import { useTheme } from "@mui/material"
import { useState } from "react";
import CustomModel from "../../../components/custom-model/CustomModel";
import { AddNewSubject } from "../../../utils/formik/initialValues";
import { addNewSubjectValidationSchema } from "../../../utils/formik/validationSchema";
import SkeletonLoader from "../../../utilites/SkeltonLoader";
import { editorDetailsData } from "../../../utils/tableData";

export const EditorDetails = () => {
    const theme = useTheme()
    const [loading,] = useState(false)
    const [alignment, setAlignment] = useState("Subject");
    const [open, setOpen] = useState(false)
    const [modelData, setModelData] = useState({
        title: "Add New Patients",
        children: [
            { type: "input", name: "firstName", label: "First Name", placeholder: "Enter the First Name" },
            { type: "input", name: "lastName", label: "Last Name", placeholder: "Enter the Last Name" },
            { type: "dropdown", name: "gender", label: "Gender", placeholder: "Select Gender" },
            { type: "date", name: "dob", label: "Date of Birth", placeholder: "Select the date of birth" },
            { type: "dropdown", name: "disease", label: "Disease", placeholder: "Select the Disease" },
            { type: "dropdown", name: "formTemplate", label: "Form Builder", placeholder: "Select  the Form template" },
        ]
    })
    const navigate = useNavigate()


    const handleAddButtonCard = () => {
        setOpen(true)
    }
    const handleNavigateToDetails = useCallback(() => {
        const UpdatedNavigationArray = location.state;
        UpdatedNavigationArray?.push({
          name: "Patient Details",
          path: `${location.pathname}/patient-details`,
        });
        navigate(`/patient-details`, {
          state: UpdatedNavigationArray,
        });
    }, []);

    const handleModelValues = () => {

    }
    
    return (<div className="p-3">
        <CustomModel
            modalInitialValues={AddNewSubject}
            initialValuesValidationSchema={addNewSubjectValidationSchema}
            open={open}
            setOpen={setOpen}
            setModelData={setModelData}
            modelData={modelData}
            handleModelValues={handleModelValues}
        />
        <div className='col-start-4 col-end-13 min-h-[25rem] bg-[#FFFFFF]   rounded-xl'>
            <CustomWrapperCard addButonCard={true} handleAddButtonCard={handleAddButtonCard} loading={loading} />
            <hr className="ml-[1rem] mr-[1rem]   " />
            <TrialInfoComponent HeaderTitle={"Assigned From"} ShowHeaderButton={false}  >
                <div className="flex gap-2 justify-start flex-wrap">
                    {loading ? (
                        <SkeletonLoader rowCount={2} isTable={false} width={220} height={40} />
                    ) : (<>
                    <CustomDetailComponent BgColor="#2831720D" FirstHeading={"Pre- Infusion Form Builder 1"} Icon={<DocumentIcon Width={25} Height={25} />} ShowDeleteIcon={false} />
                    <CustomDetailComponent BgColor="#2831720D" FirstHeading={"Post- Infusion Form Builder 1"} Icon={<DocumentIcon Width={25} Height={25} />} ShowDeleteIcon={false} />
                    </>)}
                </div>
            </TrialInfoComponent>
            <hr className="ml-[1rem] mr-[1rem]   " />
            <TrialInfoComponent HeaderTitle={"Principal Investigator"} ShowHeaderButton={false}  >
                <div className="flex gap-2 justify-start flex-wrap">
                    {loading ? (
                        <SkeletonLoader rowCount={3} isTable={false} width={'15%'} height={60} />
                    ) : (<>
                    <CustomDetailComponent BgColor="#2831720D" FirstHeading={"Dr Aman Sharma"} SecondHeading={"Apollo Hospital"} Icon={<DocotorIcon Width={30} Height={30} />} ShowDeleteIcon={false} />
                    <CustomDetailComponent BgColor="#2831720D" FirstHeading={"Dr Aman Sharma"} SecondHeading={"Apollo Hospital"} Icon={<DocotorIcon Width={30} Height={30} />} ShowDeleteIcon={false} />
                    <CustomDetailComponent BgColor="#2831720D" FirstHeading={"Dr Mohit Rajput"} SecondHeading={"Apollo Hospital"} Icon={<DocotorIcon Width={30} Height={30} />} ShowDeleteIcon={false} />
                    </>)}
                </div>
            </TrialInfoComponent>
            <hr className="ml-[1rem] mr-[1rem]   " />
            <div className="p-3">
                <CustomTable
                    rows={alignment == "Subject" ? editorDetailsData?.rowsSubject : editorDetailsData?.rowsRequest}
                    columnAlign={alignment == "Subject" ? editorDetailsData?.SubjectColumnAlign : editorDetailsData?.RequestColumnAlign}
                    columns={alignment == "Subject" ? editorDetailsData?.columnsSubject : editorDetailsData?.columnsRequest}
                    TableData={alignment == "Subject" ? editorDetailsData?.SubjectTableData : editorDetailsData?.RequestTableData}
                    alignment={alignment}
                    setAlignment={setAlignment}
                    handleNavigate={handleNavigateToDetails}
                    DropdownBgColor={theme.palette.grey[200]}
                    loading={loading}
                />
            </div>
        </div>
    </div>)
}