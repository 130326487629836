// types
import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  pICoordinator: [],
  trialManager: [],
  iAQA: [],
  phaseType: [],
  formTemplateDropList: [],
  subjectDropdownList:[],

};

// ==============================|| SLICE - MENU ||============================== //

const subadminDropDown = createSlice({
  name: "subadmin-dropDown",
  initialState,
  reducers: {
    setPICoordinator(state, action) {
      state.pICoordinator = action.payload ?? state.pICoordinator;
    },
    setTrialManager(state, action) {
      state.trialManager = action.payload ?? state.trialManager;
    },
    setIAQA(state, action) {
      state.iAQA = action.payload ?? state.iAQA;
    },
    setPhaseType(state, action) {
      state.phaseType = action.payload ?? state.phaseType;
    },
    setFormTemplateDropList(state, action) {
      state.formTemplateDropList = action.payload ?? state.formTemplateDropList;
    },
    setSubjectDropdownList(state,action) {
      state.subjectDropdownList  = action.payload ?? state.subjectDropdownList;
    }
  },
});

export default subadminDropDown.reducer;

export const { setPICoordinator, setTrialManager, setIAQA, setPhaseType, setFormTemplateDropList } =
  subadminDropDown.actions;
