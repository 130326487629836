import React, {  useState } from "react";
const SocketContext = React.createContext({});
const SocketProvider = (props) => {

  const [socketInstance, setSocketInstance] = useState(null);
  const [messageRead, setMessageRead] = useState(false);
  const [notifiactioCount, setNotifiactionCount] = useState(false);



  const authContextValue = {
    socketInstance,
    setSocketInstance,
    notifiactioCount,
    setNotifiactionCount,
    messageRead,
    setMessageRead
  };

  return <SocketContext.Provider value={authContextValue} {...props} />;
};

const useSocketInstance = () => React.useContext(SocketContext);

export { SocketProvider, useSocketInstance };
