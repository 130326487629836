import { Button } from "@mui/material"
import { CardIcon } from "./card-icon"
import { CardLable } from "./card-lable"
import { getUserHasAccess } from "../../utils/authorities"
import { RejectTrialManagementModalText, DescripitionTrialManagementModalText } from '../../utilites/Common';
import { useSelector } from "react-redux";

export const TableCard = ({ trail, handleDetails, handleButton, toggleDrawer }) => {
    const LoginData = useSelector((state) => state.loginData);
    const userAuthority = LoginData?.auth || [];
    return <div className="bg-white rounded-[10px] border border-neutral-200 flex flex-col lg:flex-row gap-2   lg:gap-8 xl:gap-10 !p-4">
        <CardIcon title={trail?.cardIcon?.title} icon={trail?.cardIcon?.icon} code={trail?.cardIcon?.code} />
        <div className="grid grid-cols-2 1sm:grid-cols-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-5 gap-2 md:gap-4 lg:gap-10 w-full lg:w-3/5 xl:w:2/5 mt-2 lg:mt-0">
            {trail?.cardLable?.map((lable, lableIndex) => (
                getUserHasAccess(userAuthority, "trialManage", 30) && lableIndex == 1
                    ? ""
                    : <CardLable
                        key={lableIndex}
                        className={lable?.className}
                        textColor={lable?.textColor}
                        fieldLable={lable?.fieldLable}
                        fieldValue={lable?.fieldValue}
                        underline={lable?.underline}
                        id={trail?.cardIcon?.requestID}
                        click={lable?.click}
                        onClick={lable?.click && toggleDrawer}
                    />
            ))}
            {getUserHasAccess(userAuthority, "trialManage", 30) && !getUserHasAccess(userAuthority, "trialManage", 28) && <CardLable
                onClick={() => handleDetails(trail?.cardIcon?.id, DescripitionTrialManagementModalText)}
                textColor={"#2F5597"}
                className="cursor-pointer underline "
                fieldLable={"Description"} fieldValue={"View Details"} />}
        </div>
        <div className="w-full lg:w-1/4 my-4 lg:!my-0 flex gap-2 justify-between lg:justify-end self-center">
            {getUserHasAccess(userAuthority, "trialManage", 30) && getUserHasAccess(userAuthority, "trialManage", 28)
                && (trail?.cardIcon?.status == "rejected" || trail?.cardIcon?.status == "accepted")
                && <Button
                    variant="contained"
                    className="text-sm lg:text-xs xl:text-sm px-2 lg:!px-1 xl:!px-4 w-full md:w-auto !font-[500]"
                    onClick={() => handleDetails(trail?.cardIcon?.id, RejectTrialManagementModalText)}
                    style={{ height: "2.5rem" }}>
                    View Details
                </Button>}
            {trail?.cardIcon?.status == "rejected"
                && getUserHasAccess(userAuthority, "trialManage", 28)
                && <Button
                    variant="contained"
                    className="text-sm lg:text-xs xl:text-sm px-2 lg:!px-1 xl:!px-4 w-full md:w-auto !font-[500]"
                    onClick={() => handleButton(trail?.cardIcon?.id, "pending", "Resend Request")}
                    style={{ height: "2.5rem" }}
                >
                    Resend Request
                </Button>}
            {getUserHasAccess(userAuthority, "trialManage", 29) && trail?.cardIcon?.status == "pending" && trail?.cardLable?.[3].fieldValue != 0 &&
                <Button variant="contained"
                    className="text-sm lg:text-xs xl:text-sm px-2 lg:!px-1 xl:!px-4 w-full md:w-auto !font-[500]"
                    onClick={() => handleButton(trail?.cardIcon?.id, "Accepted", "Accept Request", "acceptBtn")}
                    style={{ height: "2.5rem" }}
                >
                    Accept Request
                </Button>}
            {getUserHasAccess(userAuthority, "trialManage", 29) && trail?.cardIcon?.status == "pending" && trail?.cardLable?.[3].fieldValue != 0 &&
                <Button
                    variant="outlined"
                    className="text-sm lg:!text-xs xl:text-sm px-2 lg:!px-1 xl:!px-4 w-full md:w-auto !text-[#FF2023] !border-[#FF2023]  !font-[500]"
                    onClick={() => handleButton(trail?.cardIcon?.id, "Rejected", "Reject Request")}
                    style={{ height: "2.5rem" }}
                >
                    Reject Request
                </Button>}
        </div>
    </div >
}