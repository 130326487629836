// initial values for login:
export const loginInitialValues = {
    email: '',
    password: '',
}

// initial values for forgot password:
export const forgotPasswordInitialValues = {
    email: '',
}

//  initial values for create new password:
export const createNewPasswordInitialValues = {
    password: '',
    confirmPassword: '',
}

// initial values for add hospital:
export const addHospitalInitialValues = {
    hospitalName: "",
    hospitalCode: "",
    hospitalAddress: "",
    state: "",
    city: "",
    pincode: "",
}
// send messaget input:
export const sendMessageInputInitialValue = {
    message: ""
}

// initial values for add User-Management
export const addUserValues = {
    userType: "",
    name: "",
    email: "",
    phoneNumber: "",
    selectTrial: "",
    hospital: "",
    trial: [],
    principalInvestigator: ""
};

// initial values for add ImmunoAct QA
export const addIAQAValues = {
    userType: "3",
    name: "",
    email: "",
    phoneNumber: "",
    selectTrial: "",
};

// initial values for add Trial Manger
export const addTrialManagerValues = {
    userType: "4",
    name: "",
    email: "",
    phoneNumber: "",
    selectTrial: "",
};

// initial values for add Principal-Investigator
export const addPrincipalInvestigatorValues = {
    name: "",
    email: "",
    phone: "",
    role_id: 2,
    hospital: ""
}

// initial values for add Disease-Management
export const addDiseaseManagementValues = {
    diseaseName: "",
    description: "",
}

// initial values for add Editor-Management
export const addEditorValues = {
    name: "",
    email: "",
    phoneNumber: "",
    trial: [],
    principalInvestigator: ""
}

// initial values for add Trial-Management
export const resendTrialManagementValues = {
    description: "",
    terms: false
}

export const AddNewSubject = {
    firstName: "",
    lastName: "",
    gender: "",
    dob: "",
    disease: "",
    formTemplate: "",
    upload_file: ""
}

export const AddNewSubjectPhase = {
    firstName: "",
    lastName: "",
    gender: "",
    dob: "",
    disease: "",
    principal_investigator: "",
    editor_id: "",
    upload_file: ""
}
export const exportFormCsv = {
    formId: "",
    questionId: []
}
export const ReAssignPiEditor = {
    principal_investigator: "",
    editor_id: "",
}

export const AddProduct = {
    productName: "",
    description: ""
}


export const AddPhase = {
    title: "",
    phase_type: "",
    phase_start_date: "",
    minimum_subjects: "",
    coordinating_PI_id: "",
    description:"",
};
export const EditPhase = {
    product_id: "",
    title: "",
    phase_type: "",
    minimum_subjects: "",
    phase_start_date: "",
    coordinating_PI_id: "",
    qa_ids: [],
    manager_ids: [],
    form_template_ids: [],
    investigator_ids: [],
    description:"",

}

export const AddFormTemplate = {
    title: "",
    type: "",
    description: ""
}

export const addPiSubjectValues = {
    name: "",
    gender: "",
    dob: "",
}

export const reAssignValues = {
    editor_id: ''
}

export const updatePhaseValues = {
    phase: []
}

export const queryMessage = {
    phase: "",
    template: "",
    formField: "",
}
