import { Typography, useTheme } from "@mui/material";
import React from "react";

export const MessageArea = ({ date, convoHeading }) => {
  const theme = useTheme();
  return (
    <div>
      <div className="messageArea-heading  flex  flex-col  w-full items-center justify-start gap-[10px] ">
        <Typography variant="body1" color="secondary">
          {date}
        </Typography>
        {convoHeading && (
          <Typography
            variant="body1"
            bgcolor={theme.palette.grey[200]}
            className="p-[5px_8px_5px_8px_] rounded-[5px] mb-2"
          >
            {convoHeading}
          </Typography>
        )}
      </div>
    </div>
  );
};
