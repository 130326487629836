import { Button } from "@mui/material";
import PropTypes from "prop-types";

const CustomButton = ({ button_class, FontSize, disableElevation, ButtonSize, ButtonCenter, ButtonColor, ButtonText, fullWidthButton, BorderColor, ButtonStartIcon, ButtonEndIcon, ButtonVariant, ButtonType, ButtonDisabled, handleClick, buttonHeight
}) => {

    return (<div className={`flex ${ButtonCenter ? 'self-center' : ""} `}>
        <Button
        color="primary"
            sx={{
                fontSize: FontSize, backgroundColor: ButtonColor ? ButtonColor : ButtonVariant ? ButtonVariant : "#2F5597", borderColor: BorderColor, whiteSpace: "nowrap", borderRadius: "6px", color: '#FFFFFF', height: buttonHeight ?? '44px'
            }}
            fullWidth={fullWidthButton ?? false
            }
            endIcon={ButtonEndIcon ?? undefined}
            startIcon={ButtonStartIcon ?? undefined}
            variant={ButtonVariant ?? "contained"}
            type={ButtonType ?? "submit"}
            className={`${button_class ?? ""} ${ButtonSize} `}
            disabled={ButtonDisabled ?? false}
            onClick={handleClick}
            disableElevation={disableElevation ?? false}
        >
            {ButtonText}
        </Button>
        {ButtonDisabled && (
            <div
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                }}
            >
                <div className="circular-loader"></div>
            </div>
        )}
    </div>
    );
};

CustomButton.propTypes = {
    button_class: PropTypes.string,
    FontSize: PropTypes.string,
    disableElevation: PropTypes.bool,
    ButtonSize: PropTypes.string,
    ButtonColor: PropTypes.string,
    ButtonText: PropTypes.string,
    fullWidthButton: PropTypes.bool,
    ButtonStartIcon: PropTypes.node,
    ButtonEndIcon: PropTypes.node,
    ButtonVariant: PropTypes.string,
    BorderColor:PropTypes.string,
    ButtonType: PropTypes.string,
    ButtonCenter: PropTypes.bool,
    ButtonDisabled: PropTypes.bool,
    handleClick: PropTypes.func,
};

export default CustomButton;
