
// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    value: false,
    message: "",
    color: ""
};

// ==============================|| SLICE - MENU ||============================== //

const snackbar = createSlice({
    name: 'snackbar',
    initialState,
    reducers: {
        setSnackbar(state, action) {
            state.value = action.payload.value ?? state.value;
            state.message = action.payload.message ?? state.message;
            state.color = action.payload.color ?? state.color;
        },
        clearSnackbar(state) {
            state.value = false;
        },
        clearSnackbarMessage(state) {
            state.message = '',
            state.color = ''
        }
    }
});

export default snackbar.reducer;

export const { setSnackbar, clearSnackbar, clearSnackbarMessage } = snackbar.actions;
