import httpService from "./httpService";

const QueryApi = {
    // create roles api
    raiseQuery: (data) => httpService.post('newModifiedRaiseQuery', data),
    resolvedQuery: (data) => httpService.post('resolveQuery', data),
    approveAppointment: (data) => httpService.post('approveAppointmentAnswer', data),



    
    getAllQueryHistory: (id,version) => httpService.get(`getAllQueryHistoryByAppointment_id/${id}`, {
        params: {
            version: version,
        }
    }),
    getApointmentVersion: (id) => httpService.get(`versionDropDown`, {
        params: {
            appointment_id: id
        }
    }),

    getAppointmentAnswerDetail: (version, subject_id, appointment_id) => httpService.get(`getAppointmentAnswerDetail`, {
        params: {
            version: version,
            subject_id: subject_id,
            appointment_id: appointment_id
        }
    }),
    
    getAllQueryComment: (id, param) => httpService.get(`getQueryByAppointmentTempQreryId/${id}`, {
        params: {
            version: param?.version,
            field_id: param?.field_id,
            appointment_id: param?.appointment_id
        }
    }),


}

export default QueryApi;