import React, { useRef } from 'react';
import { Button, FormHelperText, Typography } from '@mui/material';

export const CustomUpload = ({ label, placeholder, name, handleChange, error, touched, helperText, value, InputId, fileIcon }) => {
    const fileInputRef = useRef(null);
    const handleClick = () => {
        fileInputRef.current.click();
    };

    const handleButtonClick = (event) => {
        event.stopPropagation();
        handleClick();
    };
    return (
        <div className="w-100 relative">
            <div className="text-black text-sm font-normal font-['Public Sans'] pb-2 cursor-pointer" >{label}</div>
                                            
            <div className="w-100 border rounded-md justify-between items-center cursor-pointer flex" onClick={handleClick} >
                <div className="flex justify-start items-center gap-2.5 flex-1 md:max-w-[70%] max-w-[60%] cursor-pointer">
                    <div className="mt-2  max-w-full cursor-pointer">
                        {!value && (
                            <label htmlFor="fileInput" className="text-neutral-500 text-base flex gap-3 font-normal pl-2 font-['Public Sans'] cursor-pointer">
                                <p>{fileIcon && fileIcon}</p><p className='align-self-center cursor-pointer'>  {placeholder}</p>
                            </label>
                        )}
                        <input
                            id="fileInput"
                            name={name}
                            type="file"
                            className="hidden"
                            accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf, image/*"
                            ref={fileInputRef}
                            onChange={handleChange}
                        />               
                        {value && <Typography variant='body1' className='mb-2  flex gap-3 truncate '>
                            <p>{fileIcon && fileIcon}</p><p className='align-self-center cursor-pointer truncate'>  {value}</p>
                        </Typography>}
                    </div>
                </div>
                
                <Button
                className="bg-blue-500 text-white md:max-w-[30%] max-w-[40%]"
                onClick={handleButtonClick}>
                {label}
                </Button>
            </div>
            {touched && error && (
                <FormHelperText
                    error
                    id={`standard-weight-helper-text-${InputId}`}
                    className='absolute'
                >
                    {helperText}
                </FormHelperText>
            )}
        </div>
    );
};
