// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import loginData from './login-data';
import formTemplete from "./form-template"
import rolesManagement from './roles-management';
import snackBar from './snackBar';
import templateType from './template-type';
import subAdminDropdown from './sub-admin-dropdown';
import phaseReducer from './phase-reducer';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({ menu, loginData, formTemplete, rolesManagement, snackBar, templateType, subAdminDropdown, phaseReducer });

export default reducers;
