import { useEffect } from "react";
import { useNavigate } from "react-router";

export const AuthGurad = ({ children }) => {
    // const user = sessionStorage.getItem("LoggedUserData");
    const user = localStorage.getItem("user")
    const navigate = useNavigate();
    useEffect(() => {
        if (user?.length < 1) {
            navigate("/login");
        }
    }, [user, navigate]);

    return user?.length >= 1 ? children : null;
}