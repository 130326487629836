import React from "react";

export const EditorIcon = () => {
    return (

        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="1" fillRule="evenodd" clipRule="evenodd" d="M19.2961 7.29732C19.2961 9.29836 18.4907 11.1113 17.1864 12.4296C18.351 13.0003 19.415 13.7601 20.3345 14.6795C21.4291 15.7742 22.2974 17.0737 22.8898 18.5038C23.339 19.5885 23.623 20.7322 23.7339 21.8967C23.7926 22.5124 23.2857 23.0149 22.6672 23.0149C22.0487 23.0149 21.5541 22.5118 21.4818 21.8976C21.3792 21.0274 21.1572 20.1737 20.8206 19.3609C20.3407 18.2025 19.6374 17.1499 18.7508 16.2633C17.8641 15.3766 16.8115 14.6733 15.6531 14.1935C15.4383 14.1045 15.2206 14.0235 15.0006 13.9506C14.5091 14.1727 13.9887 14.3419 13.4465 14.4511L14.7302 19.4742L14.7925 19.7181L14.6337 19.9134L12.669 22.3296L12.2811 22.8067L11.8931 22.3296L9.92847 19.9134L9.76964 19.7181L9.83197 19.4742L11.0932 14.539C10.3547 14.4476 9.6504 14.2458 8.99732 13.9508C8.77737 14.0236 8.55983 14.1045 8.34514 14.1934C7.18669 14.6733 6.1341 15.3766 5.24746 16.2632C4.36082 17.1499 3.6575 18.2025 3.17765 19.3609C2.84099 20.1737 2.61896 21.0274 2.51643 21.8976C2.44406 22.5118 1.94946 23.0149 1.33098 23.0149C0.712497 23.0149 0.205604 22.5124 0.264273 21.8967C0.375242 20.7321 0.659158 19.5885 1.10843 18.5038C1.70083 17.0736 2.56913 15.7741 3.66374 14.6795C4.5831 13.7602 5.64699 13.0005 6.81144 12.4298C5.50699 11.1115 4.7015 9.29845 4.7015 7.29732C4.7015 3.26712 7.96862 0 11.9988 0C16.029 0 19.2961 3.26712 19.2961 7.29732ZM12.1113 14.5938L10.8632 19.4779L12.2811 21.2216L13.699 19.4779L12.4476 14.5811C12.3361 14.5878 12.224 14.5921 12.1113 14.5938ZM17.3988 7.29732C17.3988 9.09156 16.5238 10.6814 15.1771 11.6634C14.1447 11.3744 13.0756 11.2269 11.9991 11.2269C10.9225 11.2269 9.85318 11.3744 8.82066 11.6635C7.47393 10.6815 6.5988 9.09163 6.5988 7.29732C6.5988 4.31497 9.01647 1.8973 11.9988 1.8973C14.9812 1.8973 17.3988 4.31497 17.3988 7.29732Z" fill="white" />
        </svg>

    );
}


