import React, { useState } from 'react'
import SubHeader from '../../layout/MainLayout/SubHeader';
import CustomSearchbar from '../../components/custom-searchbar/CustomSearchbar';
import { HospitalIcon, TotalPhaseIcon } from '../../assets/products-svg/ProductSvg';
import InfoCard from '../../components/card-component/InfoCard';
import { useNavigate } from 'react-router';
import SkeletonLoader from '../../utilites/SkeltonLoader';

const Editor = () => {
    const navigate = useNavigate();
    const [loading,] = useState(false)
    const handleDetailsButton = () => {
      navigate(`editor-details`, {
        state: [
          { name: "Editor", path: location.pathname },
          {
            name: "Details",
            path: `${location.pathname}/editor-details}`,
          },
        ],
      });
    }
    return (
        <div className='w-full'>
            <SubHeader>
                <div className='flex p-10 justify-between items-center'>
                    <div className='left_area flex flex-col gap-4'>
                        <h4 className='text-[#FFFFFF] text-2xl font-medium'>Welcome  to ACT. ONE</h4>
                        <p className='text-base text-[#FFFFFF] font-normal'>Phase</p>
                    </div>
                    <div className='right_area'>
                        <CustomSearchbar />
                    </div>
                </div>
            </SubHeader>
            <div className='grid grid-cols-3 gap-4 p-3'>
                {loading ? (
                    <SkeletonLoader rowCount={3} isTable={false} height={260} width={'100%'} />
                ) : (<>
                <InfoCard
                    ShowCardFooterBoolean={true}
                    FieldGapCloser={true}
                    ShowHeaderMenu={true}
                    HeaderDate={"01/01/2023"}
                    CardData={{
                        CardID: "#DSGf344", CardDate: "17/01/24", CardHeading: "Pertusis A",
                        CardDetailsListing:
                            [
                                { FieldName: "Total Phases", FieldValue: 34, Icon: <TotalPhaseIcon /> },
                                { FieldName: "Hospitals Onboarded", FieldValue: 219, Icon: <HospitalIcon /> },
                            ],
                        CardStatus: [
                            { StatusTitle: "Trial", StatusProgress: "Pending" },

                        ]
                    }
                    }
                    handleProductDetails={handleDetailsButton}
                />
                <InfoCard
                    ShowCardFooterBoolean={true}
                    FieldGapCloser={true}
                    ShowHeaderMenu={true}
                    HeaderDate={"01/01/2023"}
                    CardData={{
                        CardID: "#DSGf344", CardDate: "17/01/24", CardHeading: "Pertusis A",
                        CardDetailsListing:
                            [
                                { FieldName: "Total Phases", FieldValue: 34, Icon: <TotalPhaseIcon /> },
                                { FieldName: "Hospitals Onboarded", FieldValue: 219, Icon: <HospitalIcon /> },
                            ],
                        CardStatus: [
                            { StatusTitle: "Trial", StatusProgress: "Pending" },

                        ]
                    }
                    }
                    handleProductDetails={handleDetailsButton}
                />

                <InfoCard
                    ShowCardFooterBoolean={true}
                    FieldGapCloser={true}
                    ShowHeaderMenu={true}
                    HeaderDate={"01/01/2023"}
                    CardData={{
                        CardID: "#DSGf344", CardDate: "17/01/24", CardHeading: "Pertusis A",
                        CardDetailsListing:
                            [
                                { FieldName: "Total Phases", FieldValue: 34, Icon: <TotalPhaseIcon /> },
                                { FieldName: "Hospitals Onboarded", FieldValue: 219, Icon: <HospitalIcon /> },
                            ],
                        CardStatus: [
                            { StatusTitle: "Trial", StatusProgress: "Pending" },

                        ]
                    }
                    }
                    handleProductDetails={handleDetailsButton}
                />
                </>)}

            </div>
        </div>
    )
}

export default Editor;