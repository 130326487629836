

import React from "react";

export const RolesAccessIcon = () => {
    return (
        <svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="1">
                <path d="M11.2 3.2C10.3163 3.2 9.6 2.48366 9.6 1.6C9.6 0.716345 8.88366 0 8 0C7.11635 0 6.4 0.716345 6.4 1.6C6.4 2.48366 5.68366 3.2 4.8 3.2H2C0.895433 3.2 0 4.09543 0 5.2V22C0 23.1046 0.895431 24 2 24H14C15.1046 24 16 23.1046 16 22V5.2C16 4.09543 15.1046 3.2 14 3.2H11.2ZM8 0.8C8.21218 0.8 8.41566 0.884285 8.56569 1.03431C8.71572 1.18434 8.8 1.38783 8.8 1.6C8.8 1.81217 8.71572 2.01566 8.56569 2.16569C8.41566 2.31571 8.21218 2.4 8 2.4C7.78783 2.4 7.58435 2.31571 7.43432 2.16569C7.28429 2.01566 7.2 1.81217 7.2 1.6C7.2 1.38783 7.28429 1.18434 7.43432 1.03431C7.58435 0.884285 7.78783 0.8 8 0.8ZM14.4 20.4C14.4 21.5046 13.5046 22.4 12.4 22.4H3.6C2.49543 22.4 1.6 21.5046 1.6 20.4V6.8C1.6 5.69543 2.49543 4.8 3.6 4.8H5.6C6.04183 4.8 6.4 5.15817 6.4 5.6C6.4 6.04183 6.75817 6.4 7.2 6.4H8.8C9.24183 6.4 9.6 6.04183 9.6 5.6C9.6 5.15817 9.95817 4.8 10.4 4.8H12.4C13.5046 4.8 14.4 5.69543 14.4 6.8V20.4Z" fill="white" />
                <path d="M8.00313 8.00781C5.79353 8.00781 4.00313 9.79821 4.00313 12.0078C4.00313 14.2174 5.79353 16.0078 8.00313 16.0078C10.2127 16.0078 12.0031 14.2174 12.0031 12.0078C12.0031 9.79821 10.2127 8.00781 8.00313 8.00781ZM8.00313 14.4078C6.67993 14.4078 5.60313 13.331 5.60313 12.0078C5.60313 10.6846 6.67993 9.60781 8.00313 9.60781C9.32633 9.60781 10.4031 10.6846 10.4031 12.0078C10.4031 13.331 9.32633 14.4078 8.00313 14.4078ZM4.80313 17.6078C4.37878 17.6078 3.97181 17.7764 3.67175 18.0764C3.3717 18.3765 3.20312 18.7835 3.20312 19.2078V20.8078H12.8031V19.2078C12.8031 18.7835 12.6346 18.3765 12.3345 18.0764C12.0344 17.7764 11.6275 17.6078 11.2031 17.6078H4.80313Z" fill="white" />
            </g>
        </svg>
    );
};

