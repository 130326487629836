import { useEffect, useState } from "react";
import CustomModel from "../../components/custom-model/CustomModel";
import { addPrincipalInvestigatorValidationSchema } from "../../utils/formik/validationSchema";
import { addPrincipalInvestigatorValues } from "../../utils/formik/initialValues";
import SubAdminApi from "../../services/subAdminService";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../store/reducers/snackBar";

function PrincipleInvestigatorEdit({
  openModal,
  setOpenModal,
  id = "",
  piEditData,
  getSubAdmin,
  hospitals = [],
}) {
  const [initialValues, setInitialValues] = useState(
    addPrincipalInvestigatorValues
  );
  const [submitLoading,setSubmitLoading] = useState(false);
  const dispatch = useDispatch();
  const [modelData, setModelData] = useState({
    title: "Add Principal Investigator",
    editTitle: "Edit Principal Investigator",
    children: [
      {
        type: "input",
        name: "name",
        label: "Principal Investigator Name",
        placeholder: "Enter Principal Investigator Name",
        IsRequired: true,
      },
      {
        type: "dropdown",
        name: "hospital",
        label: "Hospital",
        placeholder: "Select Hospital",
        IsRequired: true,
        data: [],
      },
      {
        type: "input",
        name: "email",
        label: "Email",
        placeholder: "Enter Email",
        IsRequired: true,
      },
      {
        type: "number",
        name: "phone",
        label: "Phone Number",
        placeholder: "Enter Phone Number",
        IsRequired: true,
      },
    ],
  });
  useEffect(() => {
    setInitialValues({
      name: piEditData?.name,
      email: piEditData?.email,
      country_code: piEditData?.country_code,
      phone: piEditData?.phone,
      hospital: piEditData?.hospital_details?.id,
      role_id: piEditData?.role_id,
    });
  }, [piEditData]);

  useEffect(() => {
    const newChildren = [
      {
        type: "input",
        name: "name",
        label: "Principal Investigator Name",
        placeholder: "Enter Principal Investigator Name",
        IsRequired: true,
      },
      {
        type: "dropdown",
        name: "hospital",
        label: "Hospital",
        placeholder: "Select Hospital",
        IsRequired: true,
        data: hospitals,
      },
      {
        type: "number",
        name: "phone",
        label: "Phone Number",
        placeholder: "Enter Phone Number",
        IsRequired: true,
      },
    ];

    if (id == "") {
      newChildren.push({
        type: "input",
        name: "email",
        label: "Email",
        placeholder: "Enter Email",
        IsRequired: true,
      });
    } else {
      newChildren.push({
        type: "input",
        name: "email",
        label: "Email",
        placeholder: "Enter Email",
        IsRequired: true,
        disabled: true,
      });
    }
    setModelData((prevModelData) => ({
      ...prevModelData,
      children: newChildren,
    }));
  }, [id]);
  const handleEditModelValues = (values) => {
    setSubmitLoading(true);
    SubAdminApi.editSubAdmin(
      {
        name: values?.name,
        email: values?.email,
        country_code: "91",
        phone: values?.phone?.toString(),
        role_id: initialValues?.role_id,
        hospital_id: values?.hospital,
      },
      id
    )
      .then((res) => {
        setOpenModal(false);
        setSubmitLoading(false)
        if (res.status === 200) {
          getSubAdmin();
          dispatch(setSnackbar({ value: true, message: res.message, color: "success"}));
        }
      })
      .catch((error) => {
        dispatch(setSnackbar({ value: true, message: error, color: "error" }));
        setSubmitLoading(false)
      })

  };

  return (
    <>
      <CustomModel
        rowId={id}
        modalInitialValues={initialValues}
        initialValuesValidationSchema={addPrincipalInvestigatorValidationSchema}
        open={openModal}
        setOpen={setOpenModal}
        setModelData={setModelData}
        modelData={modelData}
        handleModelValues={handleEditModelValues}
        isLoadingBtn={submitLoading}
      />
    </>
  );
}

export default PrincipleInvestigatorEdit;
