import { useDispatch, useSelector } from "react-redux";
import BreadCrumb from "../../breadcrumb/BreadCrumb";
import FormGenerator from "../../../components/form-template/FormGenerator"
import { Button } from "@mui/material";
import FormTemlateApi from "../../../services/form-template";
import { setSnackbar } from "../../../store/reducers/snackBar";
import { CustomSnackbar } from "../../../components/snackbar/CustomSnackbar";
import {  useLocation, useNavigate, useParams } from "react-router";
import { clearTemplateValues } from "../../../store/reducers/form-template";
import { useState } from "react";
import { FormBreadCrumbGetter } from "../../../utilites/Common";

export const PreviewFormTemplate = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    const rowId = id;
    const FormTempleteData = useSelector((state) => state.formTemplete);
    const snackbar = useSelector((state) => state.snackBar);
    const [submitLoading,setSubmitLoading] = useState(false);
    const [breadCrumbArray,] = useState(FormBreadCrumbGetter(location.pathname));


    const updateForm = (status) => {
        setSubmitLoading(true);
        let data = {
            title: FormTempleteData?.title,
            description: FormTempleteData?.description,
            type_id: FormTempleteData?.type,
            status: status,
            questions: JSON.stringify(FormTempleteData?.formData),
        };
        FormTemlateApi.updateFormTemplate(data, rowId)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(clearTemplateValues());
                    dispatch(setSnackbar({ value: true, message: res?.message, color: "success" }));
                    navigate("/form-builder", { state: [] });
                    setSubmitLoading(false);
                }
            })
            .catch((err) => {
                dispatch(setSnackbar({ value: true, message: err, color: "error" }));
                setSubmitLoading(false);
            });
    };

    return (
        <div className="p-3 flex flex-col gap-4 mt-1">
            <div>
                <div>
                    <BreadCrumb
                        BreadCrumbsListing={breadCrumbArray}
                    />
                </div>
                <div className="mt-2 bg-white p-3">
                    <FormGenerator formData={FormTempleteData.formData} />
                    {FormTempleteData?.formData && (
                        <div className="flex gap-4">
                            <Button
                                variant="outlined"
                                disabled={submitLoading}
                                onClick={() =>
                                    rowId
                                        ? updateForm("Saved as Draft")
                                        : updateForm("Saved as Draft")
                                }
                                aria-label="Close"
                            >
                                Save as draft
                            </Button>
                            <Button
                                variant="contained"
                                color="info"
                                disabled={submitLoading}
                                onClick={() =>
                                    rowId ? updateForm("Completed") : updateForm("Completed")
                                }
                                aria-label="Close"
                            >
                                {rowId ? "Update" : "Submit"}
                            </Button>
                        </div>
                    )}
                </div>
            </div>
            <CustomSnackbar
                open={snackbar?.value}
                setOpen={setSnackbar()}
                color={snackbar?.color}
                message={snackbar?.message}
            />
        </div>
    );
};