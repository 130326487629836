import React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { UpperIcon } from "../../assets/common-svg/CommonSvg";
import Proptypes from "prop-types";
import { FormHelperText, InputLabel, useTheme } from "@mui/material";

export default function CustomDropdown({
  Width,
  TextSize,
  MenuList,
  MinWidth,
  handleChange,
  Height,
  value,
  LargeBorderRadius,
  ShowLargeDropdown,
  DropDownlabel,
  touched,
  error,
  DropDownlabelInput,
  PaddingY,
  PlaceholderText,
  helperText,
  InputId,
  IsRequired,
  disabled,
}) {
  const [isOpen, setIsOpen] = React.useState(false);
  const theme = useTheme();
  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  const CustomIconComponent = (props) => {
    return isOpen ? (
      <div className="cursor-pointer rotate-180 m-[1.3px] pl-2 " {...props}>
        <UpperIcon />
      </div>
    ) : (
      <div className="cursor-pointer m-[1.3px] pr-2" {...props}>
        <UpperIcon />
      </div>
    );
  };
  const borderRadiusClass = LargeBorderRadius ? "rounded-[10px]" : "rounded-[6px]";
  const minWidthClass = MinWidth ? `md:min-w-[${MinWidth ? 200 : 230}px]` : "md:min-w-[230px]";
  const combinedClassName = `${borderRadiusClass} min-w-0 w-full ${minWidthClass} border border-gray-200`;

  return (
    <div className="flex flex-col relative ">
      <div>
      {DropDownlabel ? (
        <InputLabel htmlFor={"entitlement"} className="text-current">
          {DropDownlabel}
          {IsRequired ? <span className="text-red-500">*</span> : ""}
        </InputLabel>
      ) : null}
      <FormControl
        className={combinedClassName}
        sx={{
          flexGrow: 1,
          width: Width ?? "",
          backgroundColor: disabled ? theme.palette.grey[200] : "white",
          color: disabled ? theme.palette.primary.light : "black",
        }}
      >
        {DropDownlabelInput && (
          <InputLabel
            className="text-[#828282] font-normal text-base mt-[-6px] capitalize"
            id="demo-simple-select-helper-label"
          >
            {DropDownlabelInput}
          </InputLabel>
        )}
        <Select
          value={value ||  ""}
          onChange={(value) => {
            handleChange(value);
          }}
          disabled={disabled}
          color="error"
          sx={{
            paddingY: PaddingY,
            height: Height,
            "& fieldset": { border: "none" },
            ".MuiSelect-icon": {
              top: "calc(50% - 1.3em)",
            },
            textTransform:"capitalize"
            
          }}
          placeholder="enter the text"
          className={` text-["#000000"] font-[roboto] h-[44px]  ${ShowLargeDropdown && !Height ? "h-[41px]" : null
            } `}
          inputProps={{ "aria-label": "Without label" }}
          displayEmpty
          IconComponent={CustomIconComponent}
          onOpen={handleOpen}
          onClose={handleClose}
        >
          <MenuItem value="" className="!text-[#777777] capitalize">
            <span className={`w-fit truncate text-[#777777] text-[0.9rem] capitalize`}>
              {PlaceholderText}
            </span>
          </MenuItem>
          {MenuList &&
            MenuList?.map((list) => (
              <MenuItem
                className={`text-[${TextSize}] capitalize`}
                value={list.id}
                key={list.id}
              >
                {" "}
                {list.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      </div>
      {touched && error &&   (
        <FormHelperText
          error
          id={`standard-weight-helper-text-${InputId}`}
          className="absolute left-0 bottom-[-23px]"
        >
          {helperText}
        </FormHelperText>
      )}
    </div>
  );
}

CustomDropdown.Proptypes = {
  TextSize: Proptypes.string,
  Width: Proptypes.string,
  MenuList: Proptypes.array,
  handleChange: Proptypes.func,
  handleOpen: Proptypes.func,
  handleClose: Proptypes.func,
  value: Proptypes.string,
  isOpen: Proptypes.bool,
  ShowLargeDropdown: Proptypes.bool,
  ShowLabel: Proptypes.bool,
  PlaceholderText: Proptypes.string,
  HideBorderBoolean: Proptypes.bool,
  LargeBorderRadius: Proptypes.bool,
};
