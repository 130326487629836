import httpService from "./httpService";

const RolesApi = {
    // create roles api
    createRole: (data) => httpService.post('addRole', data),
    deleteRole: (id) => httpService.delete(`deleteRole/${id}`),
    getRoleDetails: (id) => httpService.get(`roleDetails/${id}`),
    getRoleModulesDetails: (id) => httpService.get(`getRoleModulePermissions/${id}`),
    assignPermissionsToRole: (data) => httpService.post('assignPermissionsToRole', data),
    getAllModules: (limit=100) => httpService.get(`moduleList`, {
        params: {
            limit,
        }
    }),
    updateRoles: (data, id) => httpService.post(`updateRole/${id}`, data),
    getAllRoles: (limit, page, search) => httpService.get('roleList', {
        params: {
            limit,
            page,
            searchData: search
        }
    }),


}

export default RolesApi;