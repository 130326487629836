import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { clearTemplateValues, setFormData, setTemplateValues } from "../../store/reducers/form-template"
import CustomTable from "../../components/custom-table/CustomTable"
import CustomModel from "../../components/custom-model/CustomModel"
import { AddFormTemplate } from "../../utils/formik/initialValues"
import { addFormTemplateValidationSchema } from "../../utils/formik/validationSchema"
import { formTemplateData } from "../../utils/tableData"
import DeleteConfirmContent from "../../components/modal-contents/DeleteConfirmModalContent"
import FormTemlateApi from "../../services/form-template"
import { setSnackbar } from "../../store/reducers/snackBar"
import { CustomSnackbar } from "../../components/snackbar/CustomSnackbar"
import moment from "moment/moment";
import { TemplateStatus } from "../../utils/common";
const convertFormData = (data) => {
  return data.map(form => {
    let title = form.title;
    let template_type = form.template_type;
    let created_at = moment(form.created_at).format("DD/MM/yyyy");
    let status = form?.status
    let id = form?.id
    let is_assigned = form?.is_assigned
    return [title, template_type, created_at, status, id, is_assigned];
  });
}
export const FormTemplateListing = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const snackbar = useSelector((state) => state.snackBar);
  const [formState, setFormState] = useState(null);
  const [templateData, setTemplateData] = useState(formTemplateData);
  const [modelState, setModelState] = useState(AddFormTemplate);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [openDeleteConfirmModal, setOpenDeleteConfirmModal] =
    React.useState(false);
  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentpage] = useState(1);
  const [tableFilters, setTablesFilters] = useState({
    searchData: "",
    status: "",
  });
  const [rowId, setRowId] = useState(null);
  const [modelData, setModelData] = useState({
    title: "Add New Form",
    editTitle: "Edit Form Builder",
    children: [
      {
        type: "input",
        name: "title",
        label: "Title",
        placeholder: "Enter Title",
        IsRequired: true,
      },
      {
        type: "dropdown",
        name: "type",
        label: "Type",
        placeholder: "Select Type",
        IsRequired: true,
      },
      {
        type: "description",
        name: "description",
        label: "Description",
        placeholder: "Enter description",
        IsRequired: true,
      },
    ],
  });

  const handleNavigateToDetails = (row) => {
    navigate(`form-view/${row[4]}`, {
      state: [
        { name: "Form Builder", path: location.pathname },
        {
          name: "Form View",
          path: `${location.pathname}/form-view`,
        },
      ],
    });
  };

  useEffect(() => {
    setLoading(true);
    getAllTemplateType();
  }, []);

  useEffect(() => {
    setLoading(true);
    getTemplateData();
  }, [currentPage, tableFilters]);

  const getAllTemplateType = () => {
    FormTemlateApi.getTemplateType()
      .then((res) => {
        if (res.status == 200) {
          setModelData((prevState) => ({
            ...prevState,
            children: prevState.children.map((child) =>
              child.name === "type" ? { ...child, data: res?.data } : child
            ),
          }));
        } else if (res.status == 204) {
          setLoading(false);
        }
      })
      .catch((err) => {
        dispatch(setSnackbar({ value: true, message: err, color: "error" }));
      });
  };

  const getTemplateData = () => {
    let filters = {
      ...tableFilters,
      status: TemplateStatus?.find((op) => op.id === tableFilters?.status)
        ?.name,
    };
    FormTemlateApi.getAllFormListing(10, currentPage, filters)

      .then((res) => {
        if (res.status == 200) {
          let rowData = convertFormData(res?.data);
          setTemplateData((prev) => ({
            ...prev,
            rows: [...rowData],
          }));
          setTotalPage(
            Math.ceil(
              res?.page_details?.no_of_records / res?.page_details?.limit
            )
          );
          setCurrentpage(res?.page_details?.page);
          setLoading(false);
        } else if (res.status == 204) {
          setTemplateData((prev) => ({
            ...prev,
            rows: [],
          }));
          setLoading(false);
        }
      })
      .catch((err) => {
        dispatch(setSnackbar({ value: true, message: err, color: "error" }));
        setLoading(false);
      });
  };

  const formTemaplateDetails = (id) => {
    FormTemlateApi.getFormTemplateDetails(id)
      .then((res) => {
        if (res?.status == 200) {
          res = res?.data;
          let data = {
            title: res?.title,
            description: res?.description,
            type: res?.template_type?.id,
          };
          setFormState({ status: res?.status, questions: res?.questions })
          dispatch(setFormData({ formData: res?.questions }));
          dispatch(setTemplateValues({ ...data }));
          setModelState(data);
          setOpenAddModal(true);
        }
      })
      .catch((error) => {
        dispatch(setSnackbar({ value: true, message: error, color: "error" }));
      });
  };
  const formTemaplateDelete = () => {
    setSubmitLoading(true);
    setOpenDeleteConfirmModal(false);
    FormTemlateApi.deleteFormTemplate(rowId)
      .then((res) => {
        if (res?.status == 200) {
          dispatch(setSnackbar({ value: true, message: res?.message, color: "success" }));
          setRowId("");
          setLoading(true);
          setSubmitLoading(false);
          getTemplateData();
        } else {
          setSubmitLoading(false);
          dispatch(
            setSnackbar({ value: true, message: res?.message, color: "error" })
          );
          setOpenDeleteConfirmModal(false);
        }
      })
      .catch((error) => {
        dispatch(setSnackbar({ value: true, message: error, color: "error" }));
        setOpenDeleteConfirmModal(false);
        setSubmitLoading(false);
      });
  };

  const handleOpenAddFormModal = () => {
    dispatch(clearTemplateValues());
    setModelState(AddFormTemplate);
    setRowId("");
    setOpenAddModal(true);
  };

  const handleOpenEditFormModal = (row) => {
    setRowId(row[4]);
    formTemaplateDetails(row[4]);
  };

  const addNewForm = (values) => {
    setSubmitLoading(true);
    let data = {
      title: values?.title,
      description: values?.description,
      type_id: values?.type,
      status: !rowId ? "Saved as Draft" : formState?.status,
      questions: !rowId ? formState?.questions ? JSON.stringify(formState?.questions) : "[]" : JSON.stringify(formState?.questions),
    };
    if (!rowId) {
      FormTemlateApi.addFormTemplate(data)
        .then((res) => {
          dispatch(
            setTemplateValues({
              title: values?.title,
              description: values?.description,
              type: values?.type,
            })
          );
          navigate(`create-form-builder/${res?.data?.id}`, {
            state: [
              { name: "Form Builder", path: location.pathname },
              {
                name: "Add New Form",
                path: `${location.pathname}/create-form-builder/${res?.data?.id}`,
              },
            ],
          });
          setSubmitLoading(false);
        })
        .catch((err) => {
          dispatch(setSnackbar({ value: true, message: err, color: "error" }));
          setSubmitLoading(false);
        });
    } else {
      FormTemlateApi.updateFormTemplate(data, rowId)
        .then((res) => {
          if (res.status === 200) {
            dispatch(
              setTemplateValues({
                title: values?.title,
                description: values?.description,
                type: values?.type,
              })
            );
            navigate(`update-form-builder/${rowId}`, {
              state: [
                { name: "Form Builder", path: location.pathname },
                {
                  name: "Update Form Builder",
                  path: `${location.pathname}/update-form-builder/${res?.data?.id}`,
                },
              ],
            });
          }
          setSubmitLoading(false);
        })
        .catch((err) => {
          dispatch(setSnackbar({ value: true, message: err, color: "error" }));
          setSubmitLoading(false);
        });
    }
  };

  const handleAddModelValues = (values) => {
    addNewForm(values);
  };
  const handleOpenDeleteConfirmModal = (row) => {
    setRowId(row[4]);
    setOpenDeleteConfirmModal(true);
  };
  const handleTableFilters = (value) => {
    setTablesFilters({ ...tableFilters, searchData: value });
    setCurrentpage(1)
  };

  const handleChangeDropDown = (e) => {
    setTablesFilters({ ...tableFilters, status: e.target.value });
    setCurrentpage(1)
  };
  const handleCloneButton = (row) => {
    setModelData((prevState) => ({
      ...prevState,
      title: "ClONE FORM TEMPLATE",
    }));
    formTemaplateDetails(row[4]);
  };
  const handlePagination = (value) => {
    setCurrentpage(value);
  };
  return (
    <>
      <div className="flex flex-col gap-0 bg-white p-0 !pb-0">
        <CustomModel
          rowId={rowId}
          modalInitialValues={modelState}
          initialValuesValidationSchema={addFormTemplateValidationSchema}
          open={openAddModal}
          setOpen={setOpenAddModal}
          setModelData={setModelData}
          modelData={modelData}
          handleModelValues={handleAddModelValues}
          isLoadingBtn={submitLoading}
        />
        <CustomTable
          rows={templateData?.rows}
          columnAlign={templateData?.columnAlign}
          columns={templateData?.columns}
          TableData={templateData?.TableData}
          tableFilters={tableFilters}
          handleTableFilters={handleTableFilters}
          handleChangeDropDown={handleChangeDropDown}
          handleNavigate={handleNavigateToDetails}
          handleButtonClick={handleOpenAddFormModal}
          handleEditButton={handleOpenEditFormModal}
          handleCloneButton={handleCloneButton}
          handleDeleteButton={handleOpenDeleteConfirmModal}
          loading={loading}
          handleChangePage={handlePagination}
          currentPage={currentPage}
          totalpage={totalPage}
        />
      </div>
      <DeleteConfirmContent
        open={openDeleteConfirmModal}
        setOpen={() => setOpenDeleteConfirmModal(false)}
        handleDelete={formTemaplateDelete}
        isLoading={submitLoading}
      />
      <CustomSnackbar
        open={snackbar?.value}
        setOpen={dispatch(setSnackbar)}
        color={snackbar?.color}
        message={snackbar?.message}
      />
    </>
  );
}