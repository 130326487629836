/* eslint-disable react/no-children-prop */
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import CustomTable from "../../components/custom-table/CustomTable";
import DeleteConfirmContent from "../../components/modal-contents/DeleteConfirmModalContent";
import { addPrincipalInvestigatorValues } from "../../utils/formik/initialValues";
import { addPrincipalInvestigatorValidationSchema } from "../../utils/formik/validationSchema";
import CustomModel from "../../components/custom-model/CustomModel";
import { principleInvestigatortData } from "../../utils/tableData";
import { CustomSnackbar } from "../../components/snackbar/CustomSnackbar";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../store/reducers/snackBar";
import PrincipleApi from "../../services/principleInvestigatorService";
import HospitalApi from "../../services/hospitalService";


const convertPIData = (data) => {
    return data.map(piData => {
        let piId = piData?.id;
        let piName = piData?.name;
        let hospitalName = piData?.hospital_details?.name;
        let noOfAssignedTrials = piData?.phaseCount;
        let noOfAssignedEditors = piData?.editorCount;
        let noOfAssignedSubjects = piData?.totalSubjectCount;
        let piEmail = piData?.email;
        let piCity = piData?.hospital_details?.city_details?.name
        return [piName, hospitalName, noOfAssignedTrials, noOfAssignedEditors, noOfAssignedSubjects, piEmail, piCity, piId];
    });
}

export const PrinciPleInvestigatorPage = () => {
    // const [searchValue, setSearchValue] = React.useState("");
    const snackbar = useSelector((state) => state.snackBar);
    const [openAddModal, setOpenAddModal] = React.useState(false);
    const [openEditPrincipalModal, setOpenEditPrincipalModal] = React.useState(false);
    const [openDeleteConfirmModal, setOpenDeleteConfirmModal] = React.useState(false);
    const [initialValues, setInitialValues] = useState(addPrincipalInvestigatorValues)
    const [totalPage, setTotalPage] = useState(1);
    const [currentPage, setCurrentpage] = useState(1);
    const [hospitals, setHospitals] = useState([]);
    const [loading, setLoading] = useState(false);
    const [piData, setPIData] = useState(principleInvestigatortData);
    const [piDataResponse, setPIDataResponse] = useState([]);
    const [rowId, setRowId] = useState(null)
    const [deleteRowData, setDeleteRowData] = useState(null)
    const [hospital, setHospital] = useState(null);
    const [tableFilters, setTablesFilters] = useState({ searchData: '' })
    const [submitLoading, setSubmitLoading] = useState(false);
    const [modelData, setModelData] = useState({
        title: "Add Principal Investigator",
        editTitle: 'Edit Principal Investigator',
        children: [
            { type: "input", name: "name", label: "Principal Investigator Name", placeholder: "Enter Principal Investigator Name", IsRequired: true },
            { type: "dropdown", name: "hospital", label: "Hospital", placeholder: "Select Hospital", IsRequired: true, data: [] },
            { type: "input", name: "email", label: "Email", placeholder: "Enter Email", IsRequired: true },
            { type: "number", name: "phone", label: "Phone Number", placeholder: "Enter Phone Number", IsRequired: true },
        ]
    })

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleNavigateToDetails = React.useCallback((row) => {
      navigate(`principal-investigator-details/${row[row?.length - 1]}`, {
        state: [
          { name: "Principal Investigator", path: location.pathname },
          {
            name: "Details",
            path: `${location.pathname}/principal-investigator-details/${row[row?.length - 1]}`,
          },
        ],
      });
    }, []);

    const handleOpenAddModal = () => {
        setInitialValues(addPrincipalInvestigatorValues)
        setRowId("");
        setOpenAddModal(true)
    }

    const handleOpenEditPrincipalModal = (row) => {
        setRowId(row[row?.length - 1])
        const piEditData = piDataResponse.find(pi => pi.id === row[row?.length - 1]);
        setInitialValues({
            name: piEditData?.name,
            email: piEditData?.email,
            country_code: piEditData?.country_code,
            phone: piEditData?.phone,
            hospital: piEditData?.hospital_details?.id,
            role_id: initialValues?.role_id
        })
        setOpenEditPrincipalModal(true);
    }

    const handleAddModelValues = async (values) => {
        const { hospital, ...rest } = values;
        const piValues = {
            ...rest,
            country_code: '91',
            phone: values.phone.toString(),
            hospital_id: hospital, 
        };

        try {
            setSubmitLoading(true);
            const res = await PrincipleApi.addPrincipleInvestigator(piValues);

            if (res.status === 200) {
                dispatch(setSnackbar({ value: true, message: res.message, color: "success" }));
                getPrincipalInvestigatorsList();
                setOpenAddModal(false); 
                setSubmitLoading(false);
            }
        } catch (error) {
            dispatch(setSnackbar({ value: true, message: error, color: "error" }));
            setSubmitLoading(false);
        }
    };


    const handleEditModelValues = async (values) => {
        try {
            const data = {
                name: values.name,
                email: values.email,
                phone: values?.phone.toString(),
                hospital_id: values?.hospital,
                country_code: '91',
                role_id: values?.role_id
            }
            setSubmitLoading(true);
            const res = await PrincipleApi.editPrincipleInvestigator(data, rowId)
                if (res.status === 200) {
                    dispatch(setSnackbar({ value: true, message: res.message, color: "success" }));
                    getPrincipalInvestigatorsList()
                    setOpenEditPrincipalModal(false);
                    setSubmitLoading(false);
                }

        } catch (error) {
            dispatch(setSnackbar({ value: true, message: error, color: "error" }));
            setSubmitLoading(false);
        }
    }

    const handleOpenDeleteConfirmModal = async (row) => {
        setOpenDeleteConfirmModal(true);
        setDeleteRowData(row)
    }
    const handleDeleteRow = async () => {
        try {
            setSubmitLoading(true);
            setOpenDeleteConfirmModal(false);
            const res = await PrincipleApi.deletePIData(deleteRowData[deleteRowData?.length - 1])
                if (res?.status === 200) {
                    getPrincipalInvestigatorsList();
                    setSubmitLoading(false);
                    dispatch(setSnackbar({ value: true, message: res.message, color: "success" }));
                }

        } catch (error) {
            dispatch(setSnackbar({ value: true, message: error, color: "error" }));
            setSubmitLoading(false);
        }
       
    }

    const handleTableFilters = (e) => {
        setCurrentpage(1)
        const search_value = e;
        if (/^\s/.test(search_value)) {
            return;
        } else {
            setTablesFilters({ ...tableFilters, searchData: search_value })
        }
    }
    const getHospitalList = () => HospitalApi.getHospitalData('', 1, '')
        .then((res) => {
            const rowData = res?.data?.map((list) => {
                return {
                    id: list?.id,
                    name: list?.name + ' ' + `(${list?.city_name})`
                };
            });
            setHospitals(rowData);
            setModelData(prevState => ({
                ...prevState,
                children: prevState.children.map(child =>
                    child.name === 'hospital' ? { ...child, data: hospitals } : child
                )
            }));
            setTotalPage(Math.ceil(res.data?.page_details?.no_of_records / res.data?.page_details?.limit));
            // setLoading(false);
        }
        )
        .catch((error) => {
            dispatch(setSnackbar({ value: true, message: error, color: "error" }));
        })

    const handlePagination = async (value) => {
        setCurrentpage(value);
    };

    const getPrincipalInvestigatorsList = () => PrincipleApi.viewPrincipleInvestigator({
        limit: 10,
        page: currentPage,
        role_id: 2,
        searchData: tableFilters?.searchData,
        hospital_id: hospital ? hospital : ""
    })
        .then((res) => {
            let rowData = convertPIData(res?.data || [])
            setPIDataResponse(res?.data)
            setPIData(prev => ({
                ...prev,
                rows: [...rowData]
            }));
            setTotalPage(Math.ceil(res?.page_details?.no_of_records / res?.page_details?.limit));
            setCurrentpage(res?.page_details?.page);
            setLoading(false);
        }
    ).catch((error) => {
        dispatch(setSnackbar({ value: true, message: error, color: "error" }));
    })

    useEffect(() => {
        getHospitalList();
    }, [])

    useEffect(() => {
        if (tableFilters?.searchData[tableFilters?.searchData?.length - 1] != " ") {
        setLoading(true)
        getPrincipalInvestigatorsList();
        }
    }, [currentPage, tableFilters, hospital])

    useEffect(() => {
        const newChildren = [
            { type: "input", name: "name", label: "Principal Investigator Name", placeholder: "Enter Principal Investigator Name", IsRequired: true },
            { type: "dropdown", name: "hospital", label: "Hospital", placeholder: "Select Hospital", IsRequired: true, data: hospitals },
            // { type: "number", name: "country_code", label: "Country Code", placeholder: "Country Code", IsRequired: true },
            { type: "number", name: "phone", label: "Phone Number", placeholder: "Enter Phone Number", IsRequired: true },
        ];

        if (rowId == '') {
            newChildren.push({ type: "input", name: "email", label: "Email", placeholder: "Enter Email", IsRequired: true },
            );
        } else {
            newChildren.push({ type: "input", name: "email", label: "Email", placeholder: "Enter Email", IsRequired: true, disabled: true },)
        }
        setModelData(prevModelData => ({
            ...prevModelData,
            children: newChildren
        }));
    }, [rowId]);

    const handleHospitalChange = (e) => {
        setHospital(e.target.value);
        setCurrentpage(1)
    }

    const filteredHospitals = hospitals?.map(hospital => ({
        id: hospital.id,
        name: hospital.name
    }));
    return <>
        <div className="flex flex-col gap-0 bg-white p-0 !pb-0">
            <CustomModel
                rowId={rowId}
                modalInitialValues={initialValues}
                initialValuesValidationSchema={addPrincipalInvestigatorValidationSchema}
                open={openEditPrincipalModal ? openEditPrincipalModal : openAddModal}
                setOpen={openEditPrincipalModal ? setOpenEditPrincipalModal : setOpenAddModal}
                setModelData={setModelData}
                modelData={modelData}
                handleModelValues={openEditPrincipalModal ? handleEditModelValues : handleAddModelValues}
                hospitals={hospitals}
                isLoadingBtn={submitLoading}
            />
            <CustomTable
                rows={piData?.rows}
                columnAlign={piData?.columnAlign}
                columns={piData?.columns}
                TableData={piData?.TableData}
                tableFilters={tableFilters}
                handleTableFilters={handleTableFilters}
                handleNavigate={handleNavigateToDetails}
                handleButtonClick={handleOpenAddModal}
                handleEditButton={handleOpenEditPrincipalModal}
                handleDeleteButton={handleOpenDeleteConfirmModal}
                loading={loading}
                totalpage={totalPage}
                currentPage={currentPage}
                handleChangePage={handlePagination}
                MenuList={filteredHospitals}
                handleChangeDropDown={handleHospitalChange}
                dropDownValue={hospital}
            />
        </div>
        <DeleteConfirmContent open={openDeleteConfirmModal} setOpen={setOpenDeleteConfirmModal} handleCancel={() => setOpenDeleteConfirmModal(false)} handleDelete={handleDeleteRow} isLoading={submitLoading} />
        <CustomSnackbar open={snackbar?.value} setOpen={setSnackbar} color={snackbar?.color} message={snackbar?.message} />
    </>
}

