import React, { useState } from "react";
import { FormHelperText, Checkbox } from "@mui/material";
import PropTypes from "prop-types";
const CustomCheckbox = React.memo(
  ({
    error,
    InputId,
    value,
    InputName,
    onChange,
    helperText,
    touched,
    errorClasss,
    placeholder,
  }) => {
    const [checked, setChecked] = useState(value);

    const handleCheckboxChange = (event) => {
      setChecked(event.target.checked);
      onChange(event);
    };

    return (
      <div className="relative flex-grow mt-3">
        <div className="flex items-center">
          <Checkbox
            id={InputId}
            name={InputName}
            checked={checked}
            onChange={handleCheckboxChange}
            className="p-0 mr-1"
            sx={{
              "&.Mui-checked": {
                color: "rgb(53, 113, 255)",
              },
            }}
          />

          <span className="text-sm">{placeholder}</span>
        </div>
        {touched && error && (
          <FormHelperText
            error
            id={`standard-weight-helper-text-${InputId}`}
            sx={errorClasss ? {} : { position: "absolute" }}
            className=""
          >
            {helperText}
          </FormHelperText>
        )}
      </div>
    );
  }
);

CustomCheckbox.propTypes = {
  error: PropTypes.bool,
  InputId: PropTypes.string,
  value: PropTypes.bool,
  InputName: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  touched: PropTypes.bool,
};

export default CustomCheckbox;
