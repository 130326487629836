// material-ui
import { Box,useMediaQuery } from '@mui/material';

// project import
import Profile from './Profile';
import MobileSection from './MobileSection';
import React from 'react';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = ({handleSetOpenLogoutSnackbarTrue}) => {
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <>
      {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}
      {!matchesXs && <Profile handleSetOpenLogoutSnackbarTrue={handleSetOpenLogoutSnackbarTrue} />}
      {matchesXs && <MobileSection handleSetOpenLogoutSnackbarTrue={handleSetOpenLogoutSnackbarTrue} />}
    </>
  );
};

export default HeaderContent;
