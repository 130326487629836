import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const SkeltonLoaderHeader = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        padding: 2,
        border: "1px solid #e0e0e0",
        borderRadius: 2,
        backgroundColor: "#fff",
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Typography variant="h6">
          <Skeleton width="60%" />
        </Typography>
        <Typography variant="body2" color="textSecondary">
          <Skeleton width="40%" />
        </Typography>
        <Box sx={{ display: "flex", gap: 1, marginTop: 1 }}>
          <Skeleton variant="rectangular" width={50} height={30} />
          <Skeleton variant="rectangular" width={50} height={30} />
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ marginLeft: 1 }}>
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton width={100} />
            <Skeleton width={80} />
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ marginLeft: 1 }}>
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton width={100} />
            <Skeleton width={80} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SkeltonLoaderHeader;
