import dashboard from "../menu-items/dashboard";

//dev api base url
//export const baseURL = "http://13.234.173.125:8081";


//staging api base url
//  export const baseURL =  "https://immunoact.hashstudioz.com";

// uat api base url
export const baseURL =  "https://uat.immunoact.hashstudioz.com";


// Patient table data conversion
export const convertSubjectData = (data) => {
  return data?.map((sub) => {
    let transformCode = sub?.subject_code;
    let transformedSubjectInitial = sub?.sub_initials;
    let transformeInvestigator = sub?.investigatorDetails?.name;
    let transformedEditor = sub?.editorDetails?.name;
    return [
      transformCode,
      transformedSubjectInitial,
      transformeInvestigator,
      transformedEditor,
    ];
  });
};

export const checkForSubchild = (firstSegment) => {
  let isChild = null
  for (let item of dashboard.children) {
    if (item?.subChildren) {
      for (let subItem of item.subChildren) {
        if (subItem.id === firstSegment) {
          isChild = subItem;
          isChild.parent = item.title;
          isChild.parentId = item.id;
          break;
        }
      }
    }
  }
  return isChild
};




function isDarkColor(color) {
  const r = parseInt(color.substr(1, 2), 16);
  const g = parseInt(color.substr(3, 2), 16);
  const b = parseInt(color.substr(5, 2), 16);
  const brightness = 0.299 * r + 0.587 * g + 0.114 * b;
  return brightness < 128;
}

const usedColors = new Set();

export function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color;

  do {
    color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
  } while (
    isDarkColor(color) ||
    color === "#000000" ||
    color === "#00008B" ||
    usedColors.has(color)
  );

  usedColors.add(color);
  return color;
}
const formatDate = (dateString) => {
  const [year, month, day] = dateString.split('-');
  return `${day}/${month}/${year}`;
};

// Helper function to get all unique questions
const getAllUniqueQuestions = (data) => {
  const questions = new Set();
  data.forEach((item) => {
    item.appointment.forEach((appointment) => {
      appointment.reports.forEach((report) => {
        questions.add(report.question.trim()); // Add question to the set
      });
    });
  });
  return Array.from(questions); // Convert set back to an array
};

export const jsonToCsv = (data) => {
  const csvRows = [];

  // Define static headers (excluding the columns to be moved to the end)
  const headers = [
    'Patient ID', 
    'First Name', 
    'Last Name',
    'Appointment Title'
  ];

  // Get all unique dynamic headers (questions) from all appointments
  const dynamicHeaders = getAllUniqueQuestions(data);

  // Add the columns to be moved to the end
  const endingHeaders = [
    'Open Queries',
    'Pending Approval',
    'Approved',
  ];

  // Combine headers and push to CSV rows
  csvRows.push([...headers, ...dynamicHeaders, ...endingHeaders].map(header => `"${header}"`).join(','));

  // Extract rows
  data.forEach((item) => {
    item.appointment.forEach((appointment) => {
      // Static data without the moved columns
      const staticData = [
        item.subject.subject_code,
        item.subject.first_name,
        item.subject.last_name,
        appointment.title
      ];

      // Dynamic data from reports
      const dynamicData = dynamicHeaders.map((header) => {
        // Find the report with the current question (header)
        const report = appointment.reports.find(r => r.question.trim() === header);
        let answer = report?.answer || ''; // Use empty string if no answer is found

        // Format date answers
        if (typeof answer === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(answer)) {
          answer = formatDate(answer); // Assuming you have a formatDate function
        }

        // Handle answers that might be arrays or null
        if (Array.isArray(answer)) {
          return `"${answer.filter(Boolean).join(', ')}"`; // Filter out nulls and join array answers with commas
        } else if (answer === null || answer === "") {
          return ''; // Leave empty for null or empty answers
        } else {
          return `"${answer.replace(/\n/g, ' ')}"`; // Replace newlines with spaces
        }
      });

      // Data for the columns moved to the end
      const endingData = [
        appointment.query_raised?.length > 0 ? `"${appointment.query_raised.join(', ')}"` : 'N/A', // Join query raised with semicolon
        appointment.pending_users?.length > 0 ? `"${appointment.pending_users.join(', ')}"` : 'N/A', // Join pending users with semicolon
        appointment.approved_users?.length > 0 ? `"${appointment.approved_users.join(', ')}"` : 'N/A', // Join approved users with semicolon
      ];

      // Combine static, dynamic, and ending data and push to CSV rows
      csvRows.push([...staticData, ...dynamicData, ...endingData].join(','));
    });
  });

  return csvRows.join('\n');
};

export const downloadCsv = (csvContent, filename) => {
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);

  // Create a link element, set the download attribute, and trigger a click event
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();

  // Clean up
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};