import {
  InputAdornment,
  OutlinedInput,
  useTheme,
} from "@mui/material";
import { SearchIcon } from "../../assets/common-svg/CommonSvg";
import PropTypes from "prop-types";

export const CustomSearchbar = ({
  handleSearch = () => {},
  value,
  Placeholder,
  BorderRadius,
  disabled,
  BgColor,
}) => {
  const theme = useTheme();
  return (
    <OutlinedInput
      id="outlined-searchbar"
      type="text"
      className={`w-100 border border-grey-200 bg-[${BgColor}]`}
      value={value}
      disabled={disabled}
      onChange={(e) => {
        if (e.target.value !== " ") {
          handleSearch(e.target.value.trimStart());
        }
      }}
      classes={{ notchedOutline: "notched-outline-none" }}
      sx={{
        backgroundColor: disabled ? theme.palette.grey[200] : "white",
        color: disabled ? theme.palette.primary.light : "black",
        "& input::placeholder": {
          color: "#777777", // Change the placeholder color to blue
          opacity: 1, // Make the placeholder fully opaque
        },
        height: "45px",
        borderRadius: BorderRadius ? BorderRadius : "0.75rem",
      }}
      placeholder={Placeholder}
      endAdornment={
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      }
    />
  );
};

export default CustomSearchbar;

CustomSearchbar.proptypes = {
  handleSearch: PropTypes.func,
  handleSearchValueChange: PropTypes.func,
  Placeholder: PropTypes.string,
  Width: PropTypes.string,
  Value: PropTypes.string,
  disabled: PropTypes.bool
};
