import { Typography } from '@mui/material';
import { BackNavIcon } from '../../assets/common-svg/CommonSvg'
import PropTypes from 'prop-types';

const HeaderBackNav = ({ Title, handleBackNav, children }) => {
  return <div className="w-full mb-3 flex flex-row items-center justify-between">
    <div className='flex items-center gap-2'>
      <div onClick={handleBackNav} className='cursor-pointer mr-1 '>
        <BackNavIcon />
      </div>
       <Typography variant='h6' fontWeight={600}>{Title}</Typography>
    </div>
    <div className={` flex justify-end items-center`}>
      {children}
    </div>
  </div>
}
export default HeaderBackNav;

HeaderBackNav.proptypes = {
  Title: PropTypes.string,
  handleBackNav: PropTypes.string,
  TitleFontSize: PropTypes.string
}
