import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ListItemButton, ListItemIcon } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setActiveSmallScreenMenu, openDrawer } from "../../../../../store/reducers/menu";
import { CustomTooltip } from "../../../../../components/custom-tooltip/CustomTooltip";
import { MinusOutlined } from "@ant-design/icons";

const NavItem = ({ item, level, setSubChildMenu }) => {
  const dispatch = useDispatch();
  const { drawerOpen, openItem, activeSubMenu } = useSelector(
    (state) => state.menu
  );
  const isSelected = openItem[0] === item.id;
  const isChildSelected = openItem[1] === item.id;


  const handleItemClick = (item) => {
    if (item?.subChildren && item?.subChildren.length != 0) {
      setSubChildMenu(item.subChildren)
      dispatch(openDrawer({ drawerOpen: true }))
      dispatch(setActiveSmallScreenMenu({ activeSmallScreenMenu: [item?.id] }));
    } else {
      setSubChildMenu([])
      dispatch(setActiveSmallScreenMenu([]))
      dispatch(openDrawer({ drawerOpen: false }))
    }
  };


  const Icon = item.icon;
  const itemIcon = item.icon ? (
    <Icon style={{ fontSize: drawerOpen ? "1rem" : "1.25rem" }} />
  ) : null;

  return (
    <>
      {item.type === "child-item" ? (
        <div
          className={`flex flex-col  hover:opacity-100 ${isChildSelected ? "opacity-100" : "opacity-50"
            }`}
        >
          <Link
            to={item?.subChildren ? null : item.url}
            onClick={() => dispatch(openDrawer({ drawerOpen: false }))}
            variant="button"
            underline="none"
            className={`hover:no-underline text-white flex items-center gap-2   `}
          >
            {<MinusOutlined />}
            {item.title}
          </Link>
        </div>
      ) : (
        <div>
          <ListItemButton
            component={item?.subChildren ? 'div' : Link}
            to={item?.subChildren ? null : item.url}
            onClick={() => { handleItemClick(item) }}
            selected={isSelected}
            className="opacity-50 hover:opacity-100 hover:bg-[#1890ff1f]"
            sx={{
              zIndex: 1201,
              margin: "0px 7px 14px 7px",
              borderRadius: "7px",
              justifyContent: "start",
              px: 0,
              py: !drawerOpen && level === 1 ? 1 : 1,
              ...{
                "&.Mui-selected": {
                  bgcolor: isSelected ? "#1890ff1f" : "transparent",
                  opacity: isSelected ? "100%" : "20%",
                  ":hover": {
                    backgroundColor: "#1890ff1f",
                  },
                },
              },
            }}
          >
            <CustomTooltip title={item.title}>
              <ListItemIcon className=" flex justify-center items-center py-1">
                {activeSubMenu === item.id ? "" : itemIcon}
              </ListItemIcon>
            </CustomTooltip>
          </ListItemButton>
        </div>
      )}
    </>
  );
};

NavItem.propTypes = {
  item: PropTypes.object.isRequired,
  level: PropTypes.number.isRequired,
  setActiveItemId: PropTypes.func.isRequired,
};

export default NavItem;
