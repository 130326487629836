import {
  CSVIcon,
  DefaultFileIcon,
  DocIcon,
  PdfIcon,
  XLSXIcon,
} from "../../AppIcons";

export const getFileIcon = (file) => {
  const fileExtension =
    file?.name?.split(".").pop().toLowerCase() ||
    (file && file?.split("/")?.[1]);

  switch (fileExtension) {
    case "pdf":
      return <PdfIcon />;
    case "doc":
    case "docx":
    case "vnd.openxmlformats-officedocument.wordprocessingml.document":
      return <DocIcon />;
    case "xls":
    case "xlsx":
    case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return <XLSXIcon />;
    case "csv":
      return <CSVIcon />;
    default:
      return <DefaultFileIcon />;
  }
};
