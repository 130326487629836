import httpService from "./httpService";

const NotifiactionApi = {

    getNotifiactionList: (limit,page) => httpService.get(`notifiaction`, {
        params: {
            limit,
            page: page,
        }
    }),
    updateNotifiaction: (id) => httpService.post(`read-notifiaction`, { id: id }),
    countNotifiaction: () => httpService.get(`notifiaction-count`),

}

export default NotifiactionApi;