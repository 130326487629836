import React from "react";
import { InfoIcon } from "../../assets/images/drawerIcons/infoIcon";
import { Chip, Typography, useTheme } from "@mui/material";
import { BackNavIcon } from "../../assets/common-svg/CommonSvg";

export const MessageHeader = ({
  headingName,
  subHeading,
  rightHeading,
  handleShowQuerModal,
  color,
  handleShowMessageBox,
}) => {
  const theme = useTheme();
  return (
    <div className="flex flex-row md:flex-col gap-3 lg:!gap-0 sm:!flex-row justify-between items-start lg:items-center ">
      {/* left div */}
      <div className="flex flex-col !gap-[5px] items-start">
        <div className="flex items-center gap-2">
          <div
            onClick={handleShowMessageBox}
            className="cursor-pointer block lg:hidden"
          >
            <BackNavIcon />
          </div>
          <Typography
            className="text-sm sm:text-base capitalize"
            variant="h5"
            fontWeight={theme.typography.fontWeightBold}
          >
            {headingName}
          </Typography>
        </div>
        <Chip variant="filled" size="small" color={color} label={subHeading} />
      </div>
      {/* right div */}
      <div
        className="form-query flex items-center justify-center md:w-full sm:!w-max gap-[5px] cursor-pointer"
        onClick={handleShowQuerModal}
      >
        <Typography
          variant="body1"
          fontWeight={theme.typography.fontWeightBold}
          className="hidden md:block"
        >
          {rightHeading}
        </Typography>
        <div className="block">
          <InfoIcon />
        </div>
      </div>
    </div>
  );
};
