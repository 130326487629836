import { Typography } from "@mui/material"
import PropTypes from 'prop-types';



export const DynamicHeading = ({ HeadingFontSize, headingName, HeadingFontWeight, FontSyle, HeadingVariant, MarginBottom, HeadingAlign, heading_class, HeadingColor }) => {
    return <Typography color={HeadingColor ?? "success"} variant={HeadingVariant} className={heading_class} sx={{ fontSize: ((HeadingFontSize) ? HeadingFontSize || "22px" : null), fontWeight: HeadingFontWeight ?? "normal", fontStyle: FontSyle, marginBottom: MarginBottom }} textAlign={HeadingAlign}>{(headingName ?? "")}</Typography>
}


DynamicHeading.proptypes = {
    HeadingColor: PropTypes.string,
    HeadingFontSize: PropTypes.string,
    headingName: PropTypes.string,
    HeadingFontWeight: PropTypes.oneOf[PropTypes.number, PropTypes.string],
    FontSyle: PropTypes.string,
    heading_class: PropTypes.string,
    HeadingVariant: PropTypes.string,
    Marginbottom: PropTypes.string,
    HeadingAlign: PropTypes.string
}