import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {  ViewMoreIcon } from '../../assets/common-svg/CommonSvg';



const ITEM_HEIGHT = 48;

export default function CustomMenuBar({ handleEdit, handleDelete, cardId, options }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuList = (event, ID) => {
        setAnchorEl(null);
        switch (event.toLowerCase()) {
            case "edit":
                {
                    handleEdit(ID);
                    break;
                }
            case "delete":
                {
                    handleDelete(ID);
                    break;
                }
        }

    }

    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <ViewMoreIcon />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                    },
                }}
            >
                {options?.map((option, i) => (
                    <MenuItem
                        sx={{ '.css-qrmnmv-MuiButtonBase-root-MuiMenuItem-root': { minHeight: "none" } }}
                        divider={(i < options.length - 1) ? true : false}
                        key={option}
                        onClick={() => handleMenuList(option.optionName, cardId)}
                    >
                        <div className='flex gap-2 items-center'>{option.optionIcon}{option.optionName}</div>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}