import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CustomModal from '../custom-modal/CustomModal';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';


const AddPiAndEditorForAllModalContent = ({ open, setOpen, handleClick, isLoading = false }) => {

    return (
      <CustomModal open={open} setOpen={setOpen}>
        <div className="h-full flex flex-col gap-8 pl-3 pr-3">
          <Typography
            align="center"
            variant="h6"
            fontWeight={700}
            component="h6"
          >
            Do you want to assign this principal investigator and editor for all the unassigned Patients ?
          </Typography>
          <div className="flex justify-center gap-12">
          <LoadingButton
              variant="contained"
              className="w-[160px] h-[40px]"
              color='success'
              onClick={()=>handleClick("YES")}
              disabled={isLoading}
            >
              <span>Yes</span>
            </LoadingButton>
            <Button
              variant="contained"
              sx={{bgcolor:"#6D766D"}}
              className="w-[160px] h-[40px]"
              disabled ={isLoading}
              onClick={() => {
                handleClick("NO");
              }}
            >
              No
            </Button>
           
          </div>
        </div>
      </CustomModal>
    );
}
AddPiAndEditorForAllModalContent.proptypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    handleDelete: PropTypes.func,
}
export default AddPiAndEditorForAllModalContent;