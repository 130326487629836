import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { AppBar, IconButton, Toolbar, useMediaQuery } from '@mui/material';

// project import
import AppBarStyled from './AppBarStyled';
import HeaderContent from './HeaderContent';

// assets
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import React from 'react';
import { CustomSnackbar } from '../../../components/snackbar/CustomSnackbar';

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = ({ open, handleDrawerToggle }) => {
  const [openLogoutSnackbar, setOpenLogoutSnackbar] = React.useState(false);

  const handleSetOpenLogoutSnackbarTrue = () => {
    setOpenLogoutSnackbar(true);
  }

  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

  const iconBackColor = 'grey.200';
  const iconBackColorOpen = 'grey.200';

  // common header
  const mainHeader = (
    <Toolbar sx={{ paddingLeft: "15px !important", paddingRight: "17px !important" }}>
      {matchDownMD ? <IconButton
        disableRipple
        aria-label="open drawer"
        onClick={handleDrawerToggle}
        edge="start"
        color="secondary"
        sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor, ml: { xs: 0, lg: -2, } }}
      >
        {!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </IconButton> : null}
      <HeaderContent handleSetOpenLogoutSnackbarTrue={handleSetOpenLogoutSnackbarTrue} />
    </Toolbar>
  );

  // app-bar params
  const appBar = {
    position: 'fixed',
    color: 'inherit',
    elevation: 0,
    sx: {
      borderBottom: `1px solid ${theme.palette.divider}`

    }
  };

  return (
    <>
      {!matchDownMD ? (
        <AppBarStyled open={open} {...appBar} sx={{ backgroundColor: '#FFFFFF' }}>
          {mainHeader}
        </AppBarStyled>
      ) : (
        <AppBar sx={{ backgroundColor: "black" }} {...appBar}>{mainHeader}</AppBar>
      )}
      {openLogoutSnackbar ? <CustomSnackbar open={openLogoutSnackbar} setOpenLoginSnackbar={setOpenLogoutSnackbar} message="Logout successfully" /> : ""}
    </>
  );
};

Header.propTypes = {
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func
};

export default Header;
