/* eslint-disable react/no-children-prop */
import React, { useCallback } from "react"
import { useNavigate } from "react-router"
import CustomTable from "../../components/custom-table/CustomTable"
import AddImmunoActQAModalContent from "../../components/modal-contents/AddImmnoActQAModalContent"

const rows = [
    ['Ravi Kant Bhel', 'ravi@gmail.com', '2'],
    ['Dr Rohit Kumar', 'Rohit@gmail.com', '4'],
    ['Sandeep Parikh', 'Sandeep@gmail.com', '6'],
    ['Suresh Pandove', 'Suresh@gmail.com', '6'],
    ['Dr Om Prasad', 'Om@gmail.com', '7'],
    ['Dr Rahul Bhandari', 'Rahul@gmail.com', '8'],
    ['Dr Rohit Sharma', 'ravi@gmail.com', '6'],
    ['Dr Aman Sharma', 'Aman@gmail.com', '3'],
    ['Ravi Kant Bhel', 'Kant@gmail.com', '7'],
    ['Dr Rohit Kumar', 'Rohit@gmail.com', '9'],
];

const columns = ['Name', 'Email', 'Assigned Trails'];
const columnAlign = [
    { rowAlign: 'left', colAlign: 'left', multi: false, show: true },
    { rowAlign: 'left', colAlign: 'left', multi: true, multiIndexColumn: "Gender", show: true },
    { rowAlign: 'left', colAlign: 'left', multi: true, multiIndexColumn: 'Hospital', show: true },
    { rowAlign: 'center', colAlign: 'center', multi: false, show: true },
    { rowAlign: 'center', colAlign: 'center', multi: false, show: false },
    { rowAlign: 'center', colAlign: 'center', multi: false, show: false },
];
const TableData = {
    // tableHeading: "Subject",
    route: 'ImmunoQA',
    filters: [
        { key: 'search', class: 'col-start-1  col-end-4', inputBgColor: "#FFFFFF", placeholder: "Search by Name" },
        // { key: 'select', class: 'col-start-7  col-end-10', placeholder: "Select Editor" },
        // { key: 'select', class: 'col-start-10  col-end-13', placeholder: "Select PI" },
        { key: 'button', class: 'col-start-10  col-end-13', placeholder: "Add ImmunoACT QA" },
    ],
    actions: [
        { key: "view" },
        { key: "delete" },
        { key: "edit" },
    ]
}

export const ImmunoQA = () => {
    const [openAddQAModal, setOpenAddQAModal] = React.useState(false);
    const [openEditQAModal, setOpenEditQAModal] = React.useState(false);
    // const [openDeleteModal, setOpeDeleteModal] = React.useState(false);

    const navigate = useNavigate();
    const handleNavigateToDetails = useCallback(() => {
        navigate("/immuno-act-poc");
    }, []);

    const handleOpenAddQAModal = () => {
        setOpenAddQAModal(true);
    }

    const handleOpenEditQAModal = () => {
        setOpenEditQAModal(true);
    }


    return <>
        <div className="flex flex-col gap-0 bg-white p-0 !pb-0">
            <CustomTable
                rows={rows}
                columnAlign={columnAlign}
                columns={columns}
                TableData={TableData}
                handleNavigate={handleNavigateToDetails}
                handleButtonClick={handleOpenAddQAModal}
                handleEditButton={handleOpenEditQAModal}
            />
        </div>
        <AddImmunoActQAModalContent editSubAdminBoolean={openEditQAModal ? true : false} open={openEditQAModal ? openEditQAModal : openAddQAModal} setOpen={openEditQAModal ? setOpenEditQAModal : setOpenAddQAModal} />
    </>
}

