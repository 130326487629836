import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { addIAQAValues } from "../../utils/formik/initialValues";
import { addUserSchema } from "../../utils/formik/validationSchema";
import { QualityAssociateData } from "../../utils/tableData";
import DeleteConfirmContent from "../../components/modal-contents/DeleteConfirmModalContent";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../store/reducers/snackBar";
import SubAdminApi from "../../services/subAdminService";
const CustomTable = Loadable(
  React.lazy(() => import("../../components/custom-table/CustomTable"))
);
const CustomModel = Loadable(
  React.lazy(() => import("../../components/custom-model/CustomModel"))
);
import Loadable from "../../components/Loadable";
import { CustomSnackbar } from "../../components/snackbar/CustomSnackbar";

const convertQualityAssociateData = (data) => {
  return data.map((qualityAssociateData) => {
    let transformedName = qualityAssociateData?.name;
    let transformeEmail = qualityAssociateData?.email;
    let transformedAssigned = qualityAssociateData?.phaseCount;
    let transformRowId = qualityAssociateData?.id;
    return [
      transformedName,
      transformeEmail,
      transformedAssigned,
      transformRowId,
    ];
  });
};

export const QualityAssociate = () => {
  const dispatch = useDispatch();
  const snackbar = useSelector((state) => state.snackBar);
  const [openAddModal, setOpenAddModal] = React.useState(false);
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [openDeleteConfirmModal, setOpenDeleteConfirmModal] =
    React.useState(false);
  const [tableFilters, setTablesFilters] = useState({ searchData: "" });
  const [qualityAssociateData, setQualityAssociateData] =
    useState(QualityAssociateData);
  const [initialValues, setInitialValues] = useState(addIAQAValues);
  const [qualityAssociateResponse, setQualityAssociateResponse] = useState([]);
  const [currentPage, setCurrentpage] = useState(1);
  const [rowId, setRowId] = useState(null);
  const [roleID, setRoleID] = useState(3);
  const [roleList, setRoleList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [deleteRowData, setDeleteRowData] = useState(null);
  const [modelData, setModelData] = useState({
    title: "Add Quality Associate",
    editTitle: "Edit Quality Associate",
    children: [
      {
        type: "dropdown",
        name: "userType",
        label: "User Type",
        placeholder: "Enter Type",
        data: roleList,
        IsRequired: true,
        disabled: true,
      },
      {
        type: "input",
        name: "name",
        label: "Name",
        placeholder: "Enter Name",
        IsRequired: true,
      },
      {
        type: "input",
        name: "email",
        label: "Email",
        placeholder: "Enter Email",
        IsRequired: true,
      },

      {
        type: "number",
        name: "phoneNumber",
        label: "Phone Number",
        placeholder: "Enter Phone Number",
        IsRequired: true,
      },
    ],
  });

  useEffect(() => {
    const newChildren = [
      {
        type: "dropdown",
        name: "userType",
        label: "User Type",
        placeholder: "Enter Type",
        data: roleList,
        IsRequired: true,
        disabled: true,
      },
      {
        type: "input",
        name: "name",
        label: "Name",
        placeholder: "Enter Name",
        IsRequired: true,
      },
      {
        type: "number",
        name: "phoneNumber",
        label: "Phone Number",
        placeholder: "Enter Phone Number",
        IsRequired: true,
      },
    ];

    if (rowId == "") {
      newChildren.push({
        type: "input",
        name: "email",
        label: "Email",
        placeholder: "Enter Email",
        IsRequired: true,
      });
    } else {
      newChildren.push({
        type: "input",
        name: "email",
        label: "Email",
        placeholder: "Enter Email",
        IsRequired: true,
        disabled: true,
      });
    }
    setModelData((prevModelData) => ({
      ...prevModelData,
      children: newChildren,
    }));
  }, [rowId]);

  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigateToDetails = React.useCallback((rowData) => {
    const rowId = rowData?.[rowData?.length - 1] || "";
    navigate(`quality-associate-details/${rowId}`, {
      state: [
        { name: "Quality Associate", path: location.pathname },
        {
          name: "Details",
          path: `${location.pathname}/quality-associate-details/${rowId}`,
        },
      ],
    });
  }, []);

  useEffect(() => {
    getSubAdminListByRoles();
  }, []);

  useEffect(() => {
    if (tableFilters?.searchData[tableFilters?.searchData?.length - 1] != " ") {
      setLoading(true);
      getSubAdminList();
    }
  }, [currentPage, tableFilters, roleID]);

  const getSubAdminListByRoles = () =>
    SubAdminApi.getSubAdminListByRoles().then((res) => {
      const dataList = res?.data?.map((list) => {
        return {
          id: list?.id,
          name: list?.title,
        };
      });

      setRoleList(dataList);
    });

  const getSubAdminList = () =>
    SubAdminApi.getSubAdminData(
      10,
      currentPage,
      tableFilters?.searchData,
      roleID
    )
      .then((res) => {
        let rowData = convertQualityAssociateData(res?.data || []);
        setQualityAssociateResponse(res?.data);
        setQualityAssociateData((prev) => ({
          ...prev,
          rows: [...rowData],
        }));

        setTotalPage(
          Math.ceil(res?.page_details?.no_of_records / res?.page_details?.limit)
        );
        setCurrentpage(res?.page_details?.page);
        setLoading(false);
      })
      .catch((error) => {
        dispatch(setSnackbar({ value: true, message: error, color: "error" }));
        setLoading(false);
      });

  const handleOpenAddModal = () => {
    setInitialValues(addIAQAValues);
    setRowId("");
    setOpenAddModal(true);
  };

  const handleOpenEditPrincipalModal = (row) => {
    setRowId(row[row?.length - 1]);
    const subAdminEditData = qualityAssociateResponse.find(
      (qualityAssociateData) => qualityAssociateData.id === row[row?.length - 1]
    );
    setInitialValues({
      userType: subAdminEditData?.role_id,
      name: subAdminEditData?.name,
      email: subAdminEditData?.email,
      phoneNumber: subAdminEditData?.phone,
      selectTrial: subAdminEditData?.phase_id,
    });
    setOpenEditModal(true);
  };
  const handleAddModelValues = (values) => {
    setSubmitLoading(true);
    SubAdminApi.addSubAdmin({
      name: values?.name,
      email: values?.email,
      country_code: "91",
      phone: values?.phoneNumber.toString(),
      role_id: values?.userType,
    })
      .then((res) => {
        if (res?.status === 200) {
          getSubAdminList();
          dispatch(setSnackbar({ value: true, message: res.message , color:"success"}));
          setOpenAddModal(false);
          setSubmitLoading(false);
        }
      })
      .catch((error) => {
        dispatch(setSnackbar({ value: true, message: error, color: "error" }));
        setSubmitLoading(false);

      })
  };

  const handleEditModelValues = (values) => {
    setSubmitLoading(true);
    SubAdminApi.editSubAdmin(
      {
        name: values?.name,
        email: values?.email,
        country_code: "91",
        phone: values?.phoneNumber?.toString(),
        role_id: values?.userType,
      },
      rowId
    )
      .then((res) => {
        if (res.status === 200) {
          setOpenEditModal(false);
          setSubmitLoading(false);
          getSubAdminList();
          dispatch(setSnackbar({ value: true, message: res.message, color:"success" }));
        }
      })
      .catch((error) => {
        dispatch(setSnackbar({ value: true, message: error, color: "error" }));
        setSubmitLoading(false);
      })
  };

  const handleOpenDeleteConfirmModal = (row) => {
    setOpenDeleteConfirmModal(true);
    setDeleteRowData(row);
  };

  const handlePagination = (value) => {
    setCurrentpage(value);
  };

  const handleTableFilters = (e) => {
    setTablesFilters({ ...tableFilters, searchData: e });
    setCurrentpage(1)
  };

  const handleDeleteRow = () => {
    setSubmitLoading(true);
    setOpenDeleteConfirmModal(false);
    SubAdminApi.deleteSubAdmin(deleteRowData[3])
    .then((res) => {
      if (res?.status === 200) {
        setSubmitLoading(false);
          getSubAdminList();
          dispatch(setSnackbar({ value: true, message: res.message, color: "success" }));
        }
      })
      .catch((error) => {
        dispatch(setSnackbar({ value: true, message: error, color: "error" }));
        setSubmitLoading(false);
      })
  };

  const handleRoleIdChange = (e) => {
    setRoleID(e.target.value);
  };

  return (
    <>
      <div className="flex flex-col gap-0 bg-white p-0 !pb-0">
        <CustomModel
          rowId={rowId}
          modalInitialValues={initialValues}
          initialValuesValidationSchema={addUserSchema}
          open={openEditModal ? openEditModal : openAddModal}
          setOpen={openEditModal ? setOpenEditModal : setOpenAddModal}
          setModelData={setModelData}
          modelData={modelData}
          handleModelValues={
            openEditModal ? handleEditModelValues : handleAddModelValues
          }
          isLoadingBtn={submitLoading}
        />
        <CustomTable
          rows={qualityAssociateData?.rows}
          columnAlign={qualityAssociateData?.columnAlign}
          columns={qualityAssociateData?.columns}
          TableData={qualityAssociateData?.TableData}
          handleNavigate={handleNavigateToDetails}
          handleButtonClick={handleOpenAddModal}
          handleEditButton={handleOpenEditPrincipalModal}
          handleDeleteButton={handleOpenDeleteConfirmModal}
          handleTableFilters={handleTableFilters}
          tableFilters={tableFilters}
          handleChangePage={handlePagination}
          handleChangeDropDown={handleRoleIdChange}
          dropDownValue={roleID}
          MenuList={roleList}
          loading={loading}
          currentPage={currentPage}
          totalpage={totalPage}
          isLoadingBtn={submitLoading}
        />
      </div>
      <DeleteConfirmContent
        open={openDeleteConfirmModal}
        setOpen={setOpenDeleteConfirmModal}
        handleDelete={handleDeleteRow}
        isLoading={submitLoading}
      />
      <CustomSnackbar
        open={snackbar?.value}
        setOpen={setSnackbar}
        color={snackbar?.color}
        message={snackbar?.message}
      />
    </>
  );
};
