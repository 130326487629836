import React, { useEffect, useRef, useState } from "react";
import { SendIcon } from "../../assets/images/drawerIcons/SendIcon";
import { AttachmentIcon } from "../../assets/images/drawerIcons/AttachmentIcon";
import { Formik } from "formik";
import { sendMessageInputInitialValue } from "../../utils/formik/initialValues";
import CustomInputField from "../../components/custom-input/CustomInput";
import { ClearIcon } from "@mui/x-date-pickers";
import { Button } from "@mui/material";
import { getFileIcon } from "./GetFileIcon";
import * as Yup from "yup";
import { setSnackbar } from "../../store/reducers/snackBar";
import { useDispatch } from "react-redux";
 

export const MessageInput = ({
  emitMessage,
  selectedFile,
  setSelectedFile,
  isQueryRaised,
  handleResolveQuery,
  uploadLoading,
  receiverId
}) => {
  const [fileSrc, setFileSrc] = useState(null);
  const [fileType, setFileType] = useState(null);
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const messageValidationSchema = (selectedFile) =>
    Yup.object().shape({
      message: Yup.string().when('selectedFile', {
        is: () => !selectedFile,
        then: Yup.string().required('Message is required'),
        otherwise: Yup.string().notRequired(),
      }),
    });
  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const maxSize = 10 * 1024 * 1024; // 10 MB in bytes
    if (file) {
      if (file.size > maxSize) {
        dispatch(
          setSnackbar({
            value: true,
            message: 'File size exceeds the limit of 10MB',
            color: "error",
          })
        );
        fileInputRef.current.value = null; // Clear the input
      } else {
        setSelectedFile(file);
        const fileType = file.type;
        setFileType(fileType);

        const reader = new FileReader();
        reader.onloadend = () => {
          setFileSrc(reader.result);
        };

        if (fileType.startsWith("image/")) {
          reader.readAsDataURL(file);
        } else {
          reader.readAsText(file);
        }
      }
    } else {
      setSelectedFile(null);
      setFileSrc(null);
      setFileType(null);
    }
  };

  const handleClearFile = () => {
    setSelectedFile(null);
    fileInputRef.current.value = ""; // Clear the file input value
  };

  const renderFilePreview = () => {
    if (!fileSrc) return null;
    if (fileType?.startsWith("image/")) {
      return (
        <div className="min-w-[100px] min-h-[100px] max-w-[200px] max-h-[100px] overflow-hidden ">
          <img src={fileSrc} alt="Selected" className="w-full" />
        </div>
      );
    } else {
      return (
        <span className="flex justify-center">{getFileIcon(selectedFile)}</span>
      );
    }
  };

  return (
    <div className="w-full">
      {selectedFile && (
        <div className="bg-[#EDEDED] text-[#000] p-2 pt-3 pb-1 rounded flex  gap-2 border-b ">
          <div className="relative flex-col flex gap-2 items-center bg-[rgba(0,0,0,0.5)] rounded  p-[8px]">
            <div className="min-w-[100px] min-h-[100px] max-w-[200px] relative overflow-hidden max-h-[100px]  flex flex-wrap text-center justify-center   text-white flex-col">
              <div className="file-icon">{renderFilePreview()}</div>
              {uploadLoading && (
                <div className="absolute left-0 top-0 w-full h-full bg-[rgba(0,0,0,0.6)]"></div>
              )}
              {uploadLoading && (
                <div className="loader absolute flex items-center justify-center left-[40%] top-[30%]"></div>
              )}
            </div>
            <span className="flex-grow text-white truncate text-xs">
              {selectedFile?.name}
            </span>
            <button
              onClick={handleClearFile}
              className="absolute top-[-10px] right-[-25px] flex items-center justify-center w-6 h-6 rounded-full border border-[#E53935] bg-white text-[#E53935] ml-2 mr-3 "
            >
              <ClearIcon />
            </button>
          </div>
        </div>
      )}

      <Formik
        initialValues={sendMessageInputInitialValue}
        validationSchema={() => messageValidationSchema(selectedFile)}
        onSubmit={(values, { resetForm }) => {
          emitMessage(values, resetForm);
        }}
        context={{ selectedFile }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          resetForm
        }) => {
          useEffect(() => {
          setSelectedFile(null);
          resetForm();
        }, [receiverId]);
         return (
          <form onSubmit={handleSubmit}>
            <div className="flex items-center gap-2">
              <div className="flex flex-grow">
              <div className={ selectedFile ? "opacity-65 w-full" : "w-full"}>
              <CustomInputField
                  htmlFor="message"
                  fullWidthBoolean={true}
                  error={Boolean(touched.message && errors.message)}
                  InputId="email-login"
                  InputType="text"
                  spacing={1}
                  value={values.message}
                  InputName="message"
                  onBlur={handleBlur}
                  FlexGrowBox={true}
                  onChange={handleChange}
                  CustomClassNameMessage={`input-focus-removed bg-[#EDEDED] text-[#000] font-normal text-xs lg:text-[18px] rounded-r[0px] min-h-[50px]  !shadow-none `}
                  placeholder="Enter your message...."
                  MultiLine={false}
                  NoOfRows={1}
                  BorderRadius="6px 0px 0px 6px"
                  disabled={Boolean(selectedFile)}
                />
              </div>
                
                <div className="sendMsg_div flex items-center gap-2 pl-2 rounded-r-[6px] bg-[#EDEDED]">
                  <button type="submit" className="h-full">
                    <div className="cursor-pointer hidden md:block">
                      <SendIcon />
                    </div>
                    <div className="cursor-pointer block md:hidden">
                      <SendIcon width={15} height={15} />
                    </div>
                  </button>
                  <div className="vertical-line w-[1px] h-4 lg:h-6 bg-[#fff]"></div>
                  <input
                    name="file"
                    type="file"
                    className="hidden"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                     accept=".pdf,.png,.jpg,.jpeg,.svg,.xlsx,.csv,.doc,.docx"
                  />
                  <div
                    className="cursor-pointer hidden md:block pr-[21px]"
                    onClick={handleIconClick}
                  >
                    <AttachmentIcon />
                  </div>
                  <div
                    className="cursor-pointer block md:hidden pr-2"
                    onClick={handleIconClick}
                  >
                    <AttachmentIcon width={20} height={20} />
                  </div>
                </div>
              </div>
              {isQueryRaised && (
                <Button
                  className="!w-[180px] h-[50px] !rounded-[6px] text-sm font-medium bg-[#3571FF] hover:!bg-[rgba(53,113,255,.7)] transition-all ease-in-out duration-300 text-white"
                  onClick={handleResolveQuery}
                >
                  Query Resolved
                </Button>
              )}
            </div>
          </form>
        )
        }}
      </Formik>
    </div>
  );
};
