import Routes from './routes';
import ThemeCustomization from './themes';
import ScrollTop from './components/ScrollTop';
import { useEffect } from 'react';
import { useSocketInstance } from './context/SocketContext';
import { io } from 'socket.io-client';
import ChatApi from './services/chatService';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { baseURL, checkForSubchild } from './utils/utils';
import NotifiactionApi from './services/notofiactionService';
import { findDashboardByPermission } from './utils/authorities';
import { activeItem } from './store/reducers/menu';

function App() {
  const jwtToken = localStorage.getItem("user")
  const { socketInstance, setSocketInstance, setMessageRead, setNotifiactionCount } = useSocketInstance();
  const { loginId: loginID, permissions } = useSelector((state) => state.loginData);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const location = useLocation();
  const firstSegment = location.pathname.split('/')[1];
  const navigate = useNavigate()
  let socket = null



  useEffect(() => {
    if (jwtToken && !socketInstance) {
      socket = io(baseURL, {
        reconnection: true,
        reconnectionAttempts: 10,
        reconnectionDelay: 1000,
        extraHeaders: {
          Authorization: jwtToken
        },
      });
      socket.on("connect", handlesetConnection);
    }
  }, [])

  useEffect(() => {
    if (loginID && jwtToken && (pathname == "/" || pathname == "/login" || pathname == "/forgot-password")) {
      let dashboardItems = findDashboardByPermission(permissions);
      let isChildExist = checkForSubchild(dashboardItems[0]);
      dispatch(activeItem({ openItem: isChildExist ? [isChildExist?.parentId, firstSegment] : [firstSegment] }));
      let url = dashboardItems[0]?.url;
      navigate(url)
    }
  }, [pathname, permissions])

  const handlesetConnection = () => {
    if (socket?.connected) {
      setSocketInstance(socket);
    }
  }


  const fetchNotifiactionStatus = async () => {
    try {
      if (jwtToken) {
        const res = await NotifiactionApi.countNotifiaction();
        setNotifiactionCount(res?.data);
      }
    } catch (error) {
      console.error('Error fetching notifiaction status:', error);
    }
  };

  const fetchMessageStatus = async () => {
    try {
      if (jwtToken) {
        const res = await ChatApi.messageStatus();
        setMessageRead(res?.data?.isNewMessage || false);
      }
    } catch (error) {
      console.error('Error fetching message status:', error);
    }
  };

  useEffect(() => {
    fetchNotifiactionStatus()
    fetchMessageStatus();
  }, [jwtToken]);

  useEffect(() => {
    if (loginID && pathname !== "/message") {
      if (!socketInstance) return;

      const handleReceiveMessage = (data) => {
        // Extract the date key from the socket data
        const dateKey = Object.keys(data)[0];
        // Extract the message object from the socket data
        const message = data[dateKey][0];
        if (loginID === message?.receiver_id) {
          setMessageRead(message?.messageCount > 0);
        }
      };

      socketInstance.on("receive_message", handleReceiveMessage);

      // Cleanup function to remove the event listener
      return () => {
        socketInstance.off("receive_message", handleReceiveMessage);
      };
    } else if (loginID) {
      if (!socketInstance) return;
      const handleReceiveMessage = (data) => {
        setNotifiactionCount(data)
      };


      socketInstance.on("receive_notification", handleReceiveMessage);

      // Cleanup function to remove the event listener
      return () => {
        socketInstance.off("receive_notification", handleReceiveMessage);
      };
    }

  }, [socketInstance, loginID]);

  return (
    <ThemeCustomization>
      <ScrollTop>
        <Routes />
      </ScrollTop>
    </ThemeCustomization>

  );
}

export default App;
