import React from "react";

export const DashboardIcon = () => {
    return (
    
    // <svg width="24" height="24" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    //     <path className="icon-path" d="M6.39062 0.79248H2.60938C2.15353 0.79248 1.71636 0.973563 1.39404 1.29589C1.07171 1.61822 0.890625 2.05539 0.890625 2.51123V6.29248C0.890625 6.74832 1.07171 7.18549 1.39404 7.50782C1.71636 7.83015 2.15353 8.01123 2.60938 8.01123H6.39062C6.84647 8.01123 7.28364 7.83015 7.60596 7.50782C7.92829 7.18549 8.10938 6.74832 8.10938 6.29248V2.51123C8.10938 2.05539 7.92829 1.61822 7.60596 1.29589C7.28364 0.973563 6.84647 0.79248 6.39062 0.79248ZM6.04688 5.94873H2.95312V2.85498H6.04688V5.94873ZM14.9844 0.79248H11.2031C10.7473 0.79248 10.3101 0.973563 9.98779 1.29589C9.66546 1.61822 9.48438 2.05539 9.48438 2.51123V6.29248C9.48438 6.74832 9.66546 7.18549 9.98779 7.50782C10.3101 7.83015 10.7473 8.01123 11.2031 8.01123H14.9844C15.4402 8.01123 15.8774 7.83015 16.1997 7.50782C16.522 7.18549 16.7031 6.74832 16.7031 6.29248V2.51123C16.7031 2.05539 16.522 1.61822 16.1997 1.29589C15.8774 0.973563 15.4402 0.79248 14.9844 0.79248ZM14.6406 5.94873H11.5469V2.85498H14.6406V5.94873ZM6.39062 9.38623H2.60938C2.15353 9.38623 1.71636 9.56731 1.39404 9.88964C1.07171 10.212 0.890625 10.6491 0.890625 11.105V14.8862C0.890625 15.3421 1.07171 15.7792 1.39404 16.1016C1.71636 16.4239 2.15353 16.605 2.60938 16.605H6.39062C6.84647 16.605 7.28364 16.4239 7.60596 16.1016C7.92829 15.7792 8.10938 15.3421 8.10938 14.8862V11.105C8.10938 10.6491 7.92829 10.212 7.60596 9.88964C7.28364 9.56731 6.84647 9.38623 6.39062 9.38623ZM6.04688 14.5425H2.95312V11.4487H6.04688V14.5425ZM14.9844 9.38623H11.2031C10.7473 9.38623 10.3101 9.56731 9.98779 9.88964C9.66546 10.212 9.48438 10.6491 9.48438 11.105V14.8862C9.48438 15.3421 9.66546 15.7792 9.98779 16.1016C10.3101 16.4239 10.7473 16.605 11.2031 16.605H14.9844C15.4402 16.605 15.8774 16.4239 16.1997 16.1016C16.522 15.7792 16.7031 15.3421 16.7031 14.8862V11.105C16.7031 10.6491 16.522 10.212 16.1997 9.88964C15.8774 9.56731 15.4402 9.38623 14.9844 9.38623ZM14.6406 14.5425H11.5469V11.4487H14.6406V14.5425Z" fill="white" />
    // </svg>

    
    <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className="icon-path" d="M6.39062 0.79248H2.60938C2.15353 0.79248 1.71636 0.973563 1.39404 1.29589C1.07171 1.61822 0.890625 2.05539 0.890625 2.51123V6.29248C0.890625 6.74832 1.07171 7.18549 1.39404 7.50782C1.71636 7.83015 2.15353 8.01123 2.60938 8.01123H6.39062C6.84647 8.01123 7.28364 7.83015 7.60596 7.50782C7.92829 7.18549 8.10938 6.74832 8.10938 6.29248V2.51123C8.10938 2.05539 7.92829 1.61822 7.60596 1.29589C7.28364 0.973563 6.84647 0.79248 6.39062 0.79248ZM6.04688 5.94873H2.95312V2.85498H6.04688V5.94873ZM14.9844 0.79248H11.2031C10.7473 0.79248 10.3101 0.973563 9.98779 1.29589C9.66546 1.61822 9.48438 2.05539 9.48438 2.51123V6.29248C9.48438 6.74832 9.66546 7.18549 9.98779 7.50782C10.3101 7.83015 10.7473 8.01123 11.2031 8.01123H14.9844C15.4402 8.01123 15.8774 7.83015 16.1997 7.50782C16.522 7.18549 16.7031 6.74832 16.7031 6.29248V2.51123C16.7031 2.05539 16.522 1.61822 16.1997 1.29589C15.8774 0.973563 15.4402 0.79248 14.9844 0.79248ZM14.6406 5.94873H11.5469V2.85498H14.6406V5.94873ZM6.39062 9.38623H2.60938C2.15353 9.38623 1.71636 9.56731 1.39404 9.88964C1.07171 10.212 0.890625 10.6491 0.890625 11.105V14.8862C0.890625 15.3421 1.07171 15.7792 1.39404 16.1016C1.71636 16.4239 2.15353 16.605 2.60938 16.605H6.39062C6.84647 16.605 7.28364 16.4239 7.60596 16.1016C7.92829 15.7792 8.10938 15.3421 8.10938 14.8862V11.105C8.10938 10.6491 7.92829 10.212 7.60596 9.88964C7.28364 9.56731 6.84647 9.38623 6.39062 9.38623ZM6.04688 14.5425H2.95312V11.4487H6.04688V14.5425ZM14.9844 9.38623H11.2031C10.7473 9.38623 10.3101 9.56731 9.98779 9.88964C9.66546 10.212 9.48438 10.6491 9.48438 11.105V14.8862C9.48438 15.3421 9.66546 15.7792 9.98779 16.1016C10.3101 16.4239 10.7473 16.605 11.2031 16.605H14.9844C15.4402 16.605 15.8774 16.4239 16.1997 16.1016C16.522 15.7792 16.7031 15.3421 16.7031 14.8862V11.105C16.7031 10.6491 16.522 10.212 16.1997 9.88964C15.8774 9.56731 15.4402 9.38623 14.9844 9.38623ZM14.6406 14.5425H11.5469V11.4487H14.6406V14.5425Z" fill="white" />
    </svg>

    );
}
