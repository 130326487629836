import React from 'react';
import { DeleteCrossIcon } from "../../assets/common-svg/CommonSvg"
import PropTypes from 'prop-types'
import { Button, Typography } from '@mui/material';

const CustomDetailComponent = ({ Icon, handleDelete, ShowButton, ShowDeleteIcon, FirstHeading, SecondHeading, FlexGrow, LimitSizeBoolean }) => {


    return <div style={{ backgroundColor:'#F0F1F5' }} className={`flex items-center justify-between w-full sm:w-max  gap-[30px] px-[16px] py-[8px] rounded-[10px]  ${FlexGrow ? "flex-grow" : "flex-grow-0"} ${LimitSizeBoolean ? "max-w-[300px]" : ""}`}>
        <div className="flex items-center">
            {Icon}
            <div className="flex flex-col items-start justify-center gap-0 ml-[10px] capitalize">
                <Typography variant='body1' >{FirstHeading}</Typography>
                <Typography variant='body1' color='secondary'>{SecondHeading}</Typography>
            </div>
        </div>
        {ShowButton ? <Button size='small'>Accepted</Button> : null}
        {ShowDeleteIcon ? <div className="hover:opecity-60" onClick={() => handleDelete()}><DeleteCrossIcon ButtonHeight="25px" ButtonWidth="25px" /></div> : null}
    </div>
}
CustomDetailComponent.proptypes = {
    Icon: PropTypes.node,
    handleDelete: PropTypes.func,
    ShowButton: PropTypes.bool,
    ShowDeleteIcon: PropTypes.bool,
    FirstHeading: PropTypes.string,
    SecondHeading: PropTypes.string,
    FlexGrow: PropTypes.bool,
    Width: PropTypes.string,
    MaxWidth: PropTypes.string
}

export default CustomDetailComponent;