

import React from "react";

export const AuditHistoryIcon = () => {
    return (

        <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="1">
                <path d="M25.7047 8C25.7047 3.57647 22.1283 0 17.7047 0C15.7753 0 13.94 0.705883 12.5283 1.92941V0.941176C12.5283 0.423529 12.1047 0 11.5871 0C11.0694 0 10.6459 0.423529 10.6459 0.941176V4.23529C10.6459 4.75294 11.0694 5.17647 11.5871 5.17647H11.7283H14.8812C15.3988 5.17647 15.8224 4.75294 15.8224 4.23529C15.8224 3.71765 15.3988 3.29412 14.8812 3.29412H13.7989C14.8812 2.4 16.2459 1.88235 17.7047 1.88235C21.093 1.88235 23.8224 4.61176 23.8224 8C23.8224 11.3882 21.093 14.1176 17.7047 14.1176C14.3165 14.1176 11.5871 11.3882 11.5871 8C11.5871 7.48235 11.1636 7.05882 10.6459 7.05882H2.17532V6.44706C2.17532 5.74118 2.74003 5.17647 3.44591 5.17647H7.82238C8.34003 5.17647 8.76356 4.75294 8.76356 4.23529C8.76356 3.71765 8.34003 3.29412 7.82238 3.29412H3.44591C1.70473 3.29412 0.292969 4.70588 0.292969 6.44706V20.8471C0.292969 22.5882 1.70473 24 3.44591 24H17.8459C19.5871 24 20.9988 22.5882 20.9988 20.8471V15.2941C23.7753 14.0235 25.7047 11.2471 25.7047 8ZM19.1165 20.8471C19.1165 21.5529 18.5518 22.1176 17.8459 22.1176H3.44591C2.74003 22.1176 2.17532 21.5529 2.17532 20.8471V8.94118H9.75179C9.98709 11.0118 11.0224 12.8471 12.5283 14.1176H12.4812C11.9636 14.1176 11.54 14.5412 11.54 15.0588V15.5294C11.54 16.0471 11.9636 16.4706 12.4812 16.4706C12.9988 16.4706 13.4224 16.0471 13.4224 15.5294V15.0588C13.4224 14.9647 13.4224 14.8235 13.3753 14.7294C13.9871 15.1059 14.6459 15.4353 15.3518 15.6235C15.3988 16.0941 15.8224 16.4235 16.293 16.4235C16.6694 16.4235 16.9518 16.2353 17.14 15.9059C17.3283 15.9059 17.5165 15.9529 17.7047 15.9529C18.1753 15.9529 18.6459 15.9059 19.1165 15.8118V20.8471Z" fill="white" />
                <path d="M4.99586 10.3477C4.47822 10.3477 4.05469 10.7712 4.05469 11.2888V11.7594C4.05469 12.2771 4.47822 12.7006 4.99586 12.7006C5.51351 12.7006 5.93704 12.2771 5.93704 11.7594V11.2888C5.93704 10.7712 5.51351 10.3477 4.99586 10.3477Z" fill="white" />
                <path d="M8.76149 10.3477C8.24384 10.3477 7.82031 10.7712 7.82031 11.2888V11.7594C7.82031 12.2771 8.24384 12.7006 8.76149 12.7006C9.27914 12.7006 9.70267 12.2771 9.70267 11.7594V11.2888C9.70267 10.7712 9.27914 10.3477 8.76149 10.3477Z" fill="white" />
                <path d="M4.99586 14.1211C4.47822 14.1211 4.05469 14.5446 4.05469 15.0623V15.5329C4.05469 16.0505 4.47822 16.474 4.99586 16.474C5.51351 16.474 5.93704 16.0505 5.93704 15.5329V15.0623C5.93704 14.5446 5.51351 14.1211 4.99586 14.1211Z" fill="white" />
                <path d="M8.76149 14.1211C8.24384 14.1211 7.82031 14.5446 7.82031 15.0623V15.5329C7.82031 16.0505 8.24384 16.474 8.76149 16.474C9.27914 16.474 9.70267 16.0505 9.70267 15.5329V15.0623C9.70267 14.5446 9.27914 14.1211 8.76149 14.1211Z" fill="white" />
                <path d="M4.99586 17.8828C4.47822 17.8828 4.05469 18.3063 4.05469 18.824V19.2946C4.05469 19.8122 4.47822 20.2358 4.99586 20.2358C5.51351 20.2358 5.93704 19.8122 5.93704 19.2946V18.824C5.93704 18.3063 5.51351 17.8828 4.99586 17.8828Z" fill="white" />
                <path d="M8.76149 17.8828C8.24384 17.8828 7.82031 18.3063 7.82031 18.824V19.2946C7.82031 19.8122 8.24384 20.2358 8.76149 20.2358C9.27914 20.2358 9.70267 19.8122 9.70267 19.2946V18.824C9.70267 18.3063 9.27914 17.8828 8.76149 17.8828Z" fill="white" />
                <path d="M12.5232 17.8828C12.0056 17.8828 11.582 18.3063 11.582 18.824V19.2946C11.582 19.8122 12.0056 20.2358 12.5232 20.2358C13.0409 20.2358 13.4644 19.8122 13.4644 19.2946V18.824C13.4644 18.3063 13.0409 17.8828 12.5232 17.8828Z" fill="white" />
                <path d="M16.2966 17.8828C15.779 17.8828 15.3555 18.3063 15.3555 18.824V19.2946C15.3555 19.8122 15.779 20.2358 16.2966 20.2358C16.8143 20.2358 17.2378 19.8122 17.2378 19.2946V18.824C17.2378 18.3063 16.8143 17.8828 16.2966 17.8828Z" fill="white" />
                <path d="M17.7068 2.82422C17.1892 2.82422 16.7656 3.24775 16.7656 3.7654V8.00069C16.7656 8.51834 17.1892 8.94187 17.7068 8.94187H20.5303C21.048 8.94187 21.4715 8.51834 21.4715 8.00069C21.4715 7.48304 21.048 7.05951 20.5303 7.05951H18.648V3.7654C18.648 3.24775 18.2244 2.82422 17.7068 2.82422Z" fill="white" />
            </g>
        </svg>

    );
};

