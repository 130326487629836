import { Avatar, Skeleton } from "@mui/material"
import moment from "moment"

export const FormCommentList = ({ MenuData, loading }) => {
    return <div className="max-h-[15rem] max-w-[25rem] min-w-[20rem] overflow-y-auto cursor-row-resize">
        {MenuData?.map((data, index) => (
            <div className=" items-start gap-2.5 inline-flex w-100 pb-2 " key={index}>
                <div className="relative">
                    {loading ? <Skeleton variant="circular" width={70} height={70} /> : <Avatar className="bg-blue-900 uppercase">{data?.name}</Avatar>}
                </div>
                <div className="flex-col justify-center items-start gap-[5px] inline-flex">
                    <div className="text-black text-[15px] font-semibold font-['Public Sans']">
                        {loading ? <Skeleton width={80} height={20} /> : data?.title}
                    </div>
                    <div className="text-zinc-500 text-[15px] font-normal font-['Public Sans']">

                        {loading ? <Skeleton width={180} height={20} /> : data?.describe}
                    </div>
                    <div className="text-black text-sm font-normal font-['Public Sans']">
                        {loading ? <Skeleton width={180} height={20} /> : moment(data?.date).fromNow()}
                    </div>
                </div>
            </div>
        ))}
    </div>
}