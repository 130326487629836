/* eslint-disable react/no-children-prop */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import CustomTable from "../../components/custom-table/CustomTable";
import { addEditorValues } from "../../utils/formik/initialValues";
import { addEditorValidationSchema } from "../../utils/formik/validationSchema";
import CustomModel from "../../components/custom-model/CustomModel";
import { editorManagementData } from "../../utils/tableData";
import DeleteConfirmContent from "../../components/modal-contents/DeleteConfirmModalContent";
import EditorApi from "../../services/editorManagementService";
// import PhaseApi from "../../services/phase";
import { CustomSnackbar } from "../../components/snackbar/CustomSnackbar";
import { setSnackbar } from "../../store/reducers/snackBar";
import { useDispatch, useSelector } from "react-redux";
import ListDrawer from "../../components/ListDrawer/ListDrawer";

const convertEditorData = (data) => {
  return data?.map((editor) => {
    let transformedId = editor?.id;
    let transformedName = editor?.name;
    let transformedPhone = editor?.phone;
    let transformeEmail = editor?.email;
    let transformedPI = editor?.investigator_details?.name;
    let transformedPhase = editor?.phaseCount
    let transformedHospital = editor?.hospital_details?.name;
    let phaseList = editor?.phaseDetails
    let transformRowId = editor?.id;
    return [
      transformedId,
      transformedName,
      transformedPhone,
      transformedPI,
      transformedPhase,
      transformeEmail,
      transformedHospital,
      phaseList,
      transformRowId,
    ];
  });
};

export const EditorManagementPage = () => {
  // const [searchValue, setSearchValue] = React.useState("");
  const dispatch = useDispatch();
  const location = useLocation();
  const snackbar = useSelector((state) => state.snackBar);
  const [openAddModal, setOpenAddModal] = React.useState(false);
  const [openEditPrincipalModal, setOpenEditEModal] = React.useState(false);
  const [openDeleteConfirmModal, setOpenDeleteConfirmModal] =
    React.useState(false);
  const [currentPage, setCurrentpage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [piData, setPiData] = useState(editorManagementData);
  const [tableFilters, setTablesFilters] = useState({ searchData: "" });
  const [initialValues, setInitialValues] = useState(addEditorValues);
  const [loading, setLoading] = useState(false);
  const [piList, setPiList] = useState([]);
  // const [piValue, setPiValue] = useState('')
  const [assignedPI, setAssignedPI] = useState([]);
  const [assignedPIValue, setAssignedPIValue] = useState("");
  const [piResponse, setPiResponse] = useState([]);
  const [rowId, setRowId] = useState(null);
  const [phaseList, setPhaseList] = useState([]);
  const [deleteRowData, setDeleteRowData] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  // const [phasId, setPhaseId] = useState(null);
  const [piId, setPiId] = useState(null);
  const [phaseListData, setPhaseListData] = useState([])
  const [submitLoading, setSubmitLoading] = useState(false);
  // const [state,setState] = React.useState(0);
  const [modelData, setModelData] = useState({
    title: "Add Editor",
    editTitle: "Edit Editor",
    children: [
      {
        type: "input",
        name: "name",
        label: "Name",
        placeholder: "Enter Name",
        IsRequired: true,
      },
      {
        type: "input",
        name: "email",
        label: "Email",
        placeholder: "Enter Email",
        IsRequired: true,
      },
      {
        type: "number",
        name: "phoneNumber",
        label: "Phone Number",
        placeholder: "Enter Phone Number",
        IsRequired: true,
      },
      {
        type: "dropdown",
        name: "principalInvestigator",
        label: "Principal Investigator",
        placeholder: "Select Principal Investigator",
        data: [],
        IsRequired: true,
      },
      {
        type: "multidropdown",
        name: "trial",
        label: "Phase",
        placeholder: "Select Phase",
        data: [],
        IsRequired: true,
      },
    ],
  });
  useEffect(() => {
    const newChildren = [
      {
        type: "input",
        name: "name",
        label: "Name",
        placeholder: "Enter Name",
        IsRequired: true,
      },
      {
        type: "number",
        name: "phoneNumber",
        label: "Phone Number",
        placeholder: "Enter Phone Number",
        IsRequired: true,
      },
      {
        type: "dropdown",
        name: "principalInvestigator",
        label: "Principal Investigator",
        placeholder: "Select Principal Investigator",
        data: piList,
        IsRequired: true,
        // disabled: initialValues.trial ? true : false,
      },
      // { type: "dropdown", name: "trial", label: "Trial ", placeholder: "Select Trial", data: phaseList, IsRequired: true },
      {
        type: "multidropdown",
        name: "trial",
        label: "Phase ",
        placeholder: "Select Phase",
        data: phaseList,
        IsRequired: true,
        // disabled: initialValues.trial ? true : false,
      }
    ];

    if (rowId == "") {
      newChildren.push({
        type: "input",
        name: "email",
        label: "Email",
        placeholder: "Enter Email",
        IsRequired: true,
      });
    } else {
      newChildren.push({
        type: "input",
        name: "email",
        label: "Email",
        placeholder: "Enter Email",
        IsRequired: true,
        disabled: true,
      });

    }
    setModelData((prevModelData) => ({
      ...prevModelData,
      children: newChildren,
    }));
    // getPhase()
  }, [rowId, phaseList]);

  const navigate = useNavigate();

  const handleNavigateToDetails = React.useCallback((row) => {
    navigate(`editor-management-details/${row[row?.length - 1]}`, {
      state: [
        { name: "Editor", path: location.pathname },
        {
          name: "Details",
          path: `${location.pathname}/editor-management-details/${row[row?.length - 1]}`,
        },
      ],
    });
  }, []);

  useEffect(() => {
    // getPersonalInvestigatorList();
    // getEditorListing();
    assignedInvestigatorDropDown();
    getPersonalInvestigatorList();
  }, []);

  useEffect(() => {
    if (piId) {
      getPhase(piId);
    } else {
      setPhaseList([]);
    }
  }, [piId]);

  useEffect(() => {
    if (tableFilters?.searchData[tableFilters?.searchData?.length - 1] != " ") {
      setLoading(true);
      getEditorListing();
    }
  }, [currentPage, tableFilters, assignedPIValue]);

  const assignedInvestigatorDropDown = () =>
    EditorApi.piDropDownList()
          .then((res) => {
        setAssignedPI(res.data);
      })
      .catch((error) => {
        dispatch(setSnackbar({ value: true, message: error, color: "error" }));
      });

  const getPersonalInvestigatorList = () =>
    EditorApi.subadminDropDown()
      .then((res) => {
        setPiList(res.data);
        setModelData((prevState) => ({
          ...prevState,
          children: prevState.children.map((child) =>
            child.name === "principalInvestigator"
              ? { ...child, data: res.data }
              : child
          ),
        }));
      })
      .catch((error) => {
        dispatch(setSnackbar({ value: true, message: error, color: "error" }));
      });

  let roleID = 5; //this role-id is for PI
  const getEditorListing = () =>
    EditorApi.getPIData(
      10,
      currentPage,
      tableFilters?.searchData,
      roleID,
      assignedPIValue
    )
      .then((res) => {
        let rowData = convertEditorData(res?.data || []);
        setPiResponse(res?.data);
        setPiData((prev) => ({
          ...prev,
          rows: [...rowData],
        }));

        setTotalPage(
          Math.ceil(res?.page_details?.no_of_records / res?.page_details?.limit)
        );
        setCurrentpage(res?.page_details?.page);
        setLoading(false);
      })
      .catch((error) => {
        dispatch(setSnackbar({ value: true, message: error, color: "error" }));
        setLoading(false);
      });

  const getPhase = () =>
    EditorApi.getPhaseDropDown('', piId)
      .then((res) => {
        const data = res?.data?.map((val) => ({
          id: val.id,
          name: val.title,
        }));
        setPhaseList(data);
        setModelData((prevState) => ({
          ...prevState,
          children: prevState.children.map((child) =>
            child.name === "trial" ? { ...child, data: data } : child
          ),
        }));
      })
      .catch((error) => {
        dispatch(setSnackbar({ value: true, message: error, color: "error" }));
      });

  const handleOpenAddModal = () => {
    setInitialValues(addEditorValues);
    setRowId("");
    setOpenAddModal(true);
  };

  const handleOpenEditPrincipalModal = (row) => {
    setRowId(row[0]);
    const editorData = piResponse.find((editor) => editor.id === row[0]);
    setInitialValues({
      name: editorData?.name,
      email: editorData?.email,
      phoneNumber: editorData?.phone.toString(),
      trial: editorData?.phaseDetails.map(item => item.id),
      principalInvestigator: editorData?.investigator_details?.id,
    });
    // let phaseList = editorData?.phaseDetails.map((item) => ({
    //   id: item?.id,
    //   name: item?.title
    // }))
    // setPhaseList(phaseList);
    setPiId(editorData?.investigator_details?.id)
    // getPhase()

    setOpenEditEModal(true);
  };

  const handleAddModelValues = async (values) =>
  {
    setSubmitLoading(true);
    EditorApi.addEditor({
      name: values?.name,
      email: values?.email,
      country_code: "91",
      phone: String(values?.phoneNumber),
      phase_ids: values?.trial,
      role_id: roleID,
      investigator_id: values?.principalInvestigator,
    })
      .then((res) => {
        if (res.status === 200) {
          getEditorListing();
          dispatch(setSnackbar({ value: true, message: res.message, color: "success"}));
          setOpenAddModal(false);
          setSubmitLoading(false);
        }
      })
      .catch((error) => {
        dispatch(setSnackbar({ value: true, message: error, color: "error" }));
        setSubmitLoading(false);
      });
  }



  const handleEditModelValues = (values) =>
  {
    const editorData = piResponse.find((editor) => editor.id === rowId);
    const dataIds = editorData?.phaseDetails.map(item => item.id);
    // Filter out the IDs that are present in values?.trial
    const missingIds = dataIds.filter(id => !values?.trial.includes(id));
    const isInvestigator = editorData?.investigator_details?.id !== values?.principalInvestigator ? editorData?.investigator_details?.id : 0
    setSubmitLoading(true);
    EditorApi.editEditor(
      {
        name: values?.name,
        email: values?.email,
        country_code: "91",
        phone: values?.phoneNumber.toString(),
        phase_ids: values?.trial,
        role_id: roleID,
        investigator_id: values?.principalInvestigator,
        removedPhaseIds: missingIds,
        removedInvestigatorId: isInvestigator
      },
      rowId
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(true)
          getEditorListing();
          assignedInvestigatorDropDown();
          dispatch(setSnackbar({ value: true, message: res.message, color: "success" }));
          setOpenEditEModal(false);
          setSubmitLoading(false);
        }
      })
      .catch((error) => {
        dispatch(setSnackbar({ value: true, message: error, color: "error" }));
        setSubmitLoading(false);
      });
  }

  const handleOpenDeleteConfirmModal = (row) => {
    setDeleteRowData(row);
    setOpenDeleteConfirmModal(true);
  };

  const handlePIChange = (e) => {
    setAssignedPIValue(e.target.value);
    setCurrentpage(1)
  };

  const handleTableFilters = (e) => {
    setTablesFilters({ ...tableFilters, searchData: e });
    setCurrentpage(1)
  };

  const handlePagination = async (value) => {
    setCurrentpage(value);
  };

  const handleDeleteRow =  () =>
  {
    setSubmitLoading(true);
    setOpenDeleteConfirmModal(false);
    EditorApi.deleteEditor(deleteRowData[0])
      .then((res) => {
        if (res?.status === 200) {
          setSubmitLoading(false);
          getEditorListing();
          dispatch(
            setSnackbar({ value: true, message: res.message, color: "success" })
          );
        }
      })
      .catch((error) => {
        dispatch(setSnackbar({ value: true, message: error, color: "error" }));
        setSubmitLoading(false);
      });
  }

  const handleCellClick = (row) => {
    setOpenDrawer(!openDrawer)
    setPhaseListData(row[row?.length - 2])
  } 
  return (
    <>
      <div className="flex flex-col gap-0 bg-white p-0 !pb-0">
        <CustomModel
          rowId={rowId}
          modalInitialValues={initialValues}
          initialValuesValidationSchema={addEditorValidationSchema}
          open={openEditPrincipalModal ? openEditPrincipalModal : openAddModal}
          setOpen={openEditPrincipalModal ? setOpenEditEModal : setOpenAddModal}
          setModelData={setModelData}
          modelData={modelData}
          handleModelValues={
            openEditPrincipalModal
              ? handleEditModelValues
              : handleAddModelValues
          }
          onChange={setPiId}
          isLoadingBtn={submitLoading}
        />
        <CustomTable
          rows={piData?.rows}
          columnAlign={piData?.columnAlign}
          columns={piData?.columns}
          TableData={piData?.TableData}
          handleNavigate={handleNavigateToDetails}
          handleButtonClick={handleOpenAddModal}
          handleEditButton={handleOpenEditPrincipalModal}
          handleDeleteButton={handleOpenDeleteConfirmModal}
          handleChangeDropDown={handlePIChange}
          handleTableFilters={handleTableFilters}
          handleChangePage={handlePagination}
          tableFilters={tableFilters}
          dropDownValue={assignedPIValue}
          MenuList={assignedPI}
          loading={loading}
          currentPage={currentPage}
          totalpage={totalPage}
          handleCellClick={handleCellClick}
        />
      </div>
      <DeleteConfirmContent
        open={openDeleteConfirmModal}
        setOpen={setOpenDeleteConfirmModal}
        handleDelete={handleDeleteRow}
        isLoading={submitLoading}
      />
      {openDrawer ? <ListDrawer open={openDrawer} toggleDrawer={() => setOpenDrawer(!openDrawer)}  listData={phaseListData || []} title="Phase List"/> : null}
      <CustomSnackbar
        open={snackbar?.value}
        setOpen={setSnackbar}
        color={snackbar?.color}
        message={snackbar?.message}
      />
    </>
  );
};
