import React from "react";
import { RightArrowIcon } from "../../assets/images/drawerIcons/RightArrow";
import { Typography } from "@mui/material";

export const CustomStepper = ({ stepTitles }) => {
  return (
    <div className="query_stepper w-full md:!w-max flex overflow-x-hidden items-center rounded mb-[10px]  bg-[#FFEDCB] p-[8px_15px_8px_15px]">
      {stepTitles.map((title, index) => (
        <div key={index} className="flex items-center customQueryStepperInner">
          <Typography
            className="!text-[12px] sm:text-[10px] w-[60px] sm:w-[140px] md:w-auto truncate text-sm"
            variant="body1"
          >
            {title}
          </Typography>
          {index < stepTitles.length - 1 && (
            <div>
              <div className="mx-[10px] hidden sm:block">
                <RightArrowIcon fill={"#FB9D2C"} width={15} height={15}/>
              </div>
              <div className="mx-[10px] block sm:hidden">
                <RightArrowIcon width={16} height={16} fill={"#FB9D2C"} />
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
