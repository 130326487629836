import httpService from "./httpService";

const EditorApi = {
    getPIData: (limit, page, search, role_id, investigator_id) => httpService.get('subAdminList', {
        params: {
            limit,
            page: page,
            searchData: search,
            role_id,
            investigator_id
        }
    }),
    addEditor: (data) => httpService.post(`addSubAdmin`, data),
    editEditor: (data, id) => httpService.post(`updateSubAdmin/${id}`, data),
    deleteEditor: (id) => httpService.delete(`deleteSubAdmin/${id}`),
    subadminDropDown: () => httpService.post(`subadminDropDown/${2}`, {
        "ids": [],
    }),
    piDropDownList: () => httpService.get('assignedInvestigatorDropDown'),
    getPhaseDropDown: (search, investigator_id, editor_id) => httpService.get('phaseDropDown', {
        params: {
            searchData: search,
            investigator_id,
            editor_id
        }
    }),
}

export default EditorApi;