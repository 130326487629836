import { useState, useEffect } from 'react';

function ChartDataJSON({ series, options, chartData }) {
    const [data, setData] = useState(null);
    useEffect(() => {
            const seriesData = series?.length ? series : [];
            const optionsData = {
                dataLabels: options?.dataLabels ?? {
                    enabled: false,
                },
                stroke: options?.stroke ?? {
                    width: 0
                },
                chart: options?.chart ?? {
                    type: 'donut',
                    width: '100%',
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: options?.plotOptions ?? {
                    pie: {
                        donut: {
                            size: '84%',
                            labels: {
                                show: true,
                                name: {
                                    show: true,
                                    fontSize: '22px',
                                    offsetY: 20
                                },
                                value: {
                                    show: true,
                                    fontSize: '30px',
                                    offsetY: -20,
                                    color: '#FFFFFF',
                                    fontWeight: 'bold'
                                },
                                total: {
                                    show: true,
                                    showAlways: true,
                                    label: 'Total Patients',
                                    fontSize: '14px',
                                    color: '#FFFFFF',
                                }
                            }
                        },
                    }
                },
                labels: options?.labels ?? [],
                colors: options?.colors ?? [],
                legend: options?.legend ?? {
                    position: 'right',
                    offsetY: 30,
                    height: 200,
                    labels: {
                        colors: '#FFFFFF',
                        style: {
                            fontSize: '12px',
                        }
                    }
                },
            }
            const chartOptions = {
                seriesData,
                optionsData
            };
            setData(chartOptions);
        
    }, [chartData]);

    return data;
}

export default ChartDataJSON;
