import React from "react";
import { Box, Button, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import Loadable from "../Loadable";
import { NextIcon } from "../../assets/common-svg/CommonSvg";
import { getUserHasAccess } from "../../utils/authorities";
import { useSelector } from "react-redux";

const CardHeader = Loadable(
  React.lazy(() => import("../card-header/CardHeader"))
);

const CardMiddleSection = Loadable(
  React.lazy(() => import("../card-middle/CardMiddleSection"))
);

const ChipsListing = Loadable(
  React.lazy(() => import("../chips-listing/ChipsLisitng"))
);

const InfoCard = ({
  handleOpenDeleteModal,
  handleEditProductModal,
  PrimaryHeading,
  CardDetailsListing,
  handleProductDetails,
  CardPadding,
  FieldGapCloser,
  Code,
  ShowHeaderMenu,
  CardID,
  StartedDate,
  Description,
  ChipListingData,
  CreatedDate,
  detailsAuth,
  editAuth,
  deleteAuth
}) => {
  const theme = useTheme();
  const LoginData = useSelector((state) => state.loginData);
  const userAuthority = LoginData?.auth || [];
  return (
    <Box
      className=" w-100 rounded-2xl flex flex-col gap-4"
      bgcolor={theme.palette.common.white}
      sx={{ padding: CardPadding ?? "16px", border: "1px solid #EDEDED" }}
    >
      <CardHeader
        editAuth={editAuth}
        deleteAuth={deleteAuth}
        PrimaryHeading={PrimaryHeading}
        SecondaryHeading={Code}
        ShowMenu={ShowHeaderMenu}
        handleOpenDeleteModal={handleOpenDeleteModal}
        handleEditProductModal={handleEditProductModal}
        ProductID={CardID}
        StartedDate={StartedDate}
      />
      <CardMiddleSection
        MiddleData={Description}
        ChipData={CardDetailsListing}
        CreatedDate={CreatedDate?.slice(0, 10)}
        FieldGapCloser={FieldGapCloser}
      />
      <div className="border-y w-full"></div>
      <div className="flex justify-between items-center gap-2">
        <ChipsListing ChipListingData={ChipListingData} />
        {getUserHasAccess(userAuthority, detailsAuth?.module, detailsAuth.auth) &&
          <Button
            onClick={() => handleProductDetails(CardID)}
            color="primary"
            variant="outlined"
            endIcon={<NextIcon />}
            size="small"
          >
            Details
          </Button>}
      </div>
    </Box>
  );
};

export default InfoCard;

InfoCard.propTypes = {
  handleOpenDeleteModal: PropTypes.func,
  handleEditProductModal: PropTypes.func,
  PrimaryHeading: PropTypes.string,
  Code: PropTypes.string,
  CardID: PropTypes.number,
  StartedDate: PropTypes.string,
  Description: PropTypes.string,
  handleProductDetails: PropTypes.func,
  CardDetailsListing: PropTypes.array,
  ChipListingData: PropTypes.array,
  CreatedDate: PropTypes.string,
  ShowHeaderMenu: PropTypes.bool,
  CardPadding: PropTypes.string,
  FieldGapCloser: PropTypes.bool,
};
