
import React from "react";

export const FormBuilderIcon = () => {
    return (

        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.9983 9.42857V2.57143C17.9983 1.88944 17.7274 1.23539 17.2452 0.753154C16.7629 0.270918 16.1089 0 15.4269 0H3.4269C2.74491 0 2.09086 0.270918 1.60862 0.753154C1.12639 1.23539 0.855469 1.88944 0.855469 2.57143V21.4286C0.855469 22.1106 1.12639 22.7646 1.60862 23.2468C2.09086 23.7291 2.74491 24 3.4269 24H15.4269C16.1089 24 16.7629 23.7291 17.2452 23.2468C17.7274 22.7646 17.9983 22.1106 17.9983 21.4286V15.4286C17.5437 15.4286 17.1076 15.6092 16.7861 15.9307C16.4647 16.2522 16.284 16.6882 16.284 17.1429V21.4286C16.284 21.6559 16.1937 21.8739 16.033 22.0347C15.8722 22.1954 15.6542 22.2857 15.4269 22.2857H3.4269C3.19957 22.2857 2.98155 22.1954 2.82081 22.0347C2.66006 21.8739 2.56975 21.6559 2.56975 21.4286V2.57143C2.56975 2.3441 2.66006 2.12608 2.82081 1.96534C2.98155 1.80459 3.19957 1.71429 3.4269 1.71429H15.4269C15.6542 1.71429 15.8722 1.80459 16.033 1.96534C16.1937 2.12608 16.284 2.3441 16.284 2.57143V11.1429C16.7387 11.1429 17.1747 10.9622 17.4962 10.6408C17.8177 10.3193 17.9983 9.88323 17.9983 9.42857Z" fill="white" fillOpacity="1" />
            <path d="M13.7137 4.28516H5.1423C4.91497 4.28516 4.69695 4.37546 4.53621 4.53621C4.37546 4.69695 4.28516 4.91497 4.28516 5.1423C4.28516 5.36963 4.37546 5.58764 4.53621 5.74839C4.69695 5.90914 4.91497 5.99944 5.1423 5.99944H13.7137C13.9411 5.99944 14.1591 5.90914 14.3198 5.74839C14.4806 5.58764 14.5709 5.36963 14.5709 5.1423C14.5709 4.91497 14.4806 4.69695 14.3198 4.53621C14.1591 4.37546 13.9411 4.28516 13.7137 4.28516ZM14.5709 8.57087C14.5709 8.34354 14.4806 8.12552 14.3198 7.96478C14.1591 7.80403 13.9411 7.71373 13.7137 7.71373H5.1423C4.91497 7.71373 4.69695 7.80403 4.53621 7.96478C4.37546 8.12552 4.28516 8.34354 4.28516 8.57087C4.28516 8.7982 4.37546 9.01622 4.53621 9.17696C4.69695 9.33771 4.91497 9.42801 5.1423 9.42801H13.7137C13.9411 9.42801 14.1591 9.33771 14.3198 9.17696C14.4806 9.01622 14.5709 8.7982 14.5709 8.57087ZM5.1423 11.1423C4.91497 11.1423 4.69695 11.2326 4.53621 11.3934C4.37546 11.5541 4.28516 11.7721 4.28516 11.9994C4.28516 12.2268 4.37546 12.4448 4.53621 12.6055C4.69695 12.7663 4.91497 12.8566 5.1423 12.8566H8.57087C8.7982 12.8566 9.01622 12.7663 9.17696 12.6055C9.33771 12.4448 9.42801 12.2268 9.42801 11.9994C9.42801 11.7721 9.33771 11.5541 9.17696 11.3934C9.01622 11.2326 8.7982 11.1423 8.57087 11.1423H5.1423Z" fill="white" fillOpacity="1" />
            <path d="M10.6379 20.5679C10.5104 20.5679 10.3845 20.5393 10.2694 20.4845C10.1544 20.4296 10.053 20.3497 9.97265 20.2507C9.89233 20.1516 9.83513 20.0359 9.8052 19.912C9.77527 19.788 9.77337 19.659 9.79963 19.5342L10.4442 16.4648C10.4785 16.3025 10.5592 16.1536 10.6765 16.0362L18.7508 7.96278C19.2407 7.49524 19.892 7.23438 20.5692 7.23438C21.2465 7.23438 21.8977 7.49524 22.3876 7.96278C22.6265 8.20157 22.8159 8.48506 22.9452 8.79707C23.0744 9.10908 23.1409 9.44349 23.1409 9.78121C23.1409 10.1189 23.0744 10.4533 22.9452 10.7654C22.8159 11.0774 22.6265 11.3609 22.3876 11.5996L14.3116 19.6714C14.1946 19.7892 14.0456 19.8703 13.8831 19.9045L10.8136 20.5499C10.7558 20.5618 10.6969 20.5678 10.6379 20.5679ZM12.0736 17.0674L11.7513 18.6016L13.2831 18.2794L21.1756 10.3859C21.3363 10.2252 21.4266 10.0072 21.4266 9.77993C21.4266 9.55264 21.3363 9.33467 21.1756 9.17393C21.0127 9.01751 20.7955 8.93016 20.5696 8.93016C20.3437 8.93016 20.1266 9.01751 19.9636 9.17393L12.0736 17.0674Z" fill="white" fillOpacity="1" />
        </svg>
    );
}


