
export const CardIcon = ({ title, code, icon }) => {
    return <div className="flex gap-3 w-full lg:w-1/5">
        <div className="flex-col flex justify-center items-start">
            {icon}
        </div>
        <div className="flex-col flex justify-center items-start">
            <div className="text-zinc-500 text-sm font-normal ">{code}</div>
            <div className="text-black text-md font-semibold ">{title}</div>
        </div>
    </div>
}