import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

 const SkeletonLoader = ({ columns, rowCount, isTable, width, height, className,IsActionAvilable}) => {
    return (
        <>
            {isTable ? (
                Array.from(new Array(rowCount)).map((_, rowIndex) => (
                    <TableRow key={rowIndex}>
                        {columns.map((_, cellIndex) => (
                            <TableCell key={cellIndex}>
                                <Skeleton width="100%" />
                            </TableCell>
                        ))}
                     {   IsActionAvilable &&<TableCell>
                            <Skeleton width="100%" />
                        </TableCell>}
                    </TableRow>
                ))
            ) : (
                Array.from(new Array(rowCount)).map((_, index) => (
                    <Skeleton key={index} variant="rectangular" width={width ?? '100%'} height={height ?? 90} className={className ?? 'mt-2 rounded-md'} />
                ))
            )}
        </>
    );
};

export default SkeletonLoader;
