import React, { useEffect, useState } from "react"
import DeleteConfirmContent from "../../components/modal-contents/DeleteConfirmModalContent"
import { addHospitalInitialValues } from "../../utils/formik/initialValues"
import { addHospitalValidationSchema } from "../../utils/formik/validationSchema"
import { hospitalManagementData } from "../../utils/tableData"
import HospitalApi from "../../services/hospitalService"
import { useDispatch, useSelector } from "react-redux"
import { setSnackbar } from "../../store/reducers/snackBar"
import { CustomSnackbar } from "../../components/snackbar/CustomSnackbar"
const CustomTable = Loadable(React.lazy(() => import('../../components/custom-table/CustomTable')));
const CustomModel = Loadable(React.lazy(() => import('../../components/custom-model/CustomModel')));
import Loadable from "../../components/Loadable";

const convertHospitalData = (data) => {
    return data.map(hospital => {
        let transformedId = hospital?.hospital_code;
        let transformedName = hospital?.name;
        let transformedState =  hospital?.state_name ;
        let transformedAddress = hospital?.address+", "+hospital?.city_name+(hospital?.pincode ? ", "+hospital?.pincode : "");
        let transformRowId = hospital?.id
        return [transformedId, transformedName,transformedState, transformedAddress, transformRowId];
    });
}

export const HospitalManagement = () => {
    const dispatch = useDispatch()
    const snackbar = useSelector((state) => state.snackBar);
    const [openAddHospitalModal, setOpenAddHospitalModal] = React.useState(false);
    const [openEditHospitalModal, setOpenEditHospitalModal] = React.useState(false);
    const [openDeleteConfirmModal, setOpenDeleteConfirmModal] = React.useState(false);
    const [hospitals, setHospitals] = useState(hospitalManagementData);
    const [hospitalsResponse, setHospitalsResponse] = useState([]);
    const [initialValues, setInitialValues] = useState(addHospitalInitialValues)
    const [loading, setLoading] = useState(false)
    const [totalPage, setTotalPage] = useState(1);
    const [currentPage, setCurrentpage] = useState(1);
    const [rowId, setRowId] = useState(null)
    const [deleteRowData, setDeleteRowData] = useState(null)
    const [tableFilters, setTablesFilters] = useState({ searchData: '' })
    const [submitLoading, setSubmitLoading] = useState(false);
    const [modelData, setModelData] = useState({
        title: "Add New Hospital",
        editTitle: "Edit Hospital",
        children: [
            { type: "input", name: "hospitalName", label: "Hospital Name", placeholder: "Enter Hospital Name", IsRequired: true },
            { type: "input", name: "hospitalCode", label: "Hospital Code", placeholder: "Enter Hospital Code", IsRequired: true },
            { type: "description", name: "hospitalAddress", label: "Address", placeholder: "Enter Address", IsRequired: true },
            { type: "dropdown", name: "state", label: "State", placeholder: "Select State", data: [], IsRequired: true },
            { type: "dropdown", name: "city", label: "City", placeholder: "Select City", data: [], IsRequired: true },
            { type: "number", name: "pincode", label: "Pincode",placeholder: "Enter Pincode", IsRequired: true},
        ]
    })
  

    ////////////////////// Get state and city //////////////////////
    const getState = () => HospitalApi.getAllState().then((res) => {
        setInitialValues({ ...initialValues, city: '', state: '' })
        setModelData(prevState => ({
            ...prevState,
            children: prevState.children.map(child =>
                child.name === 'state' ? { ...child, data: res?.data?.states } : child
            )
        }));
    })
    const getCity = (state_id) => {
        if (state_id) {
            HospitalApi.getCityByState(state_id).then((res) => {
                setModelData(prevState => ({
                    ...prevState,
                    children: prevState.children.map(child =>
                        child.name === 'city' ? { ...child, data: res?.data?.cities } : child
                    )
                }));
            })
        }
    }
    ////////////////////////////////////////////////////////////////////
    const getHospitalList = () => HospitalApi.getHospitalData(10, currentPage, tableFilters?.searchData, true)
        .then((res) => {
            let rowData = convertHospitalData(res?.data || [])
            setHospitalsResponse(res?.data)
            setHospitals(prev => ({
                ...prev,
                rows: [...rowData]
            }));
            setTotalPage(Math.ceil(res?.page_details?.no_of_records / res?.page_details?.limit));
            setCurrentpage(res?.page_details?.page);
            setLoading(false);
        }
        )
        .catch((error) => {
            dispatch(setSnackbar({ value: true, message: error, color: "error" }));
            setLoading(false);
        })

    useEffect(() => {
        if (tableFilters?.searchData[tableFilters?.searchData?.length - 1] != " ") {
        setLoading(true)
        getHospitalList();
        }
    }, [currentPage, tableFilters])

    useEffect(() => {
        getState();
    }, [])

    const handleState = (value, formikVal) => {
        setInitialValues({ hospitalName: formikVal.hospitalName, hospitalCode: formikVal?.hospitalCode, hospitalAddress: formikVal.hospitalAddress, state: value, city: '', pincode: formikVal.pincode })
        getCity(value);
    }

    const handleOpenAddHospitalModal = () => {
        setInitialValues(addHospitalInitialValues)
        setRowId("")
        setOpenAddHospitalModal(true);
    }

    const handleOpenEditHospitalModal = (row) => {
        setRowId(row[row.length - 1])
        const hospitalEditData = hospitalsResponse.find(hospital => hospital.id === row[row.length - 1]);
        if (hospitalEditData) {
            getCity(hospitalEditData?.state_id);
        }
        setInitialValues({
            hospitalName: hospitalEditData?.name,
            hospitalCode: hospitalEditData?.hospital_code,
            hospitalAddress: hospitalEditData?.address,
            state: hospitalEditData?.state_id,
            city: hospitalEditData?.city_id,
            pincode: hospitalEditData?.pincode || '',
        })
        setOpenEditHospitalModal(true);
    }

    const handleAddModelValues = (values) => {
        setSubmitLoading(true);
        HospitalApi.addHospital({
            name: values?.hospitalName,
            hospital_code: values?.hospitalCode,
            address: values?.hospitalAddress,
            state_id: values?.state,
            city_id: values?.city,
            pincode: values?.pincode,
        }).then((res) => {
            if (res.status === 200) {
            getHospitalList()
            setOpenAddHospitalModal(false);
            dispatch(setSnackbar({ value: true, message: res.message, color: "success" }));
            setSubmitLoading(false);
        }
    }).catch((error) => {
        dispatch(setSnackbar({ value: true, message: error, color: "error" }));
        setSubmitLoading(false);
    })
    }


    const handleEditModelValues = (values) => {
        setSubmitLoading(true);
        HospitalApi.editHospital({
            name: values.hospitalName,
            hospital_code: values?.hospitalCode,
            address: values.hospitalAddress,
            state_id: values?.state,
            city_id: values?.city,
            pincode: values?.pincode,
        }, rowId).then((res) => {
            if (res.status === 200) {
                getHospitalList();
                setOpenEditHospitalModal(false);
                dispatch(setSnackbar({ value: true, message: res.message, color: "success" }));
                setSubmitLoading(false);
            }
        }).catch((error) => {
            dispatch(setSnackbar({ value: true, message: error, color: "error" }));
            setSubmitLoading(false);
        })
    }

    const handleOpenDeleteConfirmModal = (row) => {
        setOpenDeleteConfirmModal(true);
        setDeleteRowData(row)
    }

    const handleDeleteRow = () => {
        setSubmitLoading(true)
        setOpenDeleteConfirmModal(false);
        HospitalApi.deleteHospital(deleteRowData[deleteRowData.length - 1]).then((res) => {
        if (res?.status === 200) {
            getHospitalList();
            dispatch(setSnackbar({ value: true, message: res.message, color: "success" }));
            setSubmitLoading(false)
        }
    }).catch((error) => {
        dispatch(setSnackbar({ value: true, message: error, color: "error" }));
        setSubmitLoading(false)
    })
    }

    const handlePagination = (value) => {
        setCurrentpage(value);
    };
    const handleTableFilters = (e) => {
        setTablesFilters({ ...tableFilters, searchData: e })
        setCurrentpage(1)
    }



    return <>
        <div className="flex flex-col gap-0 bg-white p-0 !pb-0">
            <CustomModel
                rowId={rowId}
                modalInitialValues={initialValues}
                initialValuesValidationSchema={addHospitalValidationSchema}
                open={openEditHospitalModal ? openEditHospitalModal : openAddHospitalModal}
                setOpen={openEditHospitalModal ? setOpenEditHospitalModal : setOpenAddHospitalModal}
                setModelData={setModelData}
                modelData={modelData}
                handleModelValues={openEditHospitalModal ? handleEditModelValues : handleAddModelValues}
                onChange={handleState}
                isLoadingBtn={submitLoading}
            />
            <CustomTable
                rows={hospitals?.rows}
                columnAlign={hospitals?.columnAlign}
                columns={hospitals?.columns}
                TableData={hospitals?.TableData}
                handleTableFilters={handleTableFilters}
                tableFilters={tableFilters}
                handleButtonClick={handleOpenAddHospitalModal}
                handleEditButton={handleOpenEditHospitalModal}
                handleDeleteButton={handleOpenDeleteConfirmModal}
                handleChangePage={handlePagination}
                loading={loading}
                currentPage={currentPage}
                totalpage={totalPage}
            />
        </div>
        <DeleteConfirmContent open={openDeleteConfirmModal} setOpen={setOpenDeleteConfirmModal} handleDelete={handleDeleteRow} isLoading={submitLoading} />
        <CustomSnackbar open={snackbar?.value} setOpen={setSnackbar} color={snackbar?.color} message={snackbar?.message} />

    </>
}

