import axios from "axios";
import { baseURL } from "../utils/utils";


let jwtToken = localStorage.getItem("user");
const httpService = () => {
  const instance = axios.create({
    // baseURL: "https://immunoact.hashstudioz.com/api/", //staging 
    baseURL: baseURL + "/api/",  //"dev"
    timeout: 2 * 60 * 60 * 1000, // 2 hours in milliseconds
  });

  // Add a request interceptor to add the JWT token to the Authorization header
  instance.interceptors.request.use(
    (config) => {
      if (jwtToken) {
        config.headers.Authorization = `Bearer ${jwtToken}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

   // 401 status code error handling:
   const handleUnauthorized = () => {
     localStorage.clear();
    window.location.href = "/login";
  };

  instance.interceptors.response.use(
    (response) => {
      return response?.data;
    },
    (error) => {
      if (error.response) {
        const { status, data } = error.response;
        if (status === 500) {
          return Promise.reject(data?.message);
        } else if (status === 400) {
          return Promise.reject(data?.message);
        } else if (status === 422) {
          return Promise.reject(data?.errors?.length ? data?.errors : data?.message);
        } else if (status === 403 || status == 401) {
          handleUnauthorized();
          return Promise.reject(data?.message);
        } else if (status === 405) {
          return Promise.reject("Some Error Occured, please try later.");
        } else if (status === 404) {
          return Promise.reject("Some Error Occured, please try later.");
        }
      } else {
        return Promise.reject("Error here");
      }
      return Promise.reject(error);
    }
  );
  
  return instance;

};

export default httpService();


export const setJwtToken = (token) => {
  localStorage.setItem('user', token);
  jwtToken = token;
}