// material-ui
import { createTheme } from '@mui/material/styles';

// third-party
import { presetPalettes } from '@ant-design/colors';

// project import
import ThemeOption from './theme';

// ==============================|| DEFAULT THEME - PALETTE  ||============================== //

const Palette = (mode) => {
  const colors = presetPalettes;

  const greyPrimary = [
    '#ffffff',
    '#fafafa',
    '#f5f5f5',
    '#f0f0f0',
    '#d9d9d9',
    '#bfbfbf',
    '#8c8c8c',
    '#595959',
    '#262626',
    '#141414',
    '#000000'
  ];
  const greyAscent = ['#fafafa', '#bfbfbf', '#434343', '#1f1f1f'];
  const greyConstant = ['#F0F1F5', '#e6ebf1'];

  colors.grey = [...greyPrimary, ...greyAscent, ...greyConstant];

  const paletteColor = ThemeOption(colors);


  const Black = '#000';
  const WhiteBg = '#FFC0CB';
  const DisabledGrey = '#828282';
  const DarkGreen = '#00B278';
  const LightGreen = '#E4F9F2';
  const DarkOrange = '#FB9D2C'; 
  const LightOrange = '#FFEDCB';
  const DividerGrey = '#DDDDDD';
  const DarkBlue = '#3571FF';
  const DarkRed = '#FF2023';
  const LightRed = '#FFE3E3';
  const DarkGray = "#171928";
  const LightGrey="#F0F1F5";
  const LightDarkGray = "rgba(51, 59, 74, 0.5)";
  const LightBlack = "#2C2C2C";

  return createTheme({
    palette: {
      colors: {
        Black,
        WhiteBg,
        DarkGreen,
        DividerGrey,
        LightGreen,
        DarkOrange,
        DarkBlue,
        LightOrange,
        DisabledGrey,
        DarkRed,
        LightRed,
        LightGrey,
        LightBlack,
        LightDarkGray
      },
      ...paletteColor,
      primary: {
        main: DarkGray,
      },
      disabled: {
        main: DisabledGrey
      },
      secondary: {
        main: DisabledGrey
      },
      mode,
      common: {
        black: '#000',
        white: '#fff',
        bgColor: paletteColor.colors.lightGray,
      },
      text: {
        primary: paletteColor.colors.Black,
        secondary: DisabledGrey,
        disabled: paletteColor.colors.DisabledGrey,
        lightBlack: paletteColor.colors.LightBlack
      },
      action: {
        disabled: paletteColor.grey[300]
      },
      divider: DividerGrey  ,
      background: {
        plane: paletteColor.primary.whiteBg,
        paper: paletteColor.grey[0],
        default: paletteColor.grey.A50
      },
      button: {
        primary: paletteColor.colors.DarkBlue,

      }
    }
  });
};

export default Palette;
