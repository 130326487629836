import React, { useCallback, useEffect, useState } from "react";
import { DocumentIcon } from "../../assets/common-svg/CommonSvg";
import { CustomChipCard } from "../../components/custom-product-cards/custom-chip-card/CustomChipCard";
import { CustomWrapperCard } from "../../components/custom-product-cards/custom-wrapper-card/CustomWrapperCard";
import CustomDetailComponent from "../../components/doctor-detail-component/DocotorDetail";
import TrialInfoComponent from "../../components/trial-info-component/TrialInfoComponent";
import CustomTable from "../../components/custom-table/CustomTable";
import { useLocation, useNavigate } from "react-router";
import { Typography, useTheme } from "@mui/material";
import SkeletonLoader from "../../utilites/SkeltonLoader";
import {
  principleInvestigatortDetailData,
  userManagementDetailData,
} from "../../utils/tableData";
import SkeltonLoaderHeader from "../../utilites/SkeltonLoaderHeader";
import NoDataCard from "../../components/no-data-card.js";
import { useDispatch, useSelector } from "react-redux";
import SubjectApi from "../../services/subjectService.js";
import { CustomSnackbar } from "../../components/snackbar/CustomSnackbar.js";
import { setSnackbar } from "../../store/reducers/snackBar.js";
import { setPhaseDetail } from "../../store/reducers/phase-reducer.js";
import PhaseApi from "../../services/phase.js";
import SubAdminApi from "../../services/subAdminService.js";
import CustomSearchbar from "../custom-searchbar/CustomSearchbar.js";
import { useSearchParams } from "react-router-dom";
export const convertSubjectData = (data, moduleName) => {
  const moduleConfig = {
    UserManagement: [
      "subject_code",
      "full_name", // Placeholder for concatenated first and last name
      "investigatorDetails.name",
      "editorDetails.name",
      "gender",
      "id",
    ],
    PI: ["subject_code", "full_name", "editorDetails.name", "gender", "id"],
    // Add configurations for other modules here
  };

  const fields = moduleConfig[moduleName];

  return data?.map((sub) => {
    return fields.map((field) => {
      if (field === "full_name") {
        const firstName = sub.first_name ?? "Not Assigned";
        const lastName = sub.last_name ?? "";
        return `${firstName} ${lastName}`.trim();
      } else {
        const fieldParts = field.split(".");
        return fieldParts.reduce((acc, part) => acc?.[part] ?? "Not Assigned", sub);
      }
    });
  });
};


const PhaseListDetail = ({ id, roleId = "", moduleName, ShowEditorCount }) => {
  const [activeTab, setActiveTab] = useState(null);
  const [phaseDetailLoading, setPhaseDetailLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [phaseListData, setPhaseListData] = useState(null);
  const [phaseListDetails, setPhaseListDetails] = useState({});
  const [phaseListLoading, setPhaseListLoading] = useState(false);
  const [editorList, setEditorList] = useState([]);
  const [subjectData, setSubjectData] = useState(
    moduleName === "UserManagement"
      ? userManagementDetailData
      : moduleName === "PI"
      ? principleInvestigatortDetailData
      : {}
  );
  const [tableFilters, setTablesFilters] = useState({ searchData: "" });
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentpage] = useState(1);
  const [pIList, setPIList] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const snackbar = useSelector((state) => state.snackBar);
  const [piId, setPiID] = useState(null);
  const [editorId, setEditorId] = useState(null);
  const [searchParams] = useSearchParams();

  // Get the value of a specific query parameter
  const activeTrialId = searchParams.get('phase_id');

  const handleNavigateToDetails = useCallback((row) => {
    const UpdatedNavigationArray = location.state;
    UpdatedNavigationArray?.push({
      name: "Patient Details",
      path: `${location.pathname}/patient-details/${row[row.length - 1]}`,
    });
    navigate(`patient-details/${row[row.length - 1]}`, {
      state: UpdatedNavigationArray,
    });
  }, []);

  const handlePhaseSearch = (value) => {
    if (value !== " ") {
      getPhaseList(value);
    }
  };

  async function getPhaseList(search = "", limit = 100, page = 1) {
    setPhaseListLoading(true);
    setLoading(true);
    try {
      const res = await PhaseApi.getPhaseList(
        "",
        search,
        limit,
        page,
        id,
        roleId
      );
      setPhaseListData(res.data || []);
      if(activeTrialId){
        setActiveTab(activeTrialId)
      } else{
        setActiveTab(res?.data && res?.data[0].id);
      }
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));
    } finally {
      setPhaseListLoading(false);
      setLoading(false);
    }
  }

  useEffect(() => {
    getPhaseList();
  }, []);

  useEffect(() => {
    setPiID(null)
    setEditorId(null)
    if (activeTab) {
      (async function () {
        setPhaseDetailLoading(true);
        try {
          const res = await PhaseApi.getPhaseListDetails(activeTab,(moduleName==="PI")?id:"");
          setPhaseListDetails(res.data || []);
          dispatch(setPhaseDetail(res.data));
        } catch (error) {
           dispatch(
             setSnackbar({
               value: true,
               message: error?.message || error,
               color: "error",
             })
           );
        } finally {
          setPhaseDetailLoading(false);
        }
      })();
    }
  }, [activeTab]);

  // Function to get Editor for patient Sub Admin by Value and Phase List ID
  const getEditorForSubjectSubAdmin = async (value) => {
    try {
      return await SubAdminApi.getEditorForSubjectSubAdmin(
        value,
        phaseListDetails?.id
      );
    } catch (error) {
      dispatch(
        setSnackbar({
          value: true,
          message: error?.message || error ,
          color: "error",
        })
      );
    }
  };

  useEffect(() => {
    if (phaseListDetails?.id) {
      if (
        tableFilters?.searchData[tableFilters?.searchData?.length - 1] != " "
      ) {
        setLoading(true);
        getSubjectList();
      }
    }
  }, [currentPage, tableFilters, phaseListDetails, piId, editorId]);

  useEffect(() => {
    const fetchEdiorData = async () => {
      const results = await Promise.allSettled([
        getEditorForSubjectSubAdmin(2),
        getEditorForSubjectSubAdmin(5),
      ]);

      results?.forEach((result, index) => {
        if (result.status === "fulfilled") {
          const data = result.value?.data || [];
          switch (index) {
            case 0:
              setPIList(data);
              break;
            case 1:
              setEditorList(data);
              break;

            default:
              break;
          }
        } else {
          console.error(
            `Error fetching data for index ${index + 2}:`,
            result.reason
          );
        }
      });
    };
    if (phaseListDetails?.id) {
      fetchEdiorData();
    }
  }, [phaseListDetails]);

  async function getSubjectList() {
    try {
      const res = await SubjectApi.getSubjectList(
        phaseListDetails?.id,
        currentPage,
        10,
        tableFilters?.searchData,
        editorId,
        (moduleName === "PI") ? id : piId
      );
      let rowData = convertSubjectData(res?.data || [], moduleName);
      setSubjectData((prev) => ({
        ...prev,
        rows: [...rowData],
      }));
      setTotalPage(
        Math.ceil(res?.page_details?.no_of_records / res?.page_details?.limit)
      );
      setCurrentpage(res?.page_details?.page);
      setLoading(false);
    } catch (error) {
       dispatch(
         setSnackbar({
           value: true,
           message: error?.message || error ,
           color: "error",
         })
       );
      console.log("Error Patient List", error);
    }
  }

  const handlePagination = async (value) => {
    setCurrentpage(value);
  };

  const handleTableFilters = (e) => {
    setTablesFilters({ ...tableFilters, searchData: e });
    setCurrentpage(1)
  };

  const handleDropDownChange = (e, id) => {
    setCurrentpage(1)
    if (id === "selectPI") {
      setPiID(e.target.value);
    } else if (id === "editorList") {
      setEditorId(e.target.value);
    }
  };


  return (
    <div className="pt-4 pb-4 relative">
      <div className="flex flex-col lg:grid grid-cols-12 gap-4 absolute top-0 left-0 w-100 pt-4">
        <div className="col-start-1 col-end-4 pt-3 mt-1">
          <div className="pb-3">
            <CustomSearchbar
              width={"100%"}
              BgColor={"#FFFFFF"}
              Placeholder={"Search by Name"}
              handleSearch={handlePhaseSearch}
            />
          </div>
        </div>
      </div>
      {loading || (phaseListData && phaseListData?.length > 0) ? (
        <div className="flex flex-col lg:grid grid-cols-12 gap-4 pt-4 mt-2">
          <div className="col-start-1 col-end-4 w-100 mt-5    phase-list-card">
            {phaseListLoading ? (
              <div className="col-start-1 col-end-4 ">
                <SkeletonLoader rowCount={2} isTable={false} />
              </div>
            ) : (
              phaseListData?.map((item) => (
                <CustomChipCard
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  setCurrentpage={setCurrentpage}
                  data={{
                    id: item?.id,
                    title: item?.title ? `${item?.product_details?.name}:  ${item?.title}` : "",
                    badglesValue: {
                      statusBadglesValue:
                        item?.phase_status_details?.status || "",
                      typeBadglesValue: item?.phase_type_details?.name || "",
                    },
                  }}
                  key={item?.id}
                />
              ))
            )}
          </div>

          <div className="col-start-4 col-end-13 min-h-[25rem] bg-[#FFFFFF] rounded-xl">
            {phaseDetailLoading || phaseListLoading ? (
              <SkeltonLoaderHeader
                rowCount={2}
                isTable={false}
                width={200}
                height={30}
              />
            ) : (
              <CustomWrapperCard
                data={phaseListDetails || {}}
                editDeleteBtn={false}
                title={"Total Editors"}
                ShowEditorCount={ShowEditorCount}
              />
            )}
            <hr className="ml-[1rem] mr-[1rem]   " />

            <TrialInfoComponent
              HeaderTitle={"Description"}
              ShowHeaderButton={false}
            >
                 {phaseDetailLoading || phaseListLoading ? (
                  <SkeletonLoader
                    rowCount={1}
                    isTable={false}
                    width={500}
                    height={20}
                  />
                ) : (
              <Typography  variant="p" className="break-words">{(phaseListDetails?.description?.[0]?.toUpperCase()+ phaseListDetails?.description?.slice(1)) || "No Description Added"}</Typography>)}
              </TrialInfoComponent>
              
            <hr className="ml-[1rem] mr-[1rem]   " />

            <TrialInfoComponent
              HeaderTitle={"Assigned Forms"}
              ShowHeaderButton={false}
            >
              <div className="flex gap-2 justify-start flex-wrap">
                {phaseDetailLoading || phaseListLoading ? (
                  <SkeletonLoader
                    rowCount={2}
                    isTable={false}
                    width={200}
                    height={30}
                  />
                ) : (
                  <>
                    {phaseListDetails?.form_details?.length > 0
                      ? phaseListDetails?.form_details?.map((item) => (
                          <CustomDetailComponent
                            key={item?.id || ""}
                            BgColor="#2831720D"
                            FirstHeading={item.title || ""}
                            Icon={<DocumentIcon LightBlue={true} />}
                            ShowDeleteIcon={false}
                          />
                        ))
                        : "No Assigned Form Found."}
                  </>
                )}
              </div>
            </TrialInfoComponent>
            
            <hr className="ml-[1rem] mr-[1rem]   " />
            <div className="p-3">
              <CustomTable
                SearchbarBgColor="secondary"
                rows={subjectData?.rows}
                columnAlign={subjectData?.columnAlign}
                columns={subjectData?.columns}
                handleTableFilters={handleTableFilters}
                tableFilters={tableFilters}
                handleChangePage={handlePagination}
                loading={loading}
                currentPage={currentPage}
                totalpage={totalPage}
                TableData={{
                  ...subjectData?.TableData,
                  filters: subjectData?.TableData?.filters?.map((item) => {
                    if (item?.id === "editorList") {
                      return {
                        ...item,
                        MenuList: editorList,
                        dropDownValue: editorId,
                      };
                    } else if (item?.id === "selectPI") {
                      return {
                        ...item,
                        MenuList: pIList,
                        dropDownValue: piId,
                      };
                    }
                    return item;
                  }),
                }}
                handleChangeDropDown={handleDropDownChange}
                handleNavigate={handleNavigateToDetails}
                DropdownBgColor={theme?.palette?.grey[200]}
              />
            </div>
          </div>
        </div>
      ) : phaseListData !== null ? (
        <div className="pt-5  mt-4">
          <NoDataCard />
        </div>
      ) : null}
      <CustomSnackbar
        open={snackbar?.value}
        setOpen={setSnackbar}
        color={snackbar?.color}
        message={snackbar?.message}
      />
    </div>
  );
};

export default PhaseListDetail;
