import PropTypes from 'prop-types'
import { Button, Typography, useTheme } from "@mui/material"


export const TrialInfoHeader = ({ HeaderText, HandleButton, ButtonText = "sdfdf", ShowButton = true, ButtonIcon }) => {
    const theme = useTheme();
    return (
      <div className="flex  flex-row justify-between items-start md:items-center mb-[10px]">
        <Typography
          color={theme.palette.colors.LightBlack}
          variant="h6"
          className="opacity-85"
        >
          {HeaderText}
        </Typography>
        {ShowButton ? (
          <Button
            variant="outlined"
            sx={{
              "&.MuiButtonBase-root.MuiButton-outlined": {
                color: "#171928",
                padding: { xs: "6px 0px 6px 10px", sm: "3px 10px 3px 10px" },
                borderRadius: "6px",
                width: "auto",
                minWidth: { xs: "39px" },
              },
            }}
            onClick={HandleButton}
            startIcon={ButtonIcon}
          >
            <span className="hidden sm:block">{ButtonText}</span>
          </Button>
        ) : null}
      </div>
    );
}

TrialInfoHeader.proptypes = {
    HeaderText: PropTypes.string,
    HandleButton: PropTypes.func,
    ButtonText: PropTypes.string,
    ShowButton: PropTypes.bool,
    ButtonIcon: PropTypes.node
}