import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { getFileIcon } from "./GetFileIcon";
import CloudDownloadOutlined from "@ant-design/icons/CloudDownloadOutlined";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../store/reducers/snackBar";
export const ChatBox = ({
  senderMsg,
  senderTime,
  initialsChat,
  receiverHospital,
  receiverMsg,
  receiveTime,
  color = "success",
  messageType,
}) => {
  const theme = useTheme();
  const [downloadLoading, setDownloadLoading] = useState(false);
  const dispatch = useDispatch();
  const senderMsgFileName = senderMsg?.split("/")?.pop();
  const receiverMsgFileName = receiverMsg?.split("/")?.pop();

  const handleDownload = async (imageUrl) => {
    try {
      setDownloadLoading(true);
      const response = await fetch(imageUrl);
      const blob = await response.blob();

      // Extract filename from URL
      const fileName = imageUrl.substring(imageUrl.lastIndexOf("/") + 1);

      // Create a blob URL for the blob object
      const blobUrl = URL.createObjectURL(blob);

      // Create a temporary anchor element
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = fileName; // Set the filename for download
      link.style.display = "none"; // Hide the link
      document.body.appendChild(link);

      // Programmatically trigger the download
      link.click();

      // Clean up: remove the link and revoke the blob URL
      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      dispatch(
        setSnackbar({
          value: true,
          message: "Error while downloading the file",
          color: "error",
        })
      );
    } finally {
      setDownloadLoading(false);
    }
  };
  return (
    <div className="w-full px-3 sm:px-5 flex flex-col gap-[10px] mb-2">
      {senderMsg && (
        <div className=" relative cursor-pointer  rounded-tr-none bg-[#3571FF] p-[10px] w-fit  text-white flex flex-col gap-[3px] ml-auto rounded-[5px_0px_5px_5px] max-w-[50%] lg:!max-w-[45%] break-words after-triangle">
          <Typography
            className="text-sm sm:!text-base"
            fontWeight={theme.typography.fontWeightRegular}
            variant="h6"
          >
            {messageType === "text" ? (
              senderMsg
            ) : messageType?.includes("image/") ? (
              <div className="relative cursor-pointer ml-auto mr-auto my-2 svgIconDiv flex items-center justify-cente max-h-[100px] sm:max-h-[150px] h-[150px] overflow-hidden">
                <div className="overlay absolute left-0 top-0 w-full h-full bg-[rgba(0,0,0,0.5)] "></div>
                {downloadLoading ? (
                  <Box
                    className="CircularProgressdownload absolute"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <CircularProgress
                      sx={{
                        height: "30px",
                        width: "30px",
                      }}
                    />
                  </Box>
                ) : (
                  <div
                    onClick={() => {
                      handleDownload(senderMsg);
                    }}
                    className="absolute z-20 text-white downloadDiv w-full flex justify-between"
                  >
                    <CloudDownloadOutlined className="!w-10 !h-10 !m-auto" />
                  </div>
                )}

                <img src={senderMsg} className="max-w-full w-full ml-auto mr-auto"/>
              </div>
            ) : (
              <Typography
                variant="body1"
                className="relative cursor-pointer ml-auto mr-auto my-2 svgIconDiv flex items-center justify-center"
              >
                <div className="overlay absolute left-0 top-0 w-full h-full bg-[rgba(0,0,0,0.5)]"></div>
                {downloadLoading ? (
                  <Box
                    className="CircularProgressdownload absolute"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <CircularProgress
                      sx={{
                        height: "30px",
                        width: "30px",
                      }}
                    />
                  </Box>
                ) : <div
                  onClick={() => {
                    handleDownload(senderMsg);
                  }}
                  className="absolute z-20 text-white downloadDiv"
                >
                  <CloudDownloadOutlined className="!w-10 !h-10" />
                </div> }
                {getFileIcon(messageType)}
              </Typography>
            )}
          </Typography>
          {messageType !== "text" && (
            <Typography className="truncate w-full">
              {senderMsgFileName}
            </Typography>
          )}
          <Typography
            textAlign={"end"}
            className="!text-[0.6rem]"
            variant="body1"
            fontWeight={theme.typography.fontWeightRegular}
          >
            {senderTime}
          </Typography>
        </div>
      )}
      {/* receiver div */}
      {receiverMsg && (
        <div className="receiver_div flex items-start gap-[15px] ">
          <Box
            component={"div"}
            bgcolor={
              color == "success"
                ? theme.palette.success.main
                : color == "warning"
                ? theme.palette.warning.main
                : color == "error"
                ? theme.palette.error.main
                : theme.palette.info.main
            }
            className="rounded-full w-[30px] h-[30px] p-[15px] sm:p-0 sm:h-[45px] sm:w-[45px] flex items-center justify-center text-xs sm:text-base  text-white font-bold"
          >
            {initialsChat
              ? initialsChat
                  .split(" ")
                  .map((name) => name[0])
                  .join("")
                  .slice(0, 2)
                  .toUpperCase()
              : ""}
          </Box>
          <div className="before-triangle  bg-[#F2F2F7]  text-black flex flex-col items-start rounded-[0px_5px_5px_5px] max-w-[85%] md:max-w-[385px] p-[10px]">
            <Typography
              fontWeight={theme.typography.fontWeightMedium}
              className="mb-[7px] text-sm sm:!text-base"
              variant="h6"
            >
              {receiverHospital}
            </Typography>
            <Typography
              fontWeight={theme.typography.fontWeightRegular}
              className="mb-[3px] w-full text-sm sm:!text-base"
              variant="h6"
            >
              {messageType === "text" ? (
                receiverMsg
              ) : messageType?.includes("image/") ? (
                <div className="relative cursor-pointer ml-auto mr-auto my-2 svgIconDiv flex items-center justify-center h-[100px] overflow-hidden">
                  <div className="overlay absolute left-0 top-0 w-full h-full bg-[rgba(0,0,0,0.5)]"></div>
                  {downloadLoading ? (
                  <Box
                    className="CircularProgressdownload absolute"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <CircularProgress
                      sx={{
                        height: "30px",
                        width: "30px",
                      }}
                    />
                  </Box>
                ) : (
                  <div
                    onClick={() => {
                      handleDownload(receiverMsg);
                    }}
                    className="absolute z-20 text-white downloadDiv w-full flex justify-between"
                  >
                    <CloudDownloadOutlined className="!w-10 !h-10 !m-auto" />
                  </div>
                )}
                  <img src={receiverMsg}  className="max-w-full ml-auto mr-auto"/>
                </div>
              ) : (
                <Typography
                  variant="body1"
                  className="relative cursor-pointer ml-auto mr-auto my-2 svgIconDiv flex items-center justify-center"
                >
                  <div className="overlay absolute left-0 top-0 w-full h-full bg-[rgba(0,0,0,0.5)]"></div>
                  <div
                    onClick={() => {
                      handleDownload(receiverMsg);
                    }}
                    className="absolute z-20 text-white downloadDiv"
                  >
                    <CloudDownloadOutlined className="!w-10 !h-10" />
                  </div>
                  {getFileIcon(messageType)}
                </Typography>
              )}
            </Typography>
            {messageType !== "text" && (
              <Typography className="truncate w-full">
                {receiverMsgFileName}
              </Typography>
            )}
            <Typography
              variant="body2"
              textAlign={"end"}
              color={"secondary"}
              className=" !text-[0.6rem]  ml-auto"
              fontWeight={theme.typography.fontWeightRegular}
            >
              {receiveTime}
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};
