// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
  email: "",
  password: "",
  name: "",
  token: "",
  roles: "",
  role_id: "",
  auth: [],
  loginId: null,
  permissions: []
};

// ==============================|| SLICE - MENU ||============================== //

const LoginData = createSlice({
  name: 'LoginCredentials',
  initialState,
  reducers: {
    loginCredentials(state, action) {
      state.email = action.payload.email;
      state.name = action.payload.name;
      state.token = action.payload.token
      state.roles = action.payload.roles
      state.role_id = action.payload.role_id
      state.auth = action.payload.auth
      state.loginId = action.payload.loginId
      state.permissions = action.payload.permissions
    },
    logoutCredentials(state) {
      state.email = "";
      state.name = "";
      state.token = "";
      state.auth = [];
      state.roles = "";
      state.role_id =  "";
      state.permissions = []
    }
  }
});

export default LoginData.reducer;

export const { loginCredentials, logoutCredentials } = LoginData.actions;
