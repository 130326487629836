import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Box,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Grid,
  Paper,
  Popper,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';

// project import
import MainCard from '../../../../../components/MainCard';
import Transitions from '../../../../../components/@extended/Transitions';

// assets
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { logoutCredentials } from '../../../../../store/reducers/login-data';
// import getNameForPictureOfAdmin from '../../../../../utilites/Common';
import { LogoutIcon } from '../../../../../assets/images/auth/logoutIcon';
import { NotificationIcon } from '../../../../../assets/images/auth/notificationIcon';
import { MessageIcon } from '../../../../../assets/images/auth/message';
import { deepPurple } from '@mui/material/colors';
import { activeItem, openDrawer } from '../../../../../store/reducers/menu';
import { useSocketInstance } from '../../../../../context/SocketContext';

// tab panel wrapper
function TabPanel({ children, value, index, ...other }) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`profile-tabpanel-${index}`} aria-labelledby={`profile-tab-${index}`} {...other}>
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};
// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = ({ handleSetOpenLogoutSnackbarTrue }) => {

  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  // const userData = sessionStorage.getItem("LoggedUserData") ? JSON.parse(sessionStorage.getItem("LoggedUserData")):"";
  const userData = useSelector((state) => state.loginData);
  const dispatch = useDispatch();
  const { messageRead, notifiactioCount } = useSocketInstance();


  const handleCloseProfile = () => {
    // logout
    setOpen(false);
  }

  const handleNavigateToChats = () => {
    navigate('/message');
  }

  const handleNavigateToNotifications = () => {
    navigate('/notifications')
  }

  const handleLogout = async () => {
    // handleOpenSnackbar();
    localStorage.clear();
    dispatch(openDrawer({ drawerOpen: false }));
    dispatch(activeItem({ openItem: ["dashboard"] }));
    handleCloseProfile();
    handleSetOpenLogoutSnackbarTrue()
    localStorage.removeItem("user")
    // sessionStorage.removeItem("LoggedUserData");
    setTimeout(() => {
      dispatch(logoutCredentials())
      navigate("/login")
    }, 500);
  };

  const anchorRef = useRef(null);
  const notificationRef = useRef(null)
  const MessageRef = useRef(null)
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };


  const iconBackColorOpen = 'grey.300';
  return (
    <Box sx={{ flexShrink: 0, marginLeft: "auto", display: "flex" }}>
      {/* --- Notification icon added into the header --- */}
      <ButtonBase
        sx={{
          p: 0.75,
          bgcolor: "transparent",
          borderRadius: 1,
          // '&:hover': { bgcolor: 'secondary.lighter' }
        }}
        aria-label="Message"
        ref={MessageRef}
        aria-haspopup="true"
        onClick={()=>{
          dispatch(
            activeItem({ openItem: [] })
          );
          handleNavigateToChats();}}
      >
        <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
          <Tooltip title="Message">
            <div className='relative'>
              {messageRead ? <div className="bg-red-500 rounded-[50%] w-[7px] h-[7px] absolute right-[-1px] top-[-2px]"></div> : null}
              <MessageIcon />
            </div>
          </Tooltip>
        </Stack>
      </ButtonBase>
      <ButtonBase
        sx={{
          p: 1,
          bgcolor: "transparent",
          borderRadius: 1,
          // '&:hover': { bgcolor: 'secondary.lighter' }
        }}
        aria-label="Notifications"
        ref={notificationRef}
        aria-haspopup="true"
        onClick={
          ()=>
            {
              dispatch(
                activeItem({ openItem: [] })
              );
              handleNavigateToNotifications();
            }
        }
      >
        <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
          <Tooltip title="Notifications">
            <div className='relative'>
              {notifiactioCount ? <div className="bg-red-500  rounded-[50%] w-[24px] h-[23px] absolute right-[-8px] top-[-5px]">
                <span className='text-[#FFFFFF] 
                 text-[10px] flex justify-center items-center h-full'>{notifiactioCount>100?"100+":notifiactioCount}</span>
              </div> : null}
              <NotificationIcon />
            </div>
          </Tooltip>
        </Stack>
      </ButtonBase>
      <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: open ? iconBackColorOpen : "transparent",
          borderRadius: 1,
          // '&:hover': { bgcolor: 'secondary.lighter' }
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? "profile-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={"profile-nav"}
      >
        <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
          <Typography variant="subtitle1" className='username-title'>
            {userData?.name ? userData?.name : ""}
          </Typography>

          <Avatar
            sx={{
              bgcolor: deepPurple[500],
              width: 36,
              height: 36,
              borderRadius: "100%",
              fontSize: 16,
            }}
          >
            {userData.name
              ? userData.name
                .split(" ")
                .map((name) => name[0])
                .join("")
                .slice(0, 2)
                .toUpperCase()
              : ""
                .split(" ")
                .map((name) => name[0])
                .join("")
                .slice(0, 2)
                .toUpperCase()}
          </Avatar>
          {/* <Box sx={{ width: 32, height: 32, borderRadius: "100%", color: "white", fontSize: "12px", display: "flex", justifyContent: "center", backgroundColor: "#00B278", alignItems: "center" }}>{getNameForPictureOfAdmin(userData)}</Box> */}
        </Stack>
      </ButtonBase>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  boxShadow: theme.customShadows.z1,
                  width: 220,
                  minWidth: 150,
                  maxWidth: 290,
                  height: "auto",
                  [theme.breakpoints.down("md")]: {
                    maxWidth: 250,
                  },
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard
                    elevation={0}
                    border={false}
                    content={false}
                    sx={{
                      padding: "15px 10px 5px",
                    }}
                  >
                    <div className="flex flex-col text-center">
                      <Avatar
                        sx={{
                          bgcolor: deepPurple[500],
                          width: 66,
                          height: 66,
                          borderRadius: "100%",
                          fontSize: 16,
                          margin: "auto",
                          marginBottom: "5px",
                        }}
                      >
                        {userData.name
                          ? userData.name
                            .split(" ")
                            .map((name) => name[0])
                            .join("")
                            .slice(0, 2)
                            .toUpperCase()
                          : ""
                            .split(" ")
                            .map((name) => name[0])
                            .join("")
                            .slice(0, 2)
                            .toUpperCase()}
                      </Avatar>
                      <p className="text-base font-bold text-gray-700 username-title">
                        {userData?.name ? userData?.name : ""}
                      </p>
                      <p className="text-xs text-gray-500">
                        {userData?.email ? userData?.email : ""}
                      </p>
                    </div>

                    <Tooltip title="Logout">
                      <CardContent
                        onClick={handleLogout}
                        className="cursor-pointer"
                        sx={{
                          borderTop: "1px solid #ccc",
                          paddingTop: "20px",
                          paddingLeft: 0,
                          marginTop: "20px",
                        }}
                      >
                        <Grid
                          container
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid item>
                            <Stack
                              direction="row"
                              spacing={0}
                              alignItems="center"
                              height={10}
                            >
                              <LogoutIcon />
                              <Typography
                                variant="p"
                                className="text-base"
                                sx={{
                                  color: "#494949",
                                }}
                              >
                                Logout
                              </Typography>
                            </Stack>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Tooltip>
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Profile;
