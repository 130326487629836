import * as React from 'react';

const Toggle = React.memo(({ leftText, rightText, toggleWidth, disabled, setAlignment, alignment }) => {

    const handleChange = () => {
        !disabled && setAlignment((prevAlignment) => (prevAlignment === leftText ? rightText : leftText));
    };
    return (
        <div
            className={`flex ml-2 items-center h-8 md:!h-[38px] ${toggleWidth ? "w-[300px]" : "w-[6rem] md:!w-[10rem]"}  overflow-hidden bg-[#DFE0EB]  rounded-xl cursor-pointer`}
            onClick={handleChange}

        >
            <div
                className={`flex text-xs md:text-base h-[40px] justify-center flex-1 items-center ${alignment === leftText ? "rounded-tl-[10px] rounded-tr-[10px] rounded-br-[10px] text-[#fff] bg-[#007bff] " : "rounded-tl-none rounded-tr-none rounded-br-none text-[#706d67] bg-[#DFE0EB]"}`}
            >
                {leftText}
            </div>
            <div
                className={`flex text-xs md:text-base h-[40px] justify-center flex-1 items-center  ${alignment === rightText ? "rounded-tl-[10px] rounded-tr-[10px] rounded-bl-[10px] text-[#fff] bg-[#007bff]" : "rounded-tl-none rounded-tr-none rounded-bl-none text-[#706d67] bg-[#DFE0EB]"}`}
            >
                {rightText}
            </div>
        </div>
    );
});

export default Toggle;


