// types
import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  phaseDetail: [],
  asignedFormTemplate: []
};

// ==============================|| SLICE - MENU ||============================== //

const phaseReducer = createSlice({
  name: "phase-reducer",
  initialState,
  reducers: {
    setPhaseDetail(state, action) {
      state.phaseDetail = action.payload ?? state.phaseDetail;
    },
    setAssignedForm(state, action) {
      state.asignedFormTemplate = action.payload ?? state.asignedFormTemplate;
    },

  },
});

export default phaseReducer.reducer;

export const { setPhaseDetail, setAssignedForm } =
  phaseReducer.actions;
