import httpService from "./httpService";

const PrincipleApi = {
    addPrincipleInvestigator: (data) => httpService.post(`addSubAdmin`, data),
    viewPrincipleInvestigator: (data) => httpService.get(`subAdminList?limit=${data.limit}&page=${data.page}&role_id=${data.role_id}&searchData=${data?.searchData}&hospital_id=${data?.hospital_id}`, null),
    editPrincipleInvestigator: (data, id) => httpService.post(`updateSubAdmin/${id}`, data),
    viewDetailedPrincipleInvestigator: (data, id) => httpService.get(`subAdminDetails/${data}?role_id=${id}`, null),
    deletePIData: (id) => httpService.delete(`deleteSubAdmin/${id}`),
}

export default PrincipleApi;