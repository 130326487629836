import React, { useEffect, useState } from "react";
import { auditHistoryData } from "../../utils/tableData";
import { setSnackbar } from "../../store/reducers/snackBar";
import { useDispatch } from "react-redux";
import Loadable from "../../components/Loadable";
import AuditHistoryApi from "../../services/auditHistory";
import moment from "moment";
const CustomTable = Loadable(
  React.lazy(() => import("../../components/custom-table/CustomTable"))
);

const convertAuditHistoryData = (data) => {
  return data.map((audit) => {
    let transformedName = audit?.user_data?.name;
    let transformedEvent = audit?.event_data?.name;
    let transformedMessage = audit?.message;
    let transformedCreatedAt = moment(audit?.created_at).utc().format(
      "DD/MM/YYYY hh:mm A"
    );
    let transformedUserType = audit?.user_type;
    let transformedItem = audit?.item;
    let transformedEmail = audit?.user_data?.email
    return [
      transformedName,
      transformedEvent,
      transformedMessage,
      transformedCreatedAt,
      transformedUserType,
      transformedItem,
      transformedEmail
    ];
  });
};

const FormatFromYearToMonth = (Date) => {
  if (Date) {
    let SplittedDate = Date?.split("-");
    [SplittedDate[0], SplittedDate[2]] = [SplittedDate[2], SplittedDate[0]];
    let FormattedDate = SplittedDate?.join("-");
    return FormattedDate;
  }
};

export const AuditHistory = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [auditHistory, setAuditHistory] = useState(auditHistoryData);
  const [currentPage, setCurrentpage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [tableFilters, setTablesFilters] = useState({ searchData: "" });
  const [selectedDate, setSelectedDate] = useState(null);

  const getAuditListing = () =>
    AuditHistoryApi.getAuditHistoryListing(
      10,
      currentPage,
      tableFilters?.searchData,
      FormatFromYearToMonth(selectedDate?.from) || "",
      FormatFromYearToMonth(selectedDate?.to) || ""
    )
      .then((res) => {
        if (res?.data) {
          let rowData = convertAuditHistoryData(res?.data);
          setAuditHistory((prev) => ({
            ...prev,
            rows: [...rowData],
          }));
          setTotalPage(
            Math.ceil(
              res?.page_details?.no_of_records / res?.page_details?.limit
            )
          );
          setCurrentpage(res?.page_details?.page);
        } else {
          setAuditHistory((prev) => ({
            ...prev,
            rows: [],
          }));
        }
        setLoading(false);
      })
      .catch((error) => {
        dispatch(setSnackbar({ value: true, message: error, color: "error" }));
        setLoading(false);
      });

  useEffect(() => {
    setLoading(true);
    getAuditListing();
  }, [currentPage, tableFilters, selectedDate]);

  const handleTableFilters = (e) => {
    setTablesFilters({ ...tableFilters, searchData: e });
    setCurrentpage(1)
  };
  const handlePagination = (value) => {
    setCurrentpage(value);
  };

  const handleDateChange = (e) => {
    setCurrentpage(1)
    setSelectedDate(e)
  };

  return (
    <>
      <div className="flex flex-col gap-0 bg-white p-0 !pb-0">
        <CustomTable
          rows={auditHistory?.rows}
          columnAlign={auditHistory?.columnAlign}
          columns={auditHistory?.columns}
          TableData={auditHistory?.TableData}
          handleTableFilters={handleTableFilters}
          tableFilters={tableFilters}
          handleChangePage={handlePagination}
          loading={loading}
          currentPage={currentPage}
          totalpage={totalPage}
          selectedDate={selectedDate}
          handleDateChange={handleDateChange}
        />
      </div>
    </>
  );
};
