import React, { useRef } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  useTheme,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "./CustomDatePicker.css";
import { CalenderIcon } from "../../assets/common-svg/CommonSvg";
import PropTypes from "prop-types";
import moment from "moment";

const CustomDatePicker = ({
  LabelText,
  handleChange,
  value,
  InputId,
  InputName,
  FontSize,
  placeholder,
  PaddingY,
  IsRequired,
  touched,
  error,
  disabled,
  helperText,
  minDate = true,
  maxDate
}) => {
  const datePickerRef = useRef(null);
  const theme = useTheme();
  const handleIconClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setFocus();
    }
  };

  return (
    <div className="flex flex-col  flex-grow">
      <InputLabel
        htmlFor={"entitlement"}
        sx={{ fontSize: FontSize ?? "12px" }}
        className={`text-[#000000] mb-2`}
      >
        {LabelText}
        {IsRequired ? <span className="text-red-500">*</span> : ""}
      </InputLabel>
      <FormControl
        className=" rounded-[6px]"
        fullWidth={true}
        sx={{ height: "44px" }}
      >
        <Box
          component={"div"}
          sx={{
            paddingY: PaddingY,
            fontSize: PaddingY ? "17px" : "16px",
            fontFamily: "sans-serif",
            border: `1px solid ${theme.palette.primary.light}`,
            backgroundColor: disabled ? theme.palette.grey[200] : "white",
          }}
          className='text-["black"] rounded-[6px]   outline-0  relative h-full border border-gray-200'
        >
          
          <DatePicker
            ref={datePickerRef}
            className={`date-picker-custom-class block w-full h-full ${disabled ? "bg-[#F0F1F5]" : "bg-white"}  flex-grow rounded-[6px]
                  border-0 border-none outline-none 
                 `}
            selected={value || ""}
            onChange={(date) => handleChange(date)}
            id={InputId}
            name={InputName}
            // peekNextMonth
            // showIcon
            wrapperClassName={
              "react-datepicker__view-calendar-icon block ml-0 pl-0  h-full rounded-[6px]  border-class bg-[#EDEDED]"
            }
            showMonthDropdown
            showYearDropdown
            placeholderText={placeholder}
            dropdownMode="select"
            calendarIconClassname="mt-1"
            value={value ? moment(value).format("DD/MM/YYYY") : ""}
            minDate={minDate ? typeof minDate == 'boolean' ? new Date() : new Date(minDate) : null}
            maxDate={maxDate ? maxDate : null}
            onFocus={(event) => {
              event.target.setAttribute('autocomplete', 'off');
            }}
          />
          <button type="button" onClick={handleIconClick}>
            <CalenderIcon />
          </button>
        </Box>
        {touched && error && (
          <FormHelperText
            className="absolute left-0 -bottom-6 ml-0 "
            error
            id={`standard-weight-helper-text-${InputId}`}
          >
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
};

CustomDatePicker.proptypes = {
  LabelText: PropTypes.string,
  handleChange: PropTypes.func,
  value: PropTypes.string,
  InputId: PropTypes.string,
  InputName: PropTypes.string,
  placeholder: PropTypes.string,
  PaddingY: PropTypes.string,
  IsRequired: PropTypes.bool,
  disabled: PropTypes.bool
};

export default CustomDatePicker;
