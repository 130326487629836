import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

export const CustomScrollDiv = ({ heading, subHeading, highlightHeading }) => {
  return (
    <div className=' pb-2 pt-3'>
      <div className='flex items-center'>
        <Typography variant='h6' className='text-sm lg:text-base' fontWeight={400}>{heading}<span className='text-[#2F5597] cursor-pointer'>{" "}{highlightHeading}</span></Typography>
      </div>
        <Typography color='secondary' variant='body-2'>{subHeading}</Typography>
    </div>
  )
}

CustomScrollDiv.propTypes = {
  heading: PropTypes.string,
  subHeading: PropTypes.string || null,
  children: PropTypes.node,
  highlightHeading: PropTypes.string,
}
