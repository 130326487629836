import React, { useRef } from 'react';
import { FormHelperText, Typography } from '@mui/material';
import { CloseIcon, DownloadButton, PdfIcon, ImageIcon } from '../../assets/common-svg/CommonSvg';

export const CustomMultiUpload = ({ readOnly, label, accept, placeholder, name, handleChange, error, touched, helperText, value, InputId, handleRemoveFiles }) => {
    const fileInputRef = useRef(null);
    const handleClick = () => {
        fileInputRef.current.click();
    };

    const handleButtonClick = (event) => {
        event.stopPropagation();
        handleClick();
    };

    const handleDownload = (path, name) => {
        const link = document.createElement('a');
        link.href = path;
        link.download = name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    // application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain,

    return (
        <div className="w-100 ">
            <div className="text-black text-sm font-normal font-['Public Sans'] pb-2" >{label}</div>
            {!readOnly &&
                <div className="w-100 border rounded-md justify-between items-center cursor-pointer flex" >
                    <div className="flex justify-start items-center gap-2.5 flex-1" onClick={handleClick} >
                        <div className="mt-2">
                            <label htmlFor="fileInput" className="text-neutral-500 text-base flex gap-3 font-normal pl-2 font-['Public Sans']">
                                <p>  <PdfIcon /></p><p className='align-self-center cursor-pointer'>  {placeholder}</p>
                            </label>
                            <input
                                id="fileInput"
                                name={name}
                                type="file"
                                className="hidden"
                                accept={accept ? accept : "application/pdf, image/*"}
                                ref={fileInputRef}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <button
                        type="button"
                        className="p-2 bg-blue-500 text-white rounded-md mr-2"
                        onClick={handleButtonClick}
                    >
                        {label}
                    </button>
                </div>}
            <div>
                <div className='w-100 mt-2 border !mb-2 rounded-md justify-between items-center  '>
                    {value && value.length != 0
                        ? value.map((op, index) => (
                            <div key={op.name} className='flex justify-content-between'>
                                <Typography key={op.name} variant='body1' className='mb-2 ml-2 mt-2 flex gap-6 align-self-center'>
                                    <p className='align-self-center'>{op.type == "application/pdf" ? <PdfIcon /> : <ImageIcon />}</p>
                                    <p className='align-self-center '>  {op?.name}</p>
                                    {!readOnly &&
                                        <p className='align-self-center cursor-pointer' onClick={() => handleRemoveFiles(index)}> <CloseIcon fill="red" /></p>}
                                </Typography>
                                {readOnly &&
                                    <a key={op.path} onClick={() => handleDownload(op.path, op.name)} href={op.path} className='mr-3'>
                                        <DownloadButton />
                                    </a>}
                            </div>
                        ))
                        : <Typography className='text-center p-4' variant='body1'>No Files here</Typography>}
                </div>
            </div>
            {touched && error && (
                <FormHelperText
                    error
                    id={`standard-weight-helper-text-${InputId}`}
                >
                    {helperText}
                </FormHelperText>
            )}
        </div>
    );
};
