import React from "react";
import { CustomStepper } from "./CustomStepper";
import { Typography, useTheme } from "@mui/material";

export const FormQuery = ({
  queryHeading,
  stepTitles,
  msgText,
  queryTime,
  show,
  convoHeading,
  date,
  index,
}) => {
  const theme = useTheme();
  return (
    <>
      {stepTitles.length > 2 ? (
        <div
          className={`w-full px-2 ${
            show ? "block" : "hidden"
          } mt-[10px]`}
        >
          <div className="formQuery-heading flex items-center  flex-col mx-auto">
            <Typography variant="body-1" color="secondary" className="mb-[6px]">
              {queryHeading}
            </Typography>
            <CustomStepper stepTitles={stepTitles} />

            <div className="px-2 sm:!px-5 w-full">
              {msgText && (
                <div className="relative rounded-tr-none bg-[#3571FF] p-[10px] w-full  text-white flex flex-col gap-[3px] ml-auto rounded-[5px_0px_5px_5px] sm:w-fit max-w-full sm:!max-w-[45%] break-words after-triangle">
                  <Typography
                    className="text-sm sm:!text-base"
                    fontWeight={theme.typography.fontWeightRegular}
                    variant="h6"
                  >
                    {msgText}
                  </Typography>
                  <Typography
                    textAlign={"end"}
                    variant="body1"
                    fontWeight={theme.typography.fontWeightRegular}
                  >
                    {queryTime}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="messageArea-heading  flex  flex-col  w-full items-center justify-start gap-[10px] ">
          {index > 0 && (
            <Typography variant="body-1" color="secondary" className="">
              {"Form Related Query Resolved"}
            </Typography>
          )}
            {date && <Typography variant="body1" color="secondary">
            {date}
            </Typography>}
          {convoHeading && (
            <Typography
              variant="body1"
              bgcolor={theme.palette.grey[200]}
              className="p-[5px_8px_5px_8px_] rounded-[5px] mb-2"
            >
              {convoHeading}
            </Typography>
          )}
        </div>
      )}
    </>
  );
};
