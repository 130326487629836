import React from 'react';
import Chart from 'react-apexcharts';

const CardChart = ({ data }) => {
    
    const defaultStroke = data?.seriesData?.some((item) => Number(item) > 0);
    return (
      <div
        className={!defaultStroke ? "defsult-stroke-cricle" : ""}
        style={{ display: "flex", alignItems: "center" }}
      >
        {data && (
          <Chart
            options={data?.optionsData}
            series={data?.seriesData}
            type="donut"
            height={160}
          />
        )}
      </div>
    );
};

export default CardChart;
