// material-ui
import { Box, useMediaQuery } from '@mui/material';

// project import
import Profile from './Profile';
import MobileSection from './MobileSection';
import Toggle from '../../../../components/toggle-switch/ToggleSwitch'
import { useLocation } from 'react-router-dom';
import { useState } from 'react';

// ==============================|| HEADER - CONTENT ||============================== //
function transformPath(path) {
  path = path.replace(/^\//, '');
  const parts = path.split('/');

  const transformedParts = parts.map(part => {
    if (part.includes('-')) {
      return part.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    } else {
      return part.charAt(0).toUpperCase() + part.slice(1);
    }
  });
  
  return transformedParts?.[0]  || "";
  // return transformedParts.join('');
}

const HeaderContent = ({ handleSetOpenLogoutSnackbarTrue }) => {
  const location = useLocation();
  const { pathname } = location;
  const [alignment, setAlignment] = useState("CDM")
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <>
      {/* {!matchesXs && ""} */}
      {matchesXs && <Box sx={{ width: "100%", ml: { xs: 0, lg: -2 } }} />}
      <span className="font-bold ml-1 xs:ml-3 text-sm md:text-base w-[110px] 1sm:!w-full break-all truncate overflow-hidden whitespace-nowrap">
        {transformPath(pathname?.includes("products/") ? "/product"  : pathname)}
      </span>

      <div className="flex md:justify-end md:w-screen mr-2 ">
        <Toggle
          leftText={"CDM"}
          rightText={"BMR+"}
          disabled={true}
          setAlignment={setAlignment}
          alignment={alignment}
        />
      </div>
      <p className="sm:p-2 md:pl-6 md:pr-4 text-lg text-gray-400">|</p>
      {!matchesXs && (
        <Profile
          handleSetOpenLogoutSnackbarTrue={handleSetOpenLogoutSnackbarTrue}
        />
      )}
      {matchesXs && (
        <MobileSection
          handleSetOpenLogoutSnackbarTrue={handleSetOpenLogoutSnackbarTrue}
        />
      )}
    </>
  );
};

export default HeaderContent;
