import React, { useCallback, useEffect, useState } from "react";
import {
  DocotorIcon,
  DocotorPairIcon,
} from "../../assets/common-svg/CommonSvg";
import SubjectApi from "../../services/subjectService.js";
import CustomTable from "../../components/custom-table/CustomTable";
import { CustomCard } from "../../components/custom-card/CustomCard";
import { useLocation, useNavigate, useParams } from "react-router";
import SkeletonLoader from "../../utilites/SkeltonLoader";
import { editorManagementDetailData } from "../../utils/tableData";
import BreadCrumb from "../breadcrumb/BreadCrumb";
import SubAdminApi from "../../services/subAdminService";
import EditorApi from "../../services/editorManagementService.js";
import DeleteConfirmContent from "../../components/modal-contents/DeleteConfirmModalContent.js";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../store/reducers/snackBar.js";
import EditorManagementEdit from "../editor-management-edit/EditorManagementEdit.js";
import { CustomSnackbar } from "../../components/snackbar/CustomSnackbar.js";

export const convertSubjectData = (data) => {
  return data?.map((sub) => {
    let transformCode = sub?.subject_code || "";
    let transformedSubjectInitial = sub?.first_name + " " + sub?.last_name || "";
    let transformePhaseName = sub?.phase_name || "";
    let transformedAppointmentCount = sub?.appointmentCount || 0;
    let gender = sub?.gender || "";
    let id = sub?.id || "";
    return [
      transformCode,
      transformedSubjectInitial,
      transformePhaseName,
      transformedAppointmentCount,
      gender,
      id,
    ];
  });
};

export const EditorManagementDetails = () => {
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loadingDropdown, setLoadingDropdown] = useState(false);
  const [editorDetailedData, setEditorDetailedData] = useState({});
  const [tableFilters, setTablesFilters] = useState({ searchData: "" });
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentpage] = useState(1);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [phaseListData, setPhaseListData] = useState(null);
  const [phaseID, setPhaseID] = useState(null);
  const [piList, setPiList] = useState([]);
  const [piId, setPiId] = useState('')
  const [phaseTable, setPhaseTable] = useState([])
  const [openDeleteConfirmModal, setOpenDeleteConfirmModal] =
    React.useState(false);
  const [subjectData, setSubjectData] = useState(editorManagementDetailData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const snackbar = useSelector((state) => state.snackBar);

  const handleNavigateToDetails = useCallback((row) => {
    const UpdatedNavigationArray = location.state;
    UpdatedNavigationArray?.push({
      name: "Patient Details",
      path: `${location.pathname}/patient-details/${row[row.length - 1]}`,

    });
    navigate(`patient-details/${row[row.length - 1]}`, {
      state: UpdatedNavigationArray,
    });
  }, []);

  const getSubAdminDetails = async () => {
    setLoading(true);
    try {
      const res = await SubAdminApi.getSubAdminDetails(id);
      setEditorDetailedData(res?.data || {});
      setPiId(res?.data?.investigator_details?.id) 
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSubAdminDetails();
    getPersonalInvestigatorList()
    getPhaseDropTable()
  }, []);

  const getPhaseDropTable = async () => {
    try {
      const res = await EditorApi.getPhaseDropDown("", "", id);
      const data = res?.data?.map((val) => ({
        id: val.id,
        name: val.title,
      }));
      setPhaseTable(data);
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));

    }
  };


  async function getSubjectList() {
    try {
      const res = await SubjectApi.getSubjectList(
        phaseID,
        currentPage,
        10,
        tableFilters?.searchData,
        id
      );
      let rowData = convertSubjectData(res?.data || []);
      setSubjectData((prev) => ({
        ...prev,
        rows: [...rowData],
      }));
      setTotalPage(
        Math.ceil(res?.page_details?.no_of_records / res?.page_details?.limit)
      );
      setCurrentpage(res?.page_details?.page);
      setLoadingDropdown(false);
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));

    }
  }

  const handlePagination = async (value) => {
    setCurrentpage(value);
  };

  const handleTableFilters = (e) => {
    setTablesFilters({ ...tableFilters, searchData: e });
  };

  useEffect(() => {
    if (tableFilters?.searchData[tableFilters?.searchData?.length - 1] != " ") {
      setLoadingDropdown(true);
      getSubjectList();
    }
  }, [currentPage, tableFilters, id, phaseID]);

  //phase list

  const getPhase = async () => {
    try {
      const res = await EditorApi.getPhaseDropDown("", piId);
      const data = res?.data?.map((val) => ({
        id: val.id,
        name: val.title,
      }));
      setPhaseListData(data);
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));

    }
  };

  useEffect(() => {
    getPhase();
  }, [piId]);

  const handleDropDownChange = (e) => {
    setPhaseID(e.target.value);
  };
  const handleDeleteRow = async () => {
    setOpenDeleteConfirmModal(false);
    setSubmitLoading(true);
    try {
      const res = await EditorApi.deleteEditor(id);
      if (res?.status === 200) {
        setSubmitLoading(false);
        dispatch(setSnackbar({ value: true, message: res.message, color: "success" }));
        navigate("/editor-management", { state: [] });
      }
    } catch (error) {
      setSubmitLoading(false);
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));
    }
  };

  const getPersonalInvestigatorList = () =>
    EditorApi.subadminDropDown()
      .then((res) => {
        setPiList(res.data);
      })
      .catch((error) => {
        dispatch(setSnackbar({ value: true, message: error, color: "error" }));

      });

      return (
    <div className="p-2 pt-4 bg-[#F0F1F5]">
      <div className="mb-4 pl-3">
        <BreadCrumb BreadCrumbsListing={
          [
            {
              name: 'Onboard Users',
              path: 'onboard-users'
            },
            {
              name: 'Details',
              path: location.pathname
            }
          ]
        } />
      </div>

      <div className="pl-3 pr-3">
        {" "}
        {loading ? (
          <div className="col-start-1 col-end-4 ">
            <SkeletonLoader rowCount={1} isTable={false} height={160} />
          </div>
        ) : (
          <>
            {" "}
            <CustomCard
              editAuth={{ module: "editorManage", auth: 60 }}
              deleteAuth={{ module: "editorManage", auth: 61 }}
              MainHeading={editorDetailedData?.name}
              SubHeading={`${editorDetailedData?.email || ""}  |  ${editorDetailedData?.phone || ""
                }`}
              main_heading_class={"font-bold"}
              NoWrap={true}
              DetailsArray={[
                {
                  FieldName: "Assigned Phases",
                  Value: editorDetailedData?.phaseCount,
                },
                {
                  FieldName: "Principal Investigator",
                  Value: editorDetailedData?.investigator_details?.name || "",
                },
              ]}
              startIcon={<DocotorIcon Height="50px" Width="52px" />}
              endIcon={<DocotorPairIcon />}
              ArrayLowGapBoolean={true}
              chartData={editorDetailedData?.phaseDetails || []}
              handleEdit={() => setOpenEditModal(true)}
              handleDelete={() => setOpenDeleteConfirmModal(true)}
            />{" "}
          </>
        )}
      </div>
      {openEditModal ? (
        <EditorManagementEdit
          openModal={openEditModal}
          setOpenModal={setOpenEditModal}
          id={id}
          editorDetailedData={editorDetailedData}
          phaseList={phaseListData}
          piList={piList}
          getSubAdminDetails={getSubAdminDetails}
          setPiId={setPiId}
        />
      ) : null}
      <CustomTable
        rows={subjectData?.rows}
        columnAlign={subjectData?.columnAlign}
        columns={subjectData?.columns}
        TableData={subjectData?.TableData}
        handleNavigate={handleNavigateToDetails}
        handleTableFilters={handleTableFilters}
        tableFilters={tableFilters}
        handleChangePage={handlePagination}
        loading={loadingDropdown}
        currentPage={currentPage}
        totalpage={totalPage}
        MenuList={phaseTable}
        dropDownValue={phaseID}
        handleChangeDropDown={handleDropDownChange}
      />
      <DeleteConfirmContent
        open={openDeleteConfirmModal}
        setOpen={setOpenDeleteConfirmModal}
        handleCancel={() => setOpenDeleteConfirmModal(false)}
        handleDelete={handleDeleteRow}
        isLoading={submitLoading}
      />
      <CustomSnackbar
        open={snackbar?.value}
        setOpen={setSnackbar}
        color={snackbar?.color}
        message={snackbar?.message}
      />
    </div>
  );
};
