import { Button } from "@mui/material";
import CustomSearchbar from "../custom-searchbar/CustomSearchbar"
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";
import { getUserHasAccess } from "../../utils/authorities";


export const TaskManagementHeader = ({ ButtonText, ButtonAuth, handleButtonClick, SearchbarPlaceholder, handleSearch, value, disabled, showExportButton = false, handleExportButton = () => {} }) => {
  const LoginData = useSelector((state) => state.loginData);
  const userAuthority = LoginData?.auth || [];
  return (
    <div className=" w-full grid lg:grid-cols-12 gap-4">
      <div className="col-start-1 col-end-7 sm:col-end-4 w-100">
        {handleSearch && (
          <CustomSearchbar
            value={value}
            Placeholder={SearchbarPlaceholder}
            handleSearch={handleSearch}
            disabled={disabled}
          />
        )}
      </div>
      <div className="col-start-7 sm:col-start-4 col-end-13 w-100  text-end ">
        {ButtonAuth?.module ? getUserHasAccess(userAuthority, ButtonAuth?.module, ButtonAuth?.auth) &&
          <Button
            size="medium"
            variant="contained"
            onClick={handleButtonClick}
            className="pl-2 pr-2 sm:pl-5 sm:pr-5"
          >
            {ButtonText}
          </Button> : <Button
            size="medium"
            variant="contained"
            onClick={handleButtonClick}
            className="pl-2 pr-2 sm:pl-5 sm:pr-5"
          >
          {ButtonText}
        </Button>}
          {showExportButton && <Button
            size="medium"
            variant="contained"
            onClick={handleExportButton}
            className="pl-2 pr-2 sm:pl-5 sm:pr-5 ml-2"
          >
            Export
          </Button>}
      </div>
    </div>
  );
}

export default TaskManagementHeader;


TaskManagementHeader.proptypes = {
  ButtonText: PropTypes.string,
  SearchbarPlaceholder: PropTypes.string,
  handleButtonClick: PropTypes.func,
  handleSearch: PropTypes.func,
}

