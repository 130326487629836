const getNameForPictureOfAdmin = (user) => {
  let ShortName = "";
  if (user && user.name) {
    const AdminName = user.name;
    const arr = AdminName.split(" ");
    if (arr.length > 0) {
      const tempString = arr.map((element) => element[0]);
      ShortName = tempString.join("");
    }
    return ShortName;
  } else {
    return ShortName;
  }
};
export const calculateYears = (date) => {
  const birthDate = new Date(date);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }
  return age;
};

export const getCurrentYear = () => {
  const date = new Date();
  let currentYear = date.getFullYear();
  return currentYear;
};
export const AddProductModalTitleText = "Add Product ";

export const EditProductModalTitleText = "Edit Product Details";

export const AddHospitalModalText = "Add New Hospital";

export const EditHospitalModalText = "Edit Hospital";

export const FormTemplateModalText = "New Form template";

export const AddPrincipleInvestigatorModalText = "Add Principle Investigator";

export const EditPrincipleInvestigatorModalText = "Edit Principle Investigator";

export const AddImmunoActQAText = "Add ImmnoAct QA";

export const EditImmunoActQAText = "Edit ImmnoAct QA";

export const AddDiseaseManagementModalText = "Add New Disease";

export const EditDiseaseManagementModalText = "Edit Disease";

export const AddEditorManagementModalText = "Add Editor";

export const EditEditorManagementModalText = "Edit Editor";

export const ResendTrialManagementModalText = "Resend Request";

export const RejectTrialManagementModalText = "Description";

export const DescripitionTrialManagementModalText = "Description";

export const AddPhaseModalText = "Add New Phase";



export default getNameForPictureOfAdmin;

export const UrlToRouteArrayConverter = (phaseDetails, routeUrl) => {
  let productDetails = phaseDetails?.product_details;
  let BreadCrumbArray = [];
  if (routeUrl) {
    let spllitedUrlArray = routeUrl?.split("/");
    spllitedUrlArray.forEach((path, index) => {
      let DetailPageBoolean = false;
      let pathobj = {};
      if (
        spllitedUrlArray[index - 1]?.length > 0 &&
        spllitedUrlArray[index].includes(spllitedUrlArray[index - 1])
      ) {
        DetailPageBoolean = true;
      }
      if (isNaN(Number(path))) {
        let splittedPathArray = path?.split("-");
        splittedPathArray = splittedPathArray?.map(
          (word) => word?.charAt(0).toUpperCase() + word?.slice(1)
        );

        let joinedPathString = splittedPathArray?.join(" ");
        if (joinedPathString === "Product Details") {
          if (productDetails?.id == spllitedUrlArray[index + 1]) {
            pathobj.name = `${productDetails?.name[0]?.toUpperCase()+productDetails?.name.slice(1)+" " || ""}Details`;
          } else {
            pathobj.name = "Details";
          }
        } else if (joinedPathString === "Trials") {
          pathobj.name = `${phaseDetails?.title || ""}`;
        } 
        else if (joinedPathString === "Assigned Editors"||joinedPathString === "Editor Management") {
          pathobj.name = 'Editor';
        }
        else if (joinedPathString === "Patient Details") {
          pathobj.name = 'Patient Details';
        }
        else if (joinedPathString === "Role Details") {
          pathobj.name = 'Role Details';
        }
        else {
          if (DetailPageBoolean) {
            pathobj.name = "Details";
          } else {
            pathobj.name = joinedPathString;
          }
        }
        if (!isNaN(spllitedUrlArray[index + 1])) {
          let pathArray = spllitedUrlArray.slice(1, index + 2);
          let pathString = pathArray.join("/");
          pathobj.path = pathString;
        } else {
          pathobj.path = `${spllitedUrlArray[index]}`;
        }
        BreadCrumbArray.push(pathobj);
      }
    });
  }
  return BreadCrumbArray;
};

export const FormBreadCrumbGetter = (routeUrl,phaseDetails) => {
  
  let BreadCrumbArray = [];
  if (routeUrl) {
    let spllitedUrlArray = routeUrl?.split("/");
    spllitedUrlArray.forEach((path, index) => {
      let pathobj = {};
      if (isNaN(Number(path))) {
        let splittedPathArray = path?.split("-");
        splittedPathArray = splittedPathArray?.map(
          (word) => word?.charAt(0).toUpperCase() + word?.slice(1)
        );

        let joinedPathString = splittedPathArray?.join(" ");
        if (
          joinedPathString === "Create Form Builder"
        ) {
          pathobj.name = "Add New Form";
        } else if (joinedPathString === "Form Builder Preview") {
          pathobj.name = "Preview";
        } else if (joinedPathString === "Product Details") {
          if(phaseDetails)
          pathobj.name = phaseDetails?.product_details?.name + " Details";
        }
        else {
          pathobj.name = joinedPathString;
        }
        if (!isNaN(spllitedUrlArray[index + 1])) {
          let pathArray = spllitedUrlArray.slice(1, index + 2);
          let pathString = pathArray.join("/");
          pathobj.path = pathString;
        } else {
          pathobj.path = `${spllitedUrlArray[index]}`;
        }
        BreadCrumbArray.push(pathobj);
      }
    });
  }
  return BreadCrumbArray;
};
