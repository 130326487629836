import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Toolbar, useMediaQuery } from "@mui/material";

// project import
import navigation from "../../menu-items";
import Breadcrumbs from "../../../src/components/@extended/Breadcrumbs";

// types
import { openDrawer } from "../../../src/store/reducers/menu";
import HeaderEditor from "./HeaderEditor";
import MainNewDrawer from "./NewDrawer";
import Header from "./Header";
import dashboard from '../../menu-items/dashboard';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const firstSegment = location.pathname.split('/')[1];
  const { drawerOpen } = useSelector((state) => state.menu);
  const role_id = useSelector((state) => state.loginData.role_id);
  const matchesLg = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  // drawer toggler
  const [open, setOpen] = useState(drawerOpen);



  const handleDrawerToggle = () => {
    let isChild = null
    for (let item of dashboard.children) {
      if (item?.subChildren) {
        for (let subItem of item.subChildren) {
          if (subItem.id === firstSegment) {
            isChild = subItem;
            isChild.parent = item.title;
            isChild.parentId = item.id;
            break;
          }
        }
      }
    }
    if (isChild) {
      dispatch(openDrawer({ drawerOpen: (role_id === 2 && !matchesLg) ? false : !drawerOpen }));
    }
  };
  useEffect(() => {
    dispatch(openDrawer({ drawerOpen: false }));
  }, []);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
  }, [drawerOpen]);

  const handleMiniDrawerToggle = () =>{
    dispatch(openDrawer({ drawerOpen: (role_id === 2 && !matchesLg) ? false : !drawerOpen }));
  }

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      {role_id === 5 ? (
        <HeaderEditor open={open} handleDrawerToggle={handleDrawerToggle} />
      ) : (
        <Header open={open} handleDrawerToggle={handleMiniDrawerToggle} />
      )}
      {role_id === 5 ? null : (
        <MainNewDrawer open={open} handleDrawerToggle={handleDrawerToggle} />
      )}
      <Box
        component="main"
        sx={{
          maxWidth: "100%",
          width: open
            ? { xs: "100%", lg: "70%", md: "70%" }
            : { xs: "100%", lg: "100%", md: "100%", sm: "100%" },
          flexGrow: 1,
          height: "100vh",
        }}
        onClick={() => dispatch(openDrawer({ drawerOpen: false }))}
      >
        <Toolbar />
        <Breadcrumbs navigation={navigation} title />
        <Outlet />
      </Box>
    </Box>
  );
};

export default MainLayout;
