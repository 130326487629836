import React from "react";
import { NotificationCount } from "./NotificationCount";
import { Box, Chip, Typography, useTheme } from "@mui/material";

const MessageBox = ({
  initials,
  name,
  query,
  date,
  messageContent,
  isActive,
  color = "success",
  handleShowMessageBox,
  count,
  item,
}) => {
  const theme = useTheme();
  return (
    <div
      className={`relative lg:h-auto  p-[10px] user-chatlist  rounded-[10px] hover:!bg-[#F6F6FE] transition-all duration-300 ease-in-out cursor-pointer ${
        isActive ? "bg-[#F0F1F5]" : "bg-white"
      }`}
      onClick={handleShowMessageBox}
    >
      <div className="flex relative items-start">
        <Box
          component={"div"}
          bgcolor={
            color == "success"
              ? theme.palette.success.main
              : color == "warning"
              ? theme.palette.warning.main
              : color == "error"
              ? theme.palette.error.main
              : theme.palette.info.main
          }
          className={`rounded-full w-[38px] h-[38px] thumbnailuser sm:text-text-sm lg:text-base font-[roboto] flex items-center justify-center text-white font-medium`}
        >
          {initials}
        </Box>
        <div className="flex-1 justify-start min-w-0 ml-[10px]">
          <Typography
            variant="h6"
            fontWeight={theme.typography.fontWeightMedium}
            className="pb-[5px] max-w-[70%] truncate capitalize"
          >
            {name}
          </Typography>
          <div className="flex items-start flex-col gap-2 lg:gap-[10px] flex-wrap">
            <div className="d-flex flex-wrap">
              <Chip
                color="success"
                size="small"
                variant="filled"
                label={query}
                className="mr-1"
              />
              {item?.phase_details && (
                <>
                  {Array.from(new Set(item.phase_details.map(ele => ele.product_details.name))).map((productName, index) => (
                    <div key={index} className="mr-1 mb-1">
                      <Chip
                        color={color}
                        size="small"
                        variant="filled"
                        label={productName}
                        className=""
                      />
                    </div>
                  ))}
                </>
              )}

            </div>
            <Typography
              variant="h6"
              fontWeight={theme.typography.fontWeightRegular}
              className="opacity-50 truncate text-sm sm:!text-base max-w-[80%]"
            >
              {messageContent && messageContent.length > 34
                ? messageContent.slice(0, 34) + "..."
                : messageContent}
            </Typography>
          </div>
        </div>
        <Typography className="absolute right-0 top-0 chatdate" color="secondary">
          {date}
        </Typography>
        {count > 0 ? (
          <div className="absolute  bottom-0 right-0">
            <NotificationCount count={count} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default MessageBox;
