// ==============================|| PRESET THEME - THEME SELECTOR ||============================== //

const Theme = (colors) => {
  const { blue, red, gold, cyan, green, grey } = colors;
  const greyColors = {
    0: grey[0],
    50: grey[1],
    100: grey[2],
    200:'#F0F1F5',
    300: '#777777',
    400: '#828282',
    500: grey[6],
    600: grey[7],
    700: grey[8],
    800: grey[9],
    900: grey[10],
    A50: grey[15],
    A100: grey[11],
    A200: grey[12],
    A400: grey[13],
    A700: grey[14],
    A800: grey[16]
  };
  const contrastText = '#fff';

  blue[5] = "#3571FF";

  const Black = '#000';
  const WhiteBg = '#FFC0CB';
  const DisabledGrey = '#5c5959';
  const DarkGreen = '#00B278';
  const LightGreen = '#E4F9F2';
  const DarkOrange = '#FB9D2C';
  const LightOrange = '#FFEDCB';
  const DividerGrey = '#DDDDDD';
  const DarkBlue = '#3571FF';
  const DarkRed = '#FF2023';
  const LightRed = '#FFE3E3';
  const MainColor = "#"

  return {
    colors: {
      MainColor,
      Black,
      WhiteBg,
      DarkGreen,
      DividerGrey,
      LightGreen,
      DarkOrange,
      DarkBlue,
      LightOrange,
      DisabledGrey
    },
    primary: {
      lighter: blue[0],
      100: blue[1], 
      200: blue[2],
      light: blue[3],
      400: blue[4],
      main: DarkBlue,
      dark: blue[6],
      700: blue[7],
      darker: blue[8],
      900: blue[9],
      contrastText
    },
    secondary: {
      lighter: greyColors[100],
      100: greyColors[100],
      200: greyColors[200],
      light: greyColors[300],
      400: greyColors[400],
      main: DarkGreen,
      600: greyColors[600],
      dark: greyColors[700],
      800: greyColors[800],
      darker: greyColors[900],
      A100: greyColors[0],
      A200: greyColors.A400,
      A300: greyColors.A700,
      contrastText: greyColors[0]
    },
    error: {
      lighter: LightRed,
      light: red[2],
      main: DarkRed,
      dark: red[7],
      darker: red[9],
      contrastText
    },
    warning: {
      lighter: LightOrange,
      light: gold[3],
      main: DarkOrange,
      dark: gold[7],
      darker: gold[9],
      contrastText: greyColors[100]
    },
    info: {
      lighter: cyan[0],
      light: cyan[3],
      main: DarkBlue,
      dark: cyan[7],
      darker: cyan[9],
      contrastText
    },
    success: {
      lighter: LightGreen,
      light: green[3],
      main: DarkGreen,
      dark: green[7],
      darker: green[9],
      contrastText
    },
    grey: greyColors,
  };
};

export default Theme;
