import { useEffect, useState } from "react";
import CustomModel from "../../components/custom-model/CustomModel";
import { addEditorValidationSchema } from "../../utils/formik/validationSchema";
import { addPrincipalInvestigatorValues } from "../../utils/formik/initialValues";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../store/reducers/snackBar";
import EditorApi from "../../services/editorManagementService";

function EditorManagementEdit({
  openModal,
  setOpenModal,
  id = "",
  piList,
  editorDetailedData,
  phaseList = [],
  getSubAdminDetails,
  setPiId
}) {
  const [initialValues, setInitialValues] = useState(
    addPrincipalInvestigatorValues
  );

  const dispatch = useDispatch();
  const[submitLoading,setSubmitLoading] = useState(false);
  const [modelData, setModelData] = useState({
    title: "Add Editor",
    editTitle: "Edit Editor",
    children: [
      {
        type: "input",
        name: "name",
        label: "Name",
        placeholder: "Enter Name",
        IsRequired: true,
      },
      {
        type: "input",
        name: "email",
        label: "Email",
        placeholder: "Enter Email",
        IsRequired: true,
      },
      {
        type: "number",
        name: "phoneNumber",
        label: "Phone Number",
        placeholder: "Enter Phone Number",
        IsRequired: true,
      },
      {
        type: "dropdown",
        name: "principalInvestigator",
        label: "Principal Investigator",
        placeholder: "Select Principal Investigator",
        data: [],
        IsRequired: true,
      },
      {
        type: "multidropdown",
        name: "trial",
        label: "Phase",
        placeholder: "Select Phase",
        data: [],
        IsRequired: true,
      },
    ],
  });

  useEffect(() => {
    setInitialValues({
      name: editorDetailedData?.name,
      email: editorDetailedData?.email,
      phoneNumber: editorDetailedData?.phone?.toString(),
      trial: editorDetailedData?.phaseDetails.map(item => item.id),
      principalInvestigator: editorDetailedData?.investigator_details?.id,
    });
  }, [editorDetailedData]);

  useEffect(() => {
    const newChildren = [
      {
        type: "input",
        name: "name",
        label: "Name",
        placeholder: "Enter Name",
        IsRequired: true,
      },
      {
        type: "number",
        name: "phoneNumber",
        label: "Phone Number",
        placeholder: "Enter Phone Number",
        IsRequired: true,
      },
      {
        type: "dropdown",
        name: "principalInvestigator",
        label: "Principal Investigator",
        placeholder: "Select Principal Investigator",
        data: piList,
        IsRequired: true,
      },
      {
        type: "multidropdown",
        name: "trial",
        label: "Phase",
        placeholder: "Select Phase",
        data: phaseList,
        IsRequired: true,
      },
    ];

    newChildren.push({
      type: "input",
      name: "email",
      label: "Email",
      placeholder: "Enter Email",
      IsRequired: true,
      disabled: true,
    });

    setModelData((prevModelData) => ({
      ...prevModelData,
      children: newChildren,
    }));
  }, [id, piList, phaseList]);

  const handleEditModelValues = async (values) => {
    setSubmitLoading(true);
    const dataIds = editorDetailedData?.phaseDetails.map(item => item.id);
    // // Filter out the IDs that are present in values?.trial
    const missingIds = dataIds?.filter(id => !values?.trial.includes(id));
    const isInvestigator = editorDetailedData?.investigator_details?.id !== values?.principalInvestigator ? editorDetailedData?.investigator_details?.id : 0
    try {
      const res = await EditorApi.editEditor(
        {
          name: values?.name,
          email: values?.email,
          country_code: "91",
          phone: values?.phoneNumber?.toString(),
          phase_ids: values?.trial,
          role_id: editorDetailedData?.role_id || "",
          investigator_id: values?.principalInvestigator,
          removedPhaseIds: missingIds,
          removedInvestigatorId: isInvestigator
        },
        id
      );

      if (res.status === 200) {
        dispatch(setSnackbar({ value: true, message: res.message, color:"success" }));
        setOpenModal(false);
        setSubmitLoading(false);
        getSubAdminDetails();
      }
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));
      setSubmitLoading(false);
    }
  };

  const handleChange = (e) => {
    setPiId(e)
  }


  return (
    <>
      <CustomModel
        rowId={id}
        modalInitialValues={initialValues}
        initialValuesValidationSchema={addEditorValidationSchema}
        open={openModal}
        setOpen={setOpenModal}
        setModelData={setModelData}
        modelData={modelData}
        handleModelValues={handleEditModelValues}
        isLoadingBtn={submitLoading}
        onChange={handleChange}
      />
    </>
  );
}

export default EditorManagementEdit;
