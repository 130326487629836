// ==============================|| DEFAULT THEME - TYPOGRAPHY  ||============================== //


// ==============================|| DEFAULT THEME - TYPOGRAPHY  ||============================== //


const Typography = (fontFamily) => ({
  htmlFontSize: 16,
  fontFamily,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  h1: {
    fontWeight: 600,
    fontSize: '2.5rem',
    lineHeight: 1.21
  },
  h2: {
    fontWeight: 600,
    fontSize: '2rem',
    lineHeight: 1.27
  }
  // Info :- Font size 28px
  ,
  h3: {
    fontWeight: 600,
    fontSize: '1.75rem',
    // lineHeight: 1.33
  },
  //Info :- Font size 22px 
  h4: {
    fontWeight: 700,
    fontSize: '1.5rem',
  },
  // Info :- Font size 20px.
  h5: {
    fontSize: '1.1rem',
    fontWeight: 800,
    lineHeight:1
  },
  // Info :- Font size 16px 
  h6: {
    fontWeight: 600,
    fontSize: '1rem',
  },
  caption: {
    fontWeight: 400,
    fontSize: '0.75rem',
    // lineHeight: 1.66
  },
  // Info:- Font size 14px 
  body1: {
    fontSize: '0.875rem',
    fontWeight: 400
  },
  body2: {
    fontSize: '0.75rem',
    fontWeight: 400
  },
  subtitle1: {
    fontSize: '0.875rem',
    fontWeight: 600,
    // lineHeight: 1.57
  },
  //Info :- Font size 14px.
  subtitle2: {
    fontSize: '0.875rem',
    fontWeight: 400,
  },
  overline: {
    lineHeight: 1.66
  },
  button: {
    sm: {
      fontSize: ''
    },
    textTransform: 'capitalize'
  }
});

export default Typography;


// Font Size Theming
// h3 => 28px
// h4 => 22px
// h5 => 24px
// h6 => 20px

// Color Theming 

// Import useTheme() of mui to import them 
// const theme = useTheme()     //for using the mui theme in your file
// <Typography color={theme.palette.text.disabled}></Typography> // In this way give the color to the text or any component to their mui prop
// Your can directly use primray color for blue in color prop.

// Directly use Button and Typography of mui for button and text don't use custom button and dynamic heading component .


// 1. Completed the theming for the product section with working functionalites of all the buttons.
// 2. Customized the global styling of the button and chip component.
// 3. Setup the font theming for all the font sizes of project.
// 4. Started working on Drawer section.

// The chip component is styled such that it you just need to pass color and it will set text and background color automatically
// Color such as warning, success , error

// The button is also styled such that it automatiically changes padding for full width for same ui as figma.

//  const Black = '#000';
// const WhiteBg = '#FFC0CB';
// const DisabledGrey = '#828282';
// const DarkGreen = '#00B278';
// const LightGreen = '#E4F9F2';
// const DarkOrange = '#FB9D2C';
// const LightOrange = '#FFEDCB';
// const DividerGrey = '#DDDDDD';
// const DarkBlue = '#3571FF';
// const DarkRed = '#FF2023';
// const LightRed = '#FFE3E3';

// access all color in theme by  theme.theme.colors.Black 

// D:\immunioFE\immuno_fe\src\themes\theme\index.js  color file path
// D:\immunioFE\immuno_fe\src\themes\typography.js typography file path.