import React from "react";

export const ProductTrialIcon = () => {
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_785_2868"  maskUnits="userSpaceOnUse" x="3" y="0" width="18" height="5">
    <path d="M20.4025 4.41727V0.1875H3.60938V4.41727H20.4025Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_785_2868)">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.28952 1.17197C4.90814 1.17197 4.5975 1.48078 4.5975 1.86117V2.74327C4.5975 3.12328 4.90814 3.4328 5.28956 3.4328H18.7226C19.104 3.4328 19.4146 3.12328 19.4146 2.74327V1.86117C19.4146 1.48078 19.104 1.17197 18.7226 1.17197H5.28952ZM18.7226 4.41727H5.28956C4.36298 4.41727 3.60938 3.66642 3.60938 2.74327V1.86117C3.60938 0.938344 4.36298 0.1875 5.28956 0.1875H18.7226C19.6492 0.1875 20.4028 0.938344 20.4028 1.86117V2.74327C20.4028 3.66642 19.6492 4.41727 18.7226 4.41727Z" fill="white"/>
    </g>
    <path fillRule="evenodd" clipRule="evenodd" d="M5.29688 6.00113H18.7171V3.92578H5.29688V6.00113Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M5.78891 5.50894H18.221V4.41811H5.78891V5.50894ZM18.7151 6.49345H5.29484C5.02213 6.49345 4.80078 6.27291 4.80078 6.00117V3.92583C4.80078 3.65409 5.02213 3.43359 5.29484 3.43359H18.7151C18.9878 3.43359 19.2092 3.65409 19.2092 3.92583V6.00117C19.2092 6.27291 18.9878 6.49345 18.7151 6.49345Z" fill="white"/>
    <path opacity="0.15" fillRule="evenodd" clipRule="evenodd" d="M18.7238 0.679688H17.0769C17.7317 0.679688 18.2627 1.20872 18.2627 1.86112V2.74355C18.2627 3.39595 17.7317 3.92498 17.0769 3.92498H17.0703V6.00033H18.7172V3.92498H18.7238C19.3786 3.92498 19.9095 3.39595 19.9095 2.74355V1.86112C19.9095 1.20872 19.3786 0.679688 18.7238 0.679688Z" fill="#040605"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M5.60593 6.90656C5.24128 6.90656 4.94466 7.19092 4.94466 7.54114V21.4589C4.94466 21.8088 5.24133 22.0934 5.60593 22.0934H18.394C18.759 22.0934 19.0553 21.8088 19.0553 21.4589V7.54114C19.0553 7.19092 18.759 6.90656 18.394 6.90656H5.60593ZM18.394 23H5.60593C4.72046 23 4 22.3086 4 21.4588V7.54114C4 6.69139 4.72046 6 5.60593 6H18.394C19.2795 6 20 6.69139 20 7.54114V21.4589C20 22.3086 19.2795 23 18.394 23Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M11.9003 14.5C11.6275 14.5 11.4062 14.2795 11.4062 14.0078V11.0431C11.4062 10.7713 11.6275 10.5508 11.9003 10.5508C12.173 10.5508 12.3944 10.7713 12.3944 11.0431V14.0077C12.3944 14.2795 12.173 14.5 11.9003 14.5" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M13.3877 13.0197H10.412C10.1393 13.0197 9.91797 12.7992 9.91797 12.5274C9.91797 12.2557 10.1393 12.0352 10.412 12.0352H13.3877C13.6604 12.0352 13.8817 12.2557 13.8817 12.5274C13.8817 12.7992 13.6604 13.0197 13.3877 13.0197Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M15.1103 16.4025H8.61125C8.3382 16.4025 8.11719 16.182 8.11719 15.9102C8.11719 15.6385 8.3382 15.418 8.61125 15.418H15.1102C15.383 15.418 15.6043 15.6385 15.6043 15.9102C15.6043 16.182 15.383 16.4025 15.1102 16.4025" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M14.1496 18.09H9.57223C9.29914 18.09 9.07812 17.8694 9.07812 17.5978C9.07812 17.326 9.29914 17.1055 9.57219 17.1055H14.1496C14.4223 17.1055 14.6436 17.326 14.6436 17.5978C14.6436 17.8694 14.4223 18.09 14.1496 18.09Z" fill="white"/>
    </svg> 
    );
}
