import { Box, Button, Typography } from "@mui/material";
import PropTypes from "prop-types";
import CardChart from "../card-chart/CardChart";
import { DeleteIconCard } from "../../assets/common-svg/CommonSvg";
import { EditIconCard } from "../../assets/common-svg/CommonSvg";
import ChartDataJSON from "../custom-hook/chart-data/ChatDataJSON";
import "./CustomCard.css";
import { useSelector } from "react-redux";
import { getRandomColor } from "../../utils/utils";
import { getUserHasAccess } from "../../utils/authorities";

export const CustomCard = ({
  MainHeading,
  SubHeading,
  startIcon,
  ArrayLowGapBoolean,
  DetailsArray,
  NoWrap,
  handleEdit,
  handleDelete,
  chartData,
  hideEditDelete,
  editAuth,
  deleteAuth,
}) => {
  const menu = useSelector((state) => state.menu);
  const LoginData = useSelector((state) => state.loginData);
  const userAuthority = LoginData?.auth || [];
  const { drawerOpen } = menu;

  const series = chartData?.map((item) => item.subjectCount);
  const options = {
    labels: chartData?.map((item) => item.title),
    colors: chartData?.map(() => getRandomColor()),
  };
  const data = ChartDataJSON({ series, options, chartData });

  return (
    <Box
      component={"div"}
      className="flex items-center relative bg-[#252733] rounded-[10px] h-auto lg:h-[176px]"
    >
      <div className="w-full flex flex-col lg:flex-row lg:justify-between lg:gap-4 lg:p-2 md:pl-1 lg:pr-4 pb-[10px]">
        <div className="flex flex-col md:flex-row items-start py-10 gap-4 pl-4">
          <div className="flex items-center gap-4">
            {Object.keys(startIcon).length ? startIcon : null}
          </div>
          <div className="flex flex-col gap-1">
            <div className="flex flex-col justify-center items-start gap-1">
              <Typography
                className="text-lg 2xl:text-2xl"
                variant="h4"
                color="white"
              >
                {MainHeading}
              </Typography>
              <Typography
                className="text-sm 2xl:text-base"
                variant="body1"
                color="secondary"
              >
                {SubHeading}
              </Typography>
            </div>
            {!hideEditDelete ? <div className="flex flex-row gap-2 mt-2">
              {getUserHasAccess(userAuthority, editAuth?.module, editAuth?.auth) &&
                < Button
                  size="small"
                  className="bg-[#333B4A80] text-xs 2xl:text-base"
                  variant="contained"
                  startIcon={<EditIconCard />}
                  onClick={handleEdit}
                >
                  Edit
                </Button>}
              {getUserHasAccess(userAuthority, deleteAuth?.module, deleteAuth?.auth) &&
                <Button
                  size="small"
                  className="bg-[#333B4A80] text-xs 2xl:text-base"
                  variant="contained"
                  startIcon={<DeleteIconCard />}
                  onClick={handleDelete}
                >
                  Delete
                </Button>}
            </div> : ""}
          </div>
        </div>
        <div
          className={`flex  pl-2 items-center ${ArrayLowGapBoolean
            ? "gap-2 md:gap-7 xl:gap-16 2xl:gap-[3rem]"
            : "justify-between"
            } text-white`}
        >
          {DetailsArray.map((pair) => (
            <span
              className={`${NoWrap ? "whitespace-nowrap" : ""
                } flex items-center after:absolute after:-right-[4px] after:w-[1px] after:h-[30px] after:bg-white commonDivCard ${DetailsArray.length == 2 ? "pl-2" : "pl-0"
                } pr-2 2xl:!pr-10 justify-center relative`}
              key={pair.FieldName}
            >
              <div
                className={`flex flex-row items-center ${drawerOpen ? "gap-3 2xl:gap-6" : "gap-4 2xl:gap-12"
                  }`}
              >
                <div className="flex flex-col items-center gap-1 md:gap-2">
                  <Typography className="text-[10px] 1sm:text-[13px] sm:text-base lg:text-[18px] 2xl:text-[18px] font-bold break-words">
                    {pair.Value}
                  </Typography>
                  <Typography
                    color="secondary"
                    className="text-[12px] sm:text-[15px] 2xl:text-lg mb-2 lg:mb-0"
                  >
                    {pair.FieldName}
                  </Typography>
                </div>
              </div>
            </span>
          ))}
        </div>
        <div
          className={`flex customChartMain ${ArrayLowGapBoolean ? "gap-2 lg:gap-12" : "justify-between"
            } ${chartData?.length > 4 ? " chart-scrollbar" : ""}`}
        >
          {chartData?.length > 0 && !data?.seriesData?.includes(undefined) ? <CardChart data={data} /> : null}
        </div>
      </div>
    </Box>
  );
};
CustomCard.proptypes = {
  DetailsArray: PropTypes.array,
  NoWrap: PropTypes.bool,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
};
