import httpService from "./httpService";

const FormTemlateApi = {
    // create roles api
    deleteFormTemplate: (id) => httpService.delete(`deleteFormTemplate/${id}`),
    getFormTemplateDetails: (id) => httpService.get(`formTemplateDetails/${id}`),
    addFormTemplate: (data) => httpService.post('addFormTemplate', data),
    updateFormTemplate: (data, id) => httpService.post(`updateFormTemplate/${id}`, data),
    getTemplateType: () => httpService.get("templateTypeList"),
    getAllFormListing: (limit, page, filter) => httpService.get('formTemplateList', {
        params: {
            limit,
            page: page,
            searchData: filter?.searchData,
            status: filter?.status == "All" ? "" : filter?.status
        }
    }),


}

export default FormTemlateApi;