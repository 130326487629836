import React from "react";
import { Grid, Typography } from "@mui/material";
import CustomButton from "../../components/custom-button/CustomButton";
import { useNavigate } from "react-router";

export const AccessDenied = ({ removeBack = false }) => {
    const navigate = useNavigate()
    return (
        <div className="">
            <Grid container>
                <Grid
                    item
                    md={12}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Typography className="morningText" variant="h4">
                        Access Denied
                    </Typography>
                </Grid>
                <Grid
                    item
                    md={12}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div>Contact Your Administrator</div>
                </Grid>
                {!removeBack && (
                    <Grid
                        md={12}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <CustomButton
                            ButtonText={"Go Back"}
                            variant={"outlined"}
                            handleClick={() => {
                                navigate(-1)
                            }}
                        />
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

