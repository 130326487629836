import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Stack } from '@mui/material';

// project import
import DrawerHeaderStyled from './DrawerHeaderStyled';
import PrimaryLogo from '../../../../assets/main-logo/header-primary-logo.png'
import SecondaryLogo from '../../../../assets/main-logo/header-secondary-logo.png'
import { useSelector } from 'react-redux';
import dashboard from '../../../../menu-items/dashboard';

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open, handleDrawerToggle, isInvestigator, matchDownMD }) => {
  const theme = useTheme();
  const { activeSmallScreenMenu } = useSelector((state) => (state.menu));

  const handleCheckExistdrawer = () => {
    let childExist =null
    if(activeSmallScreenMenu){
      childExist=dashboard.children.find(op => op.id == activeSmallScreenMenu[0])
    }
    let check = false
    if (childExist?.subChildren) {
      check = true
    }
    return check
  }
  return (
    // only available in paid version
    <DrawerHeaderStyled className='divider-border' theme={theme} open={open} isInvestigator={isInvestigator}>
      <Stack direction="row" spacing={1} alignItems="center">
        {matchDownMD && (!handleCheckExistdrawer()
          ? <img
            src={PrimaryLogo}
            width="40px"
            onClick={() => !isInvestigator && handleDrawerToggle()}
            className={`${!isInvestigator ? 'cursor-pointer' : ""}`}
          />
          : <img src={SecondaryLogo} width={"210px"} />)
        }
        {!matchDownMD && (!open
          ? <img
            src={PrimaryLogo}
            width="40px"
            onClick={() => !isInvestigator && handleDrawerToggle()}
            className={`${!isInvestigator ? 'cursor-pointer' : ""}`}
          />
          : <img src={SecondaryLogo} width={"210px"} />)
        }
      </Stack>
    </DrawerHeaderStyled>
  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool
};

export default DrawerHeader;
