import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  templates: [],
};

// ==============================|| SLICE - MENU ||============================== //

const templateTypes = createSlice({
  name: "form-builder",
  initialState,
  reducers: {
    setAllTemplatesType(state, action) {
      state.templates = action.payload ?? state.templates;
    },
  },
});

export default templateTypes.reducer;

export const { setAllTemplatesType } = templateTypes.actions;
