// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    title: '',
    type: '',
    description: '',
    formData: [],
};

// ==============================|| SLICE - MENU ||============================== //

const formTemplete = createSlice({
    name: 'form-templete',
    initialState,
    reducers: {
        setTemplateValues(state, action) {
            state.title = action.payload.title ?? state.title;
            state.type = action.payload.type ?? state.type;
            state.description = action.payload.description ?? state.description;
        },
        setFormData(state, action) {
            state.formData = [...action.payload.formData];
        },
        clearTemplateValues(state) {
            state.title = '';
            state.type = '';
            state.description = '';
            state.formData = [];
        },
    }
});

export default formTemplete.reducer;

export const { setTemplateValues, setFormData, clearTemplateValues } = formTemplete.actions;
