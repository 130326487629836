import React from "react";

export const AttachmentIcon = ({ width, height }) => {
  return (
    <svg
      width={width ? width : "38"}
      height={height ? height : "38"}
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5" clipPath="url(#clip0_1507_7483)">
        <path
          d="M26.7578 16.586L18.97 26.2031C17.4123 28.1268 14.5645 28.4261 12.6408 26.8684C10.7173 25.3107 10.4179 22.4629 11.9757 20.5392L20.1175 10.485C21.1086 9.26104 22.8492 9.07806 24.0731 10.0692C25.297 11.0603 25.48 12.8009 24.4889 14.0249L16.347 24.0792C15.9578 24.5599 15.2454 24.6347 14.7648 24.2455C14.2841 23.8562 14.2092 23.1439 14.5985 22.6632L21.3243 14.3575L20.0129 13.2955L13.287 21.6012C12.296 22.8252 12.4789 24.5658 13.7028 25.5569C14.9268 26.5481 16.6674 26.3651 17.6585 25.1412L25.8003 15.0869C27.3579 13.1635 27.0588 10.3155 25.1351 8.75775C23.2115 7.20002 20.3635 7.49954 18.806 9.42297L10.6642 19.4772C8.50499 22.1437 8.91247 26.0206 11.5789 28.1798C14.2453 30.339 18.1222 29.9316 20.2814 27.2651L28.0692 17.648L26.7578 16.586Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1507_7483">
          <rect
            width="27"
            height="27"
            fill="white"
            transform="translate(17.0171 0.0126953) rotate(39)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
