

import React from "react";

export const HospitalIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="1">
                <path d="M23.1429 22.2857H20.5714V5.14286C20.5714 4.91553 20.4811 4.69751 20.3204 4.53677C20.1596 4.37602 19.9416 4.28571 19.7143 4.28571H17.1429V0.857143C17.1429 0.629814 17.0526 0.411797 16.8918 0.251051C16.7311 0.0903058 16.513 0 16.2857 0H7.71429C7.48696 0 7.26894 0.0903058 7.10819 0.251051C6.94745 0.411797 6.85714 0.629814 6.85714 0.857143V4.28571H4.28571C4.05839 4.28571 3.84037 4.37602 3.67962 4.53677C3.51888 4.69751 3.42857 4.91553 3.42857 5.14286V22.2857H0.857143C0.629814 22.2857 0.411797 22.376 0.251051 22.5368C0.0903058 22.6975 0 22.9155 0 23.1429C0 23.3702 0.0903058 23.5882 0.251051 23.7489C0.411797 23.9097 0.629814 24 0.857143 24H23.1429C23.3702 24 23.5882 23.9097 23.7489 23.7489C23.9097 23.5882 24 23.3702 24 23.1429C24 22.9155 23.9097 22.6975 23.7489 22.5368C23.5882 22.376 23.3702 22.2857 23.1429 22.2857ZM8.57143 1.71429H15.4286V8.57143H8.57143V1.71429ZM9.42857 22.2857V13.7143H11.1429V22.2857H9.42857ZM12.8571 22.2857V13.7143H14.5714V22.2857H12.8571ZM16.2857 22.2857V12.8571C16.2857 12.6298 16.1954 12.4118 16.0347 12.2511C15.8739 12.0903 15.6559 12 15.4286 12H8.57143C8.3441 12 8.12608 12.0903 7.96534 12.2511C7.80459 12.4118 7.71429 12.6298 7.71429 12.8571V22.2857H5.14286V6H6.85714V9.42857C6.85714 9.6559 6.94745 9.87392 7.10819 10.0347C7.26894 10.1954 7.48696 10.2857 7.71429 10.2857H16.2857C16.513 10.2857 16.7311 10.1954 16.8918 10.0347C17.0526 9.87392 17.1429 9.6559 17.1429 9.42857V6H18.8571V22.2857H16.2857Z" fill="white" />
                <path d="M10.279 6.0067H11.1362V6.86384C11.1362 7.09117 11.2265 7.30918 11.3872 7.46993C11.548 7.63068 11.766 7.72098 11.9933 7.72098C12.2206 7.72098 12.4386 7.63068 12.5994 7.46993C12.7601 7.30918 12.8504 7.09117 12.8504 6.86384V6.0067H13.7076C13.9349 6.0067 14.1529 5.91639 14.3137 5.75564C14.4744 5.5949 14.5647 5.37688 14.5647 5.14955C14.5647 4.92222 14.4744 4.70421 14.3137 4.54346C14.1529 4.38272 13.9349 4.29241 13.7076 4.29241H12.8504V3.43527C12.8504 3.20794 12.7601 2.98992 12.5994 2.82918C12.4386 2.66843 12.2206 2.57812 11.9933 2.57812C11.766 2.57812 11.548 2.66843 11.3872 2.82918C11.2265 2.98992 11.1362 3.20794 11.1362 3.43527V4.29241H10.279C10.0517 4.29241 9.83367 4.38272 9.67293 4.54346C9.51218 4.70421 9.42188 4.92222 9.42188 5.14955C9.42188 5.37688 9.51218 5.5949 9.67293 5.75564C9.83367 5.91639 10.0517 6.0067 10.279 6.0067Z" fill="white" />
            </g>
        </svg>

    );
};

