import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery } from '@mui/material';

// project import
import DrawerHeader from './DrawerHeader';
import DrawerContent from './DrawerContent';
import MiniDrawerStyled from './MiniDrawerStyled';
import { drawerWidth, miniDrawerWidth } from '../../../../src/config';
import { useSelector } from 'react-redux';
import dashboard from '../../../menu-items/dashboard';

// ==============================|| MAIN LAYOUT - DRAWER ||============================== //

const MainNewDrawer = ({ open, handleDrawerToggle, window }) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));
  const role_id = useSelector((state) => (state.loginData.role_id));
  const { activeSmallScreenMenu } = useSelector((state) => (state.menu));

  const [isInvestigator,] = useState(role_id === 2);

  // responsive drawer container
  const container = window !== undefined ? () => window().document.body : undefined;


  const handleCheckExistdrawer = () => {
    let childExist = null
    if (activeSmallScreenMenu) {
      childExist = dashboard.children.find(op => op.id == activeSmallScreenMenu[0])
    }
    let check = false
    if (childExist?.subChildren) {
      check = true
    }
    return check
  }



  // header content
  const drawerContent = useMemo(() => <DrawerContent />, []);
  const drawerHeader = useMemo(() => <DrawerHeader open={open} handleDrawerToggle={handleDrawerToggle} isInvestigator={isInvestigator} matchDownMD={matchDownMD} />, [open]);
  return (
    <Box component="nav" sx={{ flexShrink: { md: 0 }, zIndex: 1300 }} aria-label="mailbox folders">
      {!matchDownMD ? (
        <MiniDrawerStyled variant="permanent" open={open}   >
          {drawerHeader}
          {drawerContent}
        </MiniDrawerStyled>
      ) : (
        <Drawer
          container={container}
          variant="temporary"
          open={open}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', lg: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: isInvestigator ? miniDrawerWidth : handleCheckExistdrawer() ? drawerWidth : miniDrawerWidth,
              borderRight: `1px solid ${theme.palette.divider}`,
              backgroundImage: 'none',
              backgroundColor: theme.palette.primary.main,
              boxShadow: 'inherit'
            }
          }}
        >
          {open && drawerHeader}
          {open && drawerContent}
        </Drawer>
      )}
    </Box>
  );
};

MainNewDrawer.propTypes = {
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  window: PropTypes.object
};

export default MainNewDrawer;
