// ==============================|| OVERRIDES - LINK ||============================== //

export default function Link(theme) {
  return {
    MuiLink: {
      defaultProps: {
        underline: 'hover'
      },
      styleOverrides: {
        root: {
          '&.MuiLink-underlineNone': {
            color:theme.palette.common.white,
          }
        },
      }

    }
  };
}
