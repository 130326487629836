
import httpService from "./httpService";

const AuditHistoryApi = {
    getAuditHistoryListing: (limit, page, search,startDate,endDate) => httpService.get('getAuditHistory', {
        params: {
            limit,
            page: page,
            searchData: search,
            start_date: startDate,
            end_date: endDate
        }
    }),
}

export default AuditHistoryApi;