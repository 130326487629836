// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
  openItem: ['dashboard'],
  defaultId: 'dashboard',
  openComponent: 'buttons',
  drawerOpen: true,
  componentDrawerOpen: true,
  activeSubMenu: '',
  activeSmallScreenMenu: ['dashboard']
};

// ==============================|| SLICE - MENU ||============================== //

const menu = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    activeItem(state, action) {
      state.openItem = action.payload.openItem;
    },

    activeComponent(state, action) {
      state.openComponent = action.payload.openComponent;
    },

    openDrawer(state, action) {
      state.drawerOpen = action.payload.drawerOpen;
    },

    openComponentDrawer(state, action) {
      state.componentDrawerOpen = action.payload.componentDrawerOpen;
    },

    activateSubMenu(state, action) {
      state.activeSubMenu = action.payload.subMenu;
    },
    setActiveSmallScreenMenu(state, action) {
      state.activeSmallScreenMenu = action.payload.activeSmallScreenMenu;
    }
  }
});

export default menu.reducer;

export const { activeItem, activeComponent, openDrawer, openComponentDrawer, activateSubMenu, setActiveSmallScreenMenu } = menu.actions;
