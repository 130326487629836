import React from 'react';
import { ReactFormBuilder, Registry } from 'react-form-builder2';
import "./form-style.css";
const CustomDatePicker = React.forwardRef((props, ref) => {
  const { name, defaultValue, disabled } = props;
  return <input ref={ref} type="date" data-type='helo' name={name} defaultValue={defaultValue} disabled={disabled} />;
});

// const CustomMultiLineInput = React.forwardRef((props, ref) => {
//   const { name, defaultValue, disabled } = props; 
//   return <textarea ref={ref} type="text"  name={name} defaultValue={defaultValue} rows={'3'}  className='w-full form-control h-48 p-2 text-sm leading-relaxed border border-gray-300 resize-none whitespace-pre-wrap'   disabled={disabled} />
// });
Registry.register('CustomDatePicker', CustomDatePicker);
// Registry.register('TextArea', CustomMultiLineInput);
const items = [
  {
    "key": "Header",
    "name": "Header Text",
    "icon": "fas fa-heading",
    "static": true,
    "content": "Placeholder text..."
  },
  {
    "key": "Paragraph",
    "name": "Paragraph",
    "static": true,
    "icon": "fas fa-paragraph",
    "content": "Placeholder text..."
  },
  {
    "key": "LineBreak",
    "name": "Line Break",
    "static": true,
    "icon": "fas fa-arrows-alt-h"
  },
  {
    "key": "Dropdown",
    "canHaveAnswer": true,
    "name": "Dropdown",
    "icon": "far fa-caret-square-down",
    "label": "Placeholder label",
    "field_name": "dropdown_",
    "options": []
  },
  {
    "key": "Checkboxes",
    "canHaveAnswer": true,
    "name": "Checkboxes",
    "icon": "far fa-check-square",
    "label": "Placeholder label",
    "field_name": "checkboxes_",
    "options": []
  },
  {
    "key": "RadioButtons",
    "canHaveAnswer": true,
    "name": "Multiple Choice",
    "icon": "far fa-dot-circle",
    "label": "Placeholder label",
    "field_name": "radiobuttons_",
    "options": []
  },
  {
    "key": "TextInput",
    "canHaveAnswer": true,
    "name": "Text Input",
    "label": "Placeholder label",
    "icon": "fas fa-font",
    "field_name": "text_input_"
  },
  {
    "key": "EmailInput",
    "canHaveAnswer": true,
    "name": "Email",
    "label": "E-Mail",
    "icon": "fas fa-envelope",
    "field_name": "email_input_"
  },
  {
    "key": "NumberInput",
    "canHaveAnswer": true,
    "name": "Number Input",
    "label": "Placeholder label",
    "icon": "fas fa-plus",
    "field_name": "number_input_"
  },
  {
    "key": "PhoneNumber",
    "canHaveAnswer": true,
    "name": "Phone Number",
    "label": "Phone Number",
    "icon": "fas fa-phone",
    "field_name": "phone_input_"
  },
  {
    "key": "TextArea",
    // "element": 'CustomElement',
    // "type": 'custom',
    "canHaveAnswer": true,
    // "component": CustomMultiLineInput,
    "name": "Multi-line Input",
    "label": "Placeholder label",
    "icon": "fas fa-text-height",
    "field_name": "text_area_"
  },
  {
    "key": "TwoColumnRow",
    "canHaveAnswer": false,
    "name": "Two Column Row",
    "label": "",
    "icon": "fas fa-columns",
    "field_name": "two_col_row_"
  },
  {
    "key": "ThreeColumnRow",
    "canHaveAnswer": false,
    "name": "Three Columns Row",
    "label": "",
    "icon": "fas fa-columns",
    "field_name": "three_col_row_"
  },
  {
    "key": "FourColumnRow",
    "element": "MultiColumnRow",
    "canHaveAnswer": false,
    "name": "Four Columns Row",
    "label": "",
    "icon": "fas fa-columns",
    "field_name": "four_col_row_",
    "col_count": 4,
    "class_name": "col-md-3"
  },
  // {
  //   "key": "DatePicker",
  //   "canDefaultToday": true,
  //   "canReadOnly": true,
  //   "dateFormat": "dd/MM/yyyy",
  //   "timeFormat": "hh:mm aa",
  //   "showTimeSelect": false,
  //   "showTimeSelectOnly": false,
  //   "showTimeInput": false,
  //   "name": "Date",
  //   "type": "date",
  //   "icon": "far fa-calendar-alt",
  //   "label": "Placeholder label",
  //   "field_name": "date_picker_"
  // },
  {
    "key": 'CustomDatePicker',
    "element": 'CustomElement',
    "component": CustomDatePicker,
    "type": 'custom',
    "forwardRef": true,
    "canReadOnly": true,
    "dateFormat": "dd/MM/yyyy",
    "timeFormat": "hh:mm aa",
    "showTimeSelect": false,
    "showTimeSelectOnly": false,
    "showTimeInput": false,
    "name": "Date",
    "icon": "far fa-calendar-alt",
    "label": "Placeholder label",
    "field_name": "date_picker_"
  },


]



const FromBuilder = ({ formData, handleChangeEvent }) => {
  return (
    <div className="asasasasasas121212">
      <ReactFormBuilder
        data={formData}
        toolbarItems={items}
        onPost={handleChangeEvent}
      />
    </div>
  );
}
export default FromBuilder
