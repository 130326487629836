import httpService from "./httpService";
const ProductApi = {
  getProducts: (data) =>
    httpService.get(
      `productList`,{
        params:data
      }
    ),
  getProductDetails: (id) => httpService.get(`productDetails/${id}`),
  editProduct: (data, id) =>
    httpService.post(`updateProduct/${id}`, data),
  addProduct: (data) => httpService.post(`addProduct`, data),
  deleteProduct: (id) => httpService.delete(`deleteProduct/${id}`),
  subadminDropDown: (role_id, investigator_id,phase_id) => httpService.post(`subadminDropDown/${role_id}`, {
    "ids": [],
    investigator_id,
    phase_id
  }),
  updatePiEditor: (data) => httpService.post(`reassignEditorAndPIForSubject`, {
   ...data
  }),
};

export default ProductApi;