import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Slide from "@mui/material/Slide";
import PropTypes from "prop-types";
import { CloseIcon, DocumentIcon } from "../../assets/common-svg/CommonSvg";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FormHelperText } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: 300, sm: 400, md: 500, lg: 400 },
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "10px",
    py: "15px",
    maxHeight: {
        xs: "95vh",
        sm: "95vh",
        md: "95vh",
        lg: "90vh",
        xl: "95vh", // Fixed height for xl and above
    },
};
const SelectionSchema = Yup.object().shape({
    form: Yup.object().required('You must select a Form'),
});

export default function SelectFormModel({
    open,
    setOpen,
    handleValue,
    data,
    isFormLoading
}) {

    const handleClose = () => {
        setOpen(false);
    }

    const handleDivClick = (setFieldValue, div) => {
        setFieldValue('form', div);
    }
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Slide direction='left' in={open} mountOnEnter unmountOnExit>
                    <div className='relative h-full w-full'>
                            <Box sx={style} className="relative" >
                                <div className='pb-[10px] border-b border-[#DDDDDD]'>
                                    <Typography id="transition-modal-title" variant="h6" textAlign={'center'} component="h6">
                                        Select Form
                                    </Typography>
                                </div>
                                <div onClick={handleClose} className=" absolute right-0 1sm:right-[-30px] top-[-30px] cursor-pointer">
                                    <Tooltip title="close"><div><CloseIcon /></div></Tooltip>
                                </div>
                                <Formik
                                    initialValues={{ form: '' }}
                                    validationSchema={SelectionSchema}
                                    onSubmit={(values) => {
                                        handleValue(values)
                                        // Handle form submission
                                    }}
                                >
                                    {({ errors, touched, setFieldValue, handleSubmit, values }) => (
                                        <Form className="text-center">
                                            <div className="div-selection p-2 overflow-y-auto max-h-60 text-center">
                                                {data?.map((form) => (
                                                    <div
                                                        key={form?.id}
                                                        onClick={() => handleDivClick(setFieldValue, form)}
                                                        className={`cursor-pointer p-3 flex flex-col gap-2 mb-2 bg-[#f0f1f5] ${form.id == values.form.id ? " border !border-sky-500" : "border border-white"}  rounded-lg`
                                                        }>
                                                        <div className='flex flex-row text-left items-center  gap-3'>
                                                            <div>
                                                             <DocumentIcon />
                                                            </div>
                                                            <Typography variant="boaty1" className="align-self-center">{form?.title}</Typography>
                                                        </div>
                                                    </div>
                                                ))}
                                                {errors.form && touched.form && (
                                                    <FormHelperText
                                                        error
                                                        id={`standard-weight-helper-text-1`}
                                                    >
                                                        {errors.form}
                                                    </FormHelperText>
                                                )}
                                            </div>
                                            <div className="pb-2 pl-2 pr-2">
                                                <LoadingButton
                                                    className="mt-3"
                                                    variant="contained"
                                                    fullWidth
                                                    type="submit"
                                                    size="large"
                                                    onSubmit={handleSubmit}
                                                    disabled={isFormLoading}
                                                    loading={isFormLoading}
                                                >
                                                    <span>{"Submit"}</span>
                                                </LoadingButton>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </Box>
                        </div>
                    </Slide>

                </Fade>
            </Modal>
        </div>
    );
}

SelectFormModel.propTypes = {
    children: PropTypes.node,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
};