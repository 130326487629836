import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';

export const CustomIconButton = ({ children, bgColor, onClickFuntion, HoverSameBg, ButtonBoderRadius,ButtonHeight,ButtonWidth }) => {
    return <IconButton size='small'  onClick={onClickFuntion} sx={{width:ButtonWidth,height:ButtonHeight,backgroundColor: bgColor, borderRadius: ButtonBoderRadius ?? "10px", "&:hover": { backgroundColor: HoverSameBg ? bgColor : '#2F5597' } }}>
        {children}
    </IconButton>
}

CustomIconButton.proptypes = {
    children: PropTypes.node,
    bgColor: PropTypes.string,
    onClickFuntion: PropTypes.func,
    HoverSameBg: PropTypes.string,
    ButtonBoderRadius: PropTypes.string
}