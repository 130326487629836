import CustomInputField from '../../components/custom-input/CustomInput';
import CustomModal from '../custom-modal/CustomModal';
import PropTypes from 'prop-types';
import CustomDropdown from '../custom-dropdown/CustomDropdown';
import { AddImmunoActQAText, EditImmunoActQAText } from '../../utilites/Common';
import { Formik } from 'formik';
import { addUserValues } from "../../utils/formik/initialValues";
import { addUserSchema } from "../../utils/formik/validationSchema";
import { Button } from "@mui/material";

const AddImmunoActQAModalContent = ({
  open,
  setOpen,
  handleInput,
  editSubAdminBoolean,
}) => {
  const handleAddSubAdmin = () => {
    // api.addHospitalData(values)
    //   .then((res) => {
    //     setSuccessMessage(res.data.message);
    //     handleSetOpenHospitalSnackbarTrue();
    //     setTimeout(() => {
    //         setOpen(!open);
    //     }, 1500);
    //     // @info : uncomment onece basic functionality completed
    //   }
    //   )
    //   .catch((err) => {
    //     setErrorMessage(err);
    //     handleHospitalErrorSnackbar();
    //     setOpen(true);
    //   })
  };
  return (
    <CustomModal
      ModalTitle={
        editSubAdminBoolean ? EditImmunoActQAText : AddImmunoActQAText
      }
      open={open}
      setOpen={setOpen}
    >
      <div className="px-[20px] pt-[15px] flex flex-col gap-[15px] h-[532px]">
        <Formik
          initialValues={addUserValues}
          validationSchema={addUserSchema}
          onSubmit={(values) => {
            handleAddSubAdmin(values);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit} className="flex flex-col gap-7">
              <CustomInputField
                htmlFor="add-immuno-act-qa"
                label="Onboard Users Type"
                fullWidthBoolean={true}
                InputId="add-immuno-act-qa-name"
                InputType="text"
                spacing={1}
                onBlur={handleBlur}
                onChange={handleChange}
                InputName="add-immuno-act-qa-name"
                CustomClassName="bg-[#EDEDED] text-[black] font-normal text-[18px]"
                placeholder="ImmunoACT QA"
                LabelClasses={"text-[16px] text-[#000000]"}
                error={Boolean(touched.subAdminType && errors.subAdminType)}
                touched={touched.subAdminType}
                helperText={errors.subAdminType}
                value={values?.subAdminType}
              />
              <CustomInputField
                htmlFor="add-immuno-act-qa"
                label="Name"
                fullWidthBoolean={true}
                InputId="add-immuno-act-qa-name"
                InputType="text"
                spacing={1}
                onChange={handleInput ?? null}
                InputName="add-immuno-act-qa-name"
                CustomClassName="bg-[#EDEDED] text-[black] font-normal text-[18px]"
                placeholder="Enter Name"
                LabelClasses={"text-[16px] text-[#000000]"}
                error={Boolean(touched.name && errors.name)}
                touched={touched.name}
                helperText={errors.name}
                value={values?.name}
              />

              <CustomInputField
                htmlFor="add-immuno-act-qa"
                label="Email"
                fullWidthBoolean={true}
                InputId="add-immuno-act-qa-name"
                InputType="text"
                spacing={1}
                onChange={handleInput ?? null}
                InputName="add-immuno-act-qa-name"
                CustomClassName="bg-[#EDEDED] text-[black] font-normal text-[18px]"
                placeholder="Enter Email"
                LabelClasses={"text-[16px] text-[#000000]"}
                error={Boolean(touched.email && errors.email)}
                touched={touched.email}
                helperText={errors.email}
                value={values?.email}
              />
              <CustomDropdown
                LabelFontSize="15px"
                className={"flex-grow text-[#000000] "}
                MinWidth={270}
                DropDownlabel={"Select Phase"}
                fullWidthBoolean={true}
                ShowLargeDropdown={true}
                Height={"44px"}
                Width={"100%"}
                BgColor={"#EDEDED"}
                PlaceholderText="Select Phase"
                error={Boolean(touched.selectTrial && errors.selectTrial)}
                touched={touched.selectTrial}
                helperText={errors.selectTrial}
                value={values?.selectTrial}
              />

              <Button
                fullWidth
                type="submit"
                size="medium"
                onSubmit={handleSubmit}
              >
                Submit
              </Button>
            </form>
          )}
        </Formik>
      </div>
    </CustomModal>
  );
};

AddImmunoActQAModalContent.proptypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    handleInput: PropTypes.func
}

export default AddImmunoActQAModalContent;