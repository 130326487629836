import { baseURL } from "../utils/utils";
import httpService from "./httpService";
const apiURL = baseURL + "/api/";

const api = {
    // user auth
    loginUser: (data) => httpService.post(`login`, {
        user: data.email,
        password: data.password
    }),
    forgotPassword: (data) => httpService.post('forgot-password', { email: data.email }),
    changePassword: (data, token) => httpService.post('reset-password', { newPassword: data.password, confirmNewPassword: data.confirmPassword, token: token }),

    // form template
    getTemplateTypeListState: (searchData = "", limit, page) => httpService.get(`${apiURL}templateTypeList?searchData=${searchData}&limit=${limit}&page=${page}`),
    formTemplateDropDown: (ids = [], searchData = "") => httpService.post(`${apiURL}formTemplateDropDown?searchData=${searchData}`, ids),


}

export default api;
