import httpService from "./httpService";

const PIEditorApi = {
    getPIEditorData: (limit, page, search, phase_id, investigator_id) => httpService.get(`editorListForInvestigator`, {
        params: {
            limit,
            page: page,
            searchData: search,
            phase_id,
            investigator_id
        }
    }),
    updatePhase: (editor_id, phase_ids) => httpService.post(`assignEditorToPhase`, {
        editor_id,
        phase_ids
    }),
}

export default PIEditorApi;