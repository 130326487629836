import PropTypes from "prop-types";
import { MessageHeader } from "../../pages/message/MessageHeader";
import { MessageArea } from "../../pages/message/MessageArea";
import { ChatBox } from "../../pages/message/ChatBox";
import { MessageInput } from "../../pages/message/MessageInput";
import { useSocketInstance } from "../../context/SocketContext";
import { useEffect, useRef, useState } from "react";
import { FormQuery } from "../../pages/message/FormQuery";
import { WechatOutlined } from "@ant-design/icons";
import CircularProgress from "@mui/material/CircularProgress";
import ChatApi from "../../services/chatService";
import { setSnackbar } from "../../store/reducers/snackBar";
import { useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import NoDataCard from "../no-data-card";
import { Box } from "@mui/material";

const ChatDetail = ({
  showMessageBox,
  handleShowMessageBox,
  drawerOpen,
  handleShowQuerModal,
  headerData,
  messageData,
  senderId,
  receiverId,
  setSocketData,
  isQueryRaised,
  handleResolveQuery,
  setActivePage,
  totalCountMessage,
  setMessageData,
  readMessage,
  detailLoading
}) => {
  const { socketInstance } = useSocketInstance();
  const containerRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadLoading, setUploadLoading] = useState(false);
  
  const dispatch = useDispatch();

  const renderMessages = (messagesData) => {
    let newDate = "";
    let lastQueryId = "";
    return messagesData?.slice()?.reverse()?.map((item, index) => {
      let date = moment(item?.created_at).format("DD/MM/YYYY")
      let displayDate = null;
    

      // Check if the current message has a new queryId
      const isNewQuery = item.chat_query_id !== lastQueryId;

      // Update lastQueryId to the current message's queryId
      if (isNewQuery) {
        lastQueryId = item.chat_query_id;
      }

      if (date !== newDate) {
        newDate = date;
        displayDate = <MessageArea date={newDate} />;
      }
     
    
      return (
        <div key={item?.id}>
          {displayDate}
          {isNewQuery && (
            <div className="w-full">
              <FormQuery
                show={true}
                queryHeading="Form Related Query"
                stepTitles={item?.conversation_type.split(", ")}
                convoHeading={item?.conversation_type}
                msgText=""
                queryTime={new Date(item?.created_at).toLocaleTimeString()}
                index={index}
              />
            </div>
          )}
          <ChatBox
            senderMsg={item?.sender_id === senderId ? item?.message : null}
            senderTime={
              item?.sender_id === senderId
                ? moment(item?.created_at).format("hh:mm A")
                : null
            }
            initialsChat={
              item?.sender_id !== senderId
                ? item?.sender?.name
                : item?.receiver?.name
            }
            receiverMsg={item?.sender_id !== senderId ? item?.message : null}
            messageType={item?.message_type}
            receiveTime={
              item?.sender_id !== senderId
                ? moment(item?.created_at).format("hh:mm A")
                : null
            }
          />
        </div>
      );
    });
  };

  const emitMessage = async (values, resetForm) => {
    try {
      let fileUrl = "";
      let fileType = "";

      if (selectedFile) {
        setUploadLoading(true);
        const formData = new FormData();
        formData.append("files", selectedFile);

        const response = await ChatApi.fileUploadChat(formData);
        if (response && response.data && response.data.url) {
          setUploadLoading(false);
          fileUrl = response.data.url;
          fileType = response.data.type;
        } else {
          setUploadLoading(false);
        }
      }
      resetForm();
      setSelectedFile(null);
      socketInstance.emit("send_message", {
        sender_id: senderId,
        receiver_id: receiverId,
        message: fileUrl || values.message,
        message_type: fileType ? fileType : "text",
      });
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error?.response?.data?.message || error?.message || "", color: "error" }));
      setUploadLoading(false);
    }
  }; 
  useEffect(() => {
    if (!socketInstance) return;

    const handleReceiveMessage = (data) => {
      // Extract the date key from the socket data

      
      const dateKey = Object.keys(data)[0];
      // Extract the message object from the socket data
      const message = data[dateKey][0];
      if (receiverId === message?.sender_id) {
        readMessage(senderId, receiverId)
      }

      if (
        (senderId === message?.sender_id ||
          senderId === message?.receiver_id) &&
        (receiverId === message?.receiver_id ||
          receiverId === message?.sender_id)
      ) {
        setMessageData(prev =>  {
          const newState = [message, ...prev]
          return newState;
        })
      }

      setSocketData(data);
    };

    socketInstance.on("receive_message", handleReceiveMessage);
 
    return () => {
      socketInstance.off("receive_message", handleReceiveMessage);
    };
  }, [socketInstance, receiverId, senderId]);

  if (!receiverId) {
    return (
      <div
        className={`right-area ${!showMessageBox ? "hidden lg:flex" : "flex"} ${
          drawerOpen ? "col-span-8" : "col-span-12 lg:col-span-9"
        } relative bg-transparent  !pb-0 lg:flex flex-col justify-center items-center shadow-md h-[calc(100vh-108px)]`}
      >
        <div>
          <WechatOutlined className="text-[100px] text-[#ccc]" />
        </div>
        <div className="flex flex-col items-center justify-center gap-3">
          <h4 className="text-[30px] font-medium">ImmunoACT</h4>
          <span className="text-base font-normal text-[#667781]">
            Chat is not started yet. Click on the user to start chat.
          </span>
        </div>
      </div>
    );
  }
  
  return (
    <div
      className={`right-area ${!showMessageBox ? "hidden lg:flex" : "flex"} ${
        drawerOpen ? "col-span-8" : "col-span-12 lg:col-span-9"
      } relative bg-white  !pb-0 lg:flex flex-col  items-start  shadow-md overflow-y-auto h-[calc(100vh-108px)]`}
    >
      <div className="w-full">
        <div className="topHeader sticky top-0 left-0 border-b  border-[#DDDDDD] z-[100] bg-white px-2 md:!px-5 !pt-5 !pb-[15px] w-full">
          <MessageHeader
            handleShowMessageBox={handleShowMessageBox}
            handleShowQuerModal={handleShowQuerModal}
            headingName={headerData?.name}
            subHeading={headerData?.role_details?.title}
            rightHeading="Form Related Query"
            color="success"
          />
        </div>
        <div
          className="overflow-auto scroll-smooth "
          id="messageBody"
          ref={containerRef}
          style={{
            height: `calc(100vh - ${selectedFile ? "430px" : "300px"})`,
            display: "flex",
            flexDirection: "column-reverse", 
            paddingTop: "40px"
          }}
        >
        {detailLoading && messageData.length < 1 ? <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <CircularProgress />
            </Box> : messageData && messageData?.length > 0 ? (
              <InfiniteScroll
                dataLength={messageData?.length}
                next={() => setActivePage(prev => prev + 1)}
                hasMore={messageData?.length === totalCountMessage ? false : true}
                // hasMore={true}
                loader={
                  <div className="scroll-loader" style={{ 
                    textAlign: "center",     
                    position: "absolute",
                    top: "99px",
                    left: 0,
                    width: "100%" 
                  }}
                  >
                    <CircularProgress   sx={{
                        height: "10px",
                        width: "10px",
                      }}/>
                  </div>
                }
                scrollableTarget="messageBody"
                inverse={true}
              >
                {renderMessages(messageData)}
              </InfiniteScroll>
              ) :  <div className="flex justify-center h-[100%] w-[100%]">
                <NoDataCard heading={"No Chat Found"} />
              </div>}
        <div>   
        </div>
        </div>
      </div>
      <div className="messageInput-div !p-5 w-full">
        <MessageInput
          emitMessage={emitMessage}
          selectedFile={selectedFile}
          uploadLoading={uploadLoading}
          setSelectedFile={setSelectedFile}
          isQueryRaised={isQueryRaised}
          handleResolveQuery={handleResolveQuery}
          receiverId={receiverId}
        />
      </div>
    </div>
  );
};

export default ChatDetail;

ChatDetail.propTypes = {
  showMessageBox: PropTypes.bool,
  handleShowMessageBox: PropTypes.func.isRequired,
  drawerOpen: PropTypes.bool.isRequired,
  handleShowQuerModal: PropTypes.func.isRequired,
  messageData: PropTypes.array.isRequired,
};
