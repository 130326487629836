// PrivateRoute.jsx

import React from 'react';
import { Navigate } from 'react-router-dom';
import { hasAuthority } from '../utils/authorities';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ element, authorities = [] }) => {
    // Example authentication logic
    const LoginData = useSelector((state) => state.loginData);
    const userAuthority = LoginData?.auth || [];
    const user = localStorage.getItem("user"); // useSelector((state) => state.loginData);
    return (
        user ? (
            hasAuthority(authorities,userAuthority) ? (
                <>
                    {/* You can add any layout components or wrappers here */}
                    {element}
                </>
            ) : (
                <Navigate to="/access_denied" replace />
            )
        ) : (
            <Navigate to="/login" replace />
        )
    );
};

export default PrivateRoute;
