import { useEffect, useState } from "react";
import {
  DocotorIcon,
  DocotorPairIcon,
} from "../../assets/common-svg/CommonSvg";
import BreadCrumb from "../breadcrumb/BreadCrumb";
import SubAdminApi from "../../services/subAdminService";
import { CustomCard } from "../../components/custom-card/CustomCard";
import { useLocation, useNavigate, useParams } from "react-router";
import SkeletonLoader from "../../utilites/SkeltonLoader";
import PhaseListDetail from "../../components/phase-list-detail/PhaseListDetail";
import PrincipleInvestigatorEdit from "../principle-investigator-edit/PrincipleInvestigatorEdit";
import HospitalApi from "../../services/hospitalService";
import DeleteConfirmContent from "../../components/modal-contents/DeleteConfirmModalContent";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../store/reducers/snackBar";
import PrincipleApi from "../../services/principleInvestigatorService";
import { UrlToRouteArrayConverter } from "../../utilites/Common";

export const PrincipleInvestigatorDetails = () => {
  const { id } = useParams();
  const location = useLocation()
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [detailedData, setDetailedData] = useState([]);
  const [breadCrumbArray,] = useState(UrlToRouteArrayConverter({},location.pathname));
  const [openEditModal, setOpenEditModal] = useState(false);
  const [hospitals, setHospitals] = useState([]);
  const [openDeleteConfirmModal, setOpenDeleteConfirmModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getSubAdminDetails = async () => {
    setLoading(true);
    try {
      const res = await SubAdminApi.getSubAdminDetails(id);
      setDetailedData(res?.data || {});
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));

    } finally {
      setLoading(false);
    }
  };


  const getHospitalList = () =>
    HospitalApi.getHospitalData("", 1, "")
      .then((res) => {
        const rowData = res?.data?.map((list) => {
          return {
            id: list?.id,
            name: list?.name + " " + `(${list?.city_name})`,
          };
        });
        setHospitals(rowData);
      })
      .catch((error) => {
        dispatch(setSnackbar({ value: true, message: error, color: "error" }));
      });

  useEffect(() => {
    getHospitalList();
    getSubAdminDetails();
  }, []);
  
  const handleDeleteRow = async () => {
    try {
      setOpenDeleteConfirmModal(false);
      setSubmitLoading(true);
      const res = await PrincipleApi.deletePIData(id);
      if (res?.status === 200) {
        setSubmitLoading(false)
        dispatch(setSnackbar({ value: true, message: res.message, color: "success" }));
        navigate("/principal-investigator", { state: [] });
      }
    } catch (error) {
      setOpenDeleteConfirmModal(false);
      setSubmitLoading(false)
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));
    }
  };
  return (
    <div className="p-4 bg-[#F0F1F5]">
      <div className="mb-4">
        <BreadCrumb
        BreadCrumbsListing={breadCrumbArray}
        />
      </div>
      {loading ? (
        <div className="col-start-1 col-end-4 ">
          <SkeletonLoader rowCount={1} isTable={false} height={160} />
        </div>
      ) : (
        <>
          <CustomCard
            MainHeading={detailedData?.name}
            SubHeading={`${detailedData?.hospital_details?.name}  |  ${detailedData?.email}`}
            main_heading_class={"font-bold"}
            NoWrap={true}
            DetailsArray={[
              { FieldName: "Assigned Phases", Value: detailedData?.phaseCount },
              { FieldName: "Total Editors", Value: detailedData?.editorCount },
            ]}
            startIcon={<DocotorIcon Height="50px" Width="52px" />}
            endIcon={<DocotorPairIcon />}
            ArrayLowGapBoolean={true}
            handleEdit={() => setOpenEditModal(true)}
            handleDelete={() => setOpenDeleteConfirmModal(true)}
            chartData={[{title: "Total Patients", subjectCount: detailedData?.totalSubjectCount}]|| []}
          />
        </>
      )}
      <PhaseListDetail id={id} roleId={2} moduleName="PI" ShowEditorCount={true} />
      {openEditModal ? (
        <PrincipleInvestigatorEdit
          openModal={openEditModal}
          setOpenModal={setOpenEditModal}
          id={id}
          piEditData={detailedData}
          getSubAdmin={getSubAdminDetails}
          hospitals={hospitals}
        />
      ) : null}
      <DeleteConfirmContent
        open={openDeleteConfirmModal}
        setOpen={setOpenDeleteConfirmModal}
        handleCancel={() => setOpenDeleteConfirmModal(false)}
        handleDelete={handleDeleteRow}
        isLoading={submitLoading}
      />
    </div>
  );
};
