// assets
import { DashboardIcon } from '../assets/images/drawerIcons/dashboardIcon';
import { SubMenuIcons } from '../assets/images/drawerIcons/subMenuIcons';
import { TrailIcon } from '../assets/images/drawerIcons/trialIcon';
import { ProductTrialIcon } from '../assets/images/drawerIcons/productTrialIcon';
import { EditorIcon } from '../assets/images/drawerIcons/editorIcon';
import { SubjectIcon } from '../assets/images/drawerIcons/subjectIcon';
import { TrailManageIcon } from '../assets/images/drawerIcons/trailManageIcon';
import { HospitalIcon } from '../assets/images/drawerIcons/hospitalIcon';
import { AuditHistoryIcon } from '../assets/images/drawerIcons/auditHistoryIcon';
import { RolesAccessIcon } from '../assets/images/drawerIcons/rolesAccessIcon';
import { FormBuilderIcon } from '../assets/images/drawerIcons/formBuilderIcon';
// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: "group-dashboard",
  type: "group",
  children: [
    {
      id: "dashboard",
      title: "Dashboard",
      type: "item",
      url: "/dashboard",
      icon: DashboardIcon,
      breadcrumbs: false,
      authorities: [0],
    },
    {
      id: "products",
      title: "Products",
      type: "item",
      url: "/products",
      icon: ProductTrialIcon,
      breadcrumbs: false,
      authorities: [77],
    },
    {
      id: "phases",
      title: "Phases",
      type: "item",
      url: "/phases",
      icon: TrailIcon,
      breadcrumbs: false,
      authorities: [9],
    },
    {
      id: "assigned-editors",
      title: "Assigned Editors",
      type: "item",
      url: "/assigned-editors",
      icon: EditorIcon,
      breadcrumbs: false,
      role: ["editor", "admin"],
      authorities: [21],
    },
    {
      id: "patient",
      title: "Patient",
      type: "item",
      url: "/patient",
      icon: SubjectIcon,
      breadcrumbs: false,
      authorities: [15],
    },
    {
      id: "phase-review-board",
      title: "Phase Review Board",
      type: "item",
      url: "/phase-review-board",
      icon: TrailManageIcon,
      breadcrumbs: false,
      authorities: [27],
    },
    {
      id: "role-and-access-management",
      title: "Roles and Access Management",
      type: "item",
      url: "",
      icon: RolesAccessIcon,
      breadcrumbs: false,
      authorities: [49, 95],
      subChildren: [
        {
          id: "onboard-users",
          title: "Onboard Users",
          parent: "Roles and Access Management",
          type: "child-item",
          url: "/onboard-users",
          icon: SubjectIcon,
          breadcrumbs: false,
          authorities: [49],
        },
        {
          id: "roles-management",
          title: "Roles Management",
          type: "child-item",
          parent: "Roles and Access Management",
          url: "/roles-management",
          icon: SubMenuIcons,
          breadcrumbs: false,
          authorities: [95],
        }
      ]
    },
    {
      id: "form-builder",
      title: "Form Builder",
      type: "item",
      url: "/form-builder",
      icon: FormBuilderIcon,
      breadcrumbs: false,
      authorities: [1],
    },
    {
      id: "audit-history",
      title: "Audit History",
      type: "item",
      url: "/audit-history",
      icon: AuditHistoryIcon,
      breadcrumbs: false,
      authorities: [140],
    },
    {
      id: "hospital-management",
      title: "Hospital Management",
      type: "item",
      url: "",
      icon: HospitalIcon,
      breadcrumbs: false,
      authorities: [32, 67],
      subChildren: [
        {
          id: "add-hospital",
          title: "Add Hospital",
          type: "child-item",
          parent: "Hospital Management",
          url: "/add-hospital",
          icon: DashboardIcon,
          breadcrumbs: false,
          authorities: [32],
        },
        {
          id: "add-disease",
          title: "Add Disease",
          parent: "Hospital Management",
          type: "child-item",
          url: "/add-disease",
          icon: SubMenuIcons,
          breadcrumbs: false,
          authorities: [67],
        },
      ],
    },

  ],
  subChildren: [{
    id: "audit-history",
    title: "Audit History",
    type: "item",
    url: "/audit-history",
    icon: SubMenuIcons,
    breadcrumbs: false,
    authorities: [140],
  },]
}

export default dashboard;
