import { Tooltip } from "@mui/material"
import { createTheme, ThemeProvider } from "@mui/material/styles";




const theme = createTheme({
    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: "#11A2D7",

                },
                backgroundColor:"green",
                border:"3px solid blue"
            }
        }
    }
});

export const CustomTooltip = ({ children, title }) => {
    return <ThemeProvider theme={theme} >
        <Tooltip title={title} placement="right">
            {children}
        </Tooltip>
    </ThemeProvider>
}