import { Typography } from "@mui/material"
import { getUserHasAccess } from "../../utils/authorities"
import { useSelector } from "react-redux";

export const CustomHeading = ({ leftHeading, leftChild, rightHeading, rightChild,rightAuth, handleRightChild, id }) => {
    const LoginData = useSelector((state) => state.loginData);
    const userAuthority = LoginData?.auth || [];
    return <div className="flex flex-col md:flex-row justify-between items-start md:items-center text-center gap-4 pb-3 ">
        <div className="flex gap-5 align-items-center">
            <Typography variant="h6">
                {leftHeading}
            </Typography>
            <span>
                {leftChild}
            </span>
        </div>
        <div>
            {rightHeading && getUserHasAccess(userAuthority,rightAuth?.module, rightAuth.auth) && <div className="flex gap-3 ">
                <Typography variant="h6">
                    {rightHeading}
                </Typography>
                <span onClick={() => handleRightChild(id)} className="cursor-pointer"> {rightChild}</span>
            </div>}
        </div>
    </div>
}

