import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { addUserValues } from "../../utils/formik/initialValues";
import { addUserSchema } from "../../utils/formik/validationSchema";
import { userManagementData } from "../../utils/tableData";
import DeleteConfirmContent from "../../components/modal-contents/DeleteConfirmModalContent";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../store/reducers/snackBar";
import SubAdminApi from "../../services/subAdminService";
const CustomTable = Loadable(
  React.lazy(() => import("../../components/custom-table/CustomTable"))
);
const CustomModel = Loadable(
  React.lazy(() => import("../../components/custom-model/CustomModel"))
);
import Loadable from "../../components/Loadable";
import { CustomSnackbar } from "../../components/snackbar/CustomSnackbar";
import EditorApi from "../../services/editorManagementService";
import HospitalApi from "../../services/hospitalService";
import ListDrawer from "../../components/ListDrawer/ListDrawer";

const convertUserData = (data) => {
  return data.map((userMangement) => {
    let transformedName = userMangement?.name;
    let transformeEmail = userMangement?.email;
    let transformedType = userMangement?.role;
    let transformedPi = userMangement?.investigator_details?.name||"N/A";
    let transformedAssigned = userMangement?.phaseCount;
    let phaseList = userMangement?.phaseDetails
    let transformRowId = userMangement?.id;
    return [
      transformedName,
      transformeEmail,
      transformedType,
      transformedPi,
      transformedAssigned,
      phaseList,
      transformRowId,
    ];
  });
};

export const UserManagement = () => {
  const dispatch = useDispatch();
  const snackbar = useSelector((state) => state.snackBar);
  const [openAddModal, setOpenAddModal] = React.useState(false);
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [openDeleteConfirmModal, setOpenDeleteConfirmModal] =
    React.useState(false);
  const [tableFilters, setTablesFilters] = useState({ searchData: "" });
  const [userMangement, setUserMangement] = useState(userManagementData);
  const [initialValues, setInitialValues] = useState(addUserValues);
  const [userManagementResponse, setUserManagementResponse] = useState([]);
  const [currentPage, setCurrentpage] = useState(1);
  const [rowId, setRowId] = useState(null);
  const [roleID, setRoleID] = useState("");
  const [newRoleIDs, setNewRoleIDs] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [deleteRowData, setDeleteRowData] = useState(null);
  const [roleDropDownId, setRoleDropDownId] = useState('')
  const [piList, setPiList] = useState([]);
  const [phaseList, setPhaseList] = useState([]);
  const [hospitals, setHospitals] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [phaseListData, setPhaseListData] = useState([])
  const [modelData, setModelData] = useState({
    title: "Add User",
    editTitle: "Edit User",
    children: [
      {
        type: "dropdown",
        name: "userType",
        label: "User Type",
        placeholder: "Enter Type",
        data: roleList,
        IsRequired: true,
      },
      {
        type: "input",
        name: "name",
        label: "Name",
        placeholder: "Enter Name",
        IsRequired: true,
      },
      {
        type: "input",
        name: "email",
        label: "Email",
        placeholder: "Enter Email",
        IsRequired: true,
      },
      {
        type: "number",
        name: "phoneNumber",
        label: "Phone Number",
        placeholder: "Enter Phone Number",
        IsRequired: true,
      },
    ],
  });

  useEffect(() => {
    const newChildren = [
      {
        type: "input",
        name: "name",
        label: "Name",
        placeholder: "Enter Name",
        IsRequired: true,
      },
      {
        type: "number",
        name: "phoneNumber",
        label: "Phone Number",
        placeholder: "Enter Phone Number",
        IsRequired: true,
      },
    ];
    if (rowId == "") {
      newChildren.push({
        type: "input",
        name: "email",
        label: "Email",
        placeholder: "Enter Email",
        IsRequired: true,
      });
      newChildren.unshift({
        type: "dropdown",
        name: "userType",
        label: "User Type",
        placeholder: "Enter Type",
        data: roleList,
        IsRequired: true,
      })
    } else {
      newChildren.push({
        type: "input",
        name: "email",
        label: "Email",
        placeholder: "Enter Email",
        IsRequired: true,
        disabled: true,
      });
      newChildren.unshift({
        type: "dropdown",
        name: "userType",
        label: "User Type",
        placeholder: "Enter Type",
        data: roleList,
        IsRequired: true,
        disabled: true,
      })
    }
    if (roleDropDownId == 2) {
      newChildren.push({
        type: "dropdown",
        name: "hospital",
        label: "Hospital",
        placeholder: "Select Hospital",
        IsRequired: true,
        data: hospitals
      })
    } else if (roleDropDownId == 5) {
      newChildren.push({
        type: "dropdown",
        name: "principalInvestigator",
        label: "Principal Investigator",
        placeholder: "Select Principal Investigator",
        data: piList,
        IsRequired: true,
      },
        {
          type: "multidropdown",
          name: "trial",
          label: "Phase",
          placeholder: "Select Phase",
          data: phaseList,
          IsRequired: true,
        })
    }
    setModelData((prevModelData) => ({
      ...prevModelData,
      children: newChildren,
    }));
  }, [roleDropDownId, rowId])

  const navigate = useNavigate();

  const getPersonalInvestigatorList = () =>
    EditorApi.subadminDropDown()
      .then((res) => {
        setPiList(res.data);
        setModelData((prevState) => ({
          ...prevState,
          children: prevState.children.map((child) =>
            child.name === "principalInvestigator"
              ? { ...child, data: res.data }
              : child
          ),
        }));
      })
      .catch((error) => {
        dispatch(setSnackbar({ value: true, message: error, color: "error" }));
      });

  const getPhase = (piId) => {
    EditorApi.getPhaseDropDown('', piId)
      .then((res) => {
        const data = res?.data?.map((val) => ({
          id: val.id,
          name: `${val.title}(${val.product_details?.name})`,
        }));        
        setPhaseList(data);
        setModelData((prevState) => ({
          ...prevState,
          children: prevState.children.map((child) =>
            child.name === "trial" ? { ...child, data: data } : child
          ),
        }));
      })
      .catch((error) => {
        dispatch(setSnackbar({ value: true, message: error, color: "error" }));
      });
  }
  const getHospitalList = () => HospitalApi.getHospitalData('', 1, '')
    .then((res) => {
      const rowData = res?.data?.map((list) => {
        return {
          id: list?.id,
          name: list?.name + ' ' + `(${list?.city_name})`
        };
      });
      setHospitals(rowData);
      setModelData(prevState => ({
        ...prevState,
        children: prevState.children.map(child =>
          child.name === 'hospital' ? { ...child, data: rowData } : child
        )
      }));
    }
    )
    .catch((error) => {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));
    })

  const handleNavigateToDetails = React.useCallback((rowData) => {
    const rowId = rowData?.[rowData?.length - 1] || "";
    navigate(`onboard-users-details/${rowId}`, {
      state: [
        { name: "Onboard Users", path: location.pathname },
        {
          name: "Details",
          path: `${location.pathname}/onboard-users-details/${rowId}`,
        },
      ],
    });
  }, []);
  
  useEffect(() => {
    // getPhase()
    getSubAdminListByRoles();
    getPersonalInvestigatorList();
    getHospitalList()
  }, []);

  useEffect(() => {
    if (
      (tableFilters?.searchData &&
        tableFilters?.searchData[tableFilters?.searchData?.length - 1] !=
          " ") ||
      newRoleIDs?.length > 0
    ) {
      setLoading(true);
      getSubAdminList();
    }
  }, [currentPage, tableFilters, roleID, newRoleIDs]);

  const getSubAdminListByRoles = () =>
    SubAdminApi.getSubAdminListByRoles().then((res) => {
      // Filter out sub-admins with IDs 3 and 4

      // Map the filtered list to the desired format
      const dataList = res?.data?.map((list) => ({
        id: list?.id,
        name: list?.title,
      }));
      setNewRoleIDs(dataList?.map((val) => val.id));
      setRoleList(dataList);
    });

  const getSubAdminList = () => {
    SubAdminApi.getSubAdminData(
      10,
      currentPage,
      tableFilters?.searchData,
      roleID
    )
      .then((res) => {
        let rowData = convertUserData(res?.data || []);
        setUserManagementResponse(res?.data);
        setUserMangement((prev) => ({
          ...prev,
          rows: [...rowData],
        }));

        setTotalPage(
          Math.ceil(res?.page_details?.no_of_records / res?.page_details?.limit)
        );
        setCurrentpage(res?.page_details?.page);
        setLoading(false);
      })
      .catch((error) => {
        dispatch(setSnackbar({ value: true, message: error, color: "error" }));
        setLoading(false);
      });
  };



  const handleOpenAddModal = () => {
    setInitialValues(addUserValues);
    setRowId("");
    setRoleID("");
    setOpenAddModal(true);
    setRoleDropDownId('')
  };

  const handleOpenEditPrincipalModal = (row) => {
    setRowId(row[row?.length - 1]);
    const subAdminEditData = userManagementResponse.find(
      (userMangement) => userMangement.id === row[row?.length - 1]
    );
    setInitialValues({
      userType: subAdminEditData?.role_id,
      name: subAdminEditData?.name,
      email: subAdminEditData?.email,
      phoneNumber: subAdminEditData?.phone,
      selectTrial: subAdminEditData?.phase_id,
      hospital: subAdminEditData?.hospital_id,
      principalInvestigator: subAdminEditData?.investigator_details?.id,
      trial: subAdminEditData?.phaseDetails.map(item => item.id),
    });
    setOpenEditModal(true);
    setRoleDropDownId(subAdminEditData?.role_id)
    getPhase(subAdminEditData?.investigator_details?.id)
  };
  const handleAddModelValues = (values) => {
    setSubmitLoading(true);
    SubAdminApi.addSubAdmin({
      name: values?.name,
      email: values?.email,
      country_code: "91",
      phone: values?.phoneNumber.toString(),
      role_id: values?.userType,
      ...(roleDropDownId == 5 ? { phase_ids: values?.trial } : {}),
      ...(roleDropDownId == 5 ? { investigator_id: values?.principalInvestigator } : {}),
      ...(roleDropDownId == 2 ? { hospital_id: values?.hospital } : {}),
    })
      .then((res) => {
        if (res?.status === 200) {
          getSubAdminList();
          dispatch(setSnackbar({ value: true, message: res.message, color:"success" }));
          setOpenAddModal(false);
          setSubmitLoading(false);
        }
      })
      .catch((error) => {
        dispatch(setSnackbar({ value: true, message: error, color: "error" }));
        setSubmitLoading(false);
      });
  };

  const handleEditModelValues = (values) => {
    const subAdminEditData = userManagementResponse.find((userMangement) => {
      return userMangement.id == rowId;
    });
    const dataIds = subAdminEditData?.phaseDetails.map(item => item.id);
    // Filter out the IDs that are present in values?.trial
    const missingIds = dataIds?.filter(id => !values?.trial.includes(id));
    const isInvestigator = subAdminEditData?.investigator_details?.id !== values?.principalInvestigator ? subAdminEditData?.investigator_details?.id : 0
    setSubmitLoading(true);
    SubAdminApi.editSubAdmin(
      {
        name: values?.name,
        email: values?.email,
        country_code: "91",
        phone: values?.phoneNumber?.toString(),
        role_id: values?.userType,
        ...(roleDropDownId == 5 ? { phase_ids: values?.trial } : {}),
        ...(roleDropDownId == 5 ? { investigator_id: values?.principalInvestigator } : {}),
        ...(roleDropDownId == 2 ? { hospital_id: values?.hospital } : {}),
        ...(roleDropDownId == 5 ? { removedPhaseIds: missingIds } : {}),
        ...(roleDropDownId == 5 ? { removedInvestigatorId: isInvestigator } : {}),
      },
      rowId
    )
      .then((res) => {
        if (res.status === 200) {
          getSubAdminList();
          dispatch(setSnackbar({ value: true, message: res.message, color:"success" }));
          setOpenEditModal(false);
          setSubmitLoading(false);
        }
      })
      .catch((error) => {
        dispatch(setSnackbar({ value: true, message: error, color: "error" }));
        setSubmitLoading(false);
      });
  };
  const handleOpenDeleteConfirmModal = (row) => {
    setOpenDeleteConfirmModal(true);
    setDeleteRowData(row);
  };

  const handlePagination = (value) => {
    setCurrentpage(value);
  };

  const handleTableFilters = (e) => {
    setTablesFilters({ ...tableFilters, searchData: e });
    setCurrentpage(1)
  };

  const handleDeleteRow = () => {
    setSubmitLoading(true);
    SubAdminApi.deleteSubAdmin(deleteRowData[deleteRowData?.length - 1])
      .then((res) => {
        if (res?.status === 200) {
          getSubAdminList();
          dispatch(setSnackbar({ value: true, message: res.message, color:"success" }));
          setOpenDeleteConfirmModal(false);
          setSubmitLoading(false);
        }
      })
      .catch((error) => {
        dispatch(setSnackbar({ value: true, message: error, color: "error" }));
        setSubmitLoading(false);
        setOpenDeleteConfirmModal(false);
      });
  };

  const handleRoleIdChange = (e) => {
    setRoleID(e.target.value);
    setCurrentpage(1)
  };

  const handleModelData = (e, name, pi) => {
    if (name == "userType") {
      setRoleDropDownId(e)
    }
    if (pi == "principalInvestigator") {
      getPhase(e)
    }
  };

  const handleCellClick = (row) => {
    setOpenDrawer(!openDrawer)
    setPhaseListData(row[row?.length - 2])
  } 

  return (
    <>
      <div className="flex flex-col gap-0 bg-white p-0 !pb-0">
        <CustomModel
          rowId={rowId}
          modalInitialValues={initialValues}
          initialValuesValidationSchema={addUserSchema}
          open={openEditModal ? openEditModal : openAddModal}
          setOpen={openEditModal ? setOpenEditModal : setOpenAddModal}
          setModelData={setModelData}
          modelData={modelData}
          isLoadingBtn={submitLoading}
          handleModelValues={
            openEditModal ? handleEditModelValues : handleAddModelValues
          }
          onChange={handleModelData}
        />
        <CustomTable
          rows={userMangement?.rows}
          columnAlign={userMangement?.columnAlign}
          columns={userMangement?.columns}
          TableData={userMangement?.TableData}
          handleNavigate={handleNavigateToDetails}
          handleButtonClick={handleOpenAddModal}
          handleEditButton={handleOpenEditPrincipalModal}
          handleDeleteButton={handleOpenDeleteConfirmModal}
          handleTableFilters={handleTableFilters}
          tableFilters={tableFilters}
          handleChangePage={handlePagination}
          handleChangeDropDown={handleRoleIdChange}
          dropDownValue={roleID}
          MenuList={roleList}
          loading={loading}
          currentPage={currentPage}
          totalpage={totalPage}
          handleCellClick={handleCellClick}
        />
      </div>
      <DeleteConfirmContent
        open={openDeleteConfirmModal}
        setOpen={setOpenDeleteConfirmModal}
        handleDelete={handleDeleteRow}
        isLoading={submitLoading}
      />
      <CustomSnackbar
        open={snackbar?.value}
        setOpen={setSnackbar()}
        color={snackbar?.color}
        message={snackbar?.message}
      />
      {openDrawer ? <ListDrawer open={openDrawer} toggleDrawer={() => setOpenDrawer(!openDrawer)} listData={phaseListData || []} title="Phase List" /> : null}
    </>
  );
};
