import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { Box, List, Typography, useMediaQuery } from "@mui/material";

// project import
import NavItem from "./NavItem";
import {
  // checkMasterData,
  hasAuthority,
} from "../../../../../utils/authorities";
import { activeItem, openDrawer } from "../../../../../store/reducers/menu";
import { useTheme } from "@emotion/react";
import { useLocation } from "react-router";
import { useState } from "react";
import dashboard from "../../../../../menu-items/dashboard";

// ==============================|| NAVIGATION - LIST GROUP ||============================== //
const NavGroup = ({ item }) => {
  const menu = useSelector((state) => state.menu);
  const LoginData = useSelector((state) => state.loginData);
  const userAuthority = LoginData?.auth || [];
  const location = useLocation();
  const firstSegment = location.pathname.split('/')[1];
  const theme = useTheme();
  const dispatch = useDispatch();
  const { drawerOpen, openItem } = menu;
  const matchDownLG = useMediaQuery(theme.breakpoints.down("lg"));
  const [activeItemId, setActiveItemId] = React.useState(openItem[0]);
  const role_id = useSelector((state) => state.loginData.role_id);

  const [subChildMenu, setSubChildMenu] = useState([])


  const handleSetActiveItemId = (id) => {
    setActiveItemId(id);
  };

  const handleHighlightTab = () => {
    let isChild = null
    for (let item of dashboard.children) {
      if (item?.subChildren) {
        for (let subItem of item.subChildren) {
          if (subItem.id === firstSegment) {
            isChild = subItem;
            isChild.parent = item.title;
            isChild.parentId = item.id;
            break;
          }
        }
      }
    }
    if (isChild) {
      dispatch(activeItem({ openItem: [isChild?.parentId, firstSegment] }));
      setActiveItemId([isChild?.parentId, firstSegment]);
      setSubChildMenu(dashboard.children.find(op => isChild.parentId == op.id).subChildren)
    }
    else {
      dispatch(activeItem({ openItem: [firstSegment] }));
      setSubChildMenu([])
      setActiveItemId([firstSegment]);
    }
  }

  React.useEffect(() => {
    if (firstSegment) {
      handleHighlightTab();
    }
  }, [firstSegment])

  React.useEffect(() => {
    if (activeItemId === "master-data" && !matchDownLG && drawerOpen) {
      dispatch(openDrawer({ drawerOpen: false }));
    }
  }, []);



  const checkMasterDataRoute = (menuItem) => {
    if (menuItem && menuItem.subChildren) {
      return menuItem;
    } else {
      return menuItem;
    }
  }

  const navChildMenu = (
    <div className="flex flex-col gap-5 pl-[22px]  pr-[22px] pt-4 ">
      {LoginData?.token && <Typography variant="h6" fontWeight={500} color="white">
        {subChildMenu[0]?.parent}
      </Typography>}
      {subChildMenu?.map((menuItem) => {
        return menuItem?.authorities && hasAuthority(menuItem?.authorities, userAuthority) && <NavItem
          key={menuItem.id}
          item={menuItem}
          level={1}
          activeItemId={activeItemId}
          setActiveItemId={handleSetActiveItemId}
        />
      })}
    </div>
  );

  const navMainMenu = (
    <div className={` border-r divider-border pt-4`}>
      {item.children?.map((menuItem) => {
        if (menuItem?.authorities && hasAuthority(menuItem?.authorities, userAuthority)) {
          return ((menuItem?.authorities[0] == 9 || menuItem?.authorities[0] == 15) && role_id == 1 ? "" : < NavItem
            key={menuItem.id}
            item={checkMasterDataRoute(menuItem)}
            level={1}
            activeItemId={activeItemId}
            setActiveItemId={handleSetActiveItemId}
            setSubChildMenu={setSubChildMenu}
          />
          );
        }
      })}
    </div>
  );

  return (
    <List
      subheader={
        item.title &&
        drawerOpen && (
          <Box sx={{ pl: 3, mb: 1.5 }}>
            <Typography variant="subtitle2" color="textSecondary">
              {item.title}
            </Typography>
          </Box>
        )
      }
      sx={{
        height: "100%",
        mb: drawerOpen ? 0 : 0,
        py: 0,
        zIndex: 0,
        display: "flex",
      }}
    >
      {navMainMenu}
      {subChildMenu && subChildMenu.length != 0 && navChildMenu}
      {/* {navChildMenu} */}
      {/* {roles?.toLowerCase() === "investigator" ? null : navChildMenu} */}
    </List>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object,
};

export default NavGroup;
