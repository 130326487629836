import React, { useMemo } from "react";
import { useEffect, useState } from "react";
import {
  DocotorIcon,
  DocotorPairIcon,
} from "../../assets/common-svg/CommonSvg";
import { CustomCard } from "../../components/custom-card/CustomCard";
import { useLocation, useNavigate, useParams } from "react-router";
import SkeletonLoader from "../../utilites/SkeltonLoader";
import PhaseListDetail from "../../components/phase-list-detail/PhaseListDetail";
import BreadCrumb from "../breadcrumb/BreadCrumb";
import SubAdminApi from "../../services/subAdminService";
import DeleteConfirmContent from "../../components/modal-contents/DeleteConfirmModalContent";
import { CustomSnackbar } from "../../components/snackbar/CustomSnackbar";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../store/reducers/snackBar";
import SubAdminEdit from "../sub-admin-edit/SubAdminEdit";
import { EditorManagementDetails } from "../editor-management-details/EditorManagementDetails";

export const UserManagementDetails = () => {
  const snackbar = useSelector((state) => state.snackBar);
  const [loading] = useState(false);
  const { pathname } = useLocation();
  const [userManagementDetail, setUserManagementDetail] = useState({});
  const [roleList, setRoleList] = useState([]);
  const [openEditPrincipalModal, setOpenEditPrincipalModal] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [openDeleteConfirmModal, setOpenDeleteConfirmModal] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getUserManagementDetails = async () => {
    try {
      const res = await SubAdminApi.getSubAdminDetails(id);
      setUserManagementDetail(res?.data || {});
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));
    }
  };

  useEffect(() => {
    getUserManagementDetails();
  }, []);

  const getSubAdminListByRoles = () =>
    SubAdminApi.getSubAdminListByRoles().then((res) => {
      const dataList = res?.data?.map((list) => {
        return {
          id: list?.id,
          name: list?.title,
        };
      });

      setRoleList(dataList);
    });
  useEffect(() => {
    getSubAdminListByRoles();

  }, []);

  const NavigateToPreviousRoute = () => {
    const parts = pathname.split('/').filter(part => part !== '');
    return parts[0] || null;
  }

  const handleDeleteRow = () => {
    setSubmitLoading(true);
    setOpenDeleteConfirmModal(false);
    SubAdminApi.deleteSubAdmin(id)
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setSnackbar({ value: true, message: res.message, color: "success" }));
          setSubmitLoading(false);
          navigate(`/${NavigateToPreviousRoute()}`, { state: [] });
        }
      })
      .catch((error) => {
        dispatch(setSnackbar({ value: true, message: error, color: "error" }));
        setSubmitLoading(false);
      });
  }

  const phaseListGrid = useMemo(() => {
    if(userManagementDetail?.role_id){
      return <PhaseListDetail id={id} roleId={userManagementDetail?.role_id} moduleName={userManagementDetail?.role_id === 2 ? "PI" : "UserManagement"} ShowEditorCount={true} />
    }
  }, [userManagementDetail?.role_id]);
 
  if(userManagementDetail?.role_id === 5){
    return <EditorManagementDetails />
  }

  return (
    <div className="p-4 bg-[#F0F1F5]">
      {openEditPrincipalModal ? (
        <SubAdminEdit
          openModal={openEditPrincipalModal}
          setOpenModal={setOpenEditPrincipalModal}
          id={id}
          subAdminDetail={userManagementDetail}
          roleList={roleList}
          getSubAdmin={getUserManagementDetails}
        />
      ) : null}
      
      <div className="mb-3 md:mb-4">
        <BreadCrumb BreadCrumbsListing={[
          { name: "Onboard Users", path: "onboard-users" },
          { name: "Details", path: location.pathname }]}
         />
      </div>
      {loading ? (
        <div className="col-start-1 col-end-4 ">
          <SkeletonLoader rowCount={1} isTable={false} height={160} />
        </div>
      ) : (
        <>
          <CustomCard
              editAuth={{ module: userManagementDetail?.role_id != 2 ? "userManagement" : "PI", auth: 51 }}
              deleteAuth={{ module: userManagementDetail?.role_id != 2 ? "userManagement" : "PI", auth: 52 }}

            MainHeading={userManagementDetail?.name || ""}
            SubHeading={
              (userManagementDetail?.email || " ") +
              " | " +
              (userManagementDetail?.role || " ")
            }
            main_heading_class={"font-bold"}
            NoWrap={true}
            DetailsArray={pathname?.includes("onboard-users-details") ? [] : [
              {
                FieldName: "Assigned Phases",
                Value: userManagementDetail?.phaseCount || 0,
              },
              {
                FieldName: "Total PI",
                Value: userManagementDetail?.investigatorCount || 0,
              },
              {
                FieldName: "Total Editors",
                Value: userManagementDetail?.editorCount || 0,
              },
            ]}
            chartData={userManagementDetail?.phaseDetails || []}
            startIcon={<DocotorIcon Height="50px" Width="52px" />}
            endIcon={<DocotorPairIcon />}
            ArrayLowGapBoolean={true}
            handleEdit={() => setOpenEditPrincipalModal(true)}
            handleDelete={() => setOpenDeleteConfirmModal(true)}
          />
        </>
      )}
      {phaseListGrid}
      <DeleteConfirmContent
        open={openDeleteConfirmModal}
        setOpen={setOpenDeleteConfirmModal}
        handleDelete={handleDeleteRow}
        isLoading={submitLoading}
      />
      <CustomSnackbar
        open={snackbar?.value}
        setOpen={setSnackbar()}
        color={snackbar?.color}
        message={snackbar?.message}
      />
    </div>
  );
};
