import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CustomModal from '../custom-modal/CustomModal';
import { DeleteModalIcon } from '../../assets/common-svg/CommonSvg';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';


const DeleteConfirmContent = ({ open, setOpen, handleDelete, isLoading = false }) => {
    const handleClose = () => { setOpen(false) };

    return (
      <CustomModal open={open} setOpen={setOpen}>
        <div className="h-full flex flex-col gap-8 pl-3 pr-3">
          <div className="self-center">
            <DeleteModalIcon />
          </div>
          <Typography
            align="center"
            id="spring-modal-title"
            variant="h5"
            fontWeight={700}
            component="h5"
          >
            Are you sure you want to delete ?
          </Typography>
          <div className="flex justify-center gap-12">
            <Button
              variant="contained"
              style={{ backgroundColor: "#DDDDDD", color: "#828282" }}
              className="w-[160px] h-[40px]"
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              style={{ backgroundColor: "#FD5456" }}
              className="w-[160px] h-[40px]"
              onClick={handleDelete}
              disabled={isLoading}
              loading={isLoading}
            >
              <span>Delete</span>
            </LoadingButton>
          </div>
        </div>
      </CustomModal>
    );
}
DeleteConfirmContent.proptypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    handleDelete: PropTypes.func,
}
export default DeleteConfirmContent;