import React, { useEffect, useRef, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import { TextField, IconButton, Box, InputAdornment } from "@mui/material";
import { CalendarOutlined, CloseOutlined } from "@ant-design/icons";
import moment from "moment/moment";
import { isEmpty } from "lodash";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./CustomDatePicker.css";

const CustomDateRangePicker = ({ selectedDate, handleDateChange }) => {
  const [filterBy, setFilterBy] = useState({});
  const [date, setDate] = useState([
    {
      startDate:  new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const wrapperRef = useRef(null);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShowDatePicker(false);
    }
  };
  

  const handleDatePicker = (date) => {
    handleDateChange(date)
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);
  
  return (
    <Box position="relative" display="inline-block" ref={wrapperRef} className="w-100">
      <TextField
        placeholder="Select Date Range"
        value={filterBy?.from ? filterBy?.from + " - " + filterBy?.to : ""}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              {selectedDate&&Object.keys(selectedDate)?.length>0 &&
              <IconButton
                sx={{
                  borderRadius: '50%', // Make it rounded
                }}

                onClick={() =>{
                  //  setSelectedDate(null);
                  handleDateChange(null)
                   setFilterBy({});
                   setDate([
                    {
                      startDate:  new Date(),
                      endDate: new Date(),
                      key: "selection",
                    },])
                  }}
              
             >
              <CloseOutlined style={{color:"red"}} />
              </IconButton>}
              <IconButton  onClick={() => setShowDatePicker(!showDatePicker)} >
                <CalendarOutlined />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{ backgroundColor: "white", borderColor: "#ccc" }}
        className="hide-legend date-input w-100"
      />
      {showDatePicker && (
        <Box position="absolute" zIndex={1} right={0}>
          <>
            <DateRangePicker
              onChange={(item) => {
                setDate([item.selection]);
              }}
              inputRanges={[]}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={date}
              direction="horizontal"
              className="custom-range-picker"
            />
            <div className="d-flex justify-content-end calenderBtn">
              <button
                className="bg-transparent hover:!bg-[#007bff] text-[#007bff] font-semibold hover:text-white py-2 px-4 border border-[#007bff] hover:border-transparent rounded"
                onClick={() => {
                  setDate([
                    {
                      startDate: new Date(),
                      endDate: addDays(new Date(), 0),
                      key: "selection",
                    },
                  ]);
                  setShowDatePicker(false);

                  setFilterBy({
                    ...filterBy,
                    from: "",
                    to: "",
                  });
                  handleDatePicker({
                    ...filterBy,
                    from: "",
                    to: "",
                  });
                }}

              >
                Reset
              </button>
              <button
                className="bg-[#007bff] hover:bg-[#007bff] text-white font-bold py-2 px-4 rounded ml-3"
                onClick={() => {
                  setFilterBy({
                    ...filterBy,
                    from: moment(!isEmpty(date) && date[0]?.startDate).format(
                      "DD-MM-YYYY"
                    ),
                    to: moment(!isEmpty(date) && date[0]?.endDate).format(
                      "DD-MM-YYYY"
                    ),
                  });
                  setShowDatePicker(false);
                  handleDatePicker({
                    ...filterBy,
                    from: moment(!isEmpty(date) && date[0]?.startDate).format(
                      "DD-MM-YYYY"
                    ),
                    to: moment(!isEmpty(date) && date[0]?.endDate).format(
                      "DD-MM-YYYY"
                    ),
                  })
                }}
              >
                Apply
              </button>
            </div>
          </>
        </Box>
      )}
    </Box>
  );
};

export default CustomDateRangePicker;
