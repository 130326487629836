import React from "react";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export const CustomProgressBar = ({percentage = 0}) => {
   
    return (<div style={{ position: "relative", width: 100, height: 100 }}>
        <CircularProgressbarWithChildren value={percentage} strokeWidth={10}
            styles={buildStyles({
                pathColor: `#00B278`,
                trailColor: '#ffffff',
            })}
        >
            <p className="text-[0.6rem] text-gray-500">Completed</p>
            <div style={{ fontSize: 12, marginTop: -5 }}>
                <span className="font-bold text-[1rem]">{percentage<=100?percentage:100}%</span>
            </div>
        </CircularProgressbarWithChildren>
    </div>
    );
};
