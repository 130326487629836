// material-ui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

// ==============================|| DRAWER HEADER - STYLED ||============================== //

const DrawerHeaderStyled = styled(Box, { shouldForwardProp: (prop) => prop !== 'open' && prop !=='isInvestigator' })(({ theme, open, isInvestigator }) => ({
  ...theme.mixins.toolbar,
  display: 'flex',
  borderBottom: "1px solid",
  alignItems: 'center',
  justifyContent: open ? 'flex-start' : 'center',
  paddingLeft: theme.spacing(open ? isInvestigator? 2 : 2.1 : 0),
})); 

export default DrawerHeaderStyled;
