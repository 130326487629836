// third-party
import { configureStore } from '@reduxjs/toolkit';
// project import
import storage from 'redux-persist/lib/storage';
import persistReducer from 'redux-persist/es/persistReducer';
import { persistStore } from 'redux-persist';
import reducers from './reducers';

// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //

const persistConfig = {
  key: 'persist-key',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore(
  {
    reducer: persistedReducer,
  }
);

const persistor = persistStore(store);

const { dispatch } = store;

export { store, dispatch };

export { persistor };
