import CustomModal from '../custom-modal/CustomModal';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

export const RejectTrialManagementContent = ({ open, setOpen, modalText, title }) => {


    return (
        <CustomModal ModalTitle={title} open={open} setOpen={setOpen} >
            <div className='px-[20px] pt-[15px] flex flex-col gap-[15px]' style={{ maxHeight: "15rem", overflowY: "auto" }}>
                <ul className='list-disc pl-3'>
                    {modalText?.map((op, index) => (
                        <li key={index} >
                            <Typography className=' font-[15px] '>
                                {op?.comment}
                            </Typography>
                        </li>
                    ))}

                </ul>
            </div>
        </CustomModal>
    )
}

RejectTrialManagementContent.proptypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    handleInput: PropTypes.func
}