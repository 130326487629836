import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import Loadable from "../../../components/Loadable";
import BreadCrumb from "../../breadcrumb/BreadCrumb";
import FromBuilder from "../../../components/form-template/FormBuilder";
import { setFormData } from "../../../store/reducers/form-template";
import 'react-form-builder2/dist/app.css';
import { FormBreadCrumbGetter } from "../../../utilites/Common";

const TaskManagementHeader = Loadable(
    React.lazy(() =>
        import("../../../components/task-management-header/TaskManagementHeader")
    )
);

export const CreateFormTemplate = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams()
    const rowId = id
    const FormTempleteData = useSelector((state) => state.formTemplete);
    const [form, setForm] = useState([]);
    const [breadCrumbArray,] = useState(FormBreadCrumbGetter(location.pathname));
    const [initForm, setInitForm] = useState(false);
    const handleChangeEvent = (data) => {
        if (data.task_data) {
            setForm(data.task_data)
        }
    };

    const handlePreview = () => {
        dispatch(setFormData({ formData: form }));
        const UpdatedNavigationArray = location.state;
        UpdatedNavigationArray?.push({
            name: "Preview",
            path: `${location.pathname}/form-builder-preview/${rowId}`,
        });
        if (rowId) {
            navigate(`form-builder-preview/${rowId}`, {
                state: UpdatedNavigationArray,
            });
        } else {
            navigate(`form-builder-preview/${rowId}`, {
                state: UpdatedNavigationArray,
            });
        }
    };

    useEffect(() => {
        if (!initForm) {
            setForm(JSON.parse(JSON.stringify(FormTempleteData.formData)) || []);
            setInitForm(true);
        }
    }, [!initForm]);

    return (
        <div className="p-3 flex flex-col gap-4 mt-1">
            <div>
                <BreadCrumb  BreadCrumbsListing={breadCrumbArray}/>
                <div className="pb-3">
                    <TaskManagementHeader ButtonText='Preview'  handleButtonClick={() => handlePreview()} />
                </div>
                {initForm &&
                    <FromBuilder formData={form} handleChangeEvent={handleChangeEvent} />
                }
            </div>
        </div>
    );
};

export default CreateFormTemplate;
