// project import
import React from 'react';
import Loadable from '../components/Loadable';
import MinimalLayout from '../layout/MinimalLayout';
import NotFoundPage from '../pages/NoPageFound/NoPageFound';

// render - login
const AuthLogin = Loadable(React.lazy(() => import('../pages/authentication/Login')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/',
      element: <AuthLogin />,
    },
    {
      path: '/login',
      element: <AuthLogin />,
    },
    {
      path: '/forgot-password',
      element:<AuthLogin  ForgotPasswordBoolean={true} />,
    },
    {
      path: '/create-new-password',
      element:<AuthLogin  createPasswordBoolean={true} />,
    },
    {
      path: '*', // Catch-all route for undefined paths
      element: <NotFoundPage /> 
    }
  ],
};

export default LoginRoutes;
