import { BlankContainer } from "../blank-container/BlankContainer"
import { TrialInfoHeader } from "../trial-info-header/TrialInfoHeader"
import PropTypes from 'prop-types'
 const TrialInfoComponent = ({children,HeaderTitle,ButtonText,ShowHeaderButton,HeaderButtonIcon,HandleButton}) => {
    return <BlankContainer>
       <TrialInfoHeader HandleButton={HandleButton} ShowButton={ShowHeaderButton} HeaderText={HeaderTitle} ButtonIcon={HeaderButtonIcon} ButtonText={ButtonText}  />
       {children}
    </BlankContainer>
}

TrialInfoComponent.proptypes = {
   children:PropTypes.node,
   HeaderTitle:PropTypes.string,
   ButtonText:PropTypes.string,
   ShowHeaderButton:PropTypes.bool,
   HeaderButtonIcon:PropTypes.node,
   HandleButton:PropTypes.func,
}

export default TrialInfoComponent;