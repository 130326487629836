import { Button, Typography } from "@mui/material";
import { DeleteIcon, EditIcon } from "../../assets/common-svg/CommonSvg";
import { RolesCard } from "../../components/custom-card/RolesCard";
import { useLocation, useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import RolesApi from "../../services/rolesManagemant";
import { useDispatch, useSelector } from "react-redux";
import { CustomSnackbar } from "../../components/snackbar/CustomSnackbar";
import { setSnackbar } from "../../store/reducers/snackBar";
import SkeletonLoader from "../../utilites/SkeltonLoader";
import DeleteConfirmContent from "../../components/modal-contents/DeleteConfirmModalContent";
import BreadCrumb from "../breadcrumb/BreadCrumb";
import { getUserHasAccess } from "../../utils/authorities";

const RolesDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();
  const roleEditId = id;
  const snackbar = useSelector((state) => state.snackBar);
  const LoginData = useSelector((state) => state.loginData);
  const userAuthority = LoginData?.auth || [];
  const [deleteModal, setDeleteModal] = useState(false);
  const [rowId, setRowId] = useState("");
  const [RolesState, setRolesState] = useState({ modules: [] }); // Initialize modules as an array
  const [RolesDetails, setRolesDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const getDetails = () => {
    setLoading(true);
    RolesApi.getRoleModulesDetails(roleEditId)
      .then((res) => {
        if (res && res.status === 200 && res.data) {
          const modules = [];
          let stateModule = [];
          res.data.modules.map((op) => {
            let subCard = [];
            op.permissions.map((p, index) => {
              let obj = {
                id: p.id,
                module_id: op.module_id,
                permission_id: p.permission_id,
                label: p.name,
                name: p.description,
                disabled: index == 0 ? true : false
              };
              if (p.checked) {
                subCard.push(obj);
                stateModule.push(obj);
              } else {
                subCard.push(obj);
              }
            });
            modules.push({
              id: op.id,
              module_id: op.module_id,
              title: op.module_name,
              toggle: false,
              subCard: subCard,
            });
          });
          setTimeout(() => {
            setRolesState({ ...RolesDetails, modules: stateModule });
            setRolesDetails({
              ...res.data,
              modules: modules,
            });
            setLoading(false);
          }, 1000);
        } else {
          dispatch(
            setSnackbar({
              value: true,
              message: res?.message || "An error occurred",
              color: "error",
            })
          );
          setLoading(false);
        }
      })
      .catch((err) => {
        dispatch(setSnackbar({ value: true, message: err, color: "error" }));
        setLoading(false);
      });
  };

  useEffect(() => {
    getDetails();
  }, []);

  const handleSwitch = (e, index, rootIndex) => {
    const { checked } = e.target;
    let modules = [...RolesState.modules];

    if (checked) {
      modules = [...modules, RolesDetails?.modules[rootIndex].subCard[index]];
    } else {
      modules = modules.filter(
        (op) => op.id !== RolesDetails?.modules[rootIndex].subCard[index]?.id
      );
    }
    setRolesState({ ...RolesState, modules: modules });
  };

  const handleRootSwitch = (e, index) => {
    const { checked } = e.target;
    const roleCard = RolesDetails?.modules.map((card, moduleIndex) => {
      if (index === moduleIndex) {
        return { ...card, toggle: checked };
      }
      return card;
    });
    if (checked) {
      const Modules = RolesDetails?.modules[index]?.subCard.filter((op, index) => index != 0) || [];
      setRolesState((prevState) => {
        const newModules = Modules.filter(
          (newModule) =>
            !prevState.modules.some(
              (existingModule) => existingModule.id === newModule.id
            )
        );

        return {
          ...prevState,
          modules: [...prevState.modules, ...newModules],
        };
      });
    } else {
      setRolesState((prevState) => ({
        ...prevState,
        modules: prevState.modules.filter(
          (op) =>
            ! RolesDetails?.modules[index]?.subCard.filter((op, index) => index != 0).some((p) => op.id === p?.id)
        ),
      }));
    }
    setRolesDetails({ ...RolesDetails, modules: roleCard });
  };

  const handleUpdate = () => {
    setSubmitLoading(true);
    let data = {
      role_id: RolesDetails?.id,
      permissionIds: RolesState?.modules.map((op) => op?.id),
    };
    RolesApi.assignPermissionsToRole(data)
      .then((res) => {
        dispatch(setSnackbar({ value: true, message: res?.message, color: "success" }));
        navigate("/roles-management", { state: [] });
        setTimeout(() => setSubmitLoading(false), 300);
      })
      .catch((err) => {
        dispatch(setSnackbar({ value: true, message: err, color: "error" }));
        setLoading(false);
        setSubmitLoading(false);
      });
  };
  const handleEdit = (id) => {
    navigate(`/roles-management/edit-modules-management/${id}`, {
      state: [
        { name: "Permission", path: "/roles-management" },
        {
          name: "Edit Role",
          path: location.pathname,
        },
      ],
    });
  };
  const handleDelete = () => {
    setLoading(true);
    setSubmitLoading(true);
    RolesApi.deleteRole(rowId)
      .then((res) => {
        dispatch(setSnackbar({ value: true, message: res?.message, color: "success" }));
        navigate("/roles-management", { state: [] });
        setTimeout(() => setSubmitLoading(false), 300);
      })
      .catch((err) => {
        dispatch(setSnackbar({ value: true, message: err, color: "error" }));
        setLoading(false);
        setSubmitLoading(false);
      });
  };
  const checkEnableButton = () => {
    let checkStateCount = {};
    RolesState?.modules.forEach((permission) => {
      const moduleId = permission.module_id;
      if (checkStateCount[moduleId]) {
        checkStateCount[moduleId].count++;
      } else {
        checkStateCount[moduleId] = { count: 1 };
      }
    });
    const roleCard = RolesDetails?.modules?.map((card) => {
      if (checkStateCount[card?.module_id]?.count === card.subCard.length) {
        return { ...card, toggle: true };
      } else {
        return { ...card, toggle: false };
      }
    });
    setRolesDetails({ ...RolesDetails, modules: roleCard });
  };
  useEffect(() => {
    checkEnableButton();
  }, [RolesState?.modules]);
  return (
    <div className="p-4">
      <div className="mb-0 md:mb-4">
        <BreadCrumb
          BreadCrumbsListing={
            [{
              name: 'Permission',
              path: 'roles-management'
            },
            {
              name: 'Role Details',
              path: location.pathname
            }]
          }
        />
      </div>
      {loading ? (
        <SkeletonLoader
          rowCount={1}
          isTable={false}
          width={"100%"}
          height={90}
        />
      ) : (
        <div className="p-4 mt-3 rounded-xl bg-[#171928] align-items-center flex justify-content-between">
          <div className="flex flex-col gap-3">
            <Typography variant="h4" color="white">
              {RolesDetails?.title}
            </Typography>
            <div className="flex gap-3">
              {/* {!RolesDetails?.predefined_role && getUserHasAccess(userAuthority, "rolesManagement", 97) && ( */}
              {getUserHasAccess(userAuthority, "rolesManagement", 97) && (
                <Button
                  variant="contained"
                  className="bg-[#333B4A] text-[0.8rem]"
                  onClick={() => handleEdit(RolesDetails?.id)}
                  startIcon={
                    <EditIcon filled="white" Width="1rem" Height="1rem" />
                  }
                >
                  Edit
                </Button>
              )}
              {!RolesDetails?.predefined_role && getUserHasAccess(userAuthority, "rolesManagement", 99) && (
                <Button
                  variant="contained"
                  className="bg-[#333B4A] text-[0.8rem]"
                  startIcon={
                    <DeleteIcon filled="white" Width="1rem" Height="1rem" />
                  }
                  onClick={() => {
                    setRowId(RolesDetails?.id), setDeleteModal(true);
                  }}
                >
                  Delete
                </Button>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-2 align-items-center">
            <Typography
              className="text-sm md:text-base"
              variant="body2"
              color="white"
            >
              {RolesDetails?.modulesCount}
            </Typography>
            <Typography
              className="text-sm md:text-base"
              variant="body2"
              color="secondary"
            >
              Assigned Module
            </Typography>
          </div>
        </div>
      )}
      <div className="bg-white rounded-xl p-3 mt-3">
        <Typography variant="h5">Role Permissions</Typography>
        <div className="flex flex-col gap-3 pt-3">
          <RolesCard
            loading={loading}
            // disableChip={RolesDetails?.predefined_role && getUserHasAccess(userAuthority, "rolesManagement", 100)}
            disableChip={!getUserHasAccess(userAuthority, "rolesManagement", 100)}
            rolesCardData={RolesDetails?.modules}
            selected={RolesState?.modules}
            handleRootSwitch={handleRootSwitch}
            handleSwitch={handleSwitch}
          />
        </div>
        <div className="pt-3 text-left">
          {loading ? (
            <SkeletonLoader
              rowCount={1}
              isTable={false}
              width={"10%"}
              height={25}
            />
          ) : (
            // !RolesDetails?.predefined_role &&
            (
              <Button size="large" type="submit" disabled={submitLoading} onClick={() => handleUpdate()}>
                {"Update"}
              </Button>
            )
          )}
        </div>
      </div>
      <CustomSnackbar
        open={snackbar?.value}
        setOpen={dispatch(setSnackbar)}
        color={snackbar?.color}
        message={snackbar?.message}
      />
      <DeleteConfirmContent
        open={deleteModal}
        setOpen={setDeleteModal}
        handleDelete={handleDelete}
      />
    </div>
  );
};

export default RolesDetails;
