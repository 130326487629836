import { useEffect, useState } from "react";
import CustomModel from "../../components/custom-model/CustomModel";
import { addUserSchema } from "../../utils/formik/validationSchema";
import { addUserValues } from "../../utils/formik/initialValues";
import SubAdminApi from "../../services/subAdminService";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../store/reducers/snackBar";

function SubAdminEdit({
  openModal,
  setOpenModal,
  id = "",
  subAdminDetail,
  roleList,
  getSubAdmin,
}) {
  const [initialValues, setInitialValues] = useState(addUserValues);
  const [submitLoading,setSubmitLoading] = useState(false);
  const dispatch = useDispatch();
  const [modelData, setModelData] = useState({
    title: "Add User",
    editTitle: "Edit User",
    children: [
      {
        type: "dropdown",
        name: "userType",
        label: "User Type",
        placeholder: "Enter Type",
        data: roleList,
        IsRequired: true,
      },
      {
        type: "input",
        name: "name",
        label: "Name",
        placeholder: "Enter Name",
        IsRequired: true,
      },
      {
        type: "input",
        name: "email",
        label: "Email",
        placeholder: "Enter Email",
        IsRequired: true,
      },
      {
        type: "number",
        name: "phoneNumber",
        label: "Phone Number",
        placeholder: "Enter Phone Number",
        IsRequired: true,
      },
    ],
  });  

  useEffect(() => {
    setInitialValues({
      userType: subAdminDetail?.role_id,
      name: subAdminDetail?.name,
      email: subAdminDetail?.email,
      phoneNumber: subAdminDetail?.phone,
      selectTrial: subAdminDetail?.phase_id,
    });
  }, [subAdminDetail]);

  useEffect(() => {
    const newChildren = [
      {
        type: "input",
        name: "name",
        label: "Name",
        placeholder: "Enter Name",
        IsRequired: true,
      },
      {
        type: "number",
        name: "phoneNumber",
        label: "Phone Number",
        placeholder: "Enter Phone Number",
        IsRequired: true,
      },
    ];

    if (id == "") {
      newChildren.push({
        type: "input",
        name: "email",
        label: "Email",
        placeholder: "Enter Email",
        IsRequired: true,
      });
      newChildren.unshift({
        type: "dropdown",
        name: "userType",
        label: "Onboard Users Type",
        placeholder: "Enter Type",
        data: roleList,
        IsRequired: true,
      })
    } else {
      newChildren.push({
        type: "input",
        name: "email",
        label: "Email",
        placeholder: "Enter Email",
        IsRequired: true,
        disabled: true,
      });
      newChildren.unshift({
        type: "dropdown",
        name: "userType",
        label: "Onboard Users Type",
        placeholder: "Enter Type",
        data: roleList,
        IsRequired: true,
        disabled: true,
      })
    }
    setModelData((prevModelData) => ({
      ...prevModelData,
      children: newChildren,
    }));
  }, [id]);

  const handleEditModelValues = (values) =>{
    setOpenModal(false);
    setSubmitLoading(false);
    SubAdminApi.editSubAdmin(
      {
        name: values?.name,
        email: values?.email,
        country_code: "91",
        phone: values?.phoneNumber?.toString(),
        role_id: values?.userType,
      },
      id
    )
      .then((res) => {
        if (res.status === 200) {
          setSubmitLoading(false);
          getSubAdmin();
          dispatch(setSnackbar({ value: true, message: res.message, color:"success" }));
        }
      })
      .catch((error) => {
        dispatch(setSnackbar({ value: true, message: error, color: "error" }));
      });}
  return (
    <>
      <CustomModel
        rowId={id}
        modalInitialValues={initialValues}
        initialValuesValidationSchema={addUserSchema}
        open={openModal}
        setOpen={setOpenModal}
        setModelData={setModelData}
        modelData={modelData}
        handleModelValues={handleEditModelValues}
        isLoadingBtn={submitLoading}
      />
    </>
  );
}

export default SubAdminEdit;
