import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { DownIcon, InfoIcon } from '../../assets/common-svg/CommonSvg';

export default function CustomAccordion({
  rootDivClass,
  accordionClassName,
  backgroundColor,
  index,
  color,
  leftBorder,
  type,
  expanded,
  handleExpansion,
  style,
  title,
  status,
  children
}) {
  return (
    <div className={rootDivClass} style={style}>
      <Accordion
        className={accordionClassName}
        expanded={expanded}
        onChange={() => handleExpansion(index)}
        sx={{
          style,
          borderLeft: leftBorder ? `4px solid ${color}` : "none",
        }}
      >
        <AccordionSummary
          sx={{ backgroundColor: backgroundColor }}
          expandIcon={<DownIcon />}
          aria-controls={`panel1-content${index}`}
          id={`panel1-header${index}`}
        >
          <Typography className='flex flex-col gap-2' >
            <span className='font-bold text-[0.9rem]'>{title}</span>
            <p className='flex gap-2'>
              {status?.map((op, index) => (
                <span key={index} className='flex gap-3' style={{ color: op?.color }}>{op?.icon}{op?.text}</span>
              ))}
              {type == "draft" && <span className='flex gap-3 text-[#808080] ml-2' ><InfoIcon Height={20} Width={20} Filled="#808080" />{" Save as draft"}</span>}
            </p>
          </Typography>
        </AccordionSummary>
        <AccordionDetails className='border-t-2 border-gray-200' style={{ height: expanded ? 'auto' : 0, overflow: 'hidden' }}>
          <Typography>
            {children}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div >
  );
}
