import moment from "moment";
import { RightArrowIcon } from "../../assets/images/drawerIcons/RightArrow";

export const FormValues = ({
  rightValue,
  rightLable,
  leftLable,
  leftValue,
}) => {
  const checkValueAndReturn = (value) => {
    var isDate = new Date(value) !== "Invalid Date" && !isNaN(new Date(value));

    if (isDate) {
      return moment(value, "YYYY/MM/DD").format("DD-MM-YYYY"); // true
    } else {
      return value;
    }
  };

  return (
    <div className="flex justify-around p-3 text-center">
      <div className="w-100">
        <div className="text-zinc-500 text-sm font-normal font-['Public Sans'] pb-1">
          {leftLable}
        </div>
        <div className="px-2 py-1 bg-orange-400 rounded justify-center items-start gap-2.5 inline-flex">
          <div className="text-white text-sm font-normal font-['Public Sans'] break-all">
            {checkValueAndReturn(leftValue)}
          </div>
        </div>
      </div>
      <div className="self-center text-center">
        <RightArrowIcon width={150} height={12} />
      </div>
      <div className="w-100">
        <div className="text-zinc-500 text-sm font-normal font-['Public Sans'] pb-1">
          {rightLable}
        </div>
        <div className="px-2 py-1 bg-emerald-500 rounded justify-center items-start gap-2.5 inline-flex">
          <div className="text-white text-sm font-normal font-['Public Sans'] break-all">
            {checkValueAndReturn(rightValue)}
          </div>
        </div>
      </div>
    </div>
  );
};
