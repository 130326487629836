import httpService from "./httpService";

const HospitalApi = {

    getHospitalData: (limit, page, search, isList) => httpService.get('hospitalList', {
        params: {
            ...(limit ? { limit } : {}),
            page: page,
            searchData: search,
            ...(isList ? { isList } : {}),
        }
    }),
    getAllState: () => httpService.get('stateList'),
    getCityByState: (id) => httpService.get(`cityList?searchData=&state_id=${id}`),
    addHospital: (data) => httpService.post(`addHospital`, data),
    editHospital: (data, id) => httpService.post(`updateHospital/${id}`, data),
    deleteHospital: (id) => httpService.delete(`deleteHospital/${id}`)


}

export default HospitalApi;