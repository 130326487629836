import React, { useEffect, useState } from "react";
import MessageBox from "./MessageBox";
import { useDispatch, useSelector } from "react-redux";
import CustomSearchbar from "../../components/custom-searchbar/CustomSearchbar";
import { useSocketInstance } from "../../context/SocketContext";
import { queryMessage } from "../../utils/formik/initialValues";
import { queryMessageValidationSchema } from "../../utils/formik/validationSchema";
import CustomModel from "../../components/custom-model/CustomModel";
import { setSnackbar } from "../../store/reducers/snackBar";
import EditorApi from "../../services/editorManagementService";
import { CustomSnackbar } from "../../components/snackbar/CustomSnackbar";
import ChatApi from "../../services/chatService";
import moment from "moment";
import ChatDetail from "../../components/chat-details/chat";
import SkeletonLoader from "../../utilites/SkeltonLoader";
import { BackNavIcon } from "../../assets/common-svg/CommonSvg";
import { useNavigate } from "react-router";
import NoDataCard from "../../components/no-data-card";
const Message = () => {
  const { drawerOpen } = useSelector((state) => state.menu);
  const loginID = useSelector((state) => state.loginData.loginId);
  const dispatch = useDispatch();
  const snackbar = useSelector((state) => state.snackBar);
  const [showQueryModal, setShowQueryModal] = useState(false);
  const [showMessageBox, setShowMessageBox] = useState(false);
  const { socketInstance } = useSocketInstance();
  const [submitLoading] = useState(false);
  const [chatList, setChatList] = useState([]);
  const [activeMessageId, setActiveMessageId] = useState(null);
  const [, setActiveIndex] = useState(0);
  const [headerData, setHeaderData] = useState([]);
  const [messageData, setMessageData] = useState([]);
  const [socketData, setSocketData] = useState({});
  const [stepTitles, setStepTitles] = useState([]);
  const [isQueryRaisedInChat, setIsQueryRaisedInChat] = useState(false);
  const [phaseList, setPhaseList] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [formList, setFormList] = useState([]);
  const [listLoading, setListLoading] = useState(false);
  const [detailLoading, setDetailLoading] = useState(false);
  const { setMessageRead } = useSocketInstance();
  const [isQueryRaised, setIsQueryRaised] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [totalCountMessage, setTotalMessageCount] = useState(null);
  const [modelData, setModelData] = useState({
    title: "Form Related Query",
    children: [
      {
        type: "dropdown",
        name: "phase",
        label: "Phase",
        placeholder: "Select Phase",
        data: phaseList,
        IsRequired: true,
      },
      {
        type: "dropdown",
        name: "template",
        label: "Form Builder",
        placeholder: "Select Form",
        data: templateList,
        IsRequired: true,
      },
      {
        type: "dropdown",
        name: "formField",
        label: "Form Field",
        placeholder: "Select Form Field",
        data: formList,
        IsRequired: true,
      },
    ],
  });

  useEffect(() => {
    MessageList();
  }, [activeMessageId, activePage]);

  const handleShowQuerModal = () => {
    if (!isQueryRaisedInChat) {
      setShowQueryModal(true);
    } else {
      dispatch(
        setSnackbar({
          value: true,
          message:
            "You can't create a query because there is already one ongoing.",
          color: "error",
        })
      );
    }
  };
  const readMessage = async (loginID, renderId) => {
    try {
      const res = await ChatApi.readMessage(loginID, renderId);
      setMessageRead(res?.data?.totalUnreadChats > 0);
    } catch (error) {
      console.error("Error reading message:", error);
    }
  };
  const handleChatBack = () => {
    setShowMessageBox(!showMessageBox);
  }
  const handleShowMessageBox = async (id, index, message_count) => {
    if(id !== activeMessageId){
      setMessageData([])
      setActivePage(1)
    }
    
    try {
      const Data = chatList.find((chat) => chat.id === id);
      setHeaderData(Data);
      setShowMessageBox(!showMessageBox);
      setActiveMessageId(id);

      // Toggle the message box visibility
      setActiveIndex(index);
      if (message_count > 0) {
        // Mark the message as read
        readMessage(loginID, id);
      }
      setChatList((prev) => {
        const newState = [...prev];
        const userIndex = newState.findIndex(
          (item) => item.id === loginID || item.id === id
        );
        if (userIndex > -1) {
          newState[userIndex] = {
            ...newState[userIndex],
            message_count: 0,
          };
        }
        return newState;
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getChatList();
    getPhaseTypeDropDown();
  }, []);

  const getPhaseTypeDropDown = async () => {
    try {
      const result = await EditorApi.getPhaseDropDown();
      const data = result?.data?.map((val) => ({
        id: val.id,
        name: val?.product_details?.name+" : "+val.title,
      }));
      setPhaseList(data);
      setModelData((prevState) => ({
        ...prevState,
        children: prevState.children.map((child) =>
          child.name === "phase" ? { ...child, data: data } : child
        ),
      }));
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));
    }
  };

  const getFormTemplateDropDown = async (phaseId) => {
    try {
      const result = await ChatApi.getFormTemplateList(phaseId);
      const data = result?.data?.map((val) => ({
        id: val.id,
        name: val.title,
      }));
      setTemplateList(data);
      setModelData((prevState) => ({
        ...prevState,
        children: prevState.children.map((child) =>
          child.name === "template" ? { ...child, data: data } : child
        ),
      }));
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));
    }
  };

  const getFormFieldDropDown = async (form_template_id) => {
    try {
      const result = await ChatApi.getFormFieldList(form_template_id);
      const data = result?.data?.map((val) => ({
        id: val.id,
        name: val.label,
      }));
      setFormList(data);
      setModelData((prevState) => ({
        ...prevState,
        children: prevState.children.map((child) =>
          child.name === "formField" ? { ...child, data: data } : child
        ),
      }));
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));
    }
  };

  const getChatList = async (searchValue) => {
    setListLoading(true);
    try {
      const result = await ChatApi.getChatListData(searchValue);
      setChatList(
        result?.data?.sort(
          (a, b) => new Date(a?.created_at) - new Date(b?.created_at)
        )
      );
      setListLoading(false);
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));
      setListLoading(false);
    }
  };

  const MessageList = async () => {
    setDetailLoading(true);
    try {
      if(loginID && activeMessageId){
        const result = await ChatApi.getMessageListNew(loginID, activeMessageId, activePage, 10);
        const data = result?.data || []
        setDetailLoading(false);
        const dataNew = [...messageData, ...data]
        setMessageData(dataNew);
        setTotalMessageCount(result?.page_details?.no_of_records)
        setIsQueryRaised(result?.is_query_raised);
        setIsQueryRaisedInChat(result?.is_query_raised_in_chat);
      }
      
    } catch (error) {
      setDetailLoading(false);
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));
    }
  };

  const handleQueryModelValues = async (values) => {
    const phase = phaseList?.find((ele) => ele.id === values?.phase);
    const template = templateList?.find((ele) => ele.id === values?.template);
    const form = formList?.find((ele) => ele.id === values?.formField);
    try {
      await ChatApi.saveQuery(
        phase,
        template,
        form,
        loginID,
        activeMessageId
      );
      setStepTitles([phase.name, template.name, form.name]);
      emitMessage();
      setShowQueryModal(false);
      messageData.length = 0;
      MessageList();
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));
    }
  };

  const handleSearchValue = (e) => {
    if (e !== " ") {
      getChatList(e);
    }
  };

  const emitMessage = () => {
    socketInstance.emit("send_message", {
      sender_id: loginID,
      receiver_id: activeMessageId,
      message: "",
      message_type: "text"
    });
  };

  function isValidURL(url) {
    // Regular expression for a URL
    const urlRegex = /^(?:http|https):\/\/(?:\w+:{0,1}\w*@)?(?:\S+)(?:[a-z0-9\-._~:/?#[\]@!$&'()*+,;=%]*)$/i;
    return urlRegex.test(url);
  }


  useEffect(() => {
    const socketValue = Object.values(socketData)?.[0]?.[0];
    if ((loginID == socketValue?.sender_id || loginID == socketValue?.receiver_id) && (activeMessageId == socketValue?.sender_id || activeMessageId == socketValue?.receiver_id)) {
      socketValue?.conversation_type === "General Conversation"
        ? setIsQueryRaisedInChat(false)
        : setIsQueryRaisedInChat(true);
    }
    let nameN = isValidURL(socketValue?.message)
      ? socketValue?.message.split('/').pop()
      : socketValue?.message;
    setChatList((prev) => {
      const newState = [...prev];
      const userIndex = newState.findIndex(
        (item) =>
          item.id === socketValue?.sender_id ||
          item.id === socketValue?.receiver_id
      );

      if (userIndex > -1) {
        if (
          loginID !== socketValue?.sender_id &&
          activeMessageId !== socketValue?.sender_id &&
          loginID === socketValue?.receiver_id
        ) {

          newState[userIndex] = {
            ...newState[userIndex],
            last_message: nameN || "",
            last_message_time: socketValue?.created_at || "",
            message_count: socketValue?.messageCount || 0,
          };
          setMessageRead(socketValue?.messageCount > 0);
        } else if (
          loginID === socketValue?.receiver_id ||
          loginID === socketValue?.sender_id
        ) {
          newState[userIndex] = {
            ...newState[userIndex],
            last_message: nameN || "",
            last_message_time: socketValue?.created_at || "",
          };
        }
      }

      return newState?.sort(
        (a, b) =>
          new Date(b?.last_message_time) - new Date(a?.last_message_time)
      );
    });
  }, [socketData]);

  const handleModelData = (e, name) => {
    if (name == "phase") {
      getFormTemplateDropDown(e);
      setFormList([]);
      setModelData((prevState) => ({
        ...prevState,
        children: prevState.children.map((child) =>
          child.name === "formField" ? { ...child, data: [] } : child
        ),
      }));
    } else {
      getFormFieldDropDown(e);
    }
  };

  const handleResolveQuery = async () => {
    try {
      const result = await ChatApi.resolvedQueryChat(loginID, activeMessageId);
      emitMessage();
      messageData.length = 0;
      MessageList();
      dispatch(
        setSnackbar({
          value: true,
          message: result.message,
          color: "success",
        })
      );
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));
    }
  };
  const role_id = useSelector((state) => state.loginData.role_id);
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/phases');
  }
  return (
    <div className="p-[20px_15px_20px_15px]  ">
      {role_id === 5 &&
        <div className="cursor-pointer flex items-center mb-3 gap-1" onClick={handleNavigate}>
          <div className="block p-2 w-max">
            <BackNavIcon width={18} height={18} />
          </div>
          <span className="!m-0">Go Back</span>
        </div>
      }
      <div className="grid grid-cols-12 gap-[15px]">
        {/* left area */}
        <div
          className={`left-area ${showMessageBox ? "hidden lg:flex" : ""
            } flex flex-col ${drawerOpen ? "col-span-4" : "col-span-12 lg:col-span-3"
            }  p-[15px] gap-[15px] overflow-auto scroll-smooth bg-white h-auto max-h-[calc(100vh-108px)] shadow-md `}
        >
          <CustomSearchbar
            handleSearch={handleSearchValue}
            Placeholder="Search by Name"
            BgColor="secondary"
            BorderRadius="6px"
          />
          {listLoading ? (
            <div className="col-start-1 col-end-4 ">
              <SkeletonLoader rowCount={5} isTable={false} height={120} />
            </div>
          ) : (
            chatList?.length > 0 ? chatList?.map((item, index) => (
              <MessageBox
                key={item?.id}
                handleShowMessageBox={() =>
                  handleShowMessageBox(item?.id, index, item?.message_count)
                }
                isActive={item?.id === activeMessageId}
                date={
                  item?.last_message_time
                    ? moment(item?.last_message_time).format("DD/MM/YYYY")
                    : ""
                }
                initials={
                  item.name
                    ? item.name
                      .split(" ")
                      .map((name) => name[0])
                      .join("")
                      .slice(0, 2)
                      .toUpperCase()
                    : ""
                }
                name={item?.name}
                messageContent={isValidURL(item?.last_message)
                  ? item?.last_message.split('/').pop()
                  : item?.last_message}
                query={item?.role_details?.title}
                color="warning"
                count={item?.message_count}
                item={item}
              />
            )) : <NoDataCard />
          )}
        </div>
        {/* right area */}
        <ChatDetail
          showMessageBox={showMessageBox}
          handleShowMessageBox={handleChatBack}
          drawerOpen={drawerOpen}
          handleShowQuerModal={handleShowQuerModal}
          headerData={headerData}
          messageData={messageData}
          senderId={loginID}
          receiverId={activeMessageId}
          setSocketData={setSocketData}
          stepTitles={stepTitles}
          isQueryRaised={isQueryRaised}
          handleResolveQuery={handleResolveQuery}
          isQueryRaisedInChat={isQueryRaisedInChat}
          detailLoading={detailLoading}
          readMessage={readMessage}
          setActivePage={setActivePage}
          totalCountMessage={totalCountMessage}
          setMessageData={setMessageData}
        />
      </div>
      <CustomModel
        modalInitialValues={queryMessage}
        initialValuesValidationSchema={queryMessageValidationSchema}
        open={showQueryModal}
        setOpen={setShowQueryModal}
        setModelData={setModelData}
        modelData={modelData}
        handleModelValues={handleQueryModelValues}
        onChange={handleModelData}
        isLoadingBtn={submitLoading}
      />
      <CustomSnackbar
        open={snackbar?.value}
        setOpen={setSnackbar}
        color={snackbar?.color}
        message={snackbar?.message}
      />
    </div>
  );
};

export default Message;
