import { Button, Typography } from "@mui/material";
import CustomInputField from "../../components/custom-input/CustomInput";
import { useLocation, useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import { Formik } from "formik";
import { rolesValidationSchema } from "../../utils/formik/validationSchema";
import { RolesCard } from "../../components/custom-card/RolesCard";
import RolesApi from "../../services/rolesManagemant";
import { CustomSnackbar } from "../../components/snackbar/CustomSnackbar";
import SkeletonLoader from "../../utilites/SkeltonLoader";
import { setSnackbar } from "../../store/reducers/snackBar";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/BreadCrumb";
import { getUserHasAccess } from "../../utils/authorities";

const RolesModules = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();
  const LoginData = useSelector((state) => state.loginData);
  const userAuthority = LoginData?.auth || [];
  const snackbar = useSelector((state) => state.snackBar);
  const [permissionError, setPermissionError] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rolesState, setRolesState] = useState({
    title: "",
    description: "",
    modules: [],
  });
  const [rolesCardData, setRolesCardData] = useState([
    {
      title: "All Permissions",
      required: true,
      toggle: false,
      subCard: [],
    },
  ]);
  const [allModules, setModules] = useState([]);
  const roleEditId = id;


  const setEditValues = (id) => {
    RolesApi.getRoleDetails(id)
      .then((res) => {
        res = res?.data;
        setRolesState({
          title: res?.title,
          disableChip: res?.predefined_role,
          description: res?.description,
          modules: res?.module_details.map((op) => {
            return {
              id: op?.module_id,
              module_id: op?.id,
              name: op?.description,
              label: op?.module_name,
            };
          }),
        });
        console.log(allModules,"allModulesallModules");
      })
      .catch((err) => {
        dispatch(setSnackbar({ value: true, message: err, color: "error" }));
      });
  };

  const getModules = () => {
    const s1 = [...rolesCardData];
    RolesApi.getAllModules()
      .then((res) => {
        res = res?.data;
        let modulesOperations = []
        res.map((op) => {
          modulesOperations.push({
            id: op?.module_id,
            module_id: op?.id,
            name: op?.description,
            label: op?.module_name,
          })
        });
        s1[0].subCard = modulesOperations;
        setTimeout(() => {
          setModules(modulesOperations);
          setRolesCardData(s1);
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        setLoading(false);
        dispatch(setSnackbar({ value: true, message: err, color: "error" }));
      });
  };

  useEffect(() => {
    setLoading(true);
    getModules();
    if (roleEditId) {
      setEditValues(roleEditId);
    }
  }, []);

  const handleSwitch = (e, index) => {
    const { checked } = e.target;
    let modules = [...rolesState.modules];
    if (checked) {
      modules = [...modules, allModules[index]];
    } else {
      modules = modules.filter((op) => op.id !== allModules[index]?.id);
    }
    modules?.length == 0
      ? setPermissionError("Please select at least one permission")
      : setPermissionError("");

    setRolesState({ ...rolesState, modules: modules });
  };

  const handleRootSwitch = (e) => {
    const { checked } = e.target;
    const roleCard = rolesCardData.map((card, index) => {
      if (index === 0) {
        return { ...card, toggle: checked };
      }
      return card;
    });
    if (checked) {
      setPermissionError("");
      setRolesState({ ...rolesState, modules: allModules });
    } else {
      setRolesState({ ...rolesState, modules: [] });
      setPermissionError("Please select at least one permission");
    }
    setRolesCardData(roleCard);
  };

  const createRole = (value) => {
    const rawData = {
      title: value?.title,
      description: value?.description,
      moduleIds: rolesState?.modules?.map((op) => `${op?.module_id}`),
    };
    if (rawData?.moduleIds?.length == 0) {
      setPermissionError("Please select at least one permission");
      setSubmitLoading(false);
    } else {
      setPermissionError("");
      RolesApi.createRole(rawData)
        .then((res) => {
          if (res?.status == 200) {
            dispatch(setSnackbar({ value: true, message: res?.message, color: "success" }));
            setTimeout(() => {
              navigate("/roles-management", { state: [] });
            }, 1000);
          } else {
            dispatch(
              setSnackbar({ value: true, message: res?.error, color: "error" })

            );
          }
        })
        .catch((err) => {
          dispatch(setSnackbar({ value: true, message: err, color: "error" }));
        })
        .finally(setTimeout(() => setSubmitLoading(false), 1000))
    }
  };
  const editRole = (value) => {
    const rawData = {
      title: value?.title,
      description: value?.description,
      moduleIds: rolesState?.modules?.map((op) => `${op?.module_id}`),
    };
    if (rawData?.moduleIds?.length == 0) {
      setPermissionError("Please select at least one permission");
      setSubmitLoading(false);
    } else {
      setPermissionError("");
      RolesApi.updateRoles(rawData, roleEditId)
        .then((res) => {
          dispatch(setSnackbar({ value: true, message: res?.message, color: "success" }));
          setTimeout(() => {
            navigate("/roles-management", { state: [] });
          }, 1000);
        })
        .catch((err) => {
          dispatch(setSnackbar({ value: true, message: err, color: "error" }));
        })
        .finally(setTimeout(() => {
          setSubmitLoading(false);
        }, 1200))
    }
  };
  const handleSubmit = (values) => {
    if (submitLoading) {
      return;
    }
    setSubmitLoading(true);
    if (roleEditId) {
      editRole(values);
    } else {
      createRole(values);
    }
  };
  const checkEnableButton = () => {
    console.log(allModules, rolesState?.modules, "rolesCardData[0].subCard?.length == rolesState?.modules?.length");
    if (rolesCardData[0].subCard?.length == rolesState?.modules?.length) {
      const roleCard = rolesCardData.map((card) => {
        return { ...card, toggle: true };
      });
      setRolesCardData(roleCard);
    } else {
      const roleCard = rolesCardData.map((card) => {
        return { ...card, toggle: false };
      });
      setRolesCardData(roleCard);
    }
  };
  useEffect(() => {
    checkEnableButton();
  }, [rolesState?.modules,allModules]);

  return (
    <div className="!p-6">
      <div className="mb-0 md:mb-4">
        <BreadCrumb
          BreadCrumbsListing={[
            {
              name: 'Permission',
              path: 'roles-management'
            },
            {
              name: (location.pathname == '/roles-management/add-modules-management') ? 'Add Role' : 'Edit Role',
              path: location.pathname
            }
          ]}
        />
      </div>
      <div className="!p-4 mt-3 bg-white flex flex-col gap-4">
        <Typography variant="h5">Basic Details</Typography>
        <Formik
          initialValues={{
            description: rolesState?.description,
            title: rolesState?.title,
          }}
          enableReinitialize={true}
          validationSchema={rolesValidationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <form
              onSubmit={handleSubmit}
              className={`flex flex-col gap-6`}
            >
              {loading ? (
                <SkeletonLoader
                  rowCount={1}
                  isTable={false}
                  width={"100%"}
                  height={30}
                />
              ) : (
                <CustomInputField
                  htmlFor="title"
                  InputType="text"
                  CustomClassName="bg-[#EDEDED] text-[#777777] font-normal text-[16px] rounded-[5px]"
                  placeholder="Enter Title"
                  label="Title"
                  InputId="title"
                  InputName="title"
                  iconPosition={"end"}
                  IsRequired={true}
                  value={values?.title}
                  disabled={rolesState?.disableChip}
                  MultiLine={true}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.title && errors.title)}
                  touched={touched.title}
                  helperText={errors.title}
                  Width={"100%"}
                  Size={"small"}
                />
              )}
              {loading ? (
                <SkeletonLoader
                  rowCount={1}
                  isTable={false}
                  width={"100%"}
                  height={30}
                />
              ) : (
                <CustomInputField
                  htmlFor="description"
                  label="Description"
                  customDivClass="!mb-8"
                  IsRequired={true}
                  fullWidthBoolean={true}
                  InputId="description"
                  InputName="description"
                  InputType="text"
                  value={values.description}
                  // disabled={rolesState?.disableChip}
                  spacing={1}
                  onChange={handleChange}
                  error={Boolean(touched.description && errors.description)}
                  MultiLine={true}
                  NoOfRows={5}
                  onBlur={handleBlur}
                  CustomClassName="bg-[#F0F1F5] text-[black] font-normal text-[18px]"
                  placeholder="Enter here"
                  touched={touched.description}
                  helperText={errors.description}
                  LabelClasses="text-[16px] text-[#000000]"
                />
              )}
              <Typography variant="h5">Permission Details</Typography>
              {loading ? (
                <SkeletonLoader
                  rowCount={1}
                  isTable={false}
                  width={"100%"}
                  height={100}
                />
              ) : (
                <RolesCard
                  error={permissionError}
                  disableChip={!getUserHasAccess(userAuthority, "rolesManagement", 100)}
                  rolesCardData={rolesCardData}
                  selected={rolesState.modules}
                  handleSwitch={handleSwitch}
                  handleRootSwitch={handleRootSwitch}
                />
              )}
              {loading ? (
                <SkeletonLoader
                  rowCount={1}
                  isTable={false}
                  width={"10%"}
                  height={25}
                />
              ) : (
                // !rolesState?.disableChip && (
                <>
                  {roleEditId && getUserHasAccess(userAuthority, "rolesManagement", 97) &&
                    <Button disabled={submitLoading} fullWidth={false} size="large" type="submit">
                      Update
                    </Button>} {!roleEditId && getUserHasAccess(userAuthority, "rolesManagement", 96)
                      && <Button disabled={submitLoading} fullWidth={false} size="large" type="submit">
                        Submit
                      </Button>}
                </>
                // )
              )}
            </form>
          )}
        </Formik>
        <CustomSnackbar
          open={snackbar?.value}
          setOpen={dispatch(setSnackbar)}
          color={snackbar?.color}
          message={snackbar?.message}
        />
      </div>
    </div>
  );
};

export default RolesModules;
