import httpService from "./httpService";

const DiseaseManagementApi = {
    getDiseaseManagementData: (limit, page, search) => httpService.get('diseasesList', {
        params: {
            limit,
            page: page,
            searchData: search,
        }
    }),
    addDisease: (data) => httpService.post(`addDisease`, data),
    updateDisease: (data, id) => httpService.post(`updateDisease/${id}`, data),
    deleteDisease: (id) => httpService.delete(`deleteDisease/${id}`)
}

export default DiseaseManagementApi;