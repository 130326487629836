import httpService from "./httpService";

const ChatApi = {
    getChatListData: (searchData) => httpService.get(`chatList`, {
        params: {
            searchData
        }
    }),
    getMessageList: (sender_id, receiver_id) => httpService.post(`messageList`, {
        sender_id,
        receiver_id
    }),
    getMessageListNew: (sender_id, receiver_id, page, limit) => httpService.post(`messageListNew?page=${page}&limit=${limit}`, {
        sender_id,
        receiver_id
    }),
    readMessage: (sender_id, receiver_id) => httpService.post(`readAllMessage`, {
        sender_id,
        receiver_id
    }),
    messageStatus: () => httpService.get(`messageStatus`),
    getFormTemplateList: (phase_id) => httpService.post(`formTemplateDropDown?phase_id=${phase_id}`),
    getFormFieldList: (form_template_id) => httpService.get(`questionsDropDown`, {
        params: {
            form_template_id
        }
    }),
    saveQuery: (phase, form_template, question, sender_id, receiver_id) => httpService.post(`saveQueryChats`, {
        phase,
        form_template,
        question,
        sender_id,
        receiver_id
    }),
    resolvedQueryChat: (sender_id, receiver_id) => httpService.post(`resolvedQueryChat`, {
        sender_id,
        receiver_id
    }),
    fileUploadChat: (formData) => httpService.post(`uploadFileInChat`,  formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
          }
    }),
}

export default ChatApi;