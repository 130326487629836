import React, { useCallback, useEffect, useState } from "react";
import Loadable from "../../components/Loadable";
import {
  DocumentIcon,
  InvestigatorIcon,
  PersonSvg,
} from "../../assets/common-svg/CommonSvg";
import { CustomChipCard } from "../../components/custom-product-cards/custom-chip-card/CustomChipCard";
import { CustomWrapperCard } from "../../components/custom-product-cards/custom-wrapper-card/CustomWrapperCard";
import CustomDetailComponent from "../../components/doctor-detail-component/DocotorDetail";
import TrialInfoComponent from "../../components/trial-info-component/TrialInfoComponent";
import CustomTable from "../../components/custom-table/CustomTable";
import { useLocation, useNavigate, useParams } from "react-router";
import BreadCrumb from "../breadcrumb/BreadCrumb";
import { Typography, useTheme } from "@mui/material";
const TaskManagementHeader = Loadable(
  React.lazy(() =>
    import("../../components/task-management-header/TaskManagementHeader")
  )
);
import SkeletonLoader from "../../utilites/SkeltonLoader";
import DeleteConfirmContent from "../../components/modal-contents/DeleteConfirmModalContent";
import { AddPhase, ReAssignPiEditor } from "../../utils/formik/initialValues";
import { ReAssignValidationSchema, addFormExportSchema, addPhaseValidationSchema } from "../../utils/formik/validationSchema";
import CustomModel from "../../components/custom-model/CustomModel";
import { productDetailsData } from "../../utils/tableData";
import api from "../../services/request";
import SkeltonLoaderHeader from "../../utilites/SkeltonLoaderHeader";
import NoDataCard from "../../components/no-data-card.js";
import { useDispatch, useSelector } from "react-redux";
import {
  setFormTemplateDropList,
  setIAQA,
  setPICoordinator,
  setPhaseType,
  setTrialManager,
} from "../../store/reducers/sub-admin-dropdown.js";
import SubjectApi from "../../services/subjectService.js";
import { CustomSnackbar } from "../../components/snackbar/CustomSnackbar.js";
import { setSnackbar } from "../../store/reducers/snackBar.js";
import { setPhaseDetail } from "../../store/reducers/phase-reducer.js";
import moment from "moment";
import PhaseApi from "../../services/phase.js";
import SubAdminApi from "../../services/subAdminService.js";
import { AddNewSubjectPhase, exportFormCsv } from "../../utils/formik/initialValues";
import { addNewSubjectPhaseValidationSchema } from "../../utils/formik/validationSchema";
import DiseaseManagementApi from "../../services/diseaseService";
import { GenderList } from "../../utils/common";
import ProductApi from "../../services/productService.js";
import AddPiAndEditorForAllModalContent from "../../components/modal-contents/AddPiAndEditorForAllModalContent .js";
import { useSearchParams } from "react-router-dom";
import { downloadCsv, jsonToCsv } from "../../utils/utils.js";
import { isEmpty } from "lodash";

export const convertSubjectData = (data) => {
  return data?.map((sub) => {
    let transformCode = sub?.subject_code;
    let transformId = sub?.id;
    let transformedSubjectInitial = sub?.first_name + " " + sub?.last_name || "";
    let transformeInvestigator = sub?.investigatorDetails?.name || "Not Assigned";
    let transformedEditor = sub?.editorDetails?.name || "Not Assigned";
    let gender = sub?.gender;
    let hospitalName = sub?.hospitalDetails?.name;
    return [
      transformCode,
      transformedSubjectInitial,
      transformeInvestigator,
      transformedEditor,
      gender,
      hospitalName,
      transformId,
    ];
  });
};

export const ProductDetails = () => {
  const snackbar = useSelector((state) => state.snackBar);
  const {  role_id } = useSelector((state) => state.loginData);
  const [activeTab, setActiveTab] = useState(null);
  const { pICoordinator, trialManager, iAQA, phaseType, formTemplateDropList } =
    useSelector((state) => state?.subAdminDropdown);
  const [phaseDetailLoading, setPhaseDetailLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAddTrailPhaseModal, setOpenAddTrailPhaseModal] = useState(false);
  const [phaseListData, setPhaseListData] = useState(null);
  const [phaseListDetails, setPhaseListDetails] = useState({});
  const [phaseListLoading, setPhaseListLoading] = useState(false);
  const [assigningPiLoader, setAssignPiLoader] = useState(false);
  const [openAssignForAllModal, setOpenAssignForAllModal] = useState(false);
  const [sumbitPiEditorValues, setSubmitPiEditorValues] = useState({});
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isAddPrLoading, setIsAddPrLoading] = useState(false);
  const [isAddSubjectLoading, SetIsAddSubjectLoading] = useState(false);
  const [editorList, setEditorList] = useState([]);
  const [subjectData, setSubjectData] = useState(productDetailsData);
  const [tableFilters, setTablesFilters] = useState({ searchData: "" });
  const [totalPage, setTotalPage] = useState(1);
  const [subjectListData, setSubjectListData] = useState([])
  const [initialValues, setInitialValues] = useState(ReAssignPiEditor);
  const [currentPage, setCurrentpage] = useState(1);
  const [pIList, setPIList] = useState([]);
  const [diseaseList, setDiseaseList] = useState([])
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [piId, setPiID] = useState(null);
  const [productDetails, setProductDetails] = useState(null);
  const [editorId, setEditorId] = useState(null);
  const [openAddSubjectModal, setOpenAddSubjectModal] = useState(false);
  const [rowId, setRowId] = useState(null);
  const [openExportModal, setOpenExportModal] = useState(false)
  const [disableButton, setDisableButton] = useState(true);
  const [searchParams] = useSearchParams();
  const [exportLoading, setExportLoading] = useState(false);
  // Get the value of a specific query parameter
  const activeTrialId = searchParams.get('phase_id');
  const [subjectModelData, setSubjectModelData] = useState({
    title: "Add New Patient",
    editTitle: "Re-Assign PI/Editor",
    children: [
      {
        type: "input",
        name: "firstName",
        label: "First Name",
        placeholder: "Enter First Name",
        IsRequired: true,
      },
      {
        type: "input",
        name: "lastName",
        label: "Last Name",
        placeholder: "Enter Last Name",
        IsRequired: true,
      },
      {
        type: "dropdown",
        name: "gender",
        label: "Gender",
        placeholder: "Select gender",
        data: [],
        IsRequired: true,
      },
      {
        type: "dropdown",
        name: "principal_investigator",
        label: "Principal Investigator",
        placeholder: "Select principal investigator",
        data: [],
        IsRequired: true,
      },
      {
        type: "dropdown",
        name: "editor_id",
        label: "Editor",
        placeholder: "Select Editor",
        data: [],
        IsRequired: true,
      },
      {
        type: "dropdown",
        name: "disease",
        label: "Disease",
        placeholder: "Select disease",
        data: [],
        IsRequired: true,
      },
      {
        type: "date",
        name: "dob",
        label: "Date of Birth",
        placeholder: "Select date",
        minDate: false,
        maxDate: new Date(),
        IsRequired: true,
      },
    ],
  });
  
  const [modelData, setModelData] = useState({
    title: "Add Phase",
    children: [
      {
        type: "input",
        name: "title",
        label: "Title",
        placeholder: "Enter Title",
        IsRequired: true,
      },
      {
        type: "dropdown",
        name: "phase_type",
        label: "Phase Type",
        placeholder: "Select Phase",
        IsRequired: true,
      },
      {
        type: "date",
        name: "phase_start_date",
        label: "Phase Start Date",
        placeholder: "Select date",
        IsRequired: true,
      },
      {
        type: "input",
        name: "minimum_subjects",
        label: "Minimum no of Patients",
        placeholder: "Enter Number",
        IsRequired: true,
      },
      {
        type: "dropdown",
        name: "coordinating_PI_id",
        label: "Medical Expert ",
        placeholder: "Select Medical Expert",
        IsRequired: true,
      },
      {
        type: "description",
        name: "description",
        label: "Description",
        placeholder: "Description",
        IsRequired: true,
      },
    ],
  });
  const [exportModelData, setExportModelData] = useState({
    title: "Export Patients Data",
    children: [
      {
        type: "multidropdown",
        name: "formId",
        id: "selectFormDp",
        label: "Select Form",
        placeholder: "Select Form",
        data: [],
        IsRequired: true,
        showSelectAll: true
      },
      // {
      //   type: "multidropdown",
      //   name: "questionId",
      //   label: "Select Questions",
      //   placeholder: "Select Questions",
      //   data: [],
      //   IsRequired: true,
      //   disabled:true,
      //   showSelectAll: true
      // },
    ],
  });

  const [formQsList, setFormQsList] = useState([])
  const location = useLocation();

  useEffect(() => {
    let newChildren = []
    if (!rowId) {
      newChildren = [
        {
          type: "input",
          name: "firstName",
          label: "First Name",
          placeholder: "Enter First Name",
          IsRequired: true,
        },
        {
          type: "input",
          name: "lastName",
          label: "Last Name",
          placeholder: "Enter Last Name",
          IsRequired: true,
        },
        {
          type: "dropdown",
          name: "gender",
          label: "Gender",
          placeholder: "Select gender",
          data: GenderList,
          IsRequired: true,
        },
        {
          type: "dropdown",
          name: "principal_investigator",
          label: "Principal Investigator",
          placeholder: "Select principal investigator",
          data: pIList,
          IsRequired: true,
        },
        {
          type: "dropdown",
          name: "editor_id",
          label: "Editor",
          placeholder: "Select Editor",
          data: [],
          IsRequired: true,
        },
        {
          type: "dropdown",
          name: "disease",
          label: "Disease",
          placeholder: "Select disease",
          data: diseaseList,
          IsRequired: true,
        },
        {
          type: "date",
          name: "dob",
          label: "Date of Birth",
          placeholder: "Select date",
          minDate: false,
          maxDate: new Date(),
          IsRequired: true,
        },
      ]
    } else {
      newChildren = [
        {
          type: "dropdown",
          name: "principal_investigator",
          label: "Principal Investigator",
          placeholder: "Select principal investigator",
          data: pIList,
          IsRequired: true,
        },
        {
          type: "dropdown",
          name: "editor_id",
          label: "Editor",
          placeholder: "Select Editor",
          data: [],
          IsRequired: true
        },
      ];
    }

    setSubjectModelData((prevState) => ({
      ...prevState,
      children: newChildren,
    }));
  }, [editorList, rowId])


  const SumbitAssignAllPiAndEditor = (action) => {
    setOpenAssignForAllModal(false);
    if (action === "YES") {
      handleUpdatePiEditor({ investigator_id: sumbitPiEditorValues?.principal_investigator, editor_id: sumbitPiEditorValues?.editor_id, phase_id: phaseListDetails?.id });
    }
    else if (action === "NO") {
      handleUpdatePiEditor({ investigator_id: sumbitPiEditorValues?.principal_investigator, editor_id: sumbitPiEditorValues?.editor_id, subject_id: rowId, phase_id: phaseListDetails?.id });

    }
  }

  React.useEffect(() => {
    if (Object.keys(sumbitPiEditorValues).length > 0) {
      setOpenAddSubjectModal(false);
      setTimeout(() => {
        setOpenAssignForAllModal(true);
      }, 200);
    }

  }, [sumbitPiEditorValues])

  const handleOpenAssignPiAndEditorModal = (values) => {
    const subData = subjectListData.find((sub) => sub.id === rowId);
    if (Object.keys(subData?.editorDetails).length > 0 && Object.keys(subData?.investigatorDetails).length > 0) {
      handleUpdatePiEditor({ investigator_id: values?.principal_investigator, editor_id: values?.editor_id, subject_id: rowId, phase_id: phaseListDetails?.id });
    } else {
      setSubmitPiEditorValues(values)
    }
  }


  const handleNavigateToDetails = useCallback((row) => {
    const UpdatedNavigationArray = location.state;
    UpdatedNavigationArray?.push({
      name: "Patient Details",
      path: `${location.pathname}/patient-details/${row[row.length - 1]}`,
    });
    navigate(`patient-details/${row[row.length - 1]}`, {
      state: UpdatedNavigationArray,
    });
  }, []);
  const handleEdit = useCallback(() => {
    const UpdatedNavigationArray = location.state;
    UpdatedNavigationArray?.push({
      name: "Edit Details",
      path: `${location.pathname}/edit-trial-details/${phaseListDetails?.id}`,
    });
    navigate(`edit-trial-details/${phaseListDetails?.id}`, {
      state: UpdatedNavigationArray,
    });
  }, [phaseListDetails?.id]);
  const handleDelete = () => {
    setOpenDeleteModal(true);
  };

  const handleOpenTrailPhaseModal = () => {
    setOpenAddTrailPhaseModal(true);
  };
  const handleOpenAddSubjectModal = () => {
    if (
      phaseListDetails?.investigator_details?.principal_investigator.length == 0
    ) {
      dispatch(
        setSnackbar({
          value: true,
          message: "You can't add patient until PI is not added for phase !",
          color: "error",
        })
      );
    } else {
      setInitialValues({
        principal_investigator: '',
        editor_id: '',
      });
      setRowId(null)
      setOpenAddSubjectModal(true);
    }
  };

  const handleAddPhase = async (values) => {
    // Normalize the array fields
    const normalizeArrayField = (field) => (field ? [field] : []);
    // Create updated values
    const updatedValues = {
      ...values,
      qa_ids: normalizeArrayField(values.qa_ids),
      manager_ids: normalizeArrayField(values.manager_ids),
      form_template_ids: normalizeArrayField(values.form_template_ids),
      phase_start_date: moment(values.phase_start_date).format("MM/DD/YYYY"),
      // phase_start_date: moment(values.phase_start_date).format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)"),
      product_id: id,
      status: 1,
    };

    try {
      setIsAddPrLoading(true);

      // API call to add phase
      const response = await PhaseApi.addPhase(updatedValues);

      await getPhaseList();
      // Dispatch success snackbar message
      dispatch(
        setSnackbar({
          value: true,
          message: response.message || "Phase added successfully",
          color: "success",
        })
      );

      // Close the modal
      setOpenAddTrailPhaseModal(false);
    } catch (error) {
      dispatch(
        setSnackbar({
          value: true,
          message: error.message || error || "Failed to add phase",
          color: "error",
        })
      );
    } finally {
      setIsAddPrLoading(false); // Reset loading state
    }
  };
  const handleAddSubject = async (values) => {
    // Normalize the array fields
    // // Create updated values
    SetIsAddSubjectLoading(true);

    const formData = new FormData();
    formData.append("first_name", values.firstName);
    formData.append("last_name", values.lastName);
    if (values.upload_file) {
      if (values.upload_file instanceof FileList) {
        for (let i = 0; i < values.upload_file.length; i++) {
          formData.append("files", values.upload_file[i]);
        }
      } else {
        formData.append("files", values.upload_file);
      }
    }
    formData.append(
      "gender",
      GenderList.find((op) => op?.id == values.gender).name
    );
    formData.append("dob", moment(values.dob).format("MM/DD/YYYY"));
    formData.append("disease_id", values.disease);
    formData.append("phase_id", phaseListDetails?.id);
    formData.append("phase_code", phaseListDetails?.phase_code);
    formData.append("phase_title", phaseListDetails?.title);
    formData.append("investigator_id", values.principal_investigator);
    formData.append("editor_id", values.editor_id);
    formData.append("product_id", id);
    try {
      // API call to add phase
      const response = await SubjectApi.addSubject(formData);

      await getSubjectList();
      getPhaseDetails(activeTab);
      // Dispatch success snackbar message
      dispatch(
        setSnackbar({
          value: true,
          message: response.message || "Patient Added Successfully",
          color: "success",
        })
      );

      // Close the modal
      setOpenAddSubjectModal(false);
    } catch (error) {
      dispatch(
        setSnackbar({
          value: true,
          message: error.message || error || "Failed to Add Patient",
          color: "error",
        })
      );
    } finally {
      SetIsAddSubjectLoading(false); // Reset loading state
    }
  };

  const handlePhaseSearch = (value) => {
    if (value !== " ") {
      getPhaseList(value);
    }
  };

  async function getPhaseList(search = "", limit = 100, page = 1) {
    setPhaseListLoading(true);
    setLoading(true);
    try {
      const res = await PhaseApi.getPhaseList(id, search, limit, page);
      setPhaseListData(res.data || []);
      if (activeTrialId) {
        setActiveTab(activeTrialId)
      } else {
        setActiveTab(res?.data && res?.data[0].id);
      }
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));

    } finally {
      setPhaseListLoading(false);
      setLoading(false);
    }
  }

  const getProductDetails = async () => {
    const res = await ProductApi.getProductDetails(id);
    setProductDetails(res?.data);
  }

  useEffect(() => {
    getPhaseList();
    getProductDetails();
  }, []);

  const handleShowAddSubjectButton = (data) => {
    setDisableButton(true)
    if (data?.form_details)
      for (const value of data.form_details) {
        if (value?.is_approved) {
          setDisableButton(false)
          return
        }
      }

  }

  async function getPhaseDetails(activeTab) {
    setPhaseDetailLoading(true);
    try {
      const res = await PhaseApi.getPhaseListDetails(activeTab);
      handleShowAddSubjectButton(res?.data);
      setPhaseListDetails(res?.data || []);
      getSubjectModelData(res?.data);
      dispatch(setPhaseDetail(res?.data));
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));
    } finally {
      setPhaseDetailLoading(false);
    }
  }


  useEffect(() => {
    setEditorId(null)
    setPiID(null)
    if (activeTab) {
      getPhaseDetails(activeTab)
    }
  }, [activeTab]);

  // Function to get Sub Admin Dropdown by ID
  const getSubAdminDropdown = async (id) => {
    try {
      return await SubAdminApi.getSubAdminDropdown(id);
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));
    }
  };

  // Function to get Phase Type Dropdown
  const getPhaseTypeDropDown = async () => {
    try {
      return await PhaseApi.getPhaseTypeDropDown();
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));
    }
  };

  // Function to get Patient model Data
  const getSubjectModelData = async (phaseRes) => {
    try {
      let desRes = await DiseaseManagementApi.getDiseaseManagementData();
      setDiseaseList(desRes?.data)
      setSubjectModelData((prevState) => ({
        ...prevState,
        children: prevState.children.map((child) =>
          child.name === "disease"
            ? { ...child, data: desRes?.data }
            : child.name == "principal_investigator"
              ? {
                ...child,
                data:
                  phaseRes?.investigator_details?.principal_investigator ?? [],
              }
              : child.name == "gender"
                ? { ...child, data: GenderList }
                : child
        ),
      }));
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));
    }
  };

  // Function to get Editor Dropdown
  const getEditorDropdown = async (Pi_id) => {
    try {
      setInitialValues({ principal_investigator: Pi_id })
      if (Pi_id) {
        let res = await ProductApi.subadminDropDown(5, Pi_id, phaseListDetails?.id);
        setSubjectModelData((prevState) => ({
          ...prevState,
          children: prevState.children.map((child) =>
            child.name === "editor_id" ? { ...child, data: res?.data } : child
          ),
        }));
      } else {
        setSubjectModelData((prevState) => ({
          ...prevState,
          children: prevState.children.map((child) =>
            child.name === "editor_id" ? { ...child, placeholder: "Select Editor", data: [] } : child
          ),
        }));
      }
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));
    }
  };

  // Function to get Form Builder Dropdown
  const getFormTemplateDropDown = async () => {
    try {
      return await api.formTemplateDropDown();
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));
    }
  };

  // Function to get Editor for Patient Sub Admin by Value and Phase List ID
  const getEditorForSubjectSubAdmin = async (value) => {
    try {
      return await SubAdminApi.getEditorForSubjectSubAdmin(
        value,
        phaseListDetails?.id
      );
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));

    }
  };

  useEffect(() => {
    const fetchSubAdminDropdownData = async () => {
      const results = await Promise.allSettled([
        getSubAdminDropdown(2),
        getSubAdminDropdown(3),
        getSubAdminDropdown(4),
        getPhaseTypeDropDown(),
        getFormTemplateDropDown(),
      ]);

      results.forEach((result, index) => {
        if (result.status === "fulfilled") {
          const data = result.value?.data || [];
          switch (index) {
            case 0:
              dispatch(setPICoordinator(data));
              break;
            case 1:
              dispatch(setIAQA(data));
              break;
            case 2:
              dispatch(setTrialManager(data));
              break;
            case 3:
              dispatch(setPhaseType(data));
              break;
            case 4:
              dispatch(setFormTemplateDropList(data));
              break;
            default:
              break;
          }
        } else {
          console.error(
            `Error fetching data for index ${index + 2}:`,
            result.reason
          );
        }
      });
    };
    fetchSubAdminDropdownData();
  }, []);

  useEffect(() => {
    if (phaseListDetails?.id) {
      if (
        tableFilters?.searchData[tableFilters?.searchData?.length - 1] != " "
      ) {
        setLoading(true);
        getSubjectList();
      }
    }
  }, [currentPage, tableFilters, phaseListDetails, piId, editorId]);

  useEffect(() => {
    const fetchEdiorData = async () => {
      const results = await Promise.allSettled([
        getEditorForSubjectSubAdmin(2),
        getEditorForSubjectSubAdmin(5),
      ]);

      results?.forEach((result, index) => {
        if (result.status === "fulfilled") {
          const data = result.value?.data || [];
          switch (index) {
            case 0:
              setPIList(data);
              break;
            case 1:
              setEditorList(data);
              break;

            default:
              break;
          }
        } else {
          console.error(
            `Error fetching data for index ${index + 2}:`,
            result.reason
          );
        }
      });
    };
    if (phaseListDetails?.id) {
      fetchEdiorData();
    }
  }, [phaseListDetails]);

  useEffect(() => {
    if (modelData?.children) {
      const mapedSelectDrop = modelData.children.map((item) => {
        switch (item.name) {
          case "qa_ids":
            return { ...item, data: iAQA };
          case "coordinating_PI_id":
            return { ...item, data: pICoordinator };
          case "manager_ids":
            return { ...item, data: trialManager };
          case "phase_type":
            return { ...item, data: phaseType };
          case "form_template_ids":
            return {
              ...item,
              data: formTemplateDropList?.map((item) => ({
                id: item.id,
                name: item.title,
              })),
            };
          default:
            return item;
        }
      });

      setModelData((prevModelData) => ({
        ...prevModelData,
        children: mapedSelectDrop,
      }));
    }
  }, [iAQA, trialManager, pICoordinator]);

  async function getSubjectList() {
    try {
      const res = await SubjectApi.getSubjectList(
        phaseListDetails?.id,
        currentPage,
        10,
        tableFilters?.searchData,
        editorId,
        piId
      );
      let rowData = convertSubjectData(res?.data || []);
      setSubjectData((prev) => ({
        ...prev,
        rows: [...rowData],
      }));
      setSubjectListData(res?.data)
      setTotalPage(
        Math.ceil(res?.page_details?.no_of_records / res?.page_details?.limit)
      );
      setCurrentpage(res?.page_details?.page);
      setLoading(false);
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));
    }
  }
  const handleDeletePhase = async () => {
    try {
      setIsDeleteLoading(true);
      // Making API call to delete phase
      const response = await PhaseApi.deletePhase(phaseListDetails?.id);

      // Dispatch success snackbar message
      dispatch(
        setSnackbar({
          value: true,
          message: response.message,
          color: "success",
        })
      );

      // Refresh the phase list
      await getPhaseList();

      // Close the delete modal
      setOpenDeleteModal(false);
    } catch (error) {
      dispatch(
        setSnackbar({
          value: true,
          message: error || error.message || "Failed to delete phase",
          color: "error",
        })
      );
    } finally {
      setIsDeleteLoading(false);
    }
  };
  const handlePagination = async (value) => {
    setCurrentpage(value);
  };

  const handleTableFilters = (e) => {
    setTablesFilters({ ...tableFilters, searchData: e });
    setCurrentpage(1)
  };

  const handleDropDownChange = (e, id) => {
    setCurrentpage(1)
    if (id === "selectPI") {
      setPiID(e.target.value);
    } else if (id === "editorList") {
      setEditorId(e.target.value);
    }
  };

  const handleSwapButton = (row) => {
    setRowId(row[row.length - 1])
    setOpenAddSubjectModal(true)
    const subData = subjectListData.find((sub) => sub.id === row[row.length - 1]);
    // setEditorList([
    //   {
    //     id: subData?.editorDetails?.id,
    //     name: subData?.editorDetails?.name,
    //   },
    // ]);
    getEditorDropdown(subData?.investigatorDetails?.id, row[row.length - 1])
    setInitialValues({
      principal_investigator: subData?.investigatorDetails?.id || "",
      editor_id: subData?.editorDetails?.id || "",
    });
  };

  const handleUpdatePiEditor = async (data) => {
    setAssignPiLoader(true);
    try {
      const response = await ProductApi.updatePiEditor(data);
      setOpenAddSubjectModal(false)
      getSubjectList();
      dispatch(
        setSnackbar({
          value: true,
          message: response.message,
          color: "success",
        })
      );
    } catch (error) {
      dispatch(
        setSnackbar({
          value: true,
          message: error || error.message || "Failed to delete phase",
          color: "error",
        })
      );
    }
    finally {
      setSubmitPiEditorValues({});
      setAssignPiLoader(false);
    }
  }

  const handleExportButton = () => {
    if (phaseListDetails && phaseListDetails?.form_details?.length > 0) {
      const multiSelectData = phaseListDetails?.form_details?.map(item => ({
        id: item?.id,
        name: item.title
      }))
      setExportModelData(prevState => ({
        ...prevState,
        children: prevState.children.map((child, index) =>
          index === 0 ? { ...child, data: multiSelectData || [] } : {}
        ),
      }));
      setOpenExportModal(true);
    } else {
      dispatch(
        setSnackbar({
          value: true,
          message: "No Form Assigned",
          color: "error",
        })
      );
    }
  }


  const organizeDataByKey = (arr) => {
    const organized = arr.reduce((acc, str) => {
      const [formId, questionId] = str.split('__-=');

      if (!acc[formId]) {
        acc[formId] = {
          id: parseInt(formId),
          questions: []
        };
      }

      acc[formId].questions.push(questionId);

      return acc;
    }, {});

    // Convert the organized data to an array
    return Object.values(organized);
  };

  const handleExportModel = async (val) => {
    const updatedformQsList = Array.isArray(formQsList) ? formQsList.map(item => ({
      ...item,
      questions: Array.isArray(item.questions) ? item.questions.map(el => el.id) : []
    })) : [];

    try {
      setExportLoading(true);
      const res = await PhaseApi.exportFormCsv(phaseListDetails?.id, val?.formId?.length === 1 ? updatedformQsList : organizeDataByKey(val?.questionId))
      if (res?.data && !isEmpty(res?.data)) {
        const csvData = jsonToCsv(res?.data);
        downloadCsv(csvData, `${phaseListDetails?.product_details?.name}_${phaseListDetails?.title}.csv`);
        setOpenExportModal(false)
      } else {
        dispatch(
          setSnackbar({
            value: true,
            message: "No Patient Data Found",
            color: "error",
          })
        );
      }
    } catch (error) {
      console.log("Export Patient Error:", error)
    } finally {
      setExportLoading(false);
    }

  }

  const handleExportSelect = async (val, name) => {
    try {
      if (name === "formId") {
        if (val?.length >= 1) {
          const res = await PhaseApi.getQuestionsByFormTemplate(phaseListDetails?.id, val);
          if (val?.length === 1) {
            setFormQsList(res.data || [])
          }
          if (val?.length > 1) {
            if (res && res.data) {
              const transformedData = res.data?.map(form => ({
                ...form,
                questions: form.questions?.map(question => ({
                  ...question,
                  id: `${form.id}__-=${question.id}`  // Append form ID with separator
                }))
              }));

              // Update the state with the transformed data
              setExportModelData(prevState => {
                const updatedChildren = prevState.children.map(child =>
                  child.name === "questionId"
                    ? { ...child, data: transformedData, disabled: false } // Update existing entry
                    : child
                );

                if (!prevState.children.some(child => child.name === "questionId")) {
                  // If 'questionId' doesn't exist, add it
                  updatedChildren.push({
                    type: "multidropdown",
                    name: "questionId",
                    label: "Select Questions",
                    placeholder: "Select Questions",
                    data: transformedData,
                    IsRequired: true,
                    disabled: false,
                    showSelectAll: true
                  });
                }

                return {
                  ...prevState,
                  children: updatedChildren
                };
              });
            } else {
              console.log("No data returned from API");
            }
          }
        }
        if (val?.length <= 1) {
          setExportModelData(prevState => ({
            ...prevState,
            children: prevState.children.filter(child => child.name !== "questionId"),
          }));
        }
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className=" p-3 sm:p-4">
      {openAddSubjectModal ? (
        <CustomModel
          rowId={rowId}
          modalInitialValues={rowId ? initialValues : AddNewSubjectPhase}
          initialValuesValidationSchema={rowId ? ReAssignValidationSchema : addNewSubjectPhaseValidationSchema}
          open={openAddSubjectModal}
          setOpen={setOpenAddSubjectModal}
          setModelData={setSubjectModelData}
          modelData={subjectModelData}
          handleModelValues={rowId ? handleOpenAssignPiAndEditorModal : handleAddSubject}
          isLoadingBtn={rowId ? assigningPiLoader : isAddSubjectLoading}
          onChange={getEditorDropdown}
        />
      ) : (
        <CustomModel
          modalInitialValues={AddPhase}
          initialValuesValidationSchema={addPhaseValidationSchema}
          open={openAddTrailPhaseModal}
          setOpen={setOpenAddTrailPhaseModal}
          setModelData={setModelData}
          modelData={modelData}
          handleModelValues={handleAddPhase}
          isLoadingBtn={isAddPrLoading}
        />
      )}

      {openExportModal ? <CustomModel
        rowId={rowId}
        modalInitialValues={exportFormCsv}
        initialValuesValidationSchema={addFormExportSchema}
        open={openExportModal}
        setOpen={setOpenExportModal}
        setModelData={() => { }}
        modelData={exportModelData}
        handleModelValues={handleExportModel}
        isLoadingBtn={exportLoading}
        onChange={handleExportSelect}
        loader={exportLoading}
      /> : null}
      {/* breadcrumb */}
      <div className="mb-0 md:mb-4">
        <BreadCrumb BreadCrumbsListing={[{ name: "Products", path: "products" }, { name: `${productDetails?.name || ""} Details`, path: location.pathname }]} />
      </div>
      <div className="mt-3 md:mt-5">
        <TaskManagementHeader
          SearchbarPlaceholder="Search by Name"
          width={"100%"}
          ButtonText="Add Phase"
          ButtonAuth={{ module: "trials", auth: 10 }}
          handleButtonClick={handleOpenTrailPhaseModal}
          handleSearch={handlePhaseSearch}
          showExportButton={role_id === 1 || role_id === 4}
          handleExportButton={handleExportButton}
        />
      </div>
      {loading || (phaseListData && phaseListData?.length > 0) ? (
        <div className="flex flex-col lg:grid grid-cols-12 gap-4 pt-3">
          <div className="col-start-1 col-end-4 w-100 phase-list-card">
            {phaseListLoading ? (
              <div className="col-start-1 col-end-4 ">
                <SkeletonLoader rowCount={2} isTable={false} />
              </div>
            ) : (
              phaseListData?.map((item) => (
                <CustomChipCard
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  setCurrentpage={setCurrentpage}
                  data={{
                    id: item?.id,
                    title: item?.title ? `${item?.product_details?.name}:  ${item?.title}` : "",
                    badglesValue: {
                      statusBadglesValue:
                        item?.phase_status_details?.status || "",
                      typeBadglesValue: item?.phase_type_details?.name || "",
                    },
                  }}
                  key={item?.id}
                />
              ))
            )}
          </div>

          <div className="col-start-4 col-end-13 min-h-[25rem] bg-[#FFFFFF] rounded-xl">
            {phaseDetailLoading || phaseListLoading ? (
              <SkeltonLoaderHeader
                rowCount={2}
                isTable={false}
                width={200}
                height={30}
              />
            ) : (
              <CustomWrapperCard
                editDeleteBtn={true}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                data={phaseListDetails || {}}
              />
            )}
            <hr className="ml-[1rem] mr-[1rem]   " />

            <TrialInfoComponent
              HeaderTitle={"Description"}
              ShowHeaderButton={false}
            >
              {phaseDetailLoading || phaseListLoading ? (
                <SkeletonLoader
                  rowCount={1}
                  isTable={false}
                  width={500}
                  height={20}
                />
              ) : (
                <Typography variant="p" className="break-words">{(phaseListDetails?.description?.[0]?.toUpperCase() + phaseListDetails?.description?.slice(1)) || "No Description Added"}</Typography>)
              }
            </TrialInfoComponent>

            <hr className="ml-[1rem] mr-[1rem]   " />

            <TrialInfoComponent
              HeaderTitle={"Assigned Forms"}
              ShowHeaderButton={false}
            >
              <div className="flex gap-2 justify-start flex-wrap">
                {phaseDetailLoading || phaseListLoading ? (
                  <SkeletonLoader
                    rowCount={2}
                    isTable={false}
                    width={200}
                    height={30}
                  />
                ) : (
                  <>
                    {phaseListDetails?.form_details?.length > 0
                      ? phaseListDetails?.form_details?.map((item) => (
                        <CustomDetailComponent
                          key={item?.id || ""}
                          BgColor="#2831720D"
                          FirstHeading={item.title || ""}
                          Icon={<DocumentIcon LightBlue={true} />}
                          ShowDeleteIcon={false}
                        />
                      ))
                      : "No Assigned Form Found."}
                  </>
                )}
              </div>
            </TrialInfoComponent>
            <hr className="ml-[1rem] mr-[1rem]   " />
            <TrialInfoComponent
              HeaderTitle={"Principal Investigator"}
              ShowHeaderButton={false}
            >
              <div className="flex gap-2 justify-start flex-wrap">
                {phaseDetailLoading || phaseListLoading ? (
                  <SkeletonLoader
                    rowCount={3}
                    isTable={false}
                    width={200}
                    height={30}
                  />
                ) : (
                  <>
                    {phaseListDetails?.investigator_details
                      ?.principal_investigator?.length > 0
                      ? phaseListDetails?.investigator_details?.principal_investigator?.map(
                        (item) => (
                          <div key={item?.id} className="w-full sm:w-max">
                            <CustomDetailComponent
                              BgColor="#2831720D"
                              FirstHeading={item?.name || ""}
                              SecondHeading={
                                item?.hospital_details?.name || ""
                              }
                              Icon={<InvestigatorIcon />}
                              ShowDeleteIcon={false}
                            />
                          </div>
                        )
                      )
                      : "No Principal Investigator Found."}
                  </>
                )}
              </div>
            </TrialInfoComponent>
            <hr className="ml-[1rem] mr-[1rem]   " />

            <TrialInfoComponent
              HeaderTitle={"Quality Associate"}
              ShowHeaderButton={false}
            >
              <div className="flex gap-2 justify-start flex-wrap">
                {phaseDetailLoading || phaseListLoading ? (
                  <SkeletonLoader
                    rowCount={3}
                    isTable={false}
                    width={200}
                    height={30}
                  />
                ) : (
                  <>
                    {phaseListDetails?.immunoactQA_details?.length > 0
                      ? phaseListDetails?.immunoactQA_details?.map((item) => (
                        <div key={item?.id} className="w-full sm:w-auto">
                          <CustomDetailComponent
                            BgColor="#2831720D"
                            FirstHeading={item?.name || ""}
                            Icon={<PersonSvg />}
                            ShowDeleteIcon={false}
                          />
                        </div>
                      ))
                      : "No Quality Associate Found."}
                  </>
                )}
              </div>
            </TrialInfoComponent>
            <hr className="ml-[1rem] mr-[1rem]   " />

            <TrialInfoComponent
              HeaderTitle={"Clinical Phase Manager"}
              ShowHeaderButton={false}
            >
              <div className="flex gap-2 justify-start flex-wrap">
                {phaseDetailLoading || phaseListLoading ? (
                  <SkeletonLoader
                    rowCount={3}
                    isTable={false}
                    width={200}
                    height={30}
                  />
                ) : (
                  <>
                    {phaseListDetails?.trial_manager_details?.length > 0
                      ? phaseListDetails?.trial_manager_details?.map((item) => (
                        <div key={item?.id} className="w-full sm:w-auto">
                          <CustomDetailComponent
                            BgColor="#2831720D"
                            FirstHeading={item.name}
                            Icon={<PersonSvg />}
                            ShowDeleteIcon={false}
                          />
                        </div>
                      ))
                      : "No Clinical Phase Manager Found."}
                  </>
                )}
              </div>
            </TrialInfoComponent>
            <hr className="ml-[1rem] mr-[1rem]   " />
            <div className="p-3">
              <CustomTable
                SearchbarBgColor="secondary"
                rows={subjectData?.rows}
                columnAlign={subjectData?.columnAlign}
                columns={subjectData?.columns}
                handleTableFilters={handleTableFilters}
                tableFilters={tableFilters}
                handleChangePage={handlePagination}
                handleSwapButton={handleSwapButton}
                loading={loading}
                currentPage={currentPage}
                totalpage={totalPage}
                TableData={{
                  ...subjectData?.TableSearchActions,
                  filters: subjectData?.TableSearchActions?.filters?.map(
                    (item) => {
                      if (item?.id === "editorList") {
                        return {
                          ...item,
                          MenuList: editorList,
                          dropDownValue: editorId,
                        };
                      } else if (item?.id === "selectPI") {
                        return {
                          ...item,
                          MenuList: pIList,
                          dropDownValue: piId,
                        };
                      }
                      return item;
                    }
                  ),
                }}
                handleChangeDropDown={handleDropDownChange}
                handleNavigate={handleNavigateToDetails}
                handleTableDataButton={handleOpenAddSubjectModal}
                DropdownBgColor={theme?.palette?.grey[200]}
                disableButton={disableButton}
              />
            </div>
          </div>
        </div>
      ) : phaseListData !== null ? (
        <div className="mt-4">
          <NoDataCard />
        </div>
      ) : null}
      <DeleteConfirmContent
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        handleDelete={handleDeletePhase}
        isLoading={isDeleteLoading}
      />
      <CustomSnackbar
        open={snackbar?.value}
        setOpen={setSnackbar}
        color={snackbar?.color}
        message={snackbar?.message}
      />
      <AddPiAndEditorForAllModalContent
        open={openAssignForAllModal}
        setOpen={setOpenAssignForAllModal}
        handleClick={SumbitAssignAllPiAndEditor}
        isLoading={assigningPiLoader}
      />
    </div>
  );
};
