import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { addDiseaseManagementValues } from "../../utils/formik/initialValues";
import { addDiseaseManagementValidationSchema } from "../../utils/formik/validationSchema";
import { diseaseManagementData } from "../../utils/tableData";
import DeleteConfirmContent from "../../components/modal-contents/DeleteConfirmModalContent";
import DiseaseManagementApi from "../../services/diseaseService";
import { setSnackbar } from "../../store/reducers/snackBar";
import { useDispatch, useSelector } from "react-redux";
import { CustomSnackbar } from "../../components/snackbar/CustomSnackbar";
const CustomTable = Loadable(React.lazy(() => import('../../components/custom-table/CustomTable')));
const CustomModel = Loadable(React.lazy(() => import('../../components/custom-model/CustomModel')));
import Loadable from "../../components/Loadable";

const convertDiseaseData = (data) => {
    return data.map(disease => {
        let transformedId = disease?.disease_code;
        let transformedDiseaseName = disease?.name;
        let transformedDescription = disease?.description;
        let transformRowId = disease?.id
        return [transformedId, transformedDiseaseName, transformedDescription, transformRowId];
    });
}

export const DiseaseManagementPage = () => {
    const dispatch = useDispatch()
    const snackbar = useSelector((state) => state.snackBar);
    const [openAddModal, setOpenAddModal] = React.useState(false);
    const [openEditDiseaseModal, setOpenEditDiseaseModal] = React.useState(false);
    const [openDeleteConfirmModal, setOpenDeleteConfirmModal] = React.useState(false);
    const [loading, setLoading] = useState(false)
    const [initialValues, setInitialValues] = useState(addDiseaseManagementValues)
    const [rowId, setRowId] = useState(null)
    const [disease, setDisease] = useState(diseaseManagementData);
    const [diseaseResponse, setDiseaseResponse] = useState([]);
    const [deleteRowData, setDeleteRowData] = useState(null)
    const [currentPage, setCurrentpage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [tableFilters, setTablesFilters] = useState({ searchData: '' })
    const [submitLoading, setSubmitLoading] = useState(false);
    const [modelData, setModelData] = useState({
        title: "Add New Disease",
        editTitle: "Edit Disease",
        children: [
            { type: "input", name: "diseaseName", label: "Disease Name", placeholder: "Enter Disease Name", IsRequired: true },
            { type: "description", name: "description", label: "Description", placeholder: "Enter Description", IsRequired: true },
        ]
    })
    const navigate = useNavigate();

    const handleNavigateToDetails = React.useCallback(() => {
      // This function is not looking of any use..
      navigate("/principal-investigator-details");
    }, []);

    const getDiseaseList = () => DiseaseManagementApi.getDiseaseManagementData(10, currentPage, tableFilters?.searchData)
        .then((res) => {
            let rowData = convertDiseaseData(res?.data || [])
            setDiseaseResponse(res?.data)
            setDisease(prev => ({
                ...prev,
                rows: [...rowData]
            }));
            setTotalPage(Math.ceil(res?.page_details?.no_of_records / res?.page_details?.limit));
            setCurrentpage(res?.page_details?.page);
            setLoading(false);
        }
        )
        .catch((error) => {
            dispatch(setSnackbar({ value: true, message: error, color: "error" }));
            setLoading(false);
        })


    useEffect(() => {
        if (tableFilters?.searchData[tableFilters?.searchData?.length - 1] != " ") {
        setLoading(true)
        getDiseaseList();
        }
    }, [currentPage, tableFilters])


    const handleOpenAddModal = () => {
        setInitialValues(addDiseaseManagementValues)
        setRowId("")
        setOpenAddModal(true);
    }

    const handleOpenEditDiseaseModal = (row) => {
        setRowId(row[3])
        const diseaseEditData = diseaseResponse.find(disease => disease.id === row[3]);
        setInitialValues({
            diseaseName: diseaseEditData?.name,
            description: diseaseEditData?.description,
        })
        setOpenEditDiseaseModal(true);
    }

    const handleAddModelValues = (values) => {
        setSubmitLoading(true);
        DiseaseManagementApi.addDisease({
        name: values?.diseaseName,
        description: values?.description,
    }).then((res) => {
        if (res.status === 200) {
            setOpenAddModal(false)
            setSubmitLoading(false);
            getDiseaseList()
            dispatch(setSnackbar({ value: true, message: res.message,  color: "success" }));
        }
    }).catch((error) => {
        dispatch(setSnackbar({ value: true, message: error, color: "error" }));
        setSubmitLoading(false);
    })
    }

    const handleEditModelValues = (values) => {
        setSubmitLoading(true);
        DiseaseManagementApi.updateDisease({
        name: values?.diseaseName,
        description: values?.description,
    }, rowId).then((res) => {
        if (res.status === 200) {
            setOpenEditDiseaseModal(false)
            setSubmitLoading(false);
            getDiseaseList()
            dispatch(setSnackbar({ value: true, message: res.message,color: "success" }));
        }
    }).catch((error) => {
        dispatch(setSnackbar({ value: true, message: error, color: "error" }));
        setSubmitLoading(false);
    })
    }

    const handleOpenDeleteConfirmModal = (row) => {
        setOpenDeleteConfirmModal(true);
        setDeleteRowData(row)
    }
    const handleTableFilters = (e) => {
        setTablesFilters({ ...tableFilters, searchData: e })
        setCurrentpage(1)
    }
    const handlePagination = (value) => {
        setCurrentpage(value);
    };
    const handleDeleteRow = () =>{ 
        setSubmitLoading(true);
        setOpenDeleteConfirmModal(false)
        DiseaseManagementApi.deleteDisease(deleteRowData[3]).then((res) => {
        if (res?.status === 200) {
            setSubmitLoading(false);
            getDiseaseList()
            dispatch(setSnackbar({ value: true, message: res.message, color: "success" }));
        }
    }).catch((error) => {
        dispatch(setSnackbar({ value: true, message: error, color: "error" }));
        setSubmitLoading(false);
    })
}

    return <>
        <div className="flex flex-col gap-0 bg-white p-0 !pb-0">
            <CustomModel
                rowId={rowId}
                modalInitialValues={initialValues}
                initialValuesValidationSchema={addDiseaseManagementValidationSchema}
                open={openEditDiseaseModal ? openEditDiseaseModal : openAddModal}
                setOpen={openEditDiseaseModal ? setOpenEditDiseaseModal : setOpenAddModal}
                setModelData={setModelData}
                modelData={modelData}
                handleModelValues={openEditDiseaseModal ? handleEditModelValues : handleAddModelValues}
                isLoadingBtn={submitLoading}
            />
            <CustomTable
                rows={disease?.rows}
                columnAlign={disease?.columnAlign}
                columns={disease?.columns}
                TableData={disease?.TableData}
                handleNavigate={handleNavigateToDetails}
                handleButtonClick={handleOpenAddModal}
                handleEditButton={handleOpenEditDiseaseModal}
                handleDeleteButton={handleOpenDeleteConfirmModal}
                handleTableFilters={handleTableFilters}
                tableFilters={tableFilters}
                handleChangePage={handlePagination}
                loading={loading}
                currentPage={currentPage}
                totalpage={totalPage}
            />
        </div>
        <DeleteConfirmContent open={openDeleteConfirmModal} setOpen={setOpenDeleteConfirmModal} handleDelete={handleDeleteRow} isLoading={submitLoading} />
        <CustomSnackbar open={snackbar?.value} setOpen={setSnackbar} color={snackbar?.color} message={snackbar?.message} />

    </>
}

