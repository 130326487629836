import React from 'react';
import { Box, Typography } from '@mui/material';
import { FrownOutlined } from '@ant-design/icons';

const NoDataCard = ({ borderRadius, heading = "No Data to Display" }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: '25rem', backgroundColor: '#FFFFFF', borderRadius: borderRadius ? borderRadius : '1rem', padding: '2rem' }}
    >
      <FrownOutlined style={{ fontSize: 40, color: '#B0BEC5' }} />
      <Typography variant="h6" sx={{ marginTop: '1rem', color: '#B0BEC5' }}>
        {heading}
      </Typography>
    </Box>
  );
};

export default NoDataCard;
