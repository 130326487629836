import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Loadable from "../../components/Loadable";
import { DoctorIcon, SubjectIcon } from "../../assets/products-svg/ProductSvg";
import PhaseApi from "../../services/phase";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../store/reducers/snackBar";
import { CustomSnackbar } from "../../components/snackbar/CustomSnackbar";
import InfiniteScroll from "react-infinite-scroll-component";
import { SpinnerLoader } from "../../assets/loader/loader";
import SubHeader from "../../layout/MainLayout/SubHeader";
import NoDataCard from "../../components/no-data-card";

const CustomSearchbar = Loadable(
  React.lazy(() => import("../../components/custom-searchbar/CustomSearchbar"))
);

const InfoCard = Loadable(
  React.lazy(() => import("../../components/card-component/InfoCard"))
);

const SkeletonLoader = Loadable(
  React.lazy(() => import("../../utilites/SkeltonLoader"))
);

let phaseFilter = {
  page: 1,
  limit: 10,
  maxPages: null,
};

export const TrialsListing = () => {
  const [loading, setLoading] = useState(false);
  const [phasesData, setPhasesData] = React.useState([]);
  const [searchFilter, setSearchFilter] = React.useState("");
  const [phaseLoading, setPhaseLoading] = React.useState(false);

  const snackbar = useSelector((state) => state.snackBar);
  const { role_id } = useSelector((state) => state.loginData);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDetailsButton = (phaseId, title) => {
    navigate(`/phases/phase-details/${phaseId}`, {
      state: [
        { name: title, path: location.pathname },
        {
          name: "Details",
          path: `${location.pathname}/phase-details/${phaseId}`,
        },
      ],
    });
  };

  const getPhaseLisitng = () => {
    phaseFilter.page === 1 && setLoading(true);
    setPhaseLoading(true);
    PhaseApi.getPhaseListData(phaseFilter.limit, phaseFilter.page, searchFilter)
      .then((res) => {
        if (res.data) {
          res.page_details.page > 1
            ? setPhasesData((prev) => [...prev, ...res.data])
            : setPhasesData(res.data);
          phaseFilter.maxPages = Math.ceil(
            res.page_details.no_of_records / phaseFilter.limit
          );
        }
        else {
          setPhasesData([]);
        }
        setLoading(false);
        setPhaseLoading(false);
      })
      .catch((error) => {
        dispatch(setSnackbar({ value: true, message: error, color: "error" }));
        setLoading(false);
        setPhaseLoading(false);
      });
  };

  const PageIncrement = () => {
    phaseFilter.page++;
  };
  const ResetFilter = () => {
    phaseFilter.page = 1;
  };

  const handleSearchValue = (value) => {
    ResetFilter();
    let searchValue = value;
    setSearchFilter(searchValue);
  };

  React.useEffect(() => {
    ResetFilter();
    getPhaseLisitng();
    PageIncrement();
  }, [searchFilter]);

  const fetchData = React.useCallback(() => {
    if (phaseFilter?.page <= phaseFilter?.maxPages) {
      getPhaseLisitng();
      PageIncrement();
    }
  }, []);

  return (
    <div>
      {role_id == 5 && (
        <SubHeader>
          <div className=" px-3 py-6 grid grid-cols-12">
            <div className="left_area flex flex-col col-start-1 col-span-full gap-4  sm:col-start-1 sm:col-end-6  lg:col-start-1 lg:col-end-4">
              <h4 className="text-[#FFFFFF] text-2xl font-medium">
                Welcome to ACT. ONE
              </h4>
              <p className="text-base text-[#FFFFFF] font-normal">Phase</p>
            </div>
            <div className="mt-3 sm:mt-0 col-start-1 col-end-9 sm:col-start-9 sm:col-end-13   lg:col-start-10 lg:col-span-3">
              <CustomSearchbar
                Placeholder="Search by Name"
                handleSearch={handleSearchValue}
                value={searchFilter}
              />
            </div>
          </div>
        </SubHeader>
      )}
      <div className="p-3">
        {role_id != 5 && (
          <div className="grid md:grid-cols-6 gap-4 pb-2 text-end">
            <div className="md:col-start-6 md:col-end-13">
              <CustomSearchbar
                Placeholder="Search by Name"
                handleSearch={handleSearchValue}
                value={searchFilter}
              />
            </div>
          </div>
        )}

        <InfiniteScroll
          dataLength={phasesData?.length}
          next={fetchData}
          hasMore={phaseFilter?.page <= phaseFilter?.maxPages}
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 ">
            {loading ? (
              <SkeletonLoader
                rowCount={3}
                isTable={false}
                height={260}
                width={"100%"}
              />
            ) : (
              <>
                {phasesData && phasesData.length > 0 ? (
                  phasesData.map((phaseData, index) => {
                    return (
                      <InfoCard
                        editAuth={{ module: "trials", auth: 11 }}
                        deleteAuth={{ module: "trials", auth: 12 }}
                        detailsAuth={{ module: "trials", auth: 14 }}
                        ShowHeaderMenu={false}
                        Code={phaseData?.phase_code}
                        PrimaryHeading={`${phaseData?.product_details?.name}: ${phaseData?.title}`}
                        CardID={phaseData?.id}
                        StartedDate={phaseData?.phase_start_date}
                        FieldGapCloser={true}
                        ChipListingData={[phaseData]}
                        handleProductDetails={() =>
                          handleDetailsButton(phaseData?.id, phaseData?.title)
                        }
                        CardDetailsListing={
                          role_id == 2
                            ? [
                              {
                                FieldName: "Editors Onboarded",
                                FieldValue: phaseData.editorCount,
                                Icon: <DoctorIcon />,
                              },
                              {
                                FieldName: "Patients Enrolled",
                                FieldValue: phaseData.subjectCount,
                                Icon: <SubjectIcon />,
                              },
                            ]
                            : [
                              {
                                FieldName: "Patients Enrolled",
                                FieldValue: phaseData.subjectCount,
                                Icon: <SubjectIcon />,
                              },
                            ]
                        }
                        key={index}
                      />
                    );
                  })
                ) : (
                  <div className="grid col-span-3">
                    <NoDataCard />
                  </div>
                )}
              </>
            )}
          </div>
        </InfiniteScroll>
        {
          phaseLoading && phaseFilter.page > 2 && (
            <div className="flex justify-center">
              {" "}
              <SpinnerLoader />
            </div>
          )
        }
        <CustomSnackbar
          open={snackbar?.value}
          setOpen={setSnackbar}
          color={snackbar?.color}
          message={snackbar?.message}
        />
      </div>
    </div>
  );
};
