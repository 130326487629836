// import React, { useEffect, useState } from 'react';
import CustomInputField from "../custom-input/CustomInput"
import { ImageIcon } from "../../assets/common-svg/CommonSvg";
import { Typography } from "@mui/material";
import { CustomMultiUpload } from "../custom-upload/CustomMultiUpload";
import { useSelector } from "react-redux";
import { getUserHasAccess } from "../../utils/authorities";
// import { useSelector } from 'react-redux';
export const FormOtherDetails = ({ description, handleChangeDescription, handleRemoveFiles, appointmentData }) => {
    const LoginData = useSelector((state) => state.loginData);
    const userAuthority = LoginData?.auth || [];
    return <div className="pt-2">
        <Typography variant="h6">
            Other Details
        </Typography>
        <CustomInputField
            InputType={"text"}
            value={description ?? ""}
            onChange={(e) => handleChangeDescription(e.target.value, "description")}
            InputName={"description"}
            label={"Description"}
            NoOfRows={2}
            disabled={getUserHasAccess(userAuthority, "appointmentManager", 123) ? appointmentData?.readOnly ?? false : true}
            MultiLine={true}
            placeholder={"Enter Here"}
            CustomClassName={"bg-[#EDEDED] text-[black] font-normal text-[18px]"}
        />
        <div className="flex gap-4 pt-3">
            <CustomMultiUpload
                label={"Upload Files"}
                name={"uploadFile"}
                placeholder="Upload File"
                readOnly={getUserHasAccess(userAuthority, "appointmentManager", 123) ? appointmentData?.readOnly ?? false : true}
                value={appointmentData?.media}
                handleRemoveFiles={handleRemoveFiles}
                isDownload={getUserHasAccess(userAuthority, "appointmentManager", 123) ? !appointmentData?.readOnly ?? false : true}
                isRemove={getUserHasAccess(userAuthority, "appointmentManager", 123) ? appointmentData?.readOnly ?? false : true}
                isShowUpload={getUserHasAccess(userAuthority, "appointmentManager", 123) ? appointmentData?.readOnly ?? false : true}
                // accept={"image/*"}
                // error={Boolean(touched[child?.name] && errors[child?.name])}
                // touched={touched[child?.name]}
                // helperText={errors[child?.name]}
                fileIcon={<ImageIcon />}
                lable="Upload File"
                handleChange={(e) => handleChangeDescription(e.target.files[0], "files")}
            />
        </div>
    </div>
}