import { useEffect, useState, useCallback, memo } from "react";
import { ReactFormGenerator } from "react-form-builder2";
import "./form-style.css";
import { FormCommentMenu } from "./FormCommentMenu";
import { FormOtherDetails } from "./FormOtherDetails";
import QueryApi from "../../services/queryManage";
import { setSnackbar } from "../../store/reducers/snackBar";
import { useDispatch, useSelector } from "react-redux";
import { getUserHasAccess } from "../../utils/authorities";

const FormPreview = ({
    handleSubmit,
    getAppoinmentDetails,
    getAppoinmentVersion,
    appointmentData,
    formData,
    answerData,
    handleChangeForm,
    setAppointmentData,
    index,
    description,
    handleChangeDescription,
    handleRemoveFiles,
    SubjectData,
    submitButtonRef
}) => {
    const dispatch = useDispatch();
    const LoginData = useSelector((state) => state.loginData);
    const [anchorPosition, setAnchorPosition] = useState({ x: 0, y: 0 });
    const [open, setOpen] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [menuData, setMenuData] = useState([]);
    const [selectedFieldId, setSelectedFieldId] = useState(null);
    const [isResolved, setIsResolved] = useState(null);
    const userAuthority = LoginData?.auth || [];

    const getCommentList = useCallback(async (id) => {
        setLoading(true);
        try {
            let paramId = appointmentData?.template_queries.find(op => op.field_id === id)?.id
            const param = { field_id: id, version: appointmentData.version, appointment_id: SubjectData.appointment_details[index].id };
            const res = await QueryApi.getAllQueryComment(paramId, param);
            const comments = res?.data?.history?.map(op => ({
                title: `${op?.user_name} (${op?.role})`,
                name: `${op?.user_name[0]}${op?.user_name[1]}`,
                describe: op?.query,
                date: op?.created_at
            }));
            setIsResolved(res?.data?.is_resolved)
            setMenuData(comments || []);
        } catch (error) {
            dispatch(setSnackbar({ value: true, message: error, color: "error" }));
        } finally {
            setLoading(false);
        }
    }, [SubjectData, index, selectedFieldId, appointmentData]);

    const handleInfoIconClick = (event, fieldName, query, inputType) => {
        setSelectedFieldId("");
        const rect = event.currentTarget.getBoundingClientRect();
        const x = rect.left + rect.width / 2;
        const y = rect.bottom - 100;
        setAnchorPosition({ x, y });
        setOpen(true);
        if (query?.field_id == inputType?.name && query?.is_resolved == false) {
            setAppointmentData({ ...appointmentData, isDisableQuery: false })
        } else {
            setAppointmentData({ ...appointmentData, isDisableQuery: appointmentData?.checkDisableQuery })
        }
        setSelectedFieldId(fieldName);
        getCommentList(fieldName);
    };

    const handleClose = () => {
        setOpen(false);
    };



    useEffect(() => {
        const formFieldsInputs = document.querySelectorAll(`.form-accordion-${index} .form-group`);
        const formFieldsCheckRadio = document.querySelectorAll(`.form-accordion-${index} .form-group .custom-control`);
        formFieldsCheckRadio.forEach((field) => {
            const inputType = field.querySelector('input,textarea, select');

            let checkValueExist = null
            if (inputType?.type == "checkbox" && inputType?.checked) {
                checkValueExist = appointmentData.checkAnswer?.find(op => {
                    if (Array.isArray(op?.value)) {
                        let fieldValues = op.value.map(op => `option_${op}`) || [];
                        return fieldValues.includes(inputType.name)
                    }
                })
            } else if (inputType?.type == "radio" && inputType.checked) {
                checkValueExist = appointmentData.checkAnswer?.find(op => {
                    if (Array.isArray(op?.value)) {
                        let fieldValues = op.value.map(op => {
                            if (op.startsWith("fid_preview_")) {
                                return op || []
                            } else {
                                return `fid_preview_${op}` || []
                            }
                        })
                        return fieldValues.includes(inputType.id)
                    }
                })

            }
            let query = appointmentData?.template_queries?.find(op => inputType.type == "radio" ? (op?.field_id == inputType?.id) : (op?.field_id == inputType?.name))
            if (Array.isArray(checkValueExist?.value) && checkValueExist?.value.length != 0 && (inputType.type == "checkbox" || inputType.type == "radio")) {
                const infoIcon = document.createElement('span');
                infoIcon.innerHTML = `<i 
                class="fas fa-question-circle circle-icon cursor-pointer" 
                aria-hidden="true" data-id="${inputType.name}" 
                aria-hidden="true" data-id="${inputType.name}" 
                style=${inputType.type == "radio" && query?.field_id == inputType?.id && !query?.is_resolved
                        ? "color:red;"
                        : inputType.type != "radio" && query?.field_id == inputType?.name && !query?.is_resolved
                            ? "color:red;"
                            : ""}></i >`;
                infoIcon.className = 'info-icon z-50';
                infoIcon.style.cursor = 'pointer';
                infoIcon.addEventListener("click", (event) => handleInfoIconClick(event, inputType?.type == "radio" ? inputType.id : inputType.name, query, inputType));
                field.appendChild(infoIcon);
            }
        });

        formFieldsInputs.forEach((field) => {
            const inputType = field.querySelector('input, textarea,select');
            let checkValueExist = appointmentData.checkAnswer?.find(op => op.id == inputType?.name)
            let query = appointmentData?.template_queries?.find(op => op?.field_id == inputType?.name)
            if (!Array.isArray(checkValueExist?.value) && checkValueExist?.value && (inputType.type != "checkbox" || inputType.type != "radio")) {
                const infoIcon = document.createElement('span');
                infoIcon.innerHTML = `<i class="fas fa-question-circle circle-icon cursor-pointer z-50" aria-hidden="true" data-id="${inputType.name}" style=${query?.field_id == inputType?.name && !query?.is_resolved ? "color:red;" : ""}></i>`;
                infoIcon.className = 'info-icon z-50';
                infoIcon.style.cursor = 'pointer';
                infoIcon.addEventListener("click", (event) => handleInfoIconClick(event, inputType.name, query, inputType));
                field.appendChild(infoIcon);
            }
        });
    }, [answerData, formData]);

    const getType = (str) => {
        const match = str.match(/^(checkboxes|radiobuttons|fid_preview_radiobuttons)_/);
        return match ? match[1] : null;
    };

    const returnAnswar = () => {
        let type = ""
        const answer = answerData.find(op => {
            if (Array.isArray(op?.value) && op?.value.length != 0) {
                // Map through the array to extract types
                const types = op.value.map(item => getType(item));
                type = types[0]
                if (type == "radiobuttons" || type == "fid_preview_radiobuttons") {
                    op.value = op.value.map(op => {
                        if (op.startsWith("fid_preview_")) {
                            return op
                        } else {
                            return `fid_preview_${op}`
                        }
                    })
                    return op.value.includes(selectedFieldId)
                } else if (type == "checkboxes") {
                    return op.value.includes(selectedFieldId.replace(/option_/, ''))
                }
            } else {
                type = "other"
                return op?.name === selectedFieldId
            }
        });
        let ansValue = ""
        if (type == "radiobuttons" || type == "fid_preview_radiobuttons") {
            let radioValue = formData.find(op => op.id == answer?.id)
            let value = answer.value.find(op => op == selectedFieldId)
            ansValue = radioValue.options.find(op => value == `fid_preview_${op.key}`)?.value
        } else if (type == "checkboxes") {
            let radioValue = formData.find(op => op.id == answer?.id)
            let value = answer.value.find(op => op == selectedFieldId.replace(/option_/, ''))
            ansValue = radioValue.options.find(op => value == op.key)?.value
        } else {
            ansValue = answer?.value
        }
        return { value: ansValue, answer: answer }
    }
    const sendComment = async (value, resetForm) => {
        setButtonLoading(true);
        try {
            const ansData = returnAnswar()
            const updatedValues = {
                appointment_id: appointmentData.id,
                subject_id: SubjectData.id,
                answer_id: appointmentData.answer_id,
                editor_id: LoginData?.loginId,
                form_template_id: SubjectData.appointment_details[index].appointment_question.id,
                field_name: formData.find(op => op.id === ansData?.answer?.id)?.label,
                field_id: selectedFieldId,
                version: appointmentData.version,
                answer: ansData?.value,
                query: value?.comment
            };
            const response = await QueryApi.raiseQuery(updatedValues);
            setOpen(false);
            getAppoinmentVersion(appointmentData.id)
            getAppoinmentDetails(appointmentData.id)
            dispatch(setSnackbar({
                value: true,
                message: response.message || "Queries submitted successfully",
                color: "success",
            }));
        } catch (error) {
            dispatch(setSnackbar({
                value: true,
                message: error?.message || error || "Failed to submit queries",
                color: "error",
            }));
        } finally {
            resetForm();
            setButtonLoading(false);
        }
    };

    const handleResolvedQuery = async () => {
        setLoading(true);
        try {
            const param = {
                field_id: selectedFieldId,
                version: appointmentData.version,
                appointment_id: SubjectData.appointment_details[index].id,
                answer: returnAnswar().value,
                answer_packet: JSON.stringify(answerData)
            };
            const response = await QueryApi.resolvedQuery(param);
            await getCommentList();
            dispatch(setSnackbar({
                value: true,
                message: response.message || "Appointment added successfully",
                color: "success",
            }));
            setSelectedFieldId("");
            setOpen(false);
            getAppoinmentDetails(appointmentData.id)
        } catch (error) {
            dispatch(setSnackbar({
                value: true,
                message: error?.message || error || "Failed to add appointment",
                color: "error",
            }));
        } finally {
            setLoading(false);
        }
    };
    const returnAnswerData = (ansdata) => {
        return ansdata.map(op => {
            if (Array.isArray(op?.value)) {
                if (op?.value[0]?.includes("fid_preview_")) {
                    return { ...op, name: op.id, value: [op?.value[0].replace("fid_preview_", "")] }
                } else {
                    return { ...op, name: op.id }
                }
            } else {
                return { ...op, name: op.id }
            }
        })
    }
    console.log(userAuthority,"userAuthorityuserAuthority");
    return (
        <div
            className="overflow-y-auto overflow-x-hidden max-h-[28rem] !px-2"
        >
            <ReactFormGenerator
                form_method="POST"
                onBlur={(data) => handleChangeForm(data, "answers")}
                read_only={getUserHasAccess(userAuthority, "appointmentManager", 123) ? appointmentData?.readOnly ?? false : true}
                skip_validations={false}
                data={formData.map(op => { return { ...op, field_name: op.id } })}
                task_id={index}
                onSubmit={handleSubmit}
                submitButton={<button type="submit" ref={submitButtonRef} className="btn btn-primary hidden ">Submit</button>}
                answer_data={returnAnswerData(answerData) ?? []}
            />
            <FormOtherDetails
                index={index}
                handleRemoveFiles={handleRemoveFiles}
                handleChangeDescription={handleChangeDescription}
                description={description}
                appointmentData={appointmentData}
                handleChangeForm={handleChangeForm}
            />
            <FormCommentMenu
                open={open}
                isResolved={isResolved}
                isDisableQuery={appointmentData?.isDisableQuery}
                setOpen={setOpen}
                loading={loading}
                setButtonLoading={setButtonLoading}
                handleResolvedQuery={handleResolvedQuery}
                sendComment={sendComment}
                buttonLoading={buttonLoading}
                handleClose={handleClose}
                MenuData={menuData}
                anchorPosition={anchorPosition}
                anchorReference={"anchorPosition"}
                menuId={"basic-menu"}
            />
        </div>
    );
};

export default memo(FormPreview);
