import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import CustomTable from "../../components/custom-table/CustomTable";
import { editorListingData } from "../../utils/tableData";
import { CustomSnackbar } from "../../components/snackbar/CustomSnackbar";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../store/reducers/snackBar"
import PIEditorApi from "../../services/coordinatingPiEditorService";
import PISubjectApi from "../../services/coordinatingPiSubject";
import ListDrawer from "../../components/ListDrawer/ListDrawer";
import CustomModel from "../../components/custom-model/CustomModel";
import { updatePhaseValues } from "../../utils/formik/initialValues";
import { updatPhaseValidationSchema } from "../../utils/formik/validationSchema";

const convertPIEditorData = (data) => {
    return data?.map(editor => {
        let transformedId =  editor?.id
        let transformedName = editor?.name
        let transformedPhone = editor?.phone
        let transformedPhase = editor?.phaseCount

        let transformedEmail = editor?.email
        let phaseList = editor?.phaseDetails
        let transformedPI = editor?.id
        return [transformedId, transformedName, transformedPhone, transformedPhase, transformedEmail, phaseList, transformedPI];
    });
}

export const EditorPage = () => {
    const dispatch = useDispatch()
    const loginID = useSelector((state) => state.loginData.loginId);
    const snackbar = useSelector((state) => state.snackBar);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [tableFilters, setTablesFilters] = useState({ searchData: '' })
    const [currentPage, setCurrentpage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [piData, setPiData] = useState(editorListingData);
    const [phaseID, setPhaseID] = useState(null)
    const [phaseList, setPhaseList] = useState([]);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [phaseListData, setPhaseListData] = useState([])
    const [initialValues, setInitialValues] = useState(updatePhaseValues);
    const [openModal, setOpenModal] = React.useState(false);
    const [editorResponse, setEditorResponse] = useState([])
    const [rowId, setRowId] = useState(null)
    const [modelData, setModelData] = useState({
        editTitle: "Update Phase",
        children: [
            {
                type: "multidropdown",
                name: "phase",
                label: "Phase ",
                placeholder: "Select Phase",
                data: phaseList,
                IsRequired: true,
            }
        ],
    });
    const handleNavigateToDetails = React.useCallback((row) => {
        navigate(`assigned-editors-details/${row[row?.length - 1]}`, {
          state: [
            { name: "Assigned Editors", path: location.pathname },
            {
              name: "Details",
              path: `${location.pathname}/assigned-editors-details/${row[row?.length - 1]}`,
            },
          ],
        });
    }, []);

    useEffect(() => {
        if (tableFilters?.searchData[tableFilters?.searchData?.length - 1] != " ") {
            setLoading(true)
            getPIEditorListing();
        }
    }, [currentPage, tableFilters, phaseID])

    useEffect(() => {
        getPhase()
    }, [])

    const getPhase = () => PISubjectApi.getPhaseDropDown('', loginID).then((res) => {
        const data = res?.data?.map(val => ({
            id: val?.id,
            name: `${val?.title} (${val?.product_details?.name})`
        }));
        setModelData((prevState) => ({
            ...prevState,
            children: prevState.children.map((child) =>
                child.name === "phase"
                    ? { ...child, data: data }
                    : child
            ),
        }));
        setPhaseList(data)
    }).catch((error) => {
        dispatch(setSnackbar({ value: true, message: error, color: "error" }));
    })
    const getPIEditorListing = () => PIEditorApi.getPIEditorData(10, currentPage, tableFilters?.searchData, phaseID, loginID)
        .then((res) => {
            let rowData = convertPIEditorData(res?.data || [])
            setEditorResponse(res?.data)
            setPiData(prev => ({
                ...prev,
                rows: [...rowData]
            }));

            setTotalPage(Math.ceil(res?.page_details?.no_of_records / res?.page_details?.limit));
            setCurrentpage(res?.page_details?.page);
            setLoading(false);
        }
        )
        .catch((error) => {
            dispatch(setSnackbar({ value: true, message: error, color: "error" }));
            setLoading(false);
        })


    const handleTableFilters = (e) => {
        setTablesFilters({ ...tableFilters, searchData: e })
        setCurrentpage(1)
    }

    const handlePagination = async (value) => {
        setCurrentpage(value);
    };

    const handleDropDownChange = (e) => {
        setPhaseID(e.target.value)
        setCurrentpage(1)
    }
    const handleCellClick = (row) => {
        setOpenDrawer(!openDrawer)
        setPhaseListData(row[row?.length - 2])
    }
    

    const handleAddButton = (row) => {
        setOpenModal(true)
        setRowId(row[row?.length - 1])
        const editorData = editorResponse.find((editor) => editor.id === (row[row?.length - 1]));
        setInitialValues({
            phase: editorData?.phaseDetails.map(item => item.id),
        });
    }

    const handleUpdate = async (values) => {
        setOpenModal(false)
        try {
            const response = await PIEditorApi.updatePhase(rowId, values?.phase);
            setSubmitLoading(false);
            setLoading(true)
            getPIEditorListing()
            dispatch(setSnackbar({ value: true, message: response.message, color: "success", }));

        } catch (error) {
            dispatch(setSnackbar({ value: true, message: error, color: "error" }));
            setOpenModal(false)
            setSubmitLoading(false);
        }

    }

    return <>
        <div className="flex flex-col gap-0 bg-white p-0 !pb-0">
            <CustomModel
                rowId={rowId}
                modalInitialValues={initialValues}
                initialValuesValidationSchema={updatPhaseValidationSchema}
                open={openModal}
                setOpen={setOpenModal}
                setModelData={setModelData}
                modelData={modelData}
                handleModelValues={handleUpdate}
                isLoadingBtn={submitLoading}
            />
            <CustomTable
                rows={piData?.rows}
                columnAlign={piData?.columnAlign}
                columns={piData?.columns}
                TableData={piData?.TableData}
                handleNavigate={handleNavigateToDetails}
                loading={loading}
                handleTableFilters={handleTableFilters}
                handleChangePage={handlePagination}
                handleAddButton={handleAddButton}
                handleChangeDropDown={handleDropDownChange}
                dropDownValue={phaseID}
                MenuList={phaseList}
                tableFilters={tableFilters}
                currentPage={currentPage}
                totalpage={totalPage}
                isCloumnClick={true}
                handleCellClick={handleCellClick}
            />
        </div>
        {openDrawer ? <ListDrawer open={openDrawer} toggleDrawer={() => setOpenDrawer(!openDrawer)} listData={phaseListData || []} title="Phase List"/> : null}
        <CustomSnackbar open={snackbar?.value} setOpen={setSnackbar} color={snackbar?.color} message={snackbar?.message} />
    </>
}

