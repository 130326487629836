import { Typography } from "@mui/material"
import { CustomSwitch } from "../custom-switch/CustomSwitch"
import SkeletonLoader from "../../utilites/SkeltonLoader";


export const RolesCard = ({ loading, disableChip, rolesCardData, selected, handleSwitch, handleRootSwitch, error }) => {
    return (loading ? <SkeletonLoader rowCount={2} isTable={false} width={'100%'} height={100} /> : rolesCardData?.map((role, index) => (
        <div className='rounded-xl bg-white border border-gray-200  w-100' key={index}>
            <div className="flex justify-content-between gap-2 items-center pl-3 pr-3 pt-3">
                <Typography color={"secondary"} className="text-sm md:text-base" variant="h6">{role?.title}{role?.required && <span className="text-danger">*</span>}</Typography>
                <CustomSwitch label={"Enable All"} disableChip={disableChip} toggle={role?.toggle} handleSwitch={handleRootSwitch} index={index} />
            </div>
            <div className="grid lg:grid-cols-4 sm:grid-cols-1 gap-3 pl-3 pr-3">
                {role?.subCard?.map((subRole, subIndex) => (
                    <CustomSwitch disableChip={disableChip || subRole?.disabled} index={subIndex} rootIndex={index} key={subIndex} toggle={selected?.findIndex(op => op.id == subRole?.id) > -1} handleSwitch={handleSwitch} label={<div className="pl-2">
                        <Typography variant="h6" className="capitalize">{subRole?.label}</Typography>
                        <Typography variant="body1" color="secondary">{subRole?.name}</Typography>
                    </div>} />
                ))}
            </div>
            {error && <Typography className="p-3" color="error">{error}</Typography>}
        </div>
    )))
}