import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { clearSnackbar, clearSnackbarMessage } from '../../store/reducers/snackBar';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const CustomSnackbar = ({ open, message, color, closeSnackbar }) => {
  const dispatch = useDispatch();
  // Check if the message is an array or a string
  let formattedMessage;
  if (Array.isArray(message)) {
    // Extract values from the objects in the array
    const messagesArray = message.map(obj => {
      return Object.values(obj).join('\n');
    });

    // Join the messages and format them as JSX
    const joinedMessage = messagesArray.join('\n');
    formattedMessage = joinedMessage.split('\n').map((msg, index) => (
      <div key={index}>{msg}</div>
    ));
  } else if (typeof message == "string") {
    formattedMessage = <div>{message}</div>;
  }
  else {
    formattedMessage = <></>   //for handling {} object which throwing error 
  }

  return (
    <Stack spacing={2}>
      <Snackbar
        open={open}
        onClick={closeSnackbar}
        autoHideDuration={2000}
        transitionDuration={300}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        className="max-w-[90%] sm:max-w-[60%] md:max-w-[50%]"
        onClose={() =>{ 
          dispatch(clearSnackbar());
          setTimeout(() => {
          dispatch(clearSnackbarMessage())
        }, 100);}}
        
      >
        <Alert
          severity={color}
          sx={{ width: "100%", color: "white", fontSize: 16 }}
        >
          {formattedMessage}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

CustomSnackbar.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        field: PropTypes.string,
        message: PropTypes.string
      })
    )
  ]),
  color: PropTypes.string,
  closeSnackbar: PropTypes.func,
};
