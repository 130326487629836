
import httpService from "./httpService";

const TrailsManagementApi = {
    // get trails api
    getAllRequestedTrials: (limit, page, dataFilter) => httpService.get('getAllRequestedTrials', {
        params: {
            limit,
            page,
            searchData: dataFilter?.search,
            status: dataFilter?.status
        }
    }),

    getAllPiData: (dataFilter, id) => httpService.get(`getTrialPi/${id}`, {
        params: {
            searchData: dataFilter?.piSearch,
        }
    }),
    getAllFormData: (dataFilter, id) => httpService.get(`getTrialForms/${id}`, {
        params: {
            searchData: dataFilter?.formSearch,
        }
    }),

    updateRequest: (data) => httpService.post(`updateRequest`, data),

    getTrialHistory: (id) => httpService.get(`getTrialHistory/${id}`),

}

export default TrailsManagementApi;