import Drawer from '@mui/material/Drawer';
import PropTypes from "prop-types";

export default function CustomDrawer({ open, toggleDrawer, children }) {

    return (
        <Drawer anchor='right' sx={{ zIndex: 1300,'& .MuiDrawer-paperAnchorRight':{maxWidth:"100%"} }} onClose={toggleDrawer} open={open} >
            {children}
        </Drawer>
    );
}

CustomDrawer.proptypes = {
    open: PropTypes.bool,
    toggleDrawer: PropTypes.func,
    children: PropTypes.node
}

