import React from "react";

export const TrailIcon = () => {
    return (

        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="1">
                <path d="M14 6.19355V4.64516C14 3.79123 13.3023 3.09677 12.4444 3.09677H11.6667V1.93548C11.6667 0.868258 10.7944 0 9.72222 0H4.27778C3.20561 0 2.33333 0.868258 2.33333 1.93548V3.09677H1.55556C0.697667 3.09677 0 3.79123 0 4.64516V6.19355C0 7.04748 0.697667 7.74194 1.55556 7.74194V18.5806C1.55556 21.569 3.99778 24 7 24C10.0022 24 12.4444 21.569 12.4444 18.5806V7.74194C13.3023 7.74194 14 7.04748 14 6.19355ZM10.1111 1.93548V3.09677H7.77778V1.54839H9.72222C9.9365 1.54839 10.1111 1.72181 10.1111 1.93548ZM3.88889 1.93548C3.88889 1.72181 4.0635 1.54839 4.27778 1.54839H6.22222V3.09677H3.88889V1.93548ZM10.8889 18.5806C10.8889 20.7151 9.14433 22.4516 7 22.4516C4.85567 22.4516 3.11111 20.7151 3.11111 18.5806V7.74194H10.8889V18.5806ZM11.6667 6.19355H2.33333H1.55556V4.64516H3.11111H10.8889H12.4444L12.4452 6.19355H11.6667Z" fill="white" />
                <path d="M6.74507 21.6098C8.25878 21.6098 9.49014 20.384 9.49014 18.8781V11.8534C9.49014 11.6466 9.4074 11.4476 9.26034 11.3012C9.11328 11.1553 8.91368 11.0729 8.70584 11.0729L8.14937 11.0733C7.65369 11.0733 7.16389 11.1525 6.69331 11.3086C6.38272 11.4113 6.05959 11.4636 5.73214 11.4636H4.78431C4.35137 11.4636 4 11.8132 4 12.244V18.8781C4 20.384 5.23136 21.6098 6.74507 21.6098ZM5.56861 13.0245H5.73214C6.22821 13.0245 6.7184 12.9453 7.18899 12.7892C7.42702 12.7104 7.67212 12.6612 7.92153 12.6425V18.8781C7.92153 19.5235 7.39369 20.0488 6.74507 20.0488C6.09645 20.0488 5.56861 19.5235 5.56861 18.8781V13.0245ZM23.9171 13.846C23.7229 13.46 23.2516 13.3039 22.8645 13.4967L21.6469 14.1031L20.0783 13.3227V11.1658L21.2136 10.6011C21.601 10.4084 21.7579 9.93968 21.5645 9.55374C21.3704 9.16779 20.899 9.01169 20.512 9.20447L19.294 9.81051L17.7254 9.03004V7.97873L18.5524 7.43006C18.9128 7.19123 19.01 6.70656 18.77 6.34793C18.5301 5.9893 18.043 5.89174 17.6826 6.13134L16.941 6.62343L16.1995 6.13134C15.8399 5.89213 15.3524 5.9893 15.112 6.34793C14.8717 6.70656 14.9693 7.19123 15.3297 7.43006L16.1567 7.97873V9.02965L14.2372 9.98456C13.9717 10.1172 13.8038 10.3873 13.8038 10.6831V13.805C13.8038 14.1008 13.9717 14.3708 14.2372 14.5031L16.1567 15.458V17.6149L14.5881 18.3954L14.1548 18.1796C13.7674 17.9872 13.2964 18.1429 13.1023 18.5288C12.9085 18.9144 13.0658 19.3831 13.4532 19.5762L14.2375 19.9665C14.4583 20.0761 14.7183 20.0761 14.9391 19.9665L16.941 18.9702L18.5097 19.7507V21.2195C18.5097 21.6503 18.861 22 19.294 22C19.7269 22 20.0783 21.6503 20.0783 21.2195V19.7507L21.9979 18.7958C22.2633 18.6635 22.4312 18.3934 22.4312 18.0976V15.458L23.5665 14.8934C23.9535 14.7006 24.1108 14.2319 23.9171 13.846ZM15.3724 11.1654L16.941 10.3849L18.5097 11.1654V13.3223L16.941 14.1027L15.3724 13.3223V11.1654ZM20.8626 17.6153L19.294 18.3958L17.7254 17.6153V15.4584L19.294 14.678L20.8626 15.4584V17.6153Z" fill="white" />
            </g>
        </svg>

    );
}
